import {
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import {
  GetSnifferAllowedFromOrg,
  GetSnifferEndpointIdFromOrg,
  GetSnifferSaveImgFromOrg,
  Organisation,
  OrganisationSnifferConfig,
} from "../../models/organisation";
import React, { useEffect, useState } from "react";

import { Button } from "./../Utils/Button";
import Config from "../../config";
import { Dialog } from "../Utils/Dialog";
import { DialogVariant } from "./OrganisationManagement";
import { Endpoint } from "../../models/endpoint";
import { GlobalContext } from "../../globalContext";
import { INFINITE_PLACEHOLDER } from "../../utils/forms";
import { Sniffer } from "../../models/utils";
import { SnifferService } from "../../api/sniffer";
import { SwitchElement } from "../Utils/SwitchElement";
import classNames from "classnames";
import clone from "clone";
import { v4 as uuidv4 } from "uuid";

const snifferService = new SnifferService(Config.apiUrl);

const DEFAULT_ORG: Organisation = {
  id: 0,
  creationDate: 0,
  name: "",
  token: "",
  banned: false,
  dailyRequestsUsed: 0,
  totalRequestsUsed: 0,
  sniffersConfig: [],
};

interface Props {
  handleClose: () => void;
  open: boolean;
  onConfirm: Function;
  item?: Organisation;
  variant: DialogVariant;
  title: string;
  confirmButtonText: string;
  endpoints: Endpoint[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formElement: {
      margin: "16px 0px",
    },
    confirmButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
    },
    snifferListTitle: {
      margin: "16px 0px",
      fontSize: "16px",
      fontWeight: theme.typography.fontWeightBold,
    },
    tableColumn: {
      textAlign: "left",
      padding: "8px",
    },
    tokenEditionButton: {
      marginTop: 30,
      marginRight: 30,
    },
    endpointColumn: {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "80px!important",
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "140!important",
      },
    },
  }),
);

export const CreateAndEditOrganisationDialog: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { handleClose, open, onConfirm, item, variant, title, confirmButtonText, endpoints } = props;

  const { setFeedback, envSettings } = React.useContext(GlobalContext);

  DEFAULT_ORG.maxRequestsPerDay = envSettings.organisationCreationConfig.dailyMaxRequests;
  DEFAULT_ORG.maxRequestsTotal = envSettings.organisationCreationConfig.totalMaxRequests;
  DEFAULT_ORG.expirationHours = envSettings.organisationCreationConfig.picturesExpirationHours;

  const [organisation, setOrganisation] = useState<Organisation>(item ? clone(item) : DEFAULT_ORG);

  const [sniffers, setSniffers] = useState<Sniffer[]>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState<boolean>(false);
  const [openGenerateDialog, setOpenGenerateDialog] = React.useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);

  useEffect(() => {
    snifferService
      .getAll()
      .then((allSniffers: Sniffer[]) => setSniffers(allSniffers))
      .catch(() => {
        setFeedback({
          variant: "error",
          message: `Failed to get the list of sniffers`,
          horizontal: "right",
        });
      });
  }, [setFeedback]);

  const confirm = () => {
    onConfirm(organisation);
  };

  const cancel = () => {
    handleClose();
  };

  const onChangeSnifferList = (sniffer: Sniffer, allowed: boolean, saveImg: boolean, endpointId?: number) => {
    const organisationCopy = clone(organisation);
    const snifferList: OrganisationSnifferConfig[] = organisationCopy.sniffersConfig || [];

    const index = snifferList.findIndex((i: OrganisationSnifferConfig) => i.snifferKey === sniffer.key);
    // The sniffer is not in the list so it must be a new allowed one. Create
    // the entity and include it in the list.
    if (index === -1) {
      snifferList.push({
        allowed: allowed,
        saveImg: saveImg,
        snifferID: sniffer.id,
        snifferName: sniffer.name,
        snifferKey: sniffer.key,
        endpointId: endpointId,
      });
    } else {
      snifferList[index].allowed = allowed;
      snifferList[index].saveImg = saveImg;
      snifferList[index].endpointId = endpointId;
    }

    setOrganisation({
      ...organisation,
      sniffersConfig: snifferList,
    });
  };

  const clickOpenDialog = (checked: boolean) => {
    setShowConfirmationDialog(checked);
    setOrganisation({ ...organisation, banned: checked });
  };

  const generateToken = () => {
    const newToken = uuidv4();
    setOrganisation({ ...organisation, token: newToken });
  };

  return (
    <>
      <Dialog
        title={title}
        action={confirmButtonText}
        onConfirm={confirm}
        onClose={handleClose}
        onCancel={cancel}
        open={open}
        large={variant === DialogVariant.EDIT}
        preventAutoClose={true}
      >
        <Grid container spacing={3}>
          <Grid item sm={12} md={variant === DialogVariant.EDIT ? 6 : 12}>
            <TextField
              fullWidth={true}
              className={classes.formElement}
              label="Name"
              value={organisation.name}
              onChange={(e) => setOrganisation({ ...organisation, name: e.target.value })}
            />

            <TextField
              fullWidth={true}
              className={classes.formElement}
              label="Max daily requests"
              type="number"
              placeholder={INFINITE_PLACEHOLDER}
              InputLabelProps={{
                shrink: true,
              }}
              value={organisation.maxRequestsPerDay}
              onChange={(e) =>
                setOrganisation({
                  ...organisation,
                  maxRequestsPerDay: e.target.value ? Number(e.target.value) : undefined,
                })
              }
            />

            <TextField
              fullWidth={true}
              className={classes.formElement}
              label="Max requests in total"
              type="number"
              placeholder={INFINITE_PLACEHOLDER}
              InputLabelProps={{
                shrink: true,
              }}
              value={organisation.maxRequestsTotal}
              onChange={(e) =>
                setOrganisation({
                  ...organisation,
                  maxRequestsTotal: e.target.value ? Number(e.target.value) : undefined,
                })
              }
            />

            <TextField
              fullWidth={true}
              className={classes.formElement}
              label="Expiration hours for pictures"
              type="number"
              placeholder={INFINITE_PLACEHOLDER}
              InputLabelProps={{
                shrink: true,
              }}
              value={organisation.expirationHours}
              onChange={(e) =>
                setOrganisation({
                  ...organisation,
                  expirationHours: e.target.value ? Number(e.target.value) : undefined,
                })
              }
            />

            {variant === DialogVariant.EDIT && (
              <div className={classes.formElement}>
                <SwitchElement
                  title="Banned"
                  checked={organisation.banned}
                  onChange={(checked) => clickOpenDialog(checked)}
                />
                {edit ? (
                  <TextField
                    fullWidth={true}
                    className={classes.formElement}
                    label="Token"
                    type="string"
                    placeholder={INFINITE_PLACEHOLDER}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={organisation.token}
                    onChange={(e) => setOrganisation({ ...organisation, token: e.target.value })}
                  />
                ) : (
                  <>
                    <Typography color="textPrimary" className={classes.snifferListTitle}>
                      Token:
                    </Typography>
                    <Typography color="textPrimary">{organisation.token}</Typography>
                  </>
                )}
                <div>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => setOpenGenerateDialog(true)}
                    className={classNames(classes.confirmButton, classes.tokenEditionButton)}
                  >
                    Generate
                  </Button>
                  {!edit && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setOpenEditDialog(true)}
                      className={classNames(classes.confirmButton, classes.tokenEditionButton)}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Grid>

          {variant === DialogVariant.EDIT && (
            <Grid item sm={12} md={6}>
              <Typography color="textPrimary" className={classes.snifferListTitle}>
                Sniffer list:
              </Typography>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableColumn}>Endpoint</TableCell>
                    <TableCell className={classes.tableColumn}>Allowed</TableCell>
                    <TableCell className={classes.tableColumn}>Save images</TableCell>
                    <TableCell className={classNames(classes.tableColumn, classes.endpointColumn)}>Endpoint</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sniffers.map((s) => {
                    const allowed = GetSnifferAllowedFromOrg(s, organisation.sniffersConfig);
                    const saveImg = GetSnifferSaveImgFromOrg(s, organisation.sniffersConfig);
                    const endpointId = GetSnifferEndpointIdFromOrg(s, organisation.sniffersConfig);
                    return (
                      <TableRow key={s.name}>
                        <TableCell component="th" scope="row" className={classes.tableColumn}>
                          {s.name}
                        </TableCell>
                        <TableCell className={classes.tableColumn}>
                          <Checkbox
                            checked={allowed}
                            onChange={() => onChangeSnifferList(s, !allowed, saveImg, endpointId)}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell className={classes.tableColumn}>
                          <Checkbox
                            checked={saveImg}
                            onChange={() => onChangeSnifferList(s, allowed, !saveImg, endpointId)}
                            disabled={!allowed}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell className={classNames(classes.tableColumn, classes.endpointColumn)}>
                          <Select
                            fullWidth={true}
                            label="Endpoint"
                            value={endpointId || 0}
                            onChange={(e) => {
                              // Handle 0 as undefined because the select cannot
                              // handle undefined values in MenuItems.
                              const value = Number(e.target.value);
                              onChangeSnifferList(s, allowed, saveImg, value === 0 ? undefined : value);
                            }}
                          >
                            <MenuItem key={0} value={0}>
                              <em>Default</em>
                            </MenuItem>
                            {endpoints.map((item) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name} ({item.mode})
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </Dialog>
      {openGenerateDialog && (
        <Dialog
          title={"Generate organisation token"}
          action={"Generate"}
          onConfirm={generateToken}
          onClose={() => setOpenGenerateDialog(false)}
          open={openGenerateDialog}
          message={"generate a new token for the organisation"}
        ></Dialog>
      )}
      {openEditDialog && (
        <Dialog
          title={"Edit token"}
          action={"Edit"}
          onConfirm={() => setEdit(true)}
          onClose={() => setOpenEditDialog(false)}
          open={openEditDialog}
          message={"edit this token"}
        ></Dialog>
      )}
      {showConfirmationDialog && (
        <Dialog
          title={"Ban Organisation"}
          action={"Ban"}
          onConfirm={() => setOrganisation({ ...organisation, banned: true })}
          onClose={() => setShowConfirmationDialog(false)}
          onCancel={() => setOrganisation({ ...organisation, banned: false })}
          open={showConfirmationDialog}
          message={`ban ${item?.name}`}
        ></Dialog>
      )}
    </>
  );
};
