import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import { RejectionReasons, RejectionReasonsList } from "../models/myface";

import { Button } from "../../../components/Utils/Button";
import React from "react";

interface RejectionReasonsDialogProps {
  open: boolean;
  onClose: (values?: RejectionReasons[]) => void;
}

export const RejectionReasonsDialog: React.FC<RejectionReasonsDialogProps> = (props) => {
  const { onClose, open } = props;

  const [values, setValues] = React.useState<RejectionReasons[]>([]);
  const [showError, setShowError] = React.useState(false);

  const handleCancel = () => {
    setShowError(false);
    setValues([]);
    onClose();
  };

  const handleOk = () => {
    if (values.length === 0) {
      setShowError(true);
    } else {
      onClose(values);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const reason = event.target.value as RejectionReasons;
    if (checked && !values.includes(reason)) {
      setValues([...values, reason]);
    } else if (!checked) {
      setValues([...values?.filter((v) => v !== reason)]);
    }
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={open} keepMounted>
      <DialogTitle>Rejection Reasons</DialogTitle>
      <DialogContent dividers>
        <FormGroup>
          {RejectionReasonsList.map((option) => (
            <FormControlLabel
              key={`rejection-reason-${option}`}
              control={<Checkbox value={option} checked={values.includes(option)} onChange={onChange} name={option} />}
              label={option}
            />
          ))}
        </FormGroup>
        {showError && values.length === 0 && (
          <Typography align="right" variant="caption" color="error">
            Select at least one
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} variant="contained" color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} variant="contained" color="primary">
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};
