import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Histogram } from "./Index";
import { Card as MaterialUICard } from "@material-ui/core";
import React from "react";

interface Props {
  img: string;
  width?: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      margin: "0px 0px 20px",
      padding: "25px 20px 0px 20px",
    },
  }),
);

export const HistogramCard: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <MaterialUICard className={classes.card}>
      <Histogram img={props.img} width={props.width} />
    </MaterialUICard>
  );
};
