import { ExpectedBrightness, ExpectedLocation } from "../../models/log";
import { FaceResult, PhotoInfo, TinyFaceInfo } from "../../models/prediction";
import { Grid, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { ImageExtensionFormat, Picture } from "../../models/image";
import React, { useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { validateImageDimensions, validateImageFaces } from "../../validations/upload";

import { Button } from "../Utils/Button";
import Config from "../../config";
import { Consent } from "../Consent";
import Device from "../../config/device";
import { DropzoneArea } from "material-ui-dropzone";
import { GlobalContext } from "../../globalContext";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { PredictionResult as PredictionResultComponent } from "./PredictionResult";
import Select from "@material-ui/core/Select";
import SendIcon from "@material-ui/icons/SendRounded";
import { TensorflowPrediction } from "../../api/tensorflow_prediction";
import TextField from "@material-ui/core/TextField";
import { TinyBbox } from "../../react-app-env";
import { TinyFace } from "../../utils/tinyface";
import { UploadValidationError } from "../../models/upload";
import { croppedPicture } from "../../utils/cropping";
import { getFaces } from "../../utils/faceDetection";
import { imageSize } from "../../components/Log/LogDetail/LogDetail";
import { readFiles } from "../../utils/upload";

const service = new TensorflowPrediction(Config.apiUrl);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "40px",
      marginBottom: "50px",
    },
    image: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: "20px",
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      maxWidth: "30vh",
      maxHeight: "30vh",
      height: "auto",
    },
    imageContainer: {
      marginTop: "40px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    dragAndDropContainer: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10vw",
        marginRight: "10vw",
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: "60vh",
        marginRight: "60vh",
      },
      [theme.breakpoints.between("sm", "lg")]: {
        marginLeft: "20vh",
        marginRight: "20vh",
      },
    },
    infoContainer: {
      marginTop: "2vh",
      [theme.breakpoints.down("sm")]: {
        padding: "5px 10px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "30px 60px",
      },
      [theme.breakpoints.between("sm", "lg")]: {
        padding: "30px 60px",
      },
      textAlign: "center",
      fontSize: "20px",
      backgroundColor: theme.palette.primary.light,
      borderRadius: "20px",
      border: "2px solid",
      borderColor: theme.palette.primary.main,
    },
    buttonsPanel: { textAlign: "center", paddingTop: "20px" },
    sendButton: {
      marginTop: theme.spacing(3),
    },
    form: {
      marginTop: theme.spacing(6),
    },
    list: {
      listStyle: "square",
    },
  }),
);

// Follow this guide https://yuvaleros.github.io/material-ui-dropzone/#section-theme
// to override the Dropzone component style
const dropZoneTheme = (createMuiTheme as any)({
  overrides: {
    MuiDropzonePreviewList: {
      root: { margin: "0 auto" },
      image: {
        maxWidth: "auto !important",
      },
    },
  },
});

const filesLimit = 1;

export const UploadPhoto: React.FC = () => {
  const [image, setImage] = useState<string>("");
  const [face, setFace] = useState<FaceResult>();
  const [expectedResult, setExpectedResult] = useState<string>("");
  const [tag, setTag] = useState<string>("");
  const [imageExtensionFormat, setImageExtensionFormat] = useState<ImageExtensionFormat>(ImageExtensionFormat.Unknown);
  const { panelConfigurationData, consent, setFeedback } = React.useContext(GlobalContext);
  const {
    tinyFaceDetectorThreshold,
    maxFaces,
    extensionFormats,
    maxFileSize,
    minFileSize,
    minImageHeight,
    minImageWidth,
    maxImageHeight,
    maxImageWidth,
    selectedInputPicture,
    relativeMarginPercentageCropping,
  } = panelConfigurationData.uploadConfiguration;
  const consentAgreed = panelConfigurationData.consentConfiguration.showConsentCheckbox ? consent.accept : true;

  const conditions = [
    `Our valid image formats: ${
      panelConfigurationData.uploadConfiguration.extensionFormats.length === 0
        ? "Any"
        : panelConfigurationData.uploadConfiguration.extensionFormats.map((value) => value.toUpperCase())
    }`,
    `Pictures will not have resolutions lower than 
    ${panelConfigurationData.uploadConfiguration.minImageWidth}x${panelConfigurationData.uploadConfiguration.minImageHeight} 
    or greater than ${panelConfigurationData.uploadConfiguration.maxImageWidth}x${panelConfigurationData.uploadConfiguration.maxImageHeight}`,
    `Files size will not be lower than ${panelConfigurationData.uploadConfiguration.minFileSize}KB 
    or greater than ${panelConfigurationData.uploadConfiguration.maxFileSize}KB`,
    `Only ${panelConfigurationData.uploadConfiguration.maxFaces} face${
      panelConfigurationData.uploadConfiguration.maxFaces > 1 ? "s" : ""
    } allowed`,
    "Try to take the photo in a light area",
  ];

  const emptyBBox: TinyBbox = { x: 0, y: 0, width: 0, height: 0 };

  const getPredictions = async (
    image: string,
    selectedInputPicture: Picture,
    croppedImage: string,
    tinyFaceInfo: TinyFaceInfo,
    croppedBBox?: TinyBbox,
  ) => {
    const originalPhoto: PhotoInfo = {
      image: image,
      tinyFaceInfo: tinyFaceInfo,
    };
    const croppedPhoto: PhotoInfo = {
      image: croppedImage,
      tinyFaceInfo: {
        bBox: croppedBBox ? croppedBBox : emptyBBox,
        score: 0,
      },
    };

    service
      .checkImg({
        originalPhoto: originalPhoto,
        device: { ...Device, cameraInfo: undefined },
        fromCamera: 0,
        expectedResult: expectedResult,
        tag: tag,
        croppedPhoto: croppedPhoto,
        pictureUsed: selectedInputPicture,
        imageExtensionFormat: imageExtensionFormat,
        expectedLocation: ExpectedLocation.Unknown,
        expectedBrightness: ExpectedBrightness.Unknown,
      })
      .then((res) => {
        setFace({
          id: res.id,
          img: selectedInputPicture === Picture.OriginalPicture ? image : croppedImage,
          modelPredictionResultList: res.modelPredictionResultList,
          loading: false,
          formulaResult: res.formula,
          result: res.result,
        });
      })
      .catch((res) => {
        setFace({
          img: selectedInputPicture === Picture.OriginalPicture ? image : croppedImage,
          loading: false,
          formulaResult: [],
          error: res.message,
        });
      });
  };

  const generateValidationFeedback = (error: UploadValidationError, bboxes?: TinyBbox[], name?: string): string => {
    switch (error) {
      case UploadValidationError.NoFace:
        return "No face detected";
      case UploadValidationError.TooManyFaces:
        return `Too many faces, detected: ${bboxes?.length}. Max allowed: ${maxFaces}.`;
      case UploadValidationError.NotAnImage:
        return `File ${name} is not an image.`;
      case UploadValidationError.InvalidFormat:
        return `File ${name}, has an invalid format. Allowed formats are: ${extensionFormats}.`;
      case UploadValidationError.FileSmallSize:
        return `${name} file size too small. Min size: ${minFileSize} KB`;
      case UploadValidationError.FileLargeSize:
        return `${name} file size too large. Max size: ${maxFileSize} KB.`;
      case UploadValidationError.ImageLargeWidth:
        return `Image width too large. Max width ${maxImageWidth}.`;
      case UploadValidationError.ImageSmallWidth:
        return `Image width too small. Min width ${minImageWidth}.`;
      case UploadValidationError.ImageLargeHeight:
        return `Image height too large. Max height ${maxImageHeight}.`;
      case UploadValidationError.ImageSmallHeight:
        return `Image height too small. Min height ${minImageHeight}.`;
      case UploadValidationError.Unknown:
      default:
        return `File ${name}, not valid for unknown reason`;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();

    const dimensionValidationErrors = await validateImageDimensions(
      image,
      maxImageWidth,
      minImageWidth,
      maxImageHeight,
      minImageHeight,
    );

    if (dimensionValidationErrors.length > 0) {
      dimensionValidationErrors.forEach((error) => {
        setFeedback({
          variant: "error",
          message: generateValidationFeedback(error),
        });
      });
      return;
    }

    const tinyFaceInfos = await getFaces(image, tinyFaceDetectorThreshold);

    const facesValidationError = validateImageFaces(
      tinyFaceInfos.map((i) => {
        return i.bBox;
      }),
      maxFaces,
    );

    if (facesValidationError !== undefined) {
      setFeedback({
        variant: "error",
        message: generateValidationFeedback(
          facesValidationError,
          tinyFaceInfos.map((i) => {
            return i.bBox;
          }),
          undefined,
        ),
      });
      return;
    }

    const originalImg = image;
    let cropImg = "";

    let croppedBbox: TinyBbox = emptyBBox;

    if (selectedInputPicture === Picture.CroppedPicture && tinyFaceInfos.length > 0) {
      const canvasOriginal = document.createElement("canvas");
      const canvasCropped = document.createElement("canvas");
      const imgSize = await imageSize(originalImg);
      const resultRescale = {
        rescaledBBox: tinyFaceInfos[0].bBox,
        pictureWidth: imgSize.width,
        pictureHeight: imgSize.height,
      };
      const cropped = await croppedPicture(
        originalImg,
        resultRescale,
        canvasOriginal,
        canvasCropped,
        relativeMarginPercentageCropping * 2,
        relativeMarginPercentageCropping,
        relativeMarginPercentageCropping,
        relativeMarginPercentageCropping,
      );
      const croppedImage =
        imageExtensionFormat === ImageExtensionFormat.PNG
          ? canvasCropped.toDataURL("image/png")
          : canvasCropped.toDataURL("image/jpeg");
      cropImg = croppedImage;
      croppedBbox = cropped.croppedBbox;
    }

    if (tinyFaceInfos.length > 0) {
      getPredictions(
        originalImg,
        selectedInputPicture,
        cropImg,
        { bBox: tinyFaceInfos[0].bBox, score: tinyFaceInfos[0].score },
        croppedBbox !== emptyBBox ? croppedBbox : undefined,
      );
    } else {
      setFeedback({
        variant: "error",
        message: generateValidationFeedback(UploadValidationError.NoFace),
      });
    }
    setFace({
      id: -1,
      img: selectedInputPicture === Picture.OriginalPicture ? image : cropImg,
      loading: true,
      formulaResult: [],
    });
  };

  const mimeTypes = extensionFormats.map((format) => `image/${format}`);

  const onFileRejected = (file: File) => {
    const formatValidationError = mimeTypes.some((type) => type === file.type)
      ? undefined
      : extensionFormats.length === 0
      ? UploadValidationError.NotAnImage
      : UploadValidationError.InvalidFormat;

    if (formatValidationError) {
      setFeedback({
        variant: "error",
        message: generateValidationFeedback(formatValidationError, undefined, file.name),
      });
      return;
    }

    const fileSizeValidationError =
      file.size > maxFileSize * 1000
        ? UploadValidationError.FileLargeSize
        : file.size < minFileSize * 1000
        ? UploadValidationError.FileSmallSize
        : UploadValidationError.Unknown;

    setFeedback({
      variant: "error",
      message: generateValidationFeedback(fileSizeValidationError, undefined, file.name),
    });
  };

  const onDropRejected = (files: File[]) => {
    if (files.length > filesLimit) {
      setFeedback({
        variant: "error",
        message: `Too many files at the same time. Limit ${filesLimit}`,
      });
      return;
    }
    files.forEach((file) => {
      onFileRejected(file);
    });
  };

  const onChange = (files: File[]) => {
    readFiles(files).then((filesInfo) => {
      // Take first file in the list if it exists.
      const image = !filesInfo.length ? "" : filesInfo[0].base64content;
      const format = !filesInfo.length ? ImageExtensionFormat.Unknown : filesInfo[0].format;
      setImage(image);
      setImageExtensionFormat(format);
    });
  };

  const classes = useStyles({ acceptConsent: consentAgreed });

  return (
    <TinyFace>
      <Grid container className={classes.root}>
        {!face ? (
          <Grid container className={classes.dragAndDropContainer}>
            {consentAgreed && (
              <Grid container item direction="column" alignItems="center" justify="center">
                <Grid container item>
                  <MuiThemeProvider theme={dropZoneTheme}>
                    <DropzoneArea
                      dropzoneText={"Drag and drop a photo here!"}
                      filesLimit={filesLimit}
                      acceptedFiles={extensionFormats.length === 0 ? ["image/*"] : mimeTypes}
                      dropzoneProps={{ minSize: minFileSize * 1000 }}
                      maxFileSize={maxFileSize * 1000}
                      showFileNames={true}
                      showAlerts={false}
                      onChange={onChange}
                      onDropRejected={onDropRejected}
                    />
                  </MuiThemeProvider>
                </Grid>
                <Grid item>
                  <p>Keep in mind a few things:</p>
                  <ul className={classes.list}>
                    {conditions.map((condition, index) => (
                      <li key={index}>{condition}</li>
                    ))}
                  </ul>
                </Grid>
                <Grid item>
                  <div className={classes.sendButton}>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      endIcon={<SendIcon />}
                      disabled={!image || !consentAgreed}
                    >
                      Send
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}

            {image ? (
              <Grid container item direction="column" alignItems="center" justify="center" className={classes.form}>
                <Grid item>
                  <InputLabel htmlFor="age-simple">Expected result</InputLabel>
                  <Select
                    value={expectedResult}
                    onChange={(e) => {
                      setExpectedResult(e.target.value as string);
                    }}
                    style={{ minWidth: "160px" }}
                  >
                    <MenuItem value={"real"}>Real</MenuItem>
                    <MenuItem value={"display_attack"}>Display attack</MenuItem>
                    <MenuItem value={"print_attack"}>Print attack</MenuItem>
                    <MenuItem value={"mask_attack"}>Mask attack</MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <TextField
                    id="standard-name"
                    label="Tag"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <div className={classes.infoContainer}>
                  <Consent />
                </div>
              </Grid>
            )}
          </Grid>
        ) : (
          <PredictionResultComponent face={face} />
        )}
      </Grid>
    </TinyFace>
  );
};
