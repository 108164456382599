export function getMaxHeight(maxHeight: string) {
  let result: number;
  //If maxHeight property is measured in pixels.
  if (maxHeight.includes("px")) {
    result = Number(maxHeight.split("px")[0]);
  }
  //If maxHeight property is a number
  else if (!isNaN(Number(maxHeight))) {
    result = Number(maxHeight);
  }
  //Other cases(0, NaN, percentage etc.)
  else {
    result = 0;
  }

  return result;
}

export function updateCanvasSize(maxHeight: number, canvas?: HTMLCanvasElement, cropped?: boolean) {
  if (canvas && canvas.clientWidth + 150 > window.innerWidth) {
    const ratio = canvas.clientWidth / window.innerWidth;
    canvas.style.maxWidth = `${window.innerWidth - 150}px`;
    canvas.style.maxHeight = `${getMaxHeight(canvas.style.maxHeight) / ratio}px`;
  } else if (canvas && canvas.clientWidth < window.innerWidth) {
    const ratio = window.innerWidth / canvas.clientWidth;
    canvas.style.maxWidth = `${window.innerWidth - 150}px`;
    if (getMaxHeight(canvas.style.maxHeight) * ratio < maxHeight) {
      canvas.style.maxHeight = `${getMaxHeight(canvas.style.maxHeight) * ratio}px`;
    } else {
      let height = 350;
      if (cropped) {
        height = maxHeight;
      }
      canvas.style.maxHeight = `${height}px`;
    }
  }
}
