import { ExpectedBrightness, ExpectedLocation, ExpectedResult } from "../../../../models/log";

import { PredictionFormulaResult } from "../../../../models/prediction";
import React from "react";
import { SessionContentResult } from "../../../../models/aiClient";

interface Props {
  expectedResult?: ExpectedResult;
  expectedLocation?: ExpectedLocation;
  expectedBrightness?: ExpectedBrightness;
  debugMode?: boolean;
  showOverlay?: boolean;
  onScan: (
    res: PredictionFormulaResult,
    img?: string,
    sessionId?: string,
    requestId?: string,
    sessionContent?: SessionContentResult[],
  ) => void;
  onError: (e: Error, img?: string) => void;
}

export const AIClientModule: React.FC<Props> = () => {
  return <div>Scan type selected in the configuration is not available</div>;
};
