import { Divider, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";

import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(5),
    },
    title: {
      color: theme.palette.primary.dark,
    },
    description: {
      color: theme.palette.primary.main,
    },
    children: {
      paddingTop: theme.spacing(5),
    },
  }),
);

export interface SectionProps {
  description: string;
  title: string;
}

const ConfigurationSection: React.FC<SectionProps> = (props) => {
  const { title, description, children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="h6" color="textSecondary">
        {description}
      </Typography>
      <Divider />
      <div className={classes.children}>{children}</div>
    </div>
  );
};

export default ConfigurationSection;
