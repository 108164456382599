import "../index.css";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Button } from "./Utils/Button";
import { Consent } from "./Consent";
import { GlobalContext } from "../globalContext";
import { Link } from "./Utils/Link";
import React from "react";
import { RoleCode } from "../models/role";
import glasses from "../images/glasses.svg";
import lightRoom from "../images/light-room.svg";
import noHeadwear from "../images/no-headwear.svg";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "325px",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "100px 32px 0 32px",
      marginBottom: "50px",
    },
    instructionsContainer: {
      borderTop: "1px solid",
      borderBottom: "1px solid",
      borderTopColor: theme.palette.primary.light,
      borderBottomColor: theme.palette.primary.light,
    },
    instructionsTitle: {
      fontSize: "18px",
      lineHeight: "1.38",
      textAlign: "center",
      fontWeight: theme.typography.fontWeightRegular,
    },
    instructionsList: {
      textAlign: "center",
      display: "flex",
      paddingLeft: "0",
      color: theme.palette.primary.dark,
      "& li": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        fontSize: "16px",
        fontWeight: theme.typography.fontWeightLight,
        lineHeight: "16px",
        "& img": {
          width: "41px",
          height: "41px",
        },
      },
    },
    cameraAccessTitle: {
      fontSize: "14px",
      lineHeight: "17px",
      paddingTop: "20px",
      textAlign: "center",
      fontWeight: theme.typography.fontWeightLight,
    },
    buttonsContainer: {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.primary.light,
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      flexDirection: "column",
      display: "flex",
    },
    link: {
      textDecoration: "none",
    },
  }),
);

export const Page: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { panelConfigurationData, consent, session } = React.useContext(GlobalContext);

  // Check if the main page has to redirect to scan-dev. The condition is the
  // user must be data collector and the option in the setting must be selected.
  if (
    session &&
    session.role === RoleCode.DataCollector &&
    panelConfigurationData.consentConfiguration.hideDataCollectorMainPage
  ) {
    history.push("/scan-dev");
    return null;
  }

  const consentAgreed = panelConfigurationData.consentConfiguration.showConsentCheckbox ? consent.accept : true;

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.instructionsContainer}>
          <p className={classes.instructionsTitle}>To start the check:</p>
          <ul className={classes.instructionsList}>
            <li>
              <img src={lightRoom} alt="Find a light area" />
              Find a light area
            </li>
            <li>
              <img src={noHeadwear} alt="Remove any headwear" />
              Remove any headwear
            </li>
            <li>
              <img src={glasses} alt="Keep your glasses on" />
              Keep your glasses on
            </li>
          </ul>
        </div>
        <div className={classes.buttonsContainer}>
          <p className={classes.cameraAccessTitle}>You will be asked to enable camera access</p>
          {panelConfigurationData.scanConfiguration.showStartButton && (
            <Link to="/scan" disabled={!consentAgreed}>
              <Button variant="contained" disabled={!consentAgreed} fullWidth>
                {panelConfigurationData ? panelConfigurationData.scanConfiguration.startButtonLabel : "START"}
              </Button>
            </Link>
          )}
          {panelConfigurationData.scanConfiguration.showDevButton && (
            <Link to="/scan-dev" disabled={!consentAgreed}>
              <Button variant="contained" disabled={!consentAgreed} fullWidth>
                {panelConfigurationData ? panelConfigurationData.scanConfiguration.devButtonLabel : "DEV MODE"}
              </Button>
            </Link>
          )}
        </div>
        <Consent />
      </div>
    </div>
  );
};
