import { Consent as ConsentValue, GlobalContext } from "../globalContext";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
import { Consent as ConsentStore } from "../api/consent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
import dompurify from "dompurify";
import info from "../images/info-access-camera-page.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userAdvice: {
      marginTop: "-2px",
      fontWeight: theme.typography.fontWeightLight,
      textAlign: "justify",
    },
    checkLabel: {
      fontSize: "0.8em",
    },
  }),
);

const consentStore = new ConsentStore();

export const Consent: React.FC = () => {
  const classes = useStyles();
  const { consent, setConsent, panelConfigurationData } = React.useContext(GlobalContext);

  // This step is done to prevent XSS attacks, since dangerouslySetInnerHTML
  // does not do anything about that. Check this link for more info:
  // https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
  const cleanHTML = dompurify.sanitize(panelConfigurationData.consentConfiguration.consent);

  return (
    <>
      <img src={info} alt="User consent" />
      <p className={classes.userAdvice} dangerouslySetInnerHTML={{ __html: cleanHTML }}></p>
      {panelConfigurationData.consentConfiguration.showConsentCheckbox && (
        <FormControlLabel
          control={
            <Checkbox
              checked={consent.accept}
              onChange={(e, checked) => {
                const consentValue: ConsentValue = { accept: checked };
                consentStore.set(consentValue);
                setConsent(consentValue);
              }}
              color="primary"
            />
          }
          label={
            <span className={classes.checkLabel}>{panelConfigurationData.consentConfiguration.consentCheckbox}</span>
          }
        />
      )}
    </>
  );
};
