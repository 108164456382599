import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import Moment, * as moment from "moment";
import { MyFaceEvent, MyFaceTrackingEvent } from "../../models/myface";
import React, { useEffect, useState } from "react";

import { Button } from "../../../../components/Utils/Button";
import Config from "../../../../config";
import { DateLabel } from "../../../../components/Log/LogTable/DateLabel";
import MomentDurationFormatSetup from "moment-duration-format";
import { MyFaceService } from "../../api/myface";

const useStyles = makeStyles(() =>
  createStyles({
    tableCellNoWrap: {
      whiteSpace: "nowrap",
    },
  }),
);

const myFaceService = new MyFaceService(Config.apiUrl);

interface UserEventsDialogProps {
  userID: string;
  showOnlyErrors?: boolean;
  onClose: () => void;
}

export const UserEventsDialog: React.FC<UserEventsDialogProps> = (props) => {
  const classes = useStyles();
  const { userID, showOnlyErrors, onClose } = props;

  const [events, setEvents] = useState<MyFaceEvent[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<MyFaceEvent[]>(events);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  MomentDurationFormatSetup(moment);

  useEffect(() => {
    if (userID !== "") {
      setLoading(true);
      myFaceService
        .getEvents(userID)
        .then((res) => setEvents(res))
        .catch((e) => setError(e))
        .finally(() => setLoading(false));
    }
  }, [userID]);

  useEffect(() => {
    showOnlyErrors
      ? setFilteredEvents(events.filter((e) => e.event === MyFaceTrackingEvent.FaceCaptureModuleError))
      : setFilteredEvents(events);
  }, [events, showOnlyErrors]);

  const getTimeDiff = (prev: Date, current: Date): string => {
    const then = Moment(prev);
    const now = Moment(current);

    return Moment.duration(now.diff(then)).format();
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="lg" open={userID !== ""} keepMounted>
      <DialogTitle>User events of {userID}</DialogTitle>
      <DialogContent dividers>
        {error ? (
          <Typography align="right" variant="caption" color="error">
            Error loading events data: {error.message}
          </Typography>
        ) : loading ? (
          <>
            <CircularProgress /> Loading
          </>
        ) : filteredEvents.length === 0 ? (
          <Typography>No events</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Data</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Time Diff</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEvents.map((event, i) => (
                  <TableRow key={`user-${userID}-event-${i}`}>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {event.event}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {event.data}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      <DateLabel value={event.date} />
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {i === 0 ? "-" : <Typography>{getTimeDiff(filteredEvents[i - 1].date, event.date)}</Typography>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
