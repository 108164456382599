import { ImageInfo, PredictionResult } from "../models/prediction";

import { ApiBase } from "./base";

export class TensorflowPrediction extends ApiBase {
  checkImg = (img: ImageInfo): Promise<PredictionResult> => {
    return this.fetch<PredictionResult>("predict", {
      method: "POST",
      data: JSON.stringify(img),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
}
