import { Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { AuditLog } from "../../models/audit";
import { AuditService } from "../../api/audit";
import { Conditional } from "../Utils/Conditional";
import Config from "../../config";
import { ConfigurationProps } from "./ConfigurationPanel";
import ConfigurationSection from "./ConfigurationSection";
import ConfigurationSubsection from "./ConfigurationSubsection";
import { DateLabel } from "../Log/LogTable/DateLabel";
import { Sniffer } from "../../models/utils";

const auditService = new AuditService(Config.apiUrl);

const AUDIT_TRAIL_TABLE_SECTION_DESCRIPTION = "Track all the changes made in the configuration panel";

enum AuditTrailSection {
  AuditTrailTable = "Audit Trail table",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "30px",
      minWidth: "100%",
    },
    pagination: {
      float: "left",
    },
    changedField: {
      fontWeight: 600,
      color: theme.palette.primary.contrastText,
    },
    previousChange: {
      color: theme.palette.error.main,
    },
    newChange: {
      color: theme.palette.success.main,
    },
    tableCellNoWrap: {
      whiteSpace: "normal",
    },
    tableWrapper: {
      overflowX: "auto",
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
    },
    tableColumn: {
      padding: "8px",
    },
  }),
);

export const AuditTrailPanel: React.FC<ConfigurationProps<any>> = () => {
  const classes = useStyles();

  const [logs, setLogs] = useState<AuditLog[]>([]);
  const [error, setError] = useState<Error | undefined>();
  const [page, setPage] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  useEffect(() => {
    auditService
      .configLog(page, rowsPerPage)
      .then((res) => {
        setRowCount(res.count);
        setLogs(res.logs);
        setError(undefined);
      })
      .catch((e) => setError(e));
  }, [page, rowsPerPage]);

  return (
    <div className={classes.root}>
      <ConfigurationSection
        title={AuditTrailSection.AuditTrailTable}
        description={AUDIT_TRAIL_TABLE_SECTION_DESCRIPTION}
      >
        <ConfigurationSubsection title="Audit trail table">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Conditional error={error} errorMessage="Failed to load log of configuration">
                <div className={classes.tableWrapper}>
                  <Table stickyHeader className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className={classes.tableColumn}>
                          ID
                        </TableCell>
                        <TableCell align="center" className={classes.tableColumn}>
                          User
                        </TableCell>
                        <TableCell align="center" className={classes.tableColumn}>
                          Table
                        </TableCell>
                        <TableCell className={classes.tableColumn}>Timestamp</TableCell>
                        <TableCell className={classes.tableColumn}>Changed Fields</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logs.map((l) => (
                        <LogRow key={l.eventID} item={l} />
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className={classes.pagination}
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={rowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page",
                    }}
                    onChangePage={(_, p) => setPage(p)}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </Conditional>
            </Grid>
          </Grid>
        </ConfigurationSubsection>
      </ConfigurationSection>
    </div>
  );
};

interface RowProps {
  item: AuditLog;
}

const LogRow: React.FC<RowProps> = (props) => {
  const rowRef = useRef(null);
  const classes = useStyles();
  const endpoint: Sniffer = JSON.parse(props.item.rowData);

  function renderChanges(previousJSON: string, newJSON: string) {
    const prev = JSON.parse(previousJSON);
    const changes = JSON.parse(newJSON);
    return Object.keys(changes).map((key, i) => (
      <ul key={key}>
        {i > 0 && <br />}
        <span className={classes.changedField}>{key + ": "}</span>
        <span className={classes.previousChange}>{prev[key] ? prev[key] : "null"}</span>
        {" => "}
        <span className={classes.newChange}>{changes[key] ? changes[key] : "null"}</span>
      </ul>
    ));
  }

  return (
    <TableRow ref={rowRef}>
      <TableCell className={classes.tableCellNoWrap} align="center" component="th" scope="row">
        {props.item.eventID}
      </TableCell>
      <TableCell className={classes.tableCellNoWrap} align="center" component="th" scope="row">
        {props.item.userEmail || "-"}
      </TableCell>
      <TableCell className={classes.tableCellNoWrap} align="center" component="th" scope="row">
        {props.item.schemaName === "public" ? props.item.tableName : `${props.item.schemaName}.${props.item.tableName}`}
      </TableCell>
      <TableCell>
        <DateLabel value={new Date(props.item.actionTimestamp).getTime() / 1000} />
      </TableCell>
      <TableCell>
        {props.item.tableName === "endpoint_configurations" ? (
          <>
            <span className={classes.changedField}>{"Endpoint ID: " + endpoint.id}</span> <br />
            {renderChanges(props.item.previousFields, props.item.changedFields)}
          </>
        ) : (
          renderChanges(props.item.previousFields, props.item.changedFields)
        )}
      </TableCell>
    </TableRow>
  );
};
