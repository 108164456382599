import { LogPrediction, RGBFASData } from "../../../models/log";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { ModelLabelProps } from "../../../config/modelsConfig";
import { ModelType } from "../../../models/utils";
import React from "react";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    modelPredictionValue: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "14px",
      },
      marginLeft: 6,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.common.black,
    },
    asterisk: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);

export const AntiSpoofingResult: React.FC<ModelLabelProps> = (props) => {
  const classes = useStyles(props);
  if (!props.config || (!props.config.percentage && !props.config.text)) {
    return null;
  }

  const res = props.result as LogPrediction;
  return (
    <>
      <Typography color="textSecondary" className={classes.modelPredictionValue}>
        {props.config.text && res.prediction.toUpperCase()} {props.config.percentage && res.real.toFixed(2)}
        {props.config.percentage && props.modelType !== ModelType.FIQA && "%"}
      </Typography>
      {props.formula.indexOf(props.modelType) >= 0 && <b>&nbsp;*</b>}
      {props.config.percentage && [ModelType.RGBFAS, ModelType.RGBFASV2].indexOf(props.modelType) >= 0 && (
        <RGBFASDataField {...res} />
      )}
    </>
  );
};

// TODO: Remove this field when the model is tested and this is not needed.
const RGBFASDataField: React.FC<LogPrediction> = (props) => {
  const classes = useStyles(props);
  const rgbData = props.data ? (props.data as RGBFASData) : undefined;
  if (!rgbData) {
    return <></>;
  }

  return (
    <>
      {rgbData.embeddingNorm && (
        <Typography color="textSecondary" className={classes.modelPredictionValue}>
          - Norm: {rgbData.embeddingNorm.toFixed(7)}
        </Typography>
      )}
      {rgbData.confidence && (
        <Typography color="textSecondary" className={classes.modelPredictionValue}>
          - Confidence: {rgbData.confidence.toFixed(7)}
        </Typography>
      )}
    </>
  );
};
