import { createStyles, makeStyles } from "@material-ui/core/styles";

import { ModelCellProps } from "../../../../config/modelsConfig";
import { PredictionColor } from "../../../Utils/PredictionColor";
import { PredictionFormulaResult } from "../../../../models/prediction";
import React from "react";
import classNames from "classnames";

const useStyles = makeStyles((theme) =>
  createStyles({
    confidence: {
      color: theme.palette.primary.main,
      marginLeft: "1ch",
    },
    predictionResult: {
      display: "inline-block",
    },
    inFormula: {
      marginLeft: 2,
    },
    notInFormula: {
      marginLeft: 7,
    },
    noResult: {
      width: "100%",
      textAlign: "center",
    },
    noWrap: {
      whiteSpace: "nowrap",
    },
  }),
);

interface Props extends Omit<ModelCellProps, "prediction"> {
  extraValue?: number;
  real?: number;
  prediction?: PredictionFormulaResult;
}

export const PredictionCell: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { extraValue, real, prediction } = props;

  const isInFormula: boolean = props.formula.indexOf(props.modelType) >= 0;

  const formulaHighlightClass: string = classNames(
    classes.predictionResult,
    isInFormula ? classes.inFormula : classes.notInFormula,
  );

  return (
    <>
      {prediction !== null && prediction !== undefined && real !== null && real !== undefined ? (
        <PredictionColor prediction={prediction}>
          <span className={classes.noWrap}>
            <span className={classes.predictionResult}>{real.toFixed(2)} </span>
            <span className={formulaHighlightClass}>{isInFormula && <b>*</b>}</span>
          </span>
          {extraValue !== undefined && extraValue !== null && (
            <span className={classes.confidence}>({extraValue.toFixed(3)})</span>
          )}
        </PredictionColor>
      ) : (
        <div className={classes.noResult}>-</div>
      )}
    </>
  );
};
