import { CroppingKey, CroppingResult, MTCNNResult, ModelPredictionType, ModelResultType } from "../models/log";
import { ModelType, TimeStruct } from "../models/utils";

import { AgeEstimationDetails } from "../components/Log/LogDetail/ModelDetails/AgeEstimationDetails";
import { AgeResult } from "../components/TensorflowPrediction/Results/AgeResult";
import { AgeTableCell } from "../components/Log/LogTable/ModelCells/AgeCell";
import { AntiSpoofingCell } from "../components/Log/LogTable/ModelCells/AntiSpoofingCell";
import { AntiSpoofingDetails } from "../components/Log/LogDetail/ModelDetails/AntiSpoofingDetails";
import { AntiSpoofingResult } from "../components/TensorflowPrediction/Results/AntiSpoofingResult";
import { BiologicalSex } from "../components/TensorflowPrediction/Results/BiologicalSex";
import { BiologicalSexCell } from "../components/Log/LogTable/ModelCells/BiologicalSexCell";
import { BiologicalSexDetails } from "../components/Log/LogDetail/ModelDetails/BiologicalSexDetails";
import { FIQADetails } from "../components/Log/LogDetail/ModelDetails/FIQADetails";
import { IDRnDDetails } from "../components/Log/LogDetail/ModelDetails/IDRnDDetails";
import { IdRnDCell } from "../components/Log/LogTable/ModelCells/IdRnDCell";
import { PredictionResultConfig } from "../models/config";
import { RGBFASDetails } from "../components/Log/LogDetail/ModelDetails/RGBFASDetails";
import React from "react";

interface Components {
  Cropping: CroppingKey | CroppingKey[];
  Details: React.FC<ModelResultProps>;
  Cell: React.FC<ModelCellProps>;
  Prediction: {
    Component: React.FC<ModelLabelProps>;
    Position: "prediction" | "other";
  };
}

export interface ModelResultProps {
  modelType: ModelType;
  modelResult: ModelResultType;
  cropping: CroppingResult;
  mtcnn: MTCNNResult;
  timeDetail: TimeStruct[];
}

export interface ModelLabelProps {
  modelType: ModelType;
  result: ModelPredictionType;
  config?: PredictionResultConfig;
  formula: ModelType[];
}

export interface ModelCellProps {
  formula: ModelType[];
  modelType: ModelType;
  prediction?: ModelPredictionType;
}

export const ModelsConfig: Record<string, Components> = {};

ModelsConfig[ModelType.DepthFAS] = {
  Cropping: "192x256",
  Details: AntiSpoofingDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.MaskDetector] = {
  Cropping: "112x112",
  Details: AntiSpoofingDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.PatchFAS] = {
  Cropping: "112x112",
  Details: AntiSpoofingDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.AgeEstimation] = {
  Cropping: "256x256",
  Details: AgeEstimationDetails,
  Cell: AgeTableCell,
  Prediction: { Component: AgeResult, Position: "other" },
};
ModelsConfig[ModelType.BiologicalSex] = {
  Cropping: "256x256",
  Details: BiologicalSexDetails,
  Cell: BiologicalSexCell,
  Prediction: { Component: BiologicalSex, Position: "other" },
};
ModelsConfig[ModelType.FIQA] = {
  Cropping: "224x224",
  Details: FIQADetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.RGBFAS] = {
  Cropping: "192x256",
  Details: RGBFASDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.ContextFAS] = {
  Cropping: "112x112margin20",
  Details: AntiSpoofingDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.RGBFASV2] = {
  Cropping: "192x256",
  Details: RGBFASDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.EffNet] = {
  Cropping: "224x224offset",
  Details: AntiSpoofingDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.MetaFAS] = {
  Cropping: ["224x224offset", "192x256"],
  Details: RGBFASDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.MetaFASV2] = {
  Cropping: ["224x224offset", "192x256", "112x112margin24"],
  Details: RGBFASDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.IDRnD] = {
  Cropping: [], // No croppings
  Details: IDRnDDetails,
  Cell: IdRnDCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.MetaFASV2B] = {
  Cropping: ["224x224offset", "192x256", "112x112margin24"],
  Details: RGBFASDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.MetaFASV3] = {
  Cropping: ["224x224", "256x256"],
  Details: RGBFASDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.MetaFASV4] = {
  Cropping: ["224x224_linear_warpfill", "256x256_linear_warpfill", "256x256margin12_linear_warpfill"],
  Details: RGBFASDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.MetaFASV5] = {
  Cropping: ["112x112margin40_linear_warpfill", "224x224_linear_warpfill", "256x256margin12_linear_warpfill"],
  Details: RGBFASDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
ModelsConfig[ModelType.MetaFASV7] = {
  Cropping: ["original_img"],
  Details: RGBFASDetails,
  Cell: AntiSpoofingCell,
  Prediction: { Component: AntiSpoofingResult, Position: "prediction" },
};
