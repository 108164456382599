import { IDRnDPrediction } from "../../../../models/log";
import { ModelCellProps } from "../../../../config/modelsConfig";
import { PredictionCell } from "./PredictionCell";
import React from "react";

export const IdRnDCell: React.FC<ModelCellProps> = (props) => {
  const p = props.prediction as IDRnDPrediction;
  const { prediction, quality, real } = p;

  return <PredictionCell {...props} prediction={prediction} extraValue={quality} real={real} />;
};
