import React, { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import Config from "../../config";
import { FilterService } from "../../api/filter";
import { FilterType } from "../../models/stats";
import { Grid } from "@material-ui/core";

const filterService = new FilterService(Config.apiUrl);

interface Props {
  type?: FilterType;
  value: string | null;

  onChange: (value: string | null) => void;
  onError: (e: Error | undefined) => void;
}

export const StatFilter: React.FC<Props> = (props) => {
  const [filters, setFilters] = useState<string[]>([]);

  const { type, value, onChange, onError } = props;

  useEffect(() => {
    if (!type) {
      return;
    }
    onError(undefined);
    filterService
      .distinctValues(type)
      .then((list) => setFilters(list.sort()))
      .catch(onError);
  }, [type, onError]);

  return (
    <>
      {filters && filters.length > 0 && (
        <Grid item xs={12}>
          <ToggleButtonGroup size="small" value={value} exclusive={true} onChange={(e, value) => onChange(value)}>
            {filters.map((v, i) => {
              return (
                <ToggleButton key={i} value={v}>
                  {v ? v.toUpperCase() : "UNKNOWN"}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Grid>
      )}
    </>
  );
};
