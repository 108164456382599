import { PaginationUser, User } from "./../models/user";

import { ApiBase } from "./base";
import { Role } from "./../models/role";

export class UserService extends ApiBase {
  getAll = (page: number, pageSize: number, email?: string): Promise<PaginationUser> => {
    let url = `users?page=${page}&page-size=${pageSize}`;
    if (email) {
      url += `&email=${email}`;
    }
    return this.fetch<PaginationUser>(url);
  };

  create = (user: User): Promise<User> => {
    return this.fetch(`users`, {
      method: "POST",
      data: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  update = (user: User): Promise<User> => {
    return this.fetch(`users/${user.id}`, {
      method: "PUT",
      data: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  batchUpdate = (users: User[]): Promise<User[]> => {
    return this.fetch(`users`, {
      method: "PUT",
      data: JSON.stringify(users),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getRoles = (): Promise<Role[]> => {
    return this.fetch<Role[]>("roles");
  };
}
