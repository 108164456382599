import {
  BRCKCampaignStats,
  Config,
  DailyResult,
  DailyStats,
  FaceCaptureModuleErrorsStats,
  MyFaceEvent,
  MyFaceImage,
  MyFaceUser,
  MyFaceUserState,
  MyFaceUsersResult,
  RejectionReasons,
  ReviewStats,
  ReviewUserInfo,
} from "../models/myface";

import { ApiBase } from "../../../api/base";

export class MyFaceService extends ApiBase {
  get = async (): Promise<Config> => {
    return this.fetch<Config>("my-face/config", {
      method: "GET",
    });
  };

  update = (config: Config): Promise<void> => {
    return this.fetch<void>("my-face/config", {
      method: "PUT",
      data: JSON.stringify(config),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getUsers = (page: number, pageSize: number): Promise<MyFaceUsersResult> => {
    return this.fetch<MyFaceUsersResult>("my-face/users", {
      method: "POST",
      data: JSON.stringify({
        page: page,
        pageSize: pageSize,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getAllUsers = (): Promise<MyFaceUser[]> => {
    return this.fetch<MyFaceUser[]>("my-face/users", {
      method: "GET",
    });
  };

  getImages = (userID: string): Promise<MyFaceImage[]> => {
    return this.fetch<MyFaceImage[]>(`my-face/images/${encodeURIComponent(userID)}`, {
      method: "GET",
    });
  };

  reviewImage = (logID: number, approve?: boolean): Promise<void> => {
    return this.fetch<void>(`my-face/image/${logID}`, {
      method: "PUT",
      data: JSON.stringify(
        `${
          approve === undefined
            ? MyFaceUserState.Unknown
            : approve
            ? MyFaceUserState.Approved
            : MyFaceUserState.Rejected
        }`,
      ),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getNextUserToReview = (id?: string): Promise<ReviewUserInfo> => {
    return this.fetch<ReviewUserInfo>(`my-face/user${id ? "/" + encodeURIComponent(id) : ""}`, {
      method: "GET",
    });
  };

  getReviewStats = (): Promise<ReviewStats> => {
    return this.fetch<ReviewStats>("my-face/stats/review", {
      method: "GET",
    });
  };

  stateReviewUser = (id: string): Promise<MyFaceUserState> => {
    return this.fetch<MyFaceUserState>(`my-face/review/${encodeURIComponent(id)}`, {
      method: "GET",
    });
  };

  reviewUser = (id: string, reasons?: RejectionReasons[]): Promise<void> => {
    return this.fetch<void>(`my-face/review/${encodeURIComponent(id)}`, {
      method: "POST",
      data: reasons && reasons.length > 0 ? JSON.stringify(reasons) : "[]",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  creditUser = (id: string): Promise<void> => {
    return this.fetch<void>(`my-face/credit/${encodeURIComponent(id)}`, {
      method: "POST",
    });
  };

  getDailyStats = (page: number, pageSize: number): Promise<DailyResult> => {
    return this.fetch<DailyResult>("my-face/stats/daily", {
      method: "POST",
      data: JSON.stringify({
        page: page,
        pageSize: pageSize,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getAllDailyStats = (): Promise<DailyStats[]> => {
    return this.fetch<DailyStats[]>("my-face/stats/daily", {
      method: "GET",
    });
  };

  getBRCKCampaignStats = (): Promise<BRCKCampaignStats[]> => {
    return this.fetch<BRCKCampaignStats[]>("my-face/stats/campaigns", {
      method: "GET",
    });
  };

  getEvents = (id: string): Promise<MyFaceEvent[]> => {
    return this.fetch<MyFaceEvent[]>(`my-face/events/${encodeURIComponent(id)}`, {
      method: "GET",
    });
  };

  getFaceCaptureModuleErrorsStats = (): Promise<FaceCaptureModuleErrorsStats> => {
    return this.fetch<FaceCaptureModuleErrorsStats>("my-face/stats/face-capture-module", {
      method: "GET",
    });
  };
}
