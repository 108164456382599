import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Config from "../config";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      bottom: 0,
      color: theme.palette.primary.main,
      padding: "10px 20px",
      left: 0,
      backgroundColor: theme.palette.common.white,
      position: "fixed",
      width: "100%",
      marginBottom: 0,
      zIndex: 2,
    },
  }),
);

export const Footer: React.FC = () => {
  const classes = useStyles();
  return <div className={classes.footer}>v{Config.version}</div>;
};
