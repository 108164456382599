import { createStyles, makeStyles } from "@material-ui/core";

import { BiologicalSexPrediction } from "../../../../models/log";
import { ModelCellProps } from "../../../../config/modelsConfig";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    probs: {
      color: theme.palette.primary.main,
      marginLeft: "1ch",
    },
    noResult: {
      width: "100%",
      textAlign: "center",
    },
  }),
);

export const BiologicalSexCell: React.FC<ModelCellProps> = (props) => {
  const classes = useStyles();
  const p = props.prediction as BiologicalSexPrediction;
  return (
    <>
      {p.probs !== null ? (
        <>
          <span>{p.probs >= 0.5 ? "Female" : "Male"}</span>
          <span className={classes.probs}>{(p.probs * 100).toFixed(3)}%</span>
        </>
      ) : (
        <div className={classes.noResult}>-</div>
      )}
    </>
  );
};
