import { ImageExtensionFormat, Picture } from "../../models/image";
import React, { useCallback } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
import { ConfigurationProps } from "./ConfigurationPanel";
import ConfigurationSection from "./ConfigurationSection";
import ConfigurationSubsection from "./ConfigurationSubsection";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Grid } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { SliderBar } from "../Utils/SliderBar";
import Typography from "@material-ui/core/Typography";
import { UploadConfiguration } from "../../models/config";

const TINY_FACE_DETECTOR_SECTION_DESCRIPTION =
  "Settings to configure the Tiny Face Detector model used to validate the image uploaded";
const IMAGE_SECTION_DESCRIPTION = "Settings and requirements related to the image uploaded";
const CROPPING_SECTION_DESCRIPTION = "Settings to configure the cropping feature";

enum UploadSection {
  TinyFaceDetector = "Tiny Face Detector",
  Image = "Image",
  Cropping = "Cropping",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: { fontSize: "18px", marginTop: "20px" },
    radioGroup: {
      marginTop: "16px",
    },
    formatSection: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "6px",
    },
    radio: {
      display: "block",
    },
    radioButtonLabel: {
      color: theme.palette.text.secondary,
    },
    percentageCroppingSlider: {
      [theme.breakpoints.up("md")]: { width: "50%" },
    },
  }),
);

export const UploadPanel: React.FC<ConfigurationProps<UploadConfiguration>> = (props) => {
  const classes = useStyles();

  const { isAdmin, onConfigurationChange, config } = props;
  const section = config.uploadConfiguration;

  const picturetypes = [Picture.OriginalPicture, Picture.CroppedPicture];

  const setPanelConfigurationSlider = (value: number, name: keyof UploadConfiguration) => {
    onConfigurationChange({
      ...section,
      [name]: value,
    });
  };

  const allFormats = Object.values(ImageExtensionFormat).filter((val) => val !== ImageExtensionFormat.Unknown);

  const changeFormat = useCallback(
    (extension: string) => {
      const configClone = JSON.parse(JSON.stringify(config.uploadConfiguration)) as UploadConfiguration;
      const index = configClone.extensionFormats.findIndex((format) => format === extension);
      if (index === -1) {
        configClone.extensionFormats.push(extension);
      } else {
        configClone.extensionFormats.splice(index, 1);
      }

      configClone.extensionFormats.sort(); // Sort the values to compare the list to disable the save button.
      onConfigurationChange({ ...configClone });
    },
    [onConfigurationChange, config.uploadConfiguration],
  );

  return (
    <div>
      <ConfigurationSection title={UploadSection.TinyFaceDetector} description={TINY_FACE_DETECTOR_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Tiny Face Detector">
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.label}>
                Threshold
              </Typography>
              <SliderBar
                min={0.01}
                max={0.99}
                step={0.01}
                value={section.tinyFaceDetectorThreshold}
                onChange={(v) => setPanelConfigurationSlider(v, "tinyFaceDetectorThreshold")}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.label}>
                Max simultaneous faces
              </Typography>
              <SliderBar
                min={1}
                max={10}
                step={1}
                value={section.maxFaces}
                onChange={(v) => setPanelConfigurationSlider(v, "maxFaces")}
                disabled={!isAdmin}
              />
            </Grid>
          </Grid>
        </ConfigurationSubsection>
      </ConfigurationSection>

      <ConfigurationSection title={UploadSection.Image} description={IMAGE_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="File size (KiB)">
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.label}>
                Minimum
              </Typography>
              <SliderBar
                min={10}
                max={10000}
                step={10}
                value={section.minFileSize}
                onChange={(v) => setPanelConfigurationSlider(v, "minFileSize")}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.label}>
                Maximum
              </Typography>

              <SliderBar
                min={10}
                max={10000}
                step={100}
                value={section.maxFileSize}
                onChange={(v) => setPanelConfigurationSlider(v, "maxFileSize")}
                disabled={!isAdmin}
              />
            </Grid>
          </Grid>
        </ConfigurationSubsection>
        <ConfigurationSubsection title="Image size (pixels)">
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.label}>
                Minimum width
              </Typography>
              <SliderBar
                min={100}
                max={4000}
                step={100}
                value={section.minImageWidth}
                onChange={(v) => setPanelConfigurationSlider(v, "minImageWidth")}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.label}>
                Maximum width
              </Typography>
              <SliderBar
                min={100}
                max={4000}
                step={100}
                value={section.maxImageWidth}
                onChange={(v) => setPanelConfigurationSlider(v, "maxImageWidth")}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.label}>
                Minimum height
              </Typography>
              <SliderBar
                min={100}
                max={4000}
                step={100}
                value={section.minImageHeight}
                onChange={(v) => setPanelConfigurationSlider(v, "minImageHeight")}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.label}>
                Maximum height
              </Typography>
              <SliderBar
                min={100}
                max={4000}
                step={100}
                value={section.maxImageHeight}
                onChange={(v) => setPanelConfigurationSlider(v, "maxImageHeight")}
                disabled={!isAdmin}
              />
            </Grid>
          </Grid>
        </ConfigurationSubsection>
        <ConfigurationSubsection title="Image format">
          <Grid container>
            {allFormats.map((format) => (
              <Grid item key={format}>
                <div className={classes.formatSection}>
                  <Typography color="textSecondary" gutterBottom>
                    {format.toUpperCase()}
                  </Typography>
                  <Checkbox
                    checked={section.extensionFormats.some((f) => f === format)}
                    onChange={() => {
                      changeFormat(format);
                    }}
                    color="default"
                    disabled={!isAdmin}
                  />
                </div>
              </Grid>
            ))}
            {section.extensionFormats.length === 0 && (
              <Grid>
                <Typography color="textSecondary" gutterBottom>
                  * If no format selected any image will be accepted
                </Typography>
              </Grid>
            )}
          </Grid>
        </ConfigurationSubsection>
      </ConfigurationSection>

      <ConfigurationSection title={UploadSection.Cropping} description={CROPPING_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Picture to send">
          <div className={classes.radioGroup}>
            <RadioGroup
              row={true}
              value={section.selectedInputPicture}
              onChange={(e) => onConfigurationChange({ ...section, selectedInputPicture: e.target.value as Picture })}
            >
              {picturetypes.map((picture) => {
                return (
                  <FormControlLabel
                    color="textSecondary"
                    value={picture}
                    key={String(picture)}
                    control={<Radio color="primary" />}
                    label={picture.charAt(0).toUpperCase() + picture.slice(1)}
                    labelPlacement="top"
                    classes={{ label: classes.radioButtonLabel }}
                    disabled={!isAdmin}
                  />
                );
              })}
            </RadioGroup>
          </div>
        </ConfigurationSubsection>
        <ConfigurationSubsection title="Percentage of cropping">
          <div className={classes.percentageCroppingSlider}>
            <SliderBar
              min={0}
              max={1}
              step={0.05}
              value={section.relativeMarginPercentageCropping}
              onChange={(v) => setPanelConfigurationSlider(v, "relativeMarginPercentageCropping")}
              disabled={!isAdmin}
            />
          </div>
        </ConfigurationSubsection>
      </ConfigurationSection>
    </div>
  );
};
