import { CircularProgress, Grid, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import { MyFaceImage, MyFaceUserState, RejectionReasons, ReviewStats, ReviewUserInfo } from "../models/myface";
import React, { useCallback, useEffect } from "react";

import { Button } from "./../../../components/Utils/Button";
import Config from "../../../config";
import { ExpectedResult } from "../../../models/log";
import { GlobalContext } from "../../../globalContext";
import { LogService } from "../../../api/log";
import { MyFaceService } from "../api/myface";
import { RejectionReasonsDialog } from "./RejectionReasonsDialog";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "20px",
    },
    selectedRow: {
      backgroundColor: theme.palette.primary.light,
    },
    stateApproved: {
      color: theme.palette.success.main,
    },
    stateRejected: {
      color: theme.palette.error.main,
    },
    stateRejectedButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    selectedButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    img: {
      maxWidth: "100%",
      height: "auto",
    },
    reviewSummary: {
      margin: "20px 0 110px",
    },
    updatableVariable: {
      padding: "2px 5px",
      borderRadius: "4px",
      animation: "$highlight 1s both",
    },
    "@keyframes highlight": {
      "20%": { backgroundColor: theme.palette.primary.light },
      "30%": { backgroundColor: theme.palette.primary.light },
    },
    reviewInfo: {
      position: "fixed",
      left: 0,
      bottom: 0,
      width: "100%",
      textAlign: "right",
      padding: "10px 20px 58px",
      backgroundColor: theme.palette.common.white,
    },
  }),
);

const myFaceService = new MyFaceService(Config.apiUrl);
const logService = new LogService(Config.apiUrl);

export const Review: React.FC = () => {
  const classes = useStyles();

  const { setFeedback } = React.useContext(GlobalContext);

  const [selectedUser, setSelectedUser] = React.useState<ReviewUserInfo>({ id: "", expired: false });
  const [images, setImages] = React.useState<MyFaceImage[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [stats, setStats] = React.useState<ReviewStats>();

  React.useEffect(() => {
    if (selectedUser.id) {
      setLoading(true);
      myFaceService
        .getImages(selectedUser.id)
        .then((res) => {
          setImages(res);
        })
        .catch((e) =>
          setFeedback({
            variant: "error",
            message: `Failed to get images for user ${selectedUser.id}: ${e.message}`,
            horizontal: "right",
          }),
        )
        .finally(() => setLoading(false));
    }
  }, [setFeedback, selectedUser.id]);

  const getNextUser = useCallback(() => {
    myFaceService
      .getNextUserToReview(selectedUser.id)
      .then((res) => {
        if (!res) {
          setFeedback({
            variant: "error",
            message: "No more users to review",
            horizontal: "right",
          });
        } else {
          setSelectedUser(res);
        }
      })
      .catch((e) =>
        setFeedback({
          variant: "error",
          message: `Failed to load next user to review: ${e.message}`,
          horizontal: "right",
        }),
      );
  }, [setFeedback, selectedUser]);

  useEffect(getNextUser, []);

  const finishUserReview = (id: string, reasons?: RejectionReasons[]) => {
    myFaceService
      .reviewUser(id, reasons)
      .then(() => {
        setSelectedUser({ id: "", expired: false });
        getNextUser();
      })
      .catch((e) =>
        setFeedback({
          variant: "error",
          message: `Failed to review user ${id}: ${e.message}`,
          horizontal: "right",
        }),
      );
  };

  const [reasonsDialogOpen, setReasonsDialogOpen] = React.useState(false);
  const handleReasonsDialogClose = (reasons?: RejectionReasons[]) => {
    setReasonsDialogOpen(false);

    if (reasons) {
      finishUserReview(selectedUser.id, reasons);
    }
  };

  const reviewUser = (id: string) => () => {
    myFaceService
      .stateReviewUser(id)
      .then((res) => {
        switch (res) {
          case MyFaceUserState.Approved:
            finishUserReview(id);
            break;
          case MyFaceUserState.Rejected:
            if (selectedUser.expired) {
              finishUserReview(id);
            } else {
              setReasonsDialogOpen(true);
            }
            break;
          default:
            setFeedback({
              variant: "error",
              message: `Cannot finish review of user ${id} with state ${res}`,
              horizontal: "right",
            });
            break;
        }
      })
      .catch((e) =>
        setFeedback({
          variant: "error",
          message: `Failed to finish the review for user ${id}: ${e.message}`,
          horizontal: "right",
        }),
      );
  };

  const refreshStats = (onError: (e: Error) => void) => {
    myFaceService
      .getReviewStats()
      .then((res) => setStats(res))
      .catch(onError);
  };

  const updateExpectedResult = (logID: number, expectedResult: ExpectedResult) => {
    return () => {
      logService
        .updateExpectedResult(logID, expectedResult)
        .then(() => {
          setImages(
            images.map((img) => {
              if (img.logID === logID) {
                img.expectedResult = expectedResult;
              }
              return img;
            }),
          );
        })
        .catch((e) =>
          setFeedback({
            variant: "error",
            message: `Failed to update expected result for img with logID ${logID}: ${e.message}`,
            horizontal: "right",
          }),
        );
    };
  };

  React.useEffect(() => {
    refreshStats((e) =>
      setFeedback({
        variant: "error",
        message: `Failed to get review stats: ${e.message}`,
        horizontal: "right",
      }),
    );
  }, [setFeedback, images]);

  const reviewImg = (logID: number, approved?: boolean) => {
    return () => {
      myFaceService
        .reviewImage(logID, approved)
        .then(() => {
          setImages(
            images.map((img) => {
              if (img.logID === logID) {
                img.state =
                  approved === undefined
                    ? MyFaceUserState.Unknown
                    : approved
                    ? MyFaceUserState.Approved
                    : MyFaceUserState.Rejected;
              }
              return img;
            }),
          );
        })
        .catch((e) =>
          setFeedback({
            variant: "error",
            message: `Failed to review image with logID ${logID}: ${e.message}`,
            horizontal: "right",
          }),
        );
    };
  };

  const reviewUserDisabled = () => {
    if (images) {
      for (const img of images) {
        if (!(img.state === MyFaceUserState.Approved || img.state === MyFaceUserState.Rejected)) {
          return true;
        }
      }
    }

    return false;
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <>
          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between">
              <Typography color="textPrimary">
                Not started: {stats?.notStarted ?? "-"} Not finished: {stats?.notFinished ?? "-"}{" "}
                {stats?.expired ? `(Expired: ${stats.expired})` : ""} Pending: {stats?.pending ?? "-"} In review:{" "}
                {stats?.inReview ?? "-"} {stats?.inReviewExpired ? `(Expired: ${stats.inReviewExpired}) ` : ""}
                Approved: {stats?.approved ?? "-"} Rejected: {stats?.rejected ?? "-"}
              </Typography>
              <Button color="primary" variant="outlined" onClick={() => getNextUser()}>
                Get next user
              </Button>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            {loading ? (
              <>
                <CircularProgress /> <Typography color="textPrimary">Loading</Typography>
              </>
            ) : !selectedUser.id ? (
              <Typography color="textPrimary">No user selected</Typography>
            ) : !images.map || images.length === 0 ? (
              <Typography color="textPrimary">No images</Typography>
            ) : (
              <Grid container direction="row" justify="center">
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between" spacing={5}>
                    {images.map((img, index) => {
                      return (
                        <Grid key={`image-${index}`} item xs={12} md={6}>
                          <Grid container direction="row" justify="flex-start" className={classes.root} spacing={2}>
                            <Grid item xs={12} md={8}>
                              <img src={img.img} alt={`${img.userID} - ${img.logID}`} className={classes.img} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Grid container direction="row" spacing={2}>
                                <Grid item xs={12}>
                                  <Typography color="textPrimary" variant="h5">
                                    {index + 1}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography color="textPrimary">
                                    Expected:{" "}
                                    <span
                                      key={`${img.logID}-expected-${img.expectedResult}`}
                                      className={classes.updatableVariable}
                                    >
                                      {img.expectedResult}
                                    </span>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography color="textPrimary">
                                    State:{" "}
                                    <span
                                      key={`${img.logID}-state-${img.state}`}
                                      className={classNames(
                                        classes.updatableVariable,
                                        img.state === MyFaceUserState.Approved
                                          ? classes.stateApproved
                                          : img.state === MyFaceUserState.Rejected
                                          ? classes.stateRejected
                                          : "",
                                      )}
                                    >
                                      {!img.state ? "unknown" : img.state}
                                    </span>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Button
                                    variant={"outlined"}
                                    color="primary"
                                    size="small"
                                    onClick={reviewImg(img.logID, img.state === MyFaceUserState.Rejected)}
                                    className={
                                      img.state === MyFaceUserState.Rejected
                                        ? classes.stateRejectedButton
                                        : classes.stateRejected
                                    }
                                  >
                                    {"Reject"}
                                  </Button>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography color="textPrimary">Expected result:</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={updateExpectedResult(img.logID, ExpectedResult.Unknown)}
                                    className={
                                      img.expectedResult === ExpectedResult.Unknown ? classes.selectedButton : ""
                                    }
                                  >
                                    Unknown
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={updateExpectedResult(img.logID, ExpectedResult.Real)}
                                    className={img.expectedResult === ExpectedResult.Real ? classes.selectedButton : ""}
                                  >
                                    Real
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={updateExpectedResult(img.logID, ExpectedResult.DisplayAttack)}
                                    className={
                                      img.expectedResult === ExpectedResult.DisplayAttack ? classes.selectedButton : ""
                                    }
                                  >
                                    Display
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={updateExpectedResult(img.logID, ExpectedResult.PrintAttack)}
                                    className={
                                      img.expectedResult === ExpectedResult.PrintAttack ? classes.selectedButton : ""
                                    }
                                  >
                                    Print
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={updateExpectedResult(img.logID, ExpectedResult.MaskAttack)}
                                    className={
                                      img.expectedResult === ExpectedResult.MaskAttack ? classes.selectedButton : ""
                                    }
                                  >
                                    Mask
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} className={classes.reviewInfo}>
                    <Grid container direction="row" justify="flex-end" spacing={2}>
                      <Grid item>
                        <Typography color="textPrimary" variant="h6">
                          User ID: {selectedUser.id} {selectedUser.expired && <span>(Expired)</span>}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="textPrimary" variant="h6">
                          Approved images: {images.filter((img) => img.state === MyFaceUserState.Approved).length}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="textPrimary" variant="h6">
                          Rejected images: {images.filter((img) => img.state === MyFaceUserState.Rejected).length}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} className={classes.reviewSummary}>
                  <Grid container direction="row" justify="center" spacing={3}>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={reviewUser(selectedUser.id)}
                        disabled={reviewUserDisabled()}
                        fullWidth
                      >
                        Done
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      </Grid>
      <RejectionReasonsDialog open={reasonsDialogOpen} onClose={handleReasonsDialogClose} />
    </div>
  );
};
