import { Column, Value } from "./dragAndDrop";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Droppable } from "react-beautiful-dnd";
import React from "react";
import { Typography } from "@material-ui/core";
import { ValuePanel } from "./Value";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "8px",
      border: "solid 1px lightgrey",
      borderRadius: "2px",
      width: "220px",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      height: "350px",
    },
    title: {
      padding: "8px",
      fontWeight: theme.typography.fontWeightBold,
    },
    values: {
      padding: "8px",
      flexGrow: 1,
      display: "inline-block",
    },
  }),
);

interface Props<T> {
  column: Column;
  values: Value<T>[];
  ordered: boolean;
}

export function ColumnPanel<T>(props: Props<T>): React.ReactElement<Props<T>> {
  const classes = useStyles();
  const { column, values, ordered } = props;
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{column.title}</Typography>
      <Droppable droppableId={column.id}>
        {(provided) => (
          <div className={classes.values} ref={provided.innerRef} {...provided.droppableProps}>
            {values.map((value, index) => (
              <ValuePanel key={value.id} value={value} index={index} order={ordered}></ValuePanel>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}
