import { Grid } from "@material-ui/core";
import { Loading } from "./Loading";
import React from "react";

export const PageLoading: React.FC = () => {
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "100vh" }}>
      <div>
        <Loading visible={true} />
      </div>
    </Grid>
  );
};
