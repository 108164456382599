import { Button as ButtonComponent, ThemeProvider, createMuiTheme, useTheme } from "@material-ui/core";
import React, { ElementType } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { ButtonProps } from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: (props: Props) => ({
      cursor: props.disabled ? "not-allowed !important" : "pointer",
      pointerEvents: "auto !important" as "auto", // This need to be done to show not-allowed cursor
      color:
        props.variant === "contained"
          ? theme.palette.common.white
          : props.color === "primary"
          ? theme.palette.primary.dark
          : undefined,
    }),
  }),
);

type ButtonColor = "primary" | "secondary" | "success" | "error" | undefined;

// TODO: AITOOL-1143: Investigate why the component prop need to be added manually.
interface Props extends Omit<ButtonProps, "color"> {
  color?: ButtonColor;
  component?: ElementType;
}

// Duplicated from @material-ui/core/styles/createPalette.d.ts as it's not a public interface.
interface PaletteColor {
  light: string;
  main: string;
  dark: string;
  contrastText: string;
}

const getThemeColor = (color: ButtonColor, theme: Theme) => {
  switch (color) {
    case "secondary":
      return theme.palette.secondary;
    case "success":
      return theme.palette.success;
    case "error":
      return theme.palette.error;
    default:
      return theme.palette.primary;
  }
};

const buttonTheme = (color: PaletteColor) =>
  createMuiTheme({
    palette: {
      primary: color,
    },
  });

export const Button: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { children, color, ...buttonProps } = props;
  const theme = useTheme();
  return (
    <ThemeProvider theme={buttonTheme(getThemeColor(color, theme))}>
      <ButtonComponent className={classes.button} {...buttonProps} color="primary">
        {children}
      </ButtonComponent>
    </ThemeProvider>
  );
};
