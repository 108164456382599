import { FilledInputProps, InputProps, OutlinedInputProps, createMuiTheme } from "@material-ui/core";

import { KeyboardDateTimePicker } from "@material-ui/pickers";
import Moment from "moment";
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../../theme";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.palette.primary.contrastText,
    },
  },
});

const dateFormat = "YYYY/MM/DD HH:mm";

interface Props {
  label: string;
  value?: number;
  onChange: (value?: number) => void;
  InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
  fullWidth?: boolean;
}

export const DateTimeInput: React.FC<Props> = (props) => {
  const { label, value, onChange, InputProps, fullWidth } = props;
  const dateValue = value ? Moment.unix(value) : null;

  return (
    <ThemeProvider theme={materialTheme}>
      <KeyboardDateTimePicker
        variant="inline"
        ampm={false}
        label={label}
        value={dateValue}
        onChange={(date) => {
          const value = date ? date.unix() : undefined;
          onChange(value);
        }}
        format={dateFormat}
        InputProps={InputProps}
        fullWidth={fullWidth}
      />
    </ThemeProvider>
  );
};
