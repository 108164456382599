import { IconButton, Tooltip } from "@material-ui/core";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import { GlobalContext } from "../../globalContext";
import React from "react";

interface Props {
  title: string;
  text: string;
}

export const CopyIconButton: React.FC<Props> = (props) => {
  const { title, text } = props;
  const { setFeedback } = React.useContext(GlobalContext);

  const copyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    setFeedback({
      variant: "success",
      message: `${title} copied!`,
    });
    document.body.removeChild(textArea);
  };

  return (
    <Tooltip title={"Copy"} placement="bottom-end">
      <IconButton
        aria-label="Copy"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          copyToClipboard();
        }}
      >
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
};
