import { Divider, Grid, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionContainer: {
      cursor: "pointer",
    },
    sectionTitle: { fontSize: "1.5rem" },
    sectionTitleIcon: {
      fontSize: "1,5rem",
      marginRight: "16px",
    },
    childComponent: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "50px",
        maxWidth: "calc(100% - 100px)",
      },
    },
  }),
);

interface Props {
  // initialState indicates if the panel will show the contents or not on
  // rendering it the first time. It won't render the body if it's not provided.
  initialState?: boolean;
  storageKey: string;
  title: string;
}

export const DashboardSection: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [show, setShow] = useState(
    localStorage.getItem(props.storageKey) === null
      ? props.initialState
      : localStorage.getItem(props.storageKey) === "true",
  );

  function updateStorage() {
    localStorage.setItem(props.storageKey, String(!show));
    setShow(!show);
  }

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.sectionContainer} onClick={() => updateStorage()}>
          <Grid container direction="row" alignItems="center">
            <div className={classes.sectionTitleIcon}>{show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</div>
            <Typography color="textSecondary" className={classes.sectionTitle} gutterBottom>
              {props.title}
            </Typography>
          </Grid>
          <Divider />
        </div>
      </Grid>
      {show && (
        <Grid className={classes.childComponent}>
          <Grid item xs={12}>
            {props.children}
          </Grid>
        </Grid>
      )}
    </>
  );
};
