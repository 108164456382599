import { GoogleLogin, Login, LoginType } from "../../models/account";

const STATE_KEY = "google_auth";

export const callback = (query: URLSearchParams): Login => {
  const code = query.get("code") || "";
  const state = query.get("state") || "";
  const storedState = localStorage.getItem(STATE_KEY);

  // Throw an error if the login state token because it is needed to
  // authenticate the users.
  if (!storedState) {
    throw Error("login state key is empty");
  }

  localStorage.removeItem(STATE_KEY);

  return {
    type: LoginType.Google,
    data: { code, state, storedState },
  };
};

export const begin = (res: GoogleLogin) => {
  localStorage.setItem(STATE_KEY, res.state);
  window.location.href = res.url;
};
