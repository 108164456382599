import "@getyoti/react-face-capture/index.css";

import { FCMResult } from "../../../models/utils";
// Face Capture module docs can be found here:
// https://github.com/lampkicking/yoti-docs-web/tree/master/face-capture-module
//@ts-ignore
import FaceCapture from "@getyoti/react-face-capture";
import { GlobalContext } from "../../../globalContext";
import React from "react";

interface FaceCaptureModuleProps {
  debugMode?: boolean;
  showOverlay?: boolean;
  onScan: (res: FCMResult) => void;
  onError: (e: Error) => void;
}

export const FaceCaptureModule: React.FC<FaceCaptureModuleProps> = (props) => {
  const { debugMode, showOverlay, onScan, onError } = props;

  const { panelConfigurationData } = React.useContext(GlobalContext);

  const overlaySize =
    showOverlay || panelConfigurationData.scanConfiguration.faceCaptureModuleShowOverlay
      ? {
          PORTRAIT: panelConfigurationData.scanConfiguration.faceCaptureModuleOverlaySize,
          LANDSCAPE: panelConfigurationData.scanConfiguration.faceCaptureModuleOverlaySizeLandscape,
        }
      : undefined;

  return (
    <FaceCapture
      isDebug={debugMode ?? panelConfigurationData.scanConfiguration.faceCaptureModuleDebug}
      showOverlay={showOverlay ?? panelConfigurationData.scanConfiguration.faceCaptureModuleShowOverlay}
      overlaySize={overlaySize}
      imageType={panelConfigurationData.scanConfiguration.selectedInputPicture}
      relativeMarginCropping={panelConfigurationData.scanConfiguration.relativeMarginPercentageCropping}
      relativeEdgeCropping={panelConfigurationData.scanConfiguration.faceCaptureModuleRelativeEdgeCropping}
      captureMethod="auto"
      onSuccess={onScan}
      onError={(e: string) => onError(new Error(e))}
      format={panelConfigurationData.scanConfiguration.imageExtensionFormat}
      imageQuality={panelConfigurationData.scanConfiguration.imageJPEGCompression}
      initialAutocaptureDelay={panelConfigurationData.scanConfiguration.faceCaptureModuleInitialAutocaptureDelay}
      autocaptureDelay={panelConfigurationData.scanConfiguration.faceCaptureModuleAutocaptureDelay}
      faceSizeDeviation={{
        PORTRAIT: panelConfigurationData.scanConfiguration.faceCaptureModuleFaceSizeDeviation,
        LANDSCAPE: panelConfigurationData.scanConfiguration.faceCaptureModuleFaceSizeDeviationLandscape,
      }}
      minBBoxSize={{
        PORTRAIT: panelConfigurationData.scanConfiguration.faceCaptureModuleMinBBoxSize,
        LANDSCAPE: panelConfigurationData.scanConfiguration.faceCaptureModuleMinBBoxSizeLandscape,
      }}
      allowedCenterRelativeDeviation={panelConfigurationData.scanConfiguration.faceCaptureModuleCenterDeviation}
      maxBBoxRelativeSize={{
        PORTRAIT: panelConfigurationData.scanConfiguration.faceCaptureModuleMaxBBoxRelativeSizePortrait,
        LANDSCAPE: panelConfigurationData.scanConfiguration.faceCaptureModuleMaxBBoxRelativeSizeLandscape,
      }}
      idealBBoxRelativeSize={{
        PORTRAIT: panelConfigurationData.scanConfiguration.faceCaptureModuleIdealBBoxRelativeSize,
        LANDSCAPE: panelConfigurationData.scanConfiguration.faceCaptureModuleIdealBBoxRelativeSizeLandscape,
      }}
      widthIdealConstraint={panelConfigurationData.scanConfiguration.pictureResolutionWidth}
      scoreThreshold={panelConfigurationData.scanConfiguration.faceCaptureModuleScoreThreshold}
      brightness={{
        tooDarkThreshold: panelConfigurationData.scanConfiguration.faceCaptureModuleTooDarkThreshold,
        tooBrightThreshold: panelConfigurationData.scanConfiguration.faceCaptureModuleTooBrightThreshold,
        useBrightnessMethod: panelConfigurationData.scanConfiguration.faceCaptureModuleUseBrightnessMethod,
        useBrightnessMaskMethod: panelConfigurationData.scanConfiguration.faceCaptureModuleUseBrightnessMaskMethod,
        tooDarkThresholdMask: panelConfigurationData.scanConfiguration.faceCaptureModuleTooDarkThresholdMask,
        tooBrightThresholdMask: panelConfigurationData.scanConfiguration.faceCaptureModuleTooBrightThresholdMask,
        lowerMaskThreshold: panelConfigurationData.scanConfiguration.faceCaptureModuleLowerMaskThreshold,
        upperMaskThreshold: panelConfigurationData.scanConfiguration.faceCaptureModuleUpperMaskThreshold,
        lowerMaskAcceptanceThreshold:
          panelConfigurationData.scanConfiguration.faceCaptureModuleLowerMaskAcceptanceThreshold,
        upperMaskAcceptanceThreshold:
          panelConfigurationData.scanConfiguration.faceCaptureModuleUpperMaskAcceptanceThreshold,
      }}
    />
  );
};
