import { Grid, Paper, useTheme } from "@material-ui/core";
import { Plot, PlotInfo } from "./Plot";
import React, { useCallback, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Button } from "../../../Utils/Button";
import { CustomCheckbox } from "../../../Utils/CustomCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { HistogramCard } from "../../../Histogram/HistogramCard";
import { Picture } from "../../../../models/image";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) =>
  createStyles({
    imagePreview: {
      display: "inline-flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    imagePreviewMobile: {
      display: "block",
    },
    checkBoxMenu: {
      padding: theme.spacing(3),
      display: "inline-block",
    },
  }),
);

interface Props {
  img: string;
  mtcnnPlotInfo?: PlotInfo;
  tinyFacePlotInfo?: PlotInfo;
  id?: number;
  croppedImg?: string;
  tinyFaceCroppedPlotInfo?: PlotInfo;
  pictureUsed?: Picture;
  // The component needs the pinned image as a property instead of store it in
  // the state because the image will remain in memory when the user changes the
  // current log record to compare with this pinned image. When the user changes
  // the log, the state is reset.
  pinnedImg?: PinnedImgInfo;
  showHistogram: boolean;
  onPinnedImgChange: (img?: PinnedImgInfo) => void;
}
export interface PinnedImgInfo {
  id?: number;
  img: string;
  plotInfoList: PlotInfo[];
}

export const ImagePreview: React.FC<Props> = (props) => {
  const [plotList, setPlotList] = useState<PlotInfo[]>([]);
  const [plotListCropped, setPlotListCropped] = useState<PlotInfo[]>([]);
  const [plotChecks, setPlotChecks] = useState({ mtcnn: false, tiny: false });
  const [widthScaleFactor, setWidthScaleFactor] = useState<number>(0);
  const [heightScaleFactor, setHeightScaleFactor] = useState<number>(0);
  const useCropped = !!(props.pictureUsed === Picture.CroppedPicture && props.croppedImg);
  const plotUsed = useCropped ? plotListCropped : plotList;
  const setPlotUsedFn = useCropped ? setPlotListCropped : setPlotList;
  const { pinnedImg } = props;

  const theme = useTheme();
  const mobileWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles(props);

  useEffect(() => {
    setPlotList([]);
    setPlotListCropped([]);
  }, [props.img, props.mtcnnPlotInfo, pinnedImg]);
  const setPlot = (
    checked: boolean,
    plotInfo: PlotInfo | undefined,
    ignorePinned: boolean,
    currentList: PlotInfo[],
    setPlotFn: (p: PlotInfo[]) => void,
  ) => {
    if (!plotInfo) {
      return;
    }
    // Remove plots.
    if (!checked) {
      const newList = currentList.filter(
        (p) =>
          // Id is 0 for MTCNN plot and greater than 0 for Tiny box.
          (plotInfo.id === 0 && p.id !== 0) || (plotInfo.id > 0 && p.id === 0),
      );
      setPlotFn(newList);
      return;
    }
    // Add plot provided and the pinned plot too.
    const list = currentList.map((d) => {
      return { ...d };
    }); // Copy plot list.
    list.push(plotInfo);
    // Add the plots in pinned image if it exists.
    if (!ignorePinned && pinnedImg && pinnedImg.plotInfoList.length > 0) {
      const pinnedPlot = pinnedImg.plotInfoList.filter(
        // Id is 0 for MTCNN plot and greater than 0 for Tiny box.
        (p) => p.id === plotInfo.id || (plotInfo.id > 0 && p.id > 0),
      );
      if (pinnedPlot && pinnedPlot.length > 0) {
        // Set new color
        const newPinnedPlot = { ...pinnedPlot[0] };
        if (newPinnedPlot.color === (theme.palette as any).blue.dark) {
          newPinnedPlot.color = (theme.palette as any).blue.light;
        } else if (newPinnedPlot.color === theme.palette.success.dark) {
          newPinnedPlot.color = theme.palette.success.light;
        }
        // Add plot
        list.push(newPinnedPlot);
      }
    }
    setPlotFn(list);
  };
  const handlePin = () => {
    setPlotChecks({
      mtcnn: false,
      tiny: false,
    });
    setPlotList([]);
    setPlotListCropped([]);
    // Unselect pinned image.
    if (pinnedImg) {
      props.onPinnedImgChange(undefined);
      return;
    }
    // Pin image
    props.onPinnedImgChange({
      id: props.id,
      img: useCropped && props.croppedImg ? props.croppedImg : props.img,
      plotInfoList: plotUsed,
    });
  };
  const onCanvasLoaded = useCallback((width: number, height: number, clientWidth: number, clientHeight: number) => {
    const wSF = width / clientWidth;
    const hSF = height / clientHeight;

    setWidthScaleFactor(wSF);
    setHeightScaleFactor(hSF);
  }, []);
  return (
    <Grid container item spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={1} className={classes.checkBoxMenu}>
          <FormGroup row>
            {props.mtcnnPlotInfo && (
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={plotChecks.mtcnn}
                    onChange={(e, checked) => {
                      setPlotChecks({ ...plotChecks, mtcnn: checked });
                      setPlot(checked, props.mtcnnPlotInfo, false, plotUsed, setPlotUsedFn);
                    }}
                    value={"mtcnn"}
                    checkedcolor={(theme.palette as any).blue.dark}
                  />
                }
                label="MTCNN"
                labelPlacement="top"
              />
            )}
            {props.tinyFacePlotInfo && (
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={plotChecks.tiny}
                    onChange={(e, checked) => {
                      setPlotChecks({ ...plotChecks, tiny: checked });
                      setPlot(checked, props.tinyFacePlotInfo, useCropped, plotList, setPlotList);
                      setPlot(checked, props.tinyFaceCroppedPlotInfo, !useCropped, plotListCropped, setPlotListCropped);
                    }}
                    value={"tiny"}
                    checkedcolor={theme.palette.success.dark}
                  />
                }
                label="Tiny Face"
                labelPlacement="top"
              />
            )}
            <Button
              style={{ margin: "10px", marginTop: "20px" }}
              variant={pinnedImg !== undefined ? "contained" : "outlined"}
              color="primary"
              onClick={handlePin}
            >
              {pinnedImg !== undefined ? "Unpin ❌" : "Pin 📌"}
            </Button>
          </FormGroup>
        </Paper>
      </Grid>
      <Grid className={mobileWidth ? classes.imagePreviewMobile : classes.imagePreview} item xs={12}>
        <Plot
          data={plotList}
          image={props.img}
          pointSize={6}
          lineWidth={5}
          id={props.id}
          title={"Original image"}
          pictureUsed={props.pictureUsed === Picture.OriginalPicture || !props.pictureUsed ? true : false}
          cropped={false}
          onCanvasLoaded={onCanvasLoaded}
        />
        {props.croppedImg && (
          <Plot
            data={plotListCropped}
            image={props.croppedImg}
            pointSize={6}
            lineWidth={5}
            id={props.id}
            title={"Cropped image"}
            pictureUsed={props.pictureUsed === Picture.CroppedPicture ? true : false}
            cropped={true}
            widthScaleFactor={widthScaleFactor}
            heightScaleFactor={heightScaleFactor}
          />
        )}
        {props.showHistogram && (
          <HistogramCard
            img={
              props.pictureUsed === Picture.OriginalPicture || !props.pictureUsed
                ? props.img
                : props.croppedImg
                ? props.croppedImg
                : ""
            }
            width={650}
          />
        )}
        {pinnedImg && (
          <Plot
            data={pinnedImg.plotInfoList}
            image={pinnedImg.img}
            pointSize={6}
            lineWidth={5}
            id={pinnedImg.id}
            title={"Pinned image"}
            widthScaleFactor={widthScaleFactor}
            heightScaleFactor={heightScaleFactor}
          />
        )}
      </Grid>
    </Grid>
  );
};
