export interface Filter {
  value: string | number | boolean | null | undefined;
  secondValue?: string | number | null | undefined;
  column: string;
  type: "string" | "number" | "boolean";
  op?: string;
  secondOp?: string;
  exactMatch?: boolean;
}

export interface FilterValues {
  os: string[];
  browsers: string[];
  devices: string[];
  clientHintsOS: string[];
  clientHintsOSVersions: string[];
  clientHintsBrowsers: string[];
  clientHintsDeviceMemory: number[];
  clientHintsSaveData: boolean[];
  clientHintsDownlink: number[];
  clientHintsECT: string[];
  clientHintsRTT: number[];
  clientHintsArch: string[];
  clientHintsBitness: number[];
  clientHintsModel: string[];
  httpStatus: number[];
  errorCodes: string[];
  sniffers: string[];
  snifferUrls: string[];
  snifferModes: string[];
  secureVersions: string[];
  deepFasVersions: string[];
  maskDetectorVersions: string[];
  patchFasVersions: string[];
  deepFasRgbVersions: string[];
  deepFasRgbV2Versions: string[];
  effNetVersions: string[];
  metaFASVersions: string[];
  metaFASV2Versions: string[];
  metaFASV2BVersions: string[];
  ageEstimationVersions: string[];
  biologicalSexVersions: string[];
  qualityVersions: string[];
  contextPatchVersions: string[];
  idRnDVersions: string[];
  isSecure: (boolean | null)[];
  metaFASV3Versions: string[];
  metaFASV4Versions: string[];
  metaFASV5Versions: string[];
  metaFASV7Versions: string[];
  levelOfAssurance: string[];
  operator: string[];
  threshold: number[];
  imgValidationLevel: string[];
  ageEstimationModel: string[];
}

export enum FilterType {
  Search = "search",
  Range = "range",
  Menu = "menu",
}

export interface Sort {
  field: string;
  order: Order;
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}
