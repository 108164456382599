import {
  CancelRounded,
  CheckCircleRounded,
  CloudUploadRounded,
  DeleteRounded,
  DeviceUnknownRounded,
  FaceRounded,
  HelpRounded,
  PhotoCameraRounded,
  TrendingFlatOutlined,
} from "@material-ui/icons";
import {
  Checkbox,
  Grid,
  IconButton,
  TableCell,
  TableCellProps,
  TableRow,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  CroppingKey,
  ExpectedResult,
  Log,
  LogTableColumn,
  ModelPredictionType,
  PredictionSource,
  expectedResultToString,
} from "../../../models/log";
import { ModelType, ModelTypeName, getModelType } from "../../../models/utils";
import React, { RefObject, useRef } from "react";

import { DateLabel } from "./DateLabel";
import { GlobalContext } from "../../../globalContext";
import { ModelsConfig } from "../../../config/modelsConfig";
import { PredictionFormulaResult } from "../../../models/prediction";
import { Thumbnail } from "./Thumbnail";
import { TooltipAuditChanges } from "../../Utils/TooltipAuditChanges";
import { TooltipError } from "../../Utils/TooltipError";
import { ValueChanges } from "./ValueChanges";
import { predictionErrorFormat } from "../../../utils/error";
import { roundBrightnessScore } from "../LogDetail/ValidationResults/BrightnessScore";

const NO_VALUE_CELL: React.ReactNode = "-";

/**
 * Return the brightness validation result that will be rendered as cell value
 * the logs table. It will render a single number in case the validation results
 * only contains a cropped image. It will return a JSON string with all cropped
 * images keys and brightness result values if it contains more than one image.
 * If the validation is not success (because a validation error), it will try to
 * render the brightness validation error value.
 *
 * @param log The log to take the brightness score.
 */
const getBrightnessValidationResult = (log: Log) => {
  if (!log.croppingImgValidationResults) {
    if (log.validationResults && log.validationResults["brightness"]) {
      const brightnessScore = log.validationResults["brightness"] as number; // As this point we know the brightness is a number
      return `${roundBrightnessScore(brightnessScore)}`;
    }
    return "";
  }

  const keys = Object.keys(log.croppingImgValidationResults).map((k) => k as CroppingKey);
  if (keys.length === 1) {
    return `${log.croppingImgValidationResults[keys[0]].brightness}`;
  }
  return JSON.stringify({});
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      color: theme.palette.primary.main,
    },
    sourceIcon: {
      color: theme.palette.primary.main,
    },
    tagTableCell: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "20ch",
      whiteSpace: "nowrap",
    },
    tableCell: {
      whiteSpace: "nowrap",
    },
    predictionResultTitle: {
      fontSize: "14px",
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.error.main,
      width: "100%",
    },
    clear: {
      clear: "both",
    },
    success: {
      color: theme.palette.success.main,
    },
    error: { color: theme.palette.error.main },
    emailContainer: {
      maxWidth: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    errorCode: {
      fontSize: "14px",
      maxWidth: "200px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }),
);

interface Props {
  style?: React.CSSProperties;
  log: Log;
  checked: boolean;
  logTableColumnsOrder: (LogTableColumn | ModelTypeName)[];

  onClick: (l: Log) => void;
  onDelete: (l: Log) => void;
  onClickScroll: (rowRef: RefObject<HTMLDivElement>) => void;
  onCheckboxChange: (log: Log, checked: boolean) => void;
}

const LogTableRow: React.FC<Props> = (props) => {
  const { log, style, checked, onClick, onClickScroll, onDelete, onCheckboxChange, logTableColumnsOrder } = props;
  const {
    id,
    time,
    expectedResult,
    expectedResultChanges,
    tag,
    userEmail,
    os,
    browser,
    deviceType,
    chOS,
    chOSVersion,
    chBrowser,
    chIsMobile,
    chDeviceMemory,
    chDownlink,
    chECT,
    chRTT,
    chSaveData,
    chArch,
    chBitness,
    chModel,
    fromCamera,
    feedback,
    feedbackChanges,
    modelFormula,
    modelPredictionList,
    predictionStatusCode,
    predictionResult,
    scanType,
    snifferEndpoint,
    snifferBaseUrl,
    snifferMode,
    expectedBrightness,
    expectedBrightnessChanges,
    expectedLocation,
    expectedLocationChanges,
    storeImagesEndpoint,
    isSessionResult,
    sessionId,
    sessionFormula,
    sessionResult,
    sessionScore,
    organisationName,
    thumbnail,
    errorCode,
    laplacianMean,
    laplacianTime,
    laplacianVariance,
    brightnessScore,
    brightnessDuration,
    error,
    metadataDeviceField,
    secureVersion,
    levelOfAssurance,
    operator,
    threshold,
    isSecure,
    imgValidationLevel,
    ageEstimationModel,
  } = log;

  const theme = useTheme();

  const { panelConfigurationData } = React.useContext(GlobalContext);

  // Source icon
  let SourceIcon = DeviceUnknownRounded;
  switch (fromCamera) {
    case PredictionSource.Camera:
      SourceIcon = PhotoCameraRounded;
      break;
    case PredictionSource.Upload:
      SourceIcon = CloudUploadRounded;
      break;
    case PredictionSource.ExternalClient:
      SourceIcon = TrendingFlatOutlined;
      break;
    case PredictionSource.DataCollection:
      SourceIcon = FaceRounded;
      break;
  }

  // Feedback
  let feedbackColor = theme.palette.primary.main;
  let FeedbackIcon = HelpRounded;
  if (feedback) {
    FeedbackIcon = feedback === "correct" ? CheckCircleRounded : CancelRounded;
    feedbackColor = feedback === "correct" ? theme.palette.success.main : theme.palette.error.main;
  }

  const rowRef = useRef(null);

  const classes = useStyles();

  const predictionErrorMessage = () => {
    const failedModels = modelFormula.filter((model) => !!modelPredictionList[model].error);
    return (
      <>
        <div>{error}</div>
        {failedModels.length !== 0 && (
          <div>
            <br />
            {predictionErrorFormat(modelPredictionList, modelFormula)}
          </div>
        )}
      </>
    );
  };

  const getBooleanIcon = (value?: boolean) => {
    if (value === undefined || value === null) return "-";
    if (value) return <CheckCircleRounded className={classes.success} />;
    return <CancelRounded className={classes.error} />;
  };

  const getLogTableColumnValues = (): Record<LogTableColumn, React.ReactNode> => {
    return {
      [LogTableColumn.ID]: id,
      [LogTableColumn.Thumbnail]: <Thumbnail src={thumbnail} />,
      [LogTableColumn.Time]: <DateLabel value={time} />,
      [LogTableColumn.ExpectedResult]: (
        <ValueChanges list={expectedResultChanges} value={expectedResultToString(expectedResult as ExpectedResult)} />
      ),
      [LogTableColumn.PredictionStatus]: predictionStatusCode,
      [LogTableColumn.ErrorCode]: errorCode && (
        <Tooltip title={errorCode} disableHoverListener={!errorCode}>
          <div className={classes.errorCode}>{errorCode}</div>
        </Tooltip>
      ),
      [LogTableColumn.PredictionResult]:
        predictionResult === PredictionFormulaResult.Error ? (
          <TooltipError
            title={predictionResult}
            error={predictionErrorMessage()}
            titleStyle={classes.predictionResultTitle}
          />
        ) : (
          predictionResult
        ),
      [LogTableColumn.Feedback]:
        feedbackChanges?.length > 0 ? (
          <TooltipAuditChanges changes={feedbackChanges}>
            <Grid container alignItems="center">
              <FeedbackIcon style={{ color: feedbackColor }} /> *
            </Grid>
          </TooltipAuditChanges>
        ) : (
          <FeedbackIcon style={{ color: feedbackColor }} />
        ),
      [LogTableColumn.Source]: <SourceIcon className={classes.sourceIcon} />,
      [LogTableColumn.UserEmail]: userEmail && (
        <Tooltip title={userEmail} disableHoverListener={!userEmail}>
          <div className={classes.emailContainer}>{userEmail}</div>
        </Tooltip>
      ),
      [LogTableColumn.Organisation]: organisationName,
      [LogTableColumn.ScanType]:
        scanType === "FCM scan" ? (
          <Tooltip title="Face Capture Module scan">
            <span>{scanType}</span>
          </Tooltip>
        ) : (
          scanType
        ),
      [LogTableColumn.Session]: sessionId && (
        <Tooltip title={sessionId}>
          <span>{sessionId.substr(0, 4)}</span>
        </Tooltip>
      ),
      [LogTableColumn.IsSessionResult]: getBooleanIcon(isSessionResult),
      [LogTableColumn.SessionResult]: sessionResult,
      [LogTableColumn.SessionScore]: sessionScore?.toFixed(2),
      [LogTableColumn.SessionFormula]: sessionFormula,
      [LogTableColumn.OS]: os,
      [LogTableColumn.Browser]: browser,
      [LogTableColumn.Device]: deviceType,
      [LogTableColumn.ClientHintsOS]: chOS,
      [LogTableColumn.ClientHintsOSVersion]: chOSVersion,
      [LogTableColumn.ClientHintsBrowser]: chBrowser,
      [LogTableColumn.ClientHintsIsMobile]: getBooleanIcon(chIsMobile),
      [LogTableColumn.ClientHintDeviceMemory]: chDeviceMemory,
      [LogTableColumn.ClientHintDownlink]: chDownlink,
      [LogTableColumn.ClientHintSaveData]: getBooleanIcon(chSaveData),
      [LogTableColumn.ClientHintECT]: chECT,
      [LogTableColumn.ClientHintRTT]: chRTT,
      [LogTableColumn.ClientHintArch]: chArch,
      [LogTableColumn.ClientHintBitness]: chBitness,
      [LogTableColumn.ClientHintModel]: chModel,
      [LogTableColumn.Tag]: <div className={classes.tagTableCell}>{tag}</div>,
      [LogTableColumn.AIServiceURL]: snifferBaseUrl,
      [LogTableColumn.Endpoint]: snifferEndpoint,
      [LogTableColumn.Mode]: snifferMode,
      [LogTableColumn.StoreImagesEndpoint]: getBooleanIcon(storeImagesEndpoint),
      [LogTableColumn.LaplacianScore]: (
        <span>{laplacianMean && laplacianVariance && (laplacianVariance / laplacianMean).toFixed(3)}</span>
      ),
      [LogTableColumn.LaplacianDuration]: laplacianTime?.toFixed(3),
      [LogTableColumn.ExpectedLocation]: <ValueChanges list={expectedLocationChanges} value={expectedLocation} />,
      [LogTableColumn.ExpectedBrightness]: <ValueChanges list={expectedBrightnessChanges} value={expectedBrightness} />,
      [LogTableColumn.BrightnessScore]:
        brightnessScore === undefined || brightnessScore === null ? undefined : brightnessScore,
      [LogTableColumn.BrightnessDuration]:
        brightnessDuration === undefined || brightnessDuration === null ? undefined : brightnessDuration,
      [LogTableColumn.BrightnessValidationsResults]: getBrightnessValidationResult(log),
      [LogTableColumn.MetadataDeviceField]: metadataDeviceField,
      [LogTableColumn.SecureVersion]: secureVersion,
      [LogTableColumn.LevelOfAssurance]: levelOfAssurance,
      [LogTableColumn.Operator]: operator,
      [LogTableColumn.Threshold]: threshold,
      [LogTableColumn.IsSecure]: getBooleanIcon(isSecure),
      [LogTableColumn.ImgValidationLevel]: imgValidationLevel,
      [LogTableColumn.AgeEstimationModel]: ageEstimationModel,
    };
  };

  const getLogTableModelColumnValues = (): Record<ModelTypeName, React.ReactNode> => {
    const elements: Record<string, React.ReactNode> = {};
    for (const mt of panelConfigurationData.logConfiguration.logTableColumnsOrder) {
      if (Object.values(ModelTypeName).includes(mt as ModelTypeName)) {
        const modelType: ModelType = getModelType(mt as ModelTypeName);
        const modelPrediction: ModelPredictionType | undefined = modelPredictionList[modelType];
        const Cell = ModelsConfig[modelType] ? ModelsConfig[modelType].Cell : null;
        if (Cell) {
          elements[mt as ModelTypeName] = modelPrediction.error ? (
            <TooltipError title="error" error={modelPrediction.error} />
          ) : (
            <Cell formula={modelFormula} modelType={modelType} prediction={modelPrediction} />
          );
        }
      }
    }
    return elements;
  };

  const allLogTableColumnValues: Record<LogTableColumn | ModelTypeName, React.ReactNode> = {
    ...getLogTableColumnValues(),
    ...getLogTableModelColumnValues(),
  };

  return (
    <TableRow
      style={style}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(log);
        onClickScroll(rowRef);
      }}
      ref={rowRef}
    >
      <NoClickPropagationTableCell padding="checkbox">
        <Checkbox color="primary" checked={checked} onChange={(e) => onCheckboxChange(log, e.target.checked)} />
      </NoClickPropagationTableCell>
      <TableCell align="center">
        <IconButton
          aria-label="Delete"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete(log);
          }}
          className={classes.iconButton}
        >
          <DeleteRounded />
        </IconButton>
      </TableCell>
      {logTableColumnsOrder.map((v, i) => {
        const value = allLogTableColumnValues[v];
        return (
          <TableCell key={i} className={classes.tableCell} align="center">
            {value ? value : NO_VALUE_CELL}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const NoClickPropagationTableCell: React.FC<TableCellProps> = (props) => {
  return (
    <TableCell {...props} onClick={(e) => e.stopPropagation()}>
      {props.children}
    </TableCell>
  );
};

export default LogTableRow;
