import { ModelResultType, TensorflowResult } from "../../../models/log";
import { ModelType, TimeStruct } from "../../../models/utils";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { GlobalContext } from "../../../globalContext";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import { ListLabel } from "./LogDetail";
import { ModelDetailsContainer } from "./ModelDetails/ModelDetailsContainer";
import { ModelErrorDetails } from "./ModelDetails/ModelErrorDetails";
import { ModelsConfig } from "../../../config/modelsConfig";
import { Paper } from "@material-ui/core";
import { ProcessingTime } from "./Time/ProcessingTime";
import React from "react";
import clone from "clone";

interface Props {
  modelsResult: TensorflowResult;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    models: {
      [theme.breakpoints.down(430)]: {
        fontSize: "4vw",
        display: "grid",
      },
    },
    modelDetailsContainer: {
      padding: theme.spacing(3),
      overflowX: "auto",
    },
  }),
);

export const ModelResult: React.FC<Props> = (props) => {
  const { panelConfigurationData } = React.useContext(GlobalContext);
  const items = [];

  const classes = useStyles(props);

  // Clone the input in order to not modify the source property value. This
  // component will change the key "image_processing" in cropping and mtcnn
  // processing time lists to "image_processing_mtcnn" and
  // "image_processing_cropped".
  const modelsResult = clone(props.modelsResult);

  // Differentiate time processing keys
  modelsResult.mtcnn.processingTime.forEach((t) => {
    if (t.key === "image_processing") {
      t.key = "image_processing_mtcnn";
    }
  });
  modelsResult.cropping.processingTime.forEach((t) => {
    if (t.key === "image_processing") {
      t.key = "image_processing_cropped";
    }
  });

  for (let i = 0; i < panelConfigurationData.logConfiguration.modelsResultDisplay.length; i++) {
    const modelType = (panelConfigurationData.logConfiguration.modelsResultDisplay[i] as unknown) as ModelType;
    const modelResult: ModelResultType = props.modelsResult.aiResult.models[modelType];
    const currentModelConfig = ModelsConfig[modelType] ? ModelsConfig[modelType] : null;

    // Do not show the model result details if the configuration is not found
    if (panelConfigurationData.logConfiguration.modelsResultDisplay[i] && currentModelConfig) {
      let cropped: string[] = [],
        debugImg: string | undefined,
        timeDetail: TimeStruct[] = [];

      // Check if the processing time does not have any value. It means the model did
      // not run for this log record. Both error and old records cases return null
      // data. The only difference is that the error case contain the error prop. These
      // variables are only calculated for old records
      if (!(modelResult.processingTime && modelResult.processingTime.length > 0) && !modelResult.error) {
        continue;
      } else {
        if (typeof currentModelConfig.Cropping === "string") {
          cropped = [props.modelsResult.cropping.imgs[currentModelConfig.Cropping]];
        } else {
          cropped = currentModelConfig.Cropping.map((c) => props.modelsResult.cropping.imgs[c]);
        }
        debugImg = "debugImage" in modelResult ? modelResult.debugImage : undefined;

        timeDetail = modelsResult.mtcnn.processingTime.concat(
          modelsResult.cropping.processingTime,
          modelsResult.aiResult.processingTime,
          modelsResult.processingTime ? modelsResult.processingTime : [],
          modelResult.processingTime,
        );

        timeDetail.sort((t1, t2) => {
          if (t1.startTime > t2.startTime) {
            return 1;
          }

          if (t1.startTime < t2.startTime) {
            return -1;
          }

          return 0;
        });
      }

      items.push(
        modelResult !== undefined && (
          <Grid key={i} item xs={11} sm={6} md={4}>
            <Paper elevation={1} className={classes.modelDetailsContainer}>
              <ModelDetailsContainer
                showHistogram={panelConfigurationData.logConfiguration.showHistograms}
                showDebugImages={panelConfigurationData.logConfiguration.showDebugImages}
                cropped={cropped}
                modelType={modelType}
                debugImage={debugImg}
                modelResult={modelResult}
              >
                {modelResult.error ? (
                  <ModelErrorDetails error={modelResult.error} />
                ) : (
                  <>
                    <currentModelConfig.Details
                      modelType={modelType}
                      modelResult={modelResult}
                      cropping={modelsResult.cropping}
                      mtcnn={modelsResult.mtcnn}
                      timeDetail={timeDetail}
                    />
                    {timeDetail !== undefined && (
                      <ListItem divider className={classes.models}>
                        <Grid item xs={6} sm={12}>
                          <ProcessingTime
                            timeDetails={timeDetail.filter(
                              (t) => t.key === "cropped_" + currentModelConfig.Cropping || !t.key.includes("cropped"),
                            )}
                            startTimeKey={"predict"}
                            endTimeKey={"predict"}
                          />
                        </Grid>
                      </ListItem>
                    )}
                    {modelResult.checkpoint !== "" && modelResult.checkpoint !== undefined && (
                      <ListItem className={classes.models}>
                        <ListLabel title="Model" value={modelResult.checkpoint} />
                      </ListItem>
                    )}
                  </>
                )}
              </ModelDetailsContainer>
            </Paper>
          </Grid>
        ),
      );
    }
  }

  return <>{items}</>;
};
