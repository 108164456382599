import { Dialog as DialogMaterial, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";

import { Button } from "./Button";
import React from "react";
import classNames from "classnames";

interface Props {
  title: string;
  action: string;
  onConfirm: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  open?: boolean;
  message?: string;
  secondMessage?: string;
  className?: string;
  buttons?: JSX.Element[];
  large?: boolean;
  preventAutoClose?: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: Props) => ({
      padding: "20px 40px 20px",
      [theme.breakpoints.down("sm")]: {
        width: !props.large ? "180px" : "",
      },
      [theme.breakpoints.up("sm")]: {
        width: !props.large ? "320px" : "",
      },
    }),
    dialog: (props: Props) => ({
      maxWidth: props.large ? "1000px" : "",
    }),
    title: {
      fontSize: 22,
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: 26,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    buttonGroup: (props: Props) => ({
      display: "flex",
      flexDirection: "column",
      maxWidth: props.large ? 340 : undefined,
      marginRight: props.large ? "auto" : undefined,
      marginLeft: props.large ? "auto" : undefined,
      [theme.breakpoints.down("sm")]: {
        marginTop: 26,
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: 56,
      },
      "& button": {
        margin: "10px 0px",
      },
    }),
    message: {
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
  }),
);

export const Dialog: React.FC<Props> = (props) => {
  const {
    title,
    open,
    action,
    onConfirm,
    onClose,
    message,
    secondMessage,
    onCancel,
    className,
    large,
    buttons,
    preventAutoClose,
  } = props;
  const classes = useStyles(props);

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const closeAndExecute = () => {
    onConfirm();
    if (!preventAutoClose) {
      close();
    }
  };

  const cancel = () => {
    if (onCancel) {
      onCancel();
    }
    close();
  };

  return (
    <DialogMaterial onClose={cancel} open={open ? open : true} maxWidth={large ? "lg" : undefined}>
      <div className={className ? classNames(className, classes.root) : classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        {message && <Typography className={classes.message}>{`Are you sure you want to ${message}?`}</Typography>}
        {secondMessage && (
          <>
            <br />
            <Typography className={classes.message}>{secondMessage}</Typography>
          </>
        )}
        {props.children}
        <div className={classes.buttonGroup}>
          <Button variant="contained" fullWidth onClick={closeAndExecute}>
            {action}
          </Button>
          {buttons &&
            buttons.map((button) => {
              return button;
            })}

          <Button variant="contained" color="error" fullWidth onClick={cancel}>
            Cancel
          </Button>
        </div>
      </div>
    </DialogMaterial>
  );
};
