import { Sniffer } from "./utils";

export interface Organisation {
  id: number;
  name: string;
  creationDate: number;
  token: string;
  banned: boolean;
  dailyRequestsUsed: number;
  maxRequestsPerDay?: number;
  totalRequestsUsed: number;
  maxRequestsTotal?: number;
  sniffersConfig?: OrganisationSnifferConfig[];
  expirationHours?: number;
}

export interface OrganisationSnifferConfig {
  snifferID: number;
  snifferName: string;
  snifferKey: string;
  allowed: boolean;
  saveImg: boolean;
  endpointId?: number;
}

export interface PaginationOrg {
  orgs: Organisation[];
  count: number;
}

export const GetSnifferAllowedFromOrg = (s: Sniffer, orgs?: OrganisationSnifferConfig[]): boolean => {
  return orgs?.find((o) => o.snifferKey === s.key)?.allowed === true;
};

export const GetSnifferSaveImgFromOrg = (s: Sniffer, orgs?: OrganisationSnifferConfig[]): boolean => {
  return orgs?.find((o) => o.snifferKey === s.key)?.saveImg === true;
};

export const GetSnifferEndpointIdFromOrg = (s: Sniffer, orgs?: OrganisationSnifferConfig[]): number | undefined => {
  return orgs?.find((o) => o.snifferKey === s.key)?.endpointId;
};
