import { LogPrediction, RGBFASData } from "../../../../models/log";

import { ModelCellProps } from "../../../../config/modelsConfig";
import { PredictionCell } from "./PredictionCell";
import React from "react";
import { hasConfidence } from "../../../../models/utils";

export const AntiSpoofingCell: React.FC<ModelCellProps> = (props) => {
  const p = props.prediction as LogPrediction;
  const { prediction, real } = p;

  // Check for the confidence value in custom data.
  const rgbFASData = hasConfidence(props.modelType) && p.data ? (p.data as RGBFASData) : undefined;

  return <PredictionCell {...props} prediction={prediction} extraValue={rgbFASData?.confidence} real={real} />;
};
