import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Button } from "../../Utils/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FileInfo } from "../../../models/upload";
import React from "react";
import { readFiles } from "../../../utils/upload";

interface Props {
  disabled?: boolean;
  multiple?: boolean;
  onImagesSelected: (files: FileInfo[]) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    hidden: {
      display: "none",
    },
    uploadButton: (props: Props) => ({
      cursor: props.disabled ? "not-allowed" : "pointer",
    }),
  }),
);

export const UploadButton: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <input
        id="raised-button-file"
        className={classes.hidden}
        type="file"
        onChange={(e) => {
          readFiles(e.target.files).then((files) => {
            props.onImagesSelected(files);
          });
        }}
        disabled={props.disabled}
        multiple={props.multiple}
      />

      <label htmlFor="raised-button-file" className={classes.uploadButton}>
        <Button
          size="large"
          component="span"
          variant="contained"
          disabled={props.disabled}
          endIcon={<CloudUploadIcon />}
        >
          Upload
        </Button>
      </label>
    </>
  );
};
