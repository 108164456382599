import React, { useState } from "react";

import { NumericField } from "./NumericField";
import { StandardTextFieldProps } from "@material-ui/core";

interface Props extends Omit<StandardTextFieldProps, "onChange"> {
  onChange: (value?: number) => void;
}

export const PercentageInput: React.FC<Props> = (props) => {
  const [stringValue, setStringValue] = useState(props.value);

  return (
    <NumericField
      {...props}
      // If the input is undefined means the value needs to be
      // cleared independently of the inner stringValue.
      value={props.value !== undefined ? stringValue : undefined}
      onChange={(e) => {
        setStringValue(e === undefined ? "" : e);
        if (props.onChange) {
          props.onChange(e !== undefined ? e / 100 : undefined);
        }
      }}
    />
  );
};
