import { DeviceInfo } from "../models/device";
import { UAParser } from "ua-parser-js";

const parser = new UAParser(navigator.userAgent);
const network = (window.navigator as any).connection;
const deviceMemory = (window.navigator as any).deviceMemory;

const userAgentHints: DeviceInfo = {
  ua: parser.getUA(),
  browser: parser.getBrowser(),
  device: parser.getDevice(),
  engine: parser.getEngine(),
  os: parser.getOS(),
  cpu: parser.getCPU(),
  network: {
    downlink: network?.downlink,
    ect: network?.effectiveType,
    rtt: network?.rtt,
    saveData: network?.saveData,
  },
  deviceMemory: deviceMemory,
};
export default userAgentHints;
