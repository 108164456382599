import { Theme, Toolbar, Typography, createStyles, makeStyles } from "@material-ui/core";

import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import BlockRoundedIcon from "@material-ui/icons/BlockRounded";
import { Button } from "./../Utils/Button";
import { GlobalContext } from "../../globalContext";
import React from "react";
import { TextFieldDelay } from "../Utils/TextFieldDelay";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import classNames from "classnames";
import clsx from "clsx";
import { isAdmin } from "../../models/role";

interface Props {
  usersSelected: number[];

  onEmailChange: (email?: string) => void;
  onVerify: () => void;
  onBan: () => void;
  onCreateClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      flexDirection: "row",
      display: "flex",
    },
    highlight: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    title: {
      flex: "1 1 100%",
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
      fontSize: 18,
    },
    titleContainer: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },
    buttonGroup: {
      flexDirection: "row",
      display: "flex",
      whiteSpace: "nowrap",
      textAlign: "center",
    },
    tableButtonGroup: {
      marginRight: 15,
      marginTop: 10,
    },
    button: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      margin: "6px 10px",
    },
  }),
);

export const UserManagementTableToolbar: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { usersSelected, onVerify, onBan, onEmailChange, onCreateClick } = props;
  const { session } = React.useContext(GlobalContext);
  const isAdminUser = session ? isAdmin(session.role) : false;

  const size = usersSelected.length;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: size > 0,
      })}
    >
      {size > 0 ? (
        <Typography className={classes.title} color="inherit" variant="h6" component="div">
          {size} selected
        </Typography>
      ) : (
        <div className={classes.titleContainer}>
          <Typography className={classes.title} color="inherit" variant="h6" component="div">
            User management
          </Typography>
          {isAdminUser && (
            <div className={classNames(classes.tableButtonGroup, classes.buttonGroup)}>
              <Button variant="contained" color="primary" endIcon={<AddCircleRoundedIcon />} onClick={onCreateClick}>
                Create user
              </Button>
            </div>
          )}
          <TextFieldDelay label="Search by email" onChangeValue={onEmailChange} variant="standard" />
        </div>
      )}

      {size > 0 && (
        <div className={classes.buttonGroup}>
          <Button
            className={classes.button}
            variant="contained"
            endIcon={<VerifiedUserOutlinedIcon />}
            onClick={() => onVerify()}
          >
            Verify
          </Button>
          <Button className={classes.button} variant="contained" endIcon={<BlockRoundedIcon />} onClick={() => onBan()}>
            Ban
          </Button>
        </div>
      )}
    </Toolbar>
  );
};
