import { FaceCaptureModuleErrorCodes, FaceCaptureModuleErrorsStats } from "../../models/myface";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createStyles,
  makeStyles,
} from "@material-ui/core";

import { Conditional } from "../../../../components/Utils/Conditional";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    tableCellNoWrap: {
      whiteSpace: "nowrap",
    },
    csvDownload: {
      marginTop: "10px",
    },
  }),
);

interface FaceCaptureModuleErrorsProps {
  faceCaptureModuleErrorsStats?: FaceCaptureModuleErrorsStats;
  error?: Error;
  loading?: boolean;
}

export const FaceCaptureModuleErrors: React.FC<FaceCaptureModuleErrorsProps> = (props) => {
  const classes = useStyles();

  const { faceCaptureModuleErrorsStats, error, loading } = props;

  const renderRow = (errorName: string, num: number, percentage: number) => {
    return (
      <TableRow>
        <TableCell className={classes.tableCellNoWrap} component="th" scope="row">
          {errorName}
        </TableCell>
        <TableCell align="center" className={classes.tableCellNoWrap}>
          {num}
        </TableCell>
        <TableCell align="center" className={classes.tableCellNoWrap}>
          {`${(percentage * 100).toFixed(2)}%`}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Conditional error={error} errorMessage={"Failed to load face capture module errors stats"} loading={loading}>
      {faceCaptureModuleErrorsStats && (
        <>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Error type</TableCell>
                  <TableCell align="center">Number of Users</TableCell>
                  <TableCell align="center">Percentage (num users / signups)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRow(
                  FaceCaptureModuleErrorCodes.NoCamera,
                  faceCaptureModuleErrorsStats.noCamera,
                  faceCaptureModuleErrorsStats.noCameraPercentage,
                )}
                {renderRow(
                  FaceCaptureModuleErrorCodes.GenericCameraError,
                  faceCaptureModuleErrorsStats.genericCameraError,
                  faceCaptureModuleErrorsStats.genericCameraErrorPercentage,
                )}
                {renderRow(
                  FaceCaptureModuleErrorCodes.UnsupportedBrowser,
                  faceCaptureModuleErrorsStats.unsupportedBrowser,
                  faceCaptureModuleErrorsStats.unsupportedBrowserPercentage,
                )}
                {renderRow(
                  FaceCaptureModuleErrorCodes.NoCameraPermission,
                  faceCaptureModuleErrorsStats.noCameraPermission,
                  faceCaptureModuleErrorsStats.noCameraPermissionPercentage,
                )}
                {renderRow(
                  FaceCaptureModuleErrorCodes.Overconstrained,
                  faceCaptureModuleErrorsStats.overconstrained,
                  faceCaptureModuleErrorsStats.overconstrainedPercentage,
                )}
                {renderRow(
                  FaceCaptureModuleErrorCodes.FaceDetectionInitError,
                  faceCaptureModuleErrorsStats.faceDetectionInitError,
                  faceCaptureModuleErrorsStats.faceDetectionInitErrorPercentage,
                )}
                {renderRow(
                  FaceCaptureModuleErrorCodes.InternalError,
                  faceCaptureModuleErrorsStats.internalError,
                  faceCaptureModuleErrorsStats.internalErrorPercentage,
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Conditional>
  );
};
