import { Endpoint, EndpointPage } from "../models/endpoint";

import { ApiBase } from "./base";
import { Method } from "axios";

export class EndpointService extends ApiBase {
  getAll = (page: number, pageSize: number): Promise<EndpointPage> => {
    const url = `endpoint?page=${page}&page-size=${pageSize}`;
    return this.fetch<EndpointPage>(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  upsert = (endpoint: Endpoint, httpMethod: Method): Promise<void> => {
    return this.fetch("endpoint", {
      method: httpMethod,
      data: JSON.stringify(endpoint),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  delete = (id: number): Promise<void> => {
    const url = `endpoint/${id}`;
    return this.fetch(url, {
      method: "DELETE",
    });
  };

  getModes = (): Promise<string[]> => {
    return this.fetch<string[]>("endpoint/modes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
}
