import { Tooltip, createStyles, makeStyles, withStyles } from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import React from "react";

interface Props {
  src: string;
}

const tooltipSize = 500;
const tooltipSizeMobile = 300;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[100],
    maxWidth: tooltipSize,
    maxHeight: tooltipSize,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: "4px 4px 0 4px",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) =>
  createStyles({
    tooltipImg: {
      [theme.breakpoints.down("sm")]: {
        maxWidth: tooltipSizeMobile,
        maxHeight: tooltipSizeMobile,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: tooltipSize,
        maxHeight: tooltipSize,
      },
    },
    avatar: { "& div": { margin: "auto" } },
  }),
);

export const Thumbnail: React.FC<Props> = ({ src }) => {
  const classes = useStyles();
  const avatar = (
    <div className={classes.avatar}>
      <Avatar alt="Thumbnail" src={src} />
    </div>
  );

  return src ? (
    <HtmlTooltip placement="right" title={<img className={classes.tooltipImg} alt="Thumbnail" src={src} />}>
      {avatar}
    </HtmlTooltip>
  ) : (
    avatar
  );
};
