import { snakeToHuman } from "../utils/strings";

export interface Role {
  id: number;
  code: string;
  name: string;
  description: string;
}

export enum RoleCode {
  Admin = "admin",
  /**
   * Reviewer role is not included on the "role hierarchy".
   * It is considered as an independent role with its own permissions.
   *
   * For this reason, it is included on some of the `isAtLeast` functions
   * but not on the others.
   */
  Reviewer = "reviewer",
  Tester = "tester",
  DataCollector = "datacollector",
  User = "user",
}

export function isAdmin(role: RoleCode): boolean {
  return role === RoleCode.Admin;
}

export function isReviewer(role: RoleCode): boolean {
  return [RoleCode.Admin, RoleCode.Reviewer].indexOf(role) >= 0;
}

export function isAtLeastTester(role: RoleCode): boolean {
  return [RoleCode.Admin, RoleCode.Tester].indexOf(role) >= 0;
}

export function isAtLeastDataCollector(role: RoleCode): boolean {
  return [RoleCode.DataCollector, RoleCode.Tester, RoleCode.Admin].indexOf(role) >= 0;
}

/**
 * Receives a valid user role and returns whether it has access
 * to the panel features or not.
 * @param role: RoleCode
 * @returns whether the given role has access to the panel or not.
 */
export function hasAccess(role: RoleCode): boolean {
  return role !== RoleCode.User;
}

export function roleToString(role: RoleCode): string {
  return snakeToHuman(role);
}
