import { createStyles, makeStyles } from "@material-ui/core";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Picture } from "../../models/image";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import { SelfieCaptureType } from "../../models/config";

const useStyles = makeStyles((theme) =>
  createStyles({
    radioButtonLabel: {
      color: theme.palette.text.secondary,
    },
  }),
);

/**
 * Returns the list of valid 'Picture' values for 'selfieCaptureType' field
 * provided.
 * @param selfieCaptureType The selfie capture type that will filter the list.
 */
export const getItems = (selfieCaptureType: SelfieCaptureType) => {
  // Do a cast as 'Object.values' returns a list of strings and 'Picture' values
  // are strings.
  const list = (Object.values(Picture) as Picture[]).filter((v) => v !== Picture.None);

  switch (selfieCaptureType) {
    case SelfieCaptureType.Scan:
    case SelfieCaptureType.Button:
      return list.filter((v) => v !== Picture.CroppedPicture);
    default:
      return list.filter((v) => v !== Picture.Optional);
  }
};

interface Props {
  disabled?: boolean;
  selfieCaptureType: SelfieCaptureType;
  value: Picture;

  onChange: (value: Picture) => void;
}

export const PictureToSendForm: React.FC<Props> = ({ disabled, selfieCaptureType, value, onChange }) => {
  const classes = useStyles();
  const items = getItems(selfieCaptureType);

  return (
    <RadioGroup row={true} value={value} onChange={(e) => onChange(e.target.value as Picture)}>
      {items.map((picture) => {
        return (
          <FormControlLabel
            color="textSecondary"
            value={picture}
            key={String(picture)}
            control={<Radio color="primary" />}
            label={picture.charAt(0).toUpperCase() + picture.slice(1)}
            labelPlacement="top"
            classes={{ label: classes.radioButtonLabel }}
            disabled={disabled}
          />
        );
      })}
    </RadioGroup>
  );
};
