import * as d3 from "d3";

import React, { useEffect, useRef, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    canvas: {
      display: "none",
    },
    button: {
      color: theme.palette.primary.main,
      height: 30,
    },
  }),
);

interface Props {
  img: string;
  width?: number;
}

interface D {
  [key: string]: number;
}
interface Data {
  pixelSum: D;
  rD: D;
  gD: D;
  bD: D;
}
const colors = ["red", "green", "blue"] as const;
type ColorTuple = typeof colors;
type Color = ColorTuple[number];

function histogram(data: Data, q: SVGSVGElement, W: number) {
  const H = W / 1.8;
  const svg = d3.select(q);
  const margin = { top: 20, right: 20, bottom: 30, left: 50 };
  const width = W - margin.left - margin.right;
  const height = H - margin.top - margin.bottom;
  q.style.width = W.toString();
  q.style.height = H.toString();
  svg.selectAll(".y-axis").remove(); // Remove old y-axis

  function graphComponent(d: D, color: Color, yAxis: boolean) {
    svg.selectAll(".bar-" + color).remove();
    const data = Object.keys(d).map(function (key) {
      return { freq: d[key], idx: +key };
    });
    const maxIdx = d3.max(data, (d) => {
      return d.idx;
    });
    const x = d3
      .scaleLinear()
      .range([0, width])
      .domain([0, maxIdx || 0]);
    const maxFreq = d3.max(data, (d) => {
      return d.freq;
    });
    const y = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, maxFreq || 0]);
    const g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    if (yAxis) {
      g.append("g")
        .attr("class", "y-axis")
        .attr("transform", "translate(" + -2 + ",0)")
        .call(d3.axisLeft(y).ticks(10).tickSizeInner(10).tickSizeOuter(2));
    }
    g.selectAll(".bar-" + color)
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar-" + color)
      .attr("fill", color)
      .attr("x", (d) => {
        return x(d.idx);
      })
      .attr("y", (d) => {
        return y(d.freq);
      })
      .attr("width", 2)
      .attr("opacity", 0.7)
      .attr("height", (d) => {
        return height - y(d.freq);
      });
  }
  // Render yAxis for data with highest value.
  const gMax = Math.max(...Object.keys(data.gD).map((k) => data.gD[k]));
  const bMax = Math.max(...Object.keys(data.bD).map((k) => data.bD[k]));
  const rMax = Math.max(...Object.keys(data.rD).map((k) => data.rD[k]));
  graphComponent(data.gD, "green", gMax >= bMax && gMax >= rMax);
  graphComponent(data.bD, "blue", bMax > gMax && bMax > rMax);
  graphComponent(data.rD, "red", rMax > gMax && rMax > bMax);
}

function calcAndGraph(img: HTMLImageElement, cv: HTMLCanvasElement, svg: SVGSVGElement, width: number) {
  const rD: D = {},
    gD: D = {},
    bD: D = {};
  const ctx = cv.getContext("2d");
  if (!ctx) {
    return;
  }
  cv.width = img.width;
  cv.height = img.height;
  ctx.drawImage(img, 0, 0);
  const pixelSum: D = {};
  const iD = ctx.getImageData(0, 0, cv.width, cv.height).data;
  for (let i = 0; i < 256; i++) {
    rD[i] = 0;
    gD[i] = 0;
    bD[i] = 0;
  }
  for (let i = 0; i <= 765; i++) {
    pixelSum[i] = 0;
  }
  for (let i = 0; i < iD.length; i += 4) {
    pixelSum[iD[i] + iD[i + 1] + iD[i + 2]]++;
    rD[iD[i]]++;
    gD[iD[i + 1]]++;
    bD[iD[i + 2]]++;
  }
  histogram({ pixelSum, rD, gD, bD }, svg, width);
}

export const Histogram: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [boost, setBoost] = useState<"blend" | Color>("blend");
  const cvRef = useRef<HTMLCanvasElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);

  const svg = svgRef && svgRef.current ? svgRef.current : undefined;

  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      if (cvRef && cvRef.current && svg) {
        const width = props.width || 700;
        calcAndGraph(img, cvRef.current, svg, width);
      }
    };
    img.src = props.img.indexOf(",") >= 0 ? props.img : `data:image/png;base64,${props.img}`;
  }, [props.img, props.width, cvRef, svg]);

  useEffect(() => {
    if (!svg) {
      return;
    }

    if (boost === "blend") {
      svg.querySelectorAll("rect").forEach((bar) => {
        bar.style.opacity = "0.7";
      });
    } else {
      const deaden = colors.filter((e) => e !== boost);
      svg.querySelectorAll<SVGElement>(".bar-" + boost).forEach((bar) => {
        bar.style.opacity = "1.0";
      });
      deaden.forEach((color) => {
        svg.querySelectorAll<SVGElement>(".bar-" + color).forEach((bar) => {
          bar.style.opacity = "0.2";
        });
      });
    }
  }, [boost, svg]);

  return (
    <div>
      <ToggleButtonGroup size="small" value={boost} exclusive={true} onChange={(e, value) => setBoost(value)}>
        <ToggleButton className={classes.button} value={"red"}>
          ++R
        </ToggleButton>
        <ToggleButton className={classes.button} value={"green"}>
          ++G
        </ToggleButton>
        <ToggleButton className={classes.button} value={"blue"}>
          ++B
        </ToggleButton>
        <ToggleButton className={classes.button} value={"blend"}>
          Blend
        </ToggleButton>
      </ToggleButtonGroup>
      <div>
        <svg width="1" height="1" ref={svgRef}></svg>
      </div>
      <canvas ref={cvRef} className={classes.canvas}></canvas>
    </div>
  );
};
