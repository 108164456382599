import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Link as MaterialUILink } from "react-router-dom";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    link: (props: Props) => ({
      cursor: props.disabled ? "not-allowed" : "pointer",
      textDecoration: "none",
      padding: "12px 0px 6px 0px",
    }),
  }),
);

interface Props {
  to: string;
  disabled: boolean;
}

export const Link: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <MaterialUILink
      to={props.to}
      className={classes.link}
      onClick={(e) => {
        if (props.disabled) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {props.children}
    </MaterialUILink>
  );
};
