import React from "react";

interface Props {
  field: string;
  value: string;
}

export const FieldValidationResult: React.FC<Props> = ({ field, value }) => (
  <div>
    <b>{field}:</b> {value}
  </div>
);
