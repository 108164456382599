import { ModelType, ModelTypeName } from "../../models/utils";
import React, { useCallback, useEffect, useState } from "react";

import Config from "../../config";
import { ConfigService } from "../../api/config";
import { ConfigurationProps } from "./ConfigurationPanel";
import ConfigurationSection from "./ConfigurationSection";
import ConfigurationSubsection from "./ConfigurationSubsection";
import { DragAndDropSelection } from "../Utils/DragAndDrop/DragAndDropSelection";
import { GlobalContext } from "../../globalContext";
import { Grid } from "@material-ui/core";
import { LogConfiguration } from "../../models/config";
import { LogTableColumn } from "../../models/log";
import { SliderBar } from "../Utils/SliderBar";
import { SwitchElement } from "../Utils/SwitchElement";
import { Value } from "../Utils/DragAndDrop/dragAndDrop";

// Section constants
const CSV_DOWNLOAD_SECTION_DESCRIPTION =
  "Configuration related to the CSV file that can be downloaded from the log panel";
const PREFERENCES_SECTION_DESCRIPTION = "Settings that are applied when the log is being saved in database";
const LOG_TABLE_SECTION_DESCRIPTION = "How the logs information is displayed in the log table";
const LOG_DETAILS_SECTION_DESCRIPTION = "How the information is displayed in the details section for an specific log";

enum LogSection {
  Preferences = "Preferences",
  LogTable = "Log table",
  LogDetails = "Log details",
  CSVDownload = "CSV Download",
}

const service = new ConfigService(Config.apiUrl);

enum ModelsOrderSelectType {
  ModelsResultDisplay = "modelsResultDisplay",
  LogTableColumnsOrder = "logTableColumnsOrder",
}

export const LogPanel: React.FC<ConfigurationProps<LogConfiguration>> = (props) => {
  const { isAdmin, onConfigurationChange, config } = props;
  const section = config.logConfiguration;

  // CSV headers
  const { panelConfigurationData } = React.useContext(GlobalContext);
  const [selectedValues, setSelectedValues] = useState<string[]>(section["csvHeaders"]);
  const [allHeaders, setAllHeaders] = useState<string[]>([]);

  useEffect(() => {
    service.getCSVHeaders().then((values) => {
      setAllHeaders(values);
    });
  }, []);

  const onItemsChange = useCallback(
    (data: Value<string>[]) => {
      const selectedValuesCopy: string[] = data.map((value) => value.name);
      setSelectedValues(selectedValuesCopy);
      onConfigurationChange({
        ...section,
        csvHeaders: selectedValuesCopy,
      });
    },
    [onConfigurationChange, section],
  );

  const setPanelConfigurationSlider = (value: number, name: keyof LogConfiguration) => {
    if (!name) return;
    onConfigurationChange({
      ...section,
      [name]: value,
    });
  };

  return (
    <div>
      <ConfigurationSection title={LogSection.Preferences} description={PREFERENCES_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Log preferences">
          <SwitchElement
            title="Show Histograms"
            checked={section.showHistograms}
            onChange={(checked) =>
              onConfigurationChange({
                ...section,
                showHistograms: checked,
              })
            }
            disabled={!isAdmin}
          />
          <SwitchElement
            title="Show Debug Images"
            checked={section.showDebugImages}
            onChange={(checked) =>
              onConfigurationChange({
                ...section,
                showDebugImages: checked,
              })
            }
            disabled={!isAdmin}
          />
          <SwitchElement
            title="Requester IP"
            checked={section.saveIp}
            onChange={(checked) =>
              onConfigurationChange({
                ...section,
                saveIp: checked,
              })
            }
            disabled={!isAdmin}
          />
          <SwitchElement
            title="Query audit log"
            checked={section.queryAuditLog}
            onChange={(checked) =>
              onConfigurationChange({
                ...section,
                queryAuditLog: checked,
              })
            }
            disabled={!isAdmin}
          />
        </ConfigurationSubsection>
      </ConfigurationSection>

      <ConfigurationSection title={LogSection.LogTable} description={LOG_TABLE_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Log table order">
          <ModelsOrderSelect
            isAdmin={isAdmin}
            config={config}
            onConfigurationChange={onConfigurationChange}
            orderListName={ModelsOrderSelectType.LogTableColumnsOrder}
            allValues={[...Object.values(LogTableColumn), ...Object.values(ModelTypeName)]}
          />
        </ConfigurationSubsection>
      </ConfigurationSection>

      <ConfigurationSection title={LogSection.LogDetails} description={LOG_DETAILS_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Log details order">
          <ModelsOrderSelect
            isAdmin={isAdmin}
            config={config}
            onConfigurationChange={onConfigurationChange}
            orderListName={ModelsOrderSelectType.ModelsResultDisplay}
            allValues={Object.values(ModelType)}
          />
        </ConfigurationSubsection>
      </ConfigurationSection>

      <ConfigurationSection title={LogSection.CSVDownload} description={CSV_DOWNLOAD_SECTION_DESCRIPTION}>
        <Grid item xs={12} sm={6} key={"limit-of-images"}>
          <ConfigurationSubsection title={"Limit of images to download"} />
          <SliderBar
            min={1}
            max={5000}
            step={10}
            value={section["limitImagesDownload"]}
            onChange={(v) => setPanelConfigurationSlider(v, "limitImagesDownload")}
            disabled={!isAdmin}
          />
        </Grid>
        <ConfigurationSubsection title="CSV file headers">
          {allHeaders.length > 0 && (
            <DragAndDropSelection
              allValues={allHeaders}
              selectedValues={selectedValues}
              onItemsChange={onItemsChange}
              disable={!isAdmin}
              actualStateValues={panelConfigurationData.logConfiguration.csvHeaders}
            ></DragAndDropSelection>
          )}
        </ConfigurationSubsection>
      </ConfigurationSection>
    </div>
  );
};

interface ModelsOrderSelectProps extends ConfigurationProps<LogConfiguration> {
  orderListName: ModelsOrderSelectType;
  allValues: (ModelTypeName | LogTableColumn | ModelType)[];
}

const ModelsOrderSelect: React.FC<ModelsOrderSelectProps> = (props) => {
  const { isAdmin, onConfigurationChange, config, orderListName, allValues } = props;
  const section = config.logConfiguration;

  const [selectedValues, setSelectedValues] = useState<(ModelTypeName | LogTableColumn | ModelType)[]>(
    section[orderListName],
  );

  const { panelConfigurationData } = React.useContext(GlobalContext);

  const onItemsChange = useCallback(
    (data: Value<ModelTypeName | LogTableColumn | ModelType>[]) => {
      const selectedValuesCopy: (ModelTypeName | LogTableColumn | ModelType)[] = data.map((value) => value.name);
      setSelectedValues(selectedValuesCopy);
      onConfigurationChange({
        ...section,
        [orderListName]: selectedValuesCopy,
      });
    },
    [onConfigurationChange, orderListName, section],
  );

  return (
    <DragAndDropSelection
      allValues={allValues}
      selectedValues={selectedValues}
      onItemsChange={onItemsChange}
      disable={!isAdmin}
      actualStateValues={panelConfigurationData.logConfiguration[orderListName]}
    ></DragAndDropSelection>
  );
};
