import { ConfigurationPanelSection, PluginConfiguration } from "../../models/config";
import { Divider, Grid, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { ListLabel, logDetailUseStyles } from "../../components/Log/LogDetail/LogDetail";
import React, { Fragment } from "react";

import { Config } from "./models/myface";
import { ConfigPanel } from "./components/ConfigPanel";
import { DashboardSection } from "../../components/Dashboard/DashboardSection";
import EnvConfig from "../../config/index";
import { ExtraInfo } from "../models";
import FaceRoundedIcon from "@material-ui/icons/FaceRounded";
import { HeaderLink } from "../../components/Header";
import { MyFaceService } from "./api/myface";
import { MyFaceStatsSection } from "./components/DashboardSection";
import { Plugin } from "../plugin";
import { Review } from "./components/Review";
import { Route } from "../../components/Session";
import { isReviewer } from "../../models/role";

export class MyFace implements Plugin<Config> {
  service: MyFaceService;

  constructor() {
    this.service = new MyFaceService(EnvConfig.apiUrl);
  }

  name(): string {
    return "MyFace";
  }

  routes(): Route[] {
    return [new Route({ path: "/review", component: Review })];
  }

  headerLinks(): HeaderLink[] {
    return [
      {
        name: "Review",
        path: "/review",
        shouldBeShown: isReviewer,
      },
    ];
  }

  logDetailsExtraInfo(extraInfo: ExtraInfo): JSX.Element[] {
    const classes = logDetailUseStyles();
    const res: JSX.Element[] = [];

    if (extraInfo["userID"] !== "") {
      res.push(
        <Fragment key="my-face-user-id">
          <ListItem>
            <ListLabel title={"User ID"} value={extraInfo["userID"] ?? "-"}></ListLabel>
          </ListItem>
          <Divider />
        </Fragment>,
      );
    }

    if (extraInfo["campaign_id"] !== "" || extraInfo["parner_id"] !== "") {
      res.push(
        <Fragment key="my-face-brck-data">
          <ListItem>
            <Grid item container xs={5} sm={12}>
              <span className={classes.title}>
                <b>BRCK Data:</b>
              </span>
              <Grid item>
                <ListLabel title={"Campaign ID"} value={extraInfo["campaign_id"]}></ListLabel>
              </Grid>
              <Grid item>
                <ListLabel title={"Partner ID"} value={extraInfo["partner_id"]}></ListLabel>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
        </Fragment>,
      );
    }

    return res;
  }

  dashboardSections(): JSX.Element[] {
    return [
      <DashboardSection key="my-face-stats-section" title="MyFace stats" storageKey="myFaceStatsShown">
        <MyFaceStatsSection />
      </DashboardSection>,
    ];
  }

  configPanelSection(
    pluginNumber: number,
    setSection: (newConfig: PluginConfiguration) => void,
  ): ConfigurationPanelSection<PluginConfiguration> {
    return {
      section: ConfigPanel,
      label: "MyFace",
      icon: <FaceRoundedIcon />,
      onConfigurationChange: setSection,
      pluginNumber: pluginNumber,
    };
  }

  configPanelButton(): JSX.Element {
    return (
      <>
        <ListItemIcon>
          <FaceRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="MyFace" />
      </>
    );
  }
}
