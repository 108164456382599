import React, { useCallback, useEffect, useRef, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { GlobalContext } from "../../../../globalContext";

interface Props {
  camera: MediaDeviceInfo;
  onInitStream: (videoRef: HTMLVideoElement, stream: MediaStreamTrack) => void;
  onError?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scanVideo: {
      webkitTransform: "scaleX(-1)",
      transform: "scaleX(-1)",
      height: "auto",
      width: "100%",
      borderRadius: "20px",
      marginTop: "20px",
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

const cameraMediaConstraints: MediaStreamConstraints[] = [
  {
    audio: false,
    video: {
      width: { exact: 1280 },
      height: { exact: 720 },
      facingMode: "user",
    },
  },
];

export const Webcam: React.FC<Props> = (props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [stream, setStream] = useState<MediaStream | undefined>();

  const classes = useStyles();

  const { panelConfigurationData } = React.useContext(GlobalContext);

  const { onError, onInitStream } = props;
  const initCamera = useCallback(
    (cameraDeviceId: string, cameraGroupId: string, constraintIndexToTry: number) => {
      // TODO: update getUserMedia. This method is deprecated
      if (typeof navigator.mediaDevices !== "object") return;

      navigator.mediaDevices.getUserMedia =
        navigator.mediaDevices.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        navigator.getUserMedia;

      const constraints = cameraMediaConstraints[constraintIndexToTry];
      if (typeof constraints.video !== "boolean" && constraints.video) {
        constraints.video.advanced = [
          {
            deviceId: cameraDeviceId,
            groupId: cameraGroupId,
          },
        ];
        constraints.video = {
          width: panelConfigurationData.scanConfiguration.pictureResolutionWidth,
          height: panelConfigurationData.scanConfiguration.pictureResolutionHeight,
        };
      }

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          setStream(stream);
          const video = videoRef && videoRef.current ? videoRef.current : undefined;
          if (!video) {
            throw Error("Video panel not found");
          }
          video.srcObject = stream;
          video.addEventListener("loadeddata", () => {
            onInitStream(video, stream.getVideoTracks()[0]);
          });
        })
        .catch(() => {
          if (constraintIndexToTry < cameraMediaConstraints.length - 1) {
            initCamera(cameraDeviceId, cameraGroupId, constraintIndexToTry + 1);
          } else {
            // TODO: Show feedback in front-end (Like an image that cannot load).
            if (onError) onError();
          }
        });
    },
    [onError, onInitStream, panelConfigurationData],
  );

  const { deviceId, groupId } = props.camera;
  useEffect(() => {
    initCamera(deviceId, groupId, 0);
  }, [initCamera, deviceId, groupId]);

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getVideoTracks()[0].stop();
      }
    };
  }, [stream]);

  return (
    <>
      <video className={classes.scanVideo} ref={videoRef} autoPlay muted playsInline />
    </>
  );
};
