import { ConfigurationPanel, EnvSettings } from "../models/config";

import { ApiBase } from "./base";
import { checkConfiguration } from "../validations/configuration";

export class ConfigService extends ApiBase {
  env = (): Promise<EnvSettings> => {
    return this.fetch<EnvSettings>(`env`);
  };

  getConfiguration = async (): Promise<ConfigurationPanel> => {
    return this.fetch<ConfigurationPanel>(`config`);
  };

  saveConfiguration = async (panelConfiguration: ConfigurationPanel): Promise<void> => {
    const checkRes: string = checkConfiguration(panelConfiguration);
    if (checkRes !== "") {
      return Promise.reject(checkRes);
    }
    return this.fetch(`config`, {
      method: "PUT",
      data: JSON.stringify(panelConfiguration),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getCSVHeaders = async (): Promise<string[]> => {
    return await this.fetch<string[]>(`csv-headers`);
  };
}
