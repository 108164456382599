import { FilterUI, initialFilters } from "../../../utils/filters";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";
import { FilterType } from "../../../models/filter";
import Moment from "moment";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      margin: "0px 5px 5px 0px",
    },
  }),
);

interface Props {
  filter: FilterUI;
  handleDelete: (title: string) => void;
}

export const FilterChip: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { filter, handleDelete } = props;

  const createChipMessage = (
    title: string,
    type: string,
    format: FilterType,
    value?: string | number | boolean | null,
    op?: string,
    op2?: string,
    value2?: string | number | boolean | null,
  ) => {
    if (value === undefined || value === null || value === "null") {
      value = "unknown";
    } else {
      if (type === "boolean") {
        if (value === 1 || value) {
          value = "true";
        }
        if (value === 0 || !value) {
          value = "false";
        }
      }
      if (title === "Time") {
        if (value) {
          value = Moment.unix(value as number).format("YYYY-MM-DD HH:mm:ss");
        }
        if (value2) {
          value2 = Moment.unix(value2 as number).format("YYYY-MM-DD HH:mm:ss");
        }
      }
      if (title === "Source" && initialFilters.source.values && initialFilters.source.subtitles) {
        const index = initialFilters.source.values.findIndex((v: any) => v === value);
        if (index >= 0) {
          value = initialFilters.source.subtitles[index];
        }
      }
    }
    return `${title} ${op ? op : "is"} ${value || value === 0 ? value : ""} ${
      op2 && op ? "and " + op2 + " " + value2 : op2 && !op ? op2 + " " + value2 : ""
    }`;
  };

  return (
    <Chip
      label={createChipMessage(
        filter.title,
        filter.type,
        filter.filterType,
        filter.value,
        filter.op,
        filter.secondOp,
        filter.secondValue,
      )}
      onDelete={() => handleDelete(filter.column)}
      className={classes.chip}
    />
  );
};
