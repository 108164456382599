import { ModelType, getModelTypeName } from "../../models/utils";

import Checkbox from "@material-ui/core/Checkbox";
import { PredictionResultConfig } from "../../models/config";
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
  },
  sectionText: {
    fontSize: "16px",
    marginTop: "6px",
    marginBottom: "10px",
  },
  tableColumn: {
    textAlign: "left",
    padding: "8px",
  },
  otherServices: {
    paddingTop: "16px",
  },
}));

interface Props {
  isAdmin?: boolean;
  config: PredictionResultConfig[];
  onConfigChange: (config: PredictionResultConfig[]) => void;
}

export const ModelPredictionsSelector: React.FC<Props> = (props) => {
  const classes = useStyles();
  const modelKeys = Object.values(ModelType);

  const config: PredictionResultConfig[] = props.config
    ? props.config
    : modelKeys.map((key) => {
        return {
          model: key,
          percentage: true,
          text: true,
          antiSpoofing: false,
        };
      });

  const cloneConfig = () => JSON.parse(JSON.stringify(config)) as PredictionResultConfig[];
  const valueChange = (model: ModelType, value: boolean, propertyName: "text" | "percentage" | "antiSpoofing") => {
    const newConfig = cloneConfig();
    const configFound = newConfig.find((c) => c.model === model);
    if (configFound) {
      configFound[propertyName] = value;
      props.onConfigChange(newConfig);
    }
  };

  return (
    <>
      <Typography color="textSecondary" gutterBottom className={classes.sectionText}>
        Anti-spoofing services
      </Typography>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Show result</TableCell>
            <TableCell>Percentage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {config
            .filter((c) => c.antiSpoofing)
            .map((c) => (
              <TableRow key={c.model}>
                <TableCell component="th" scope="row" className={classes.tableColumn}>
                  {getModelTypeName(c.model)}
                </TableCell>
                <TableCell className={classes.tableColumn}>
                  <Checkbox
                    checked={c.text}
                    onChange={(e, checked) => valueChange(c.model, checked, "text")}
                    color="primary"
                    disabled={!props.isAdmin}
                  />
                </TableCell>
                <TableCell className={classes.tableColumn}>
                  <Checkbox
                    checked={c.percentage}
                    onChange={(e, checked) => valueChange(c.model, checked, "percentage")}
                    color="primary"
                    disabled={!props.isAdmin}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Typography color="textSecondary" gutterBottom className={classNames(classes.sectionText, classes.otherServices)}>
        Other services
      </Typography>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Show result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {config
            .filter((c) => !c.antiSpoofing)
            .map((c) => (
              <TableRow key={c.model}>
                <TableCell component="th" scope="row">
                  {getModelTypeName(c.model)}
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={c.text}
                    onChange={(e, checked) => valueChange(c.model, checked, "text")}
                    color="primary"
                    disabled={!props.isAdmin}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};
