import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { BiologicalSexResult } from "../../../../models/log";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { ListLabel } from "../LogDetail";
import { ModelResultProps } from "../../../../config/modelsConfig";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    models: {
      [theme.breakpoints.down(430)]: {
        fontSize: "4vw",
        display: "grid",
      },
    },
  }),
);

export const BiologicalSexDetails: React.FC<ModelResultProps> = (props) => {
  const modelResult = props.modelResult as BiologicalSexResult;
  const classes = useStyles(props);

  return (
    <>
      <ListItem className={classes.models}>
        <ListLabel title={"Result"} value={modelResult.probs >= 0.5 ? "Female" : "Male"}></ListLabel>
      </ListItem>
      <Divider />
      <ListItem className={classes.models}>
        <ListLabel
          title={"Probability (female)"}
          value={(modelResult.probs * 100).toFixed(3).toString() + "%"}
        ></ListLabel>
      </ListItem>
      <Divider />
      <ListItem className={classes.models}>
        <ListLabel title={"Threshold"} value={"0.5"}></ListLabel>
      </ListItem>
      <Divider />
    </>
  );
};
