import { ApiBase, removeLocalSessionData } from "../base";
import { Login, LoginResponse, LoginType, Session } from "../../models/account";

import Axios from "axios";

const SESSION_KEY = "AUTH_SESSION";

function getStoredSession() {
  const sessionData = localStorage.getItem(SESSION_KEY);
  if (sessionData) {
    return JSON.parse(sessionData) as Session;
  }
}

// Set interceptor to send the session token for all requests.
Axios.interceptors.request.use((config) => {
  const session = getStoredSession();
  if (session) {
    config.headers.Authorization = "Bearer " + session.token;
  }
  return config;
});

export class AccountService extends ApiBase {
  login = async (type: LoginType): Promise<LoginResponse> => {
    const res = await this.fetch<LoginResponse>(`auth/begin/${type}`);
    res.type = type;
    return res;
  };

  callback = async (info: Login): Promise<Session> => {
    const session = await this.fetch<Session>(`auth/callback/${info.type}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: JSON.stringify(info.data),
    });
    return session;
  };

  storeSession = (session: Session) => {
    localStorage.setItem(SESSION_KEY, JSON.stringify(session));
  };

  logout = (): Promise<void> => {
    removeLocalSessionData();
    return Promise.resolve();
  };

  checkSession = (): Promise<Session> => {
    return new Promise<Session>((resolve, reject) => {
      this.fetch("auth")
        .then(() => {
          const session = getStoredSession();
          if (session) {
            resolve(session);
          } else {
            reject();
          }
        })
        .catch((e) => reject(e));
    });
  };
}
