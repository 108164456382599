import { createStyles, makeStyles } from "@material-ui/core/styles";

import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Paper } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";
import { Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorWraper: {
      display: "flex",
      backgroundColor: theme.palette.grey[100],
      alignItems: "center",
      alignContent: "center",
      width: "inherit",
      height: "inherit",
    },
    iconColor: {
      color: theme.palette.grey[500],
    },
    noImageIcon: {
      width: "100%",
      height: "100%",
    },
    errorIcon: {
      width: "40%",
      height: "40%",
      padding: "30%",
    },
    loadingIcon: {
      padding: "30%",
    },
  }),
);

export enum ImageFeedbackType {
  loading = "loading",
  error = "error",
  noImage = "noImage",
}

interface Props {
  feedbackType: ImageFeedbackType;
  tooltipText?: string;
}

export const ImageFeedbackIcon: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { tooltipText, feedbackType } = props;

  const RenderFeedback = (): JSX.Element => {
    switch (feedbackType) {
      case ImageFeedbackType.loading:
        return <CircularProgress size={"40%"} className={classnames(classes.iconColor, classes.loadingIcon)} />;
      case ImageFeedbackType.error:
        return <BrokenImageIcon className={classnames(classes.iconColor, classes.errorIcon)} />;
      case ImageFeedbackType.noImage:
        return <PersonIcon className={classnames(classes.iconColor, classes.noImageIcon)} />;
    }
  };

  return (
    <Tooltip title={tooltipText ? tooltipText : feedbackType} placement={"right"} enterDelay={500}>
      <Paper className={classes.errorWraper} elevation={0}>
        {RenderFeedback()}
      </Paper>
    </Tooltip>
  );
};
