import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { Consent, GlobalContext, initialPanelConfigurationData } from "./globalContext";
import { EnvSettings, ConfigurationPanel as IConfigurationPanel } from "./models/config";
import { IconButton, Theme } from "@material-ui/core";
import { Plugin, Plugins } from "./plugin/plugin";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { SnackbarProvider, WithSnackbarProps } from "notistack";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { isAdmin, isAtLeastDataCollector, isAtLeastTester } from "./models/role";

import { Callback } from "./components/Account/Callback";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Config from "./config";
import { ConfigService } from "./api/config";
import { ConfigurationPanel } from "./components/ConfigurationPanel/ConfigurationPanel";
import { Consent as ConsentStore } from "./api/consent";
import { Crop } from "./components/TensorflowPrediction/Upload/Crop";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { DevPanel } from "./components/TensorflowPrediction/SelfieCapture/DevPanel";
import { Index as EndpointManagement } from "./components/EndpointManagement/Index";
import { Feedback } from "./components/Utils/Feedback";
import { Feedback as FeedbackEntity } from "./models/utils";
import { Footer } from "./components/Footer";
import { Log } from "./components/Log/LogTable/Log";
import { Login } from "./components/Account/Login";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MyFace } from "./plugin/myface/myface";
import { OrganisationManagement } from "./components/OrganisationManagement/OrganisationManagement";
import { Page } from "./components/Page";
import { Route } from "./components/Session";
import { Session } from "./models/account";
import { UploadPhoto } from "./components/TensorflowPrediction/UploadPhoto";
import { UserManagement } from "./components/UserManagement/UserManagement";
import { VerifyMsg } from "./components/Account/VerifyMsg";
import { WebcamLoader } from "./components/TensorflowPrediction/SelfieCapture/Webcam/WebcamLoader";
import { theme } from "./theme";

const service = new ConfigService(Config.apiUrl);
const consentStore = new ConsentStore();

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: "50px",
    overflow: "hidden",
    position: "relative",
  },
  snackbarClose: {
    color: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      height: "5px",
      marginUp: "-20px",
    },
  },
}));

export interface FeedbackRef {
  setSnackbarData: (data: FeedbackEntity) => string | number | null | undefined;
}

const App: React.FC = () => {
  // Load plugins
  const [plugins, setPlugins] = useState<Plugin<any>[]>([]);

  const [envSettings, setEnvSettings] = useState<EnvSettings>({
    env: "unknown",
    pluginList: [],
    yotiCfg: {},
    indexAgeEstimationPlot: {},
    userCreationConfig: {
      dailyMaxRequests: 0,
      totalMaxRequests: 0,
      picturesExpirationHours: 0,
    },
    organisationCreationConfig: {
      dailyMaxRequests: 0,
      totalMaxRequests: 0,
      picturesExpirationHours: 0,
    },
    scanUrl: "",
  });
  useEffect(() => {
    service
      .env()
      .then((res) => {
        setEnvSettings(res);
      })
      .catch((e) => {
        console.warn(e);
      });
  }, []);

  useEffect(() => {
    const newPlugins: Plugin<any>[] = [];
    envSettings.pluginList.forEach((plugin) => {
      switch (plugin) {
        case Plugins.MyFace:
          newPlugins.push(new MyFace());
          break;
        default:
          break;
      }
    });
    setPlugins(newPlugins);
  }, [setPlugins, envSettings]);

  const consentValue = consentStore.get();

  const [panelConfigurationData, setPanelConfigurationData] = useState<IConfigurationPanel>(
    initialPanelConfigurationData,
  );
  const [consent, setConsent] = useState<Consent>(consentValue);
  const [session, setSession] = useState<Session>();
  const [downloadingImages, setDownloadingImages] = useState<boolean>(false);

  const feedbackRef = useRef<FeedbackRef>(null);

  const [configLoaded, setConfigLoaded] = useState<boolean>();

  const onSetFeedback = useCallback(
    (data: FeedbackEntity): number | string | null | undefined => {
      if (!feedbackRef || !feedbackRef.current) return;
      return feedbackRef.current.setSnackbarData(data);
    },
    [feedbackRef],
  );

  const loadConfiguration = useCallback(() => {
    service
      .getConfiguration()
      .then((res) => {
        setPanelConfigurationData(res);
        setConfigLoaded(true);
      })
      .catch((e) => {
        setConfigLoaded(false);
        onSetFeedback({
          variant: "error",
          message: e.response && e.response.data.error_message ? e.response.data.error_message : e.message,
          horizontal: "center",
          vertical: "top",
        });
      });
  }, [setPanelConfigurationData, setConfigLoaded, onSetFeedback]);

  useEffect(() => {
    if (session !== undefined && session.verified && !session.banned) {
      loadConfiguration();
    }
  }, [loadConfiguration, session]);

  const [tinyFaceReady, setTinyFaceReady] = useState<boolean>(false);

  const CallbackComponent: React.FC = useCallback(() => {
    return <Callback onLogin={loadConfiguration} />;
  }, [loadConfiguration]);

  const ReturnToMain: React.FC = () => {
    return <Redirect to="/" />;
  };

  const notistackRef = React.createRef<WithSnackbarProps>();
  const onClickDismiss = (key: string | number) => () => {
    const snackbar = notistackRef.current;
    if (snackbar) {
      snackbar.closeSnackbar(key);
    }
  };

  const userIsAtLeastTester = session && isAtLeastTester(session.role);
  const userIsAtLeastDataCollector = session && isAtLeastDataCollector(session.role);
  const userIsAdmin = session && isAdmin(session.role);

  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.root}>
        <ThemeProvider theme={theme}>
          <GlobalContext.Provider
            value={{
              tinyFaceReady,
              setTinyFaceReady,
              configLoaded,
              setConfigLoaded,
              consent,
              setConsent,
              session,
              setSession,
              panelConfigurationData,
              setPanelConfigurationData,
              setFeedback: onSetFeedback,
              downloadingImages,
              setDownloadingImages,
              envSettings: envSettings,
              plugins,
            }}
          >
            <SnackbarProvider
              maxSnack={3}
              ref={notistackRef}
              action={(key: string | number) => (
                <IconButton className={classes.snackbarClose} onClick={onClickDismiss(key)}>
                  <CloseRoundedIcon />
                </IconButton>
              )}
            >
              <>
                <BrowserRouter basename={Config.baseName}>
                  <Switch>
                    <Route path="/" exact component={Page} />
                    <Route path="/scan" exact component={WebcamLoader} />
                    <Route path="/scan-dev" exact component={DevPanel} />
                    <Route path="/login" component={Login} anonymous={true} />
                    <Route path="/verify" component={VerifyMsg} anonymous={true} />
                    <Route path="/auth/:provider/callback" component={CallbackComponent} anonymous={true} />
                    {userIsAtLeastTester && <Route path="/upload-photo" component={UploadPhoto} />}
                    {userIsAtLeastTester && <Route path="/configuration" component={ConfigurationPanel} />}
                    {userIsAtLeastDataCollector && <Route path="/log" component={Log} />}
                    {userIsAdmin && <Route path="/dashboard" component={Dashboard} />}
                    {userIsAdmin && <Route path="/crop" component={Crop} />}
                    {userIsAdmin && <Route path="/user-management" component={UserManagement} />}
                    {userIsAdmin && <Route path="/endpoint-management" component={EndpointManagement} />}
                    {userIsAdmin && <Route path="/organisations" component={OrganisationManagement} />}
                    <Route path="*" component={ReturnToMain} />
                    {/* Setup plugins routes */}
                    {plugins.map((plugin) => {
                      return plugin.routes().map((route) => {
                        return <Route key={`plugin-route-${plugin.name()}`} {...route.props} />;
                      });
                    })}
                  </Switch>
                </BrowserRouter>
                <Footer />
                <Feedback ref={feedbackRef} />
              </>
            </SnackbarProvider>
          </GlobalContext.Provider>
        </ThemeProvider>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default App;
