import { Grid, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import { ModelCheckpointMap, ModelType, getModelTypeName, modelThresholdsFields } from "../../models/utils";
import React, { useEffect, useState } from "react";

import { Conditional } from "../Utils/Conditional";
import Config from "../../config";
import { GlobalContext } from "../../globalContext";
import { LogService } from "../../api/log";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { SelfieCaptureType } from "../../models/config";

const logService = new LogService(Config.apiUrl);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginBottom: "60px",
    },
    sectionTitle: { fontSize: "1.5rem" },
    subSectionTitle: { fontSize: "1.2rem" },
    right: {
      [theme.breakpoints.down("md")]: {
        textAlign: "right",
      },
    },
  }),
);

export const ConfigSummary: React.FC = () => {
  const classes = useStyles();

  const { panelConfigurationData } = React.useContext(GlobalContext);
  const [models, setModels] = useState<ModelCheckpointMap>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    logService
      .models()
      .then((res) => {
        setModels(res);
        setError(undefined);
      })
      .catch((e) => {
        setError(e);
      });
  }, [setError]);

  const getThreshold: (modelType: ModelType) => number = function (modelType) {
    const field = modelThresholdsFields.find((m) => m.modelType === modelType)?.field;

    return field ? panelConfigurationData.predictionConfiguration[field] : 0;
  };

  const renderKeyValue = (key: string, value: string) => {
    return (
      <>
        <Grid item xs={9} md={6}>
          <Typography color="textPrimary">{key}</Typography>
        </Grid>
        <Grid item xs={3} md={6} className={classes.right}>
          <Typography gutterBottom color="textSecondary">
            {value}
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <Conditional error={error} errorMessage="Failed to load config summary">
      <Grid container direction="row" spacing={3} className={classes.section}>
        <Grid item xs={12} md={6}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.subSectionTitle}>
                Formula:
              </Typography>
            </Grid>
            {panelConfigurationData.predictionConfiguration.predictionResult.map((predictionResult) => {
              return (
                <Grid item xs={12} key={predictionResult.model}>
                  <Grid container direction="row">
                    <Grid item xs={8}>
                      <Typography color="textPrimary">{getModelTypeName(predictionResult.model)}</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.right}>
                      <Typography gutterBottom color="textSecondary">
                        {getThreshold(predictionResult.model)}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography gutterBottom color="textSecondary">
                        {models && `${models[predictionResult.model]}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.right}>
                      <Grid>
                        {panelConfigurationData.predictionConfiguration.requestedModels.some(
                          (model) => model.key === predictionResult.model,
                        ) ? (
                          <RadioButtonCheckedIcon color="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon color="primary" />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.subSectionTitle}>
                Capture:
              </Typography>
            </Grid>
            {renderKeyValue(
              "Type",
              panelConfigurationData.scanConfiguration.selfieCaptureType === SelfieCaptureType.Scan
                ? `Scan (${panelConfigurationData.scanConfiguration.scanDelayNumber})`
                : "Button",
            )}
            {renderKeyValue("Format", panelConfigurationData.scanConfiguration.imageExtensionFormat)}
            {renderKeyValue(
              "Resolution",
              `${panelConfigurationData.scanConfiguration.pictureResolutionWidth}x${panelConfigurationData.scanConfiguration.pictureResolutionHeight}`,
            )}
            {renderKeyValue("Cropping", panelConfigurationData.scanConfiguration.selectedInputPicture)}
            <>
              <Grid item xs={3} md={6}>
                <Typography color="textPrimary">Cropping margins</Typography>
              </Grid>
              <Grid item xs={9} md={6} className={classes.right}>
                <Typography
                  gutterBottom
                  color="textSecondary"
                >{`Top: ${panelConfigurationData.scanConfiguration.upMarginPercentageCropping} Bottom: ${panelConfigurationData.scanConfiguration.downMarginPercentageCropping} Left: ${panelConfigurationData.scanConfiguration.leftMarginPercentageCropping} Right: ${panelConfigurationData.scanConfiguration.rightMarginPercentageCropping}`}</Typography>
              </Grid>
            </>
          </Grid>
        </Grid>
      </Grid>
    </Conditional>
  );
};
