import { ColumnStats, StatsMap, ToolUses } from "../models/stats";

import { ApiBase } from "./base";

export class StatsService extends ApiBase {
  getAll = (
    columnStat: ColumnStats,
    from: Date,
    to: Date,
    filterColumn: string | undefined,
    filterValue: string | null,
  ): Promise<StatsMap> => {
    let query = `stats?stat=${columnStat}&from=${(from.getTime() / 1000).toFixed(0)}&to=${(to.getTime() / 1000).toFixed(
      0,
    )}`;

    if (filterColumn && filterValue != null) {
      query = query.concat(`&filter-column=${filterColumn}&filter-value=${filterValue}`);
    }

    return this.fetch<StatsMap>(query, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getToolUses = async (): Promise<ToolUses> => {
    return this.fetch<ToolUses>(`tool-uses`);
  };
}
