import { createStyles, makeStyles } from "@material-ui/core/styles";

import { AuditLogChange } from "../../models/audit";
import { DateLabel } from "../Log/LogTable/DateLabel";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    previous: {
      textDecoration: "line-through",
      color: theme.palette.error.light,
    },
    new: {
      color: theme.palette.success.light,
    },
  }),
);

interface Props {
  changes: AuditLogChange[];
}

export const TooltipAuditChanges: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { changes } = props;
  return (
    <Tooltip
      title={
        <>
          {changes.map((change, index) => {
            return (
              <Typography variant="subtitle2" key={index}>
                <DateLabel value={new Date(change.timestamp).getTime() / 1000} />{" "}
                <span className={classes.previous}>{change.previousValue !== "" ? change.previousValue : "-"}</span>
                {" => "}
                <span className={classes.new}>{change.newValue !== "" ? change.newValue : "-"}</span>
              </Typography>
            );
          })}
        </>
      }
    >
      <div>{props.children}</div>
    </Tooltip>
  );
};
