import { ImageFeedbackIcon, ImageFeedbackType } from "../../../Utils/ImageFeedbackIcon";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    modelPhoto: {
      width: "140px",
    },
    cardHeader: {
      "& span": {
        fontSize: "15px",
      },
    },
  }),
);

interface PhotoDebugImageProps {
  title: string;
  image: string;
}

function getImageOrIcon(noImage: boolean, props: PhotoDebugImageProps) {
  if (!noImage) {
    return (
      <CardMedia component="img" alt={props.title} image={`data:image/png;base64,${props.image}`} title={props.title} />
    );
  }
  return <ImageFeedbackIcon feedbackType={ImageFeedbackType.noImage} tooltipText={"No image stored"} />;
}

export const PhotoDebugImage: React.FC<PhotoDebugImageProps> = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.modelPhoto} elevation={1}>
      <CardHeader title={props.title} titleTypographyProps={{ variant: "body2" }} className={classes.cardHeader} />
      {getImageOrIcon(!props.image.length, props)}
    </Card>
  );
};
