import { Login, LoginType } from "../../models/account";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { AccountService } from "../../api/account/account";
import Config from "../../config";
import { GlobalContext } from "../../globalContext";
import { Loading } from "../Utils/Loading";
import { hasAccess } from "../../models/role";
import { providers } from "../../api/account/factory";

const accountService = new AccountService(Config.apiUrl);

interface Props {
  onLogin: () => void;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const Callback: React.FC<Props> = ({ onLogin }) => {
  const query = useQuery();
  const params = useParams();
  const history = useHistory();
  const { setFeedback } = useContext(GlobalContext);

  // Take the provider from "params as any" as "useParams" returns an object
  // ("{}").
  const provider = (params as any).provider as LoginType;
  const callback = provider ? providers[provider] : undefined;

  /**
   * Get the login token to request the external service in order to get the
   * user data. It returns undefined if the callback is not able to return the
   * information.
   */
  const getData = () => {
    try {
      return callback ? callback(query) : undefined;
    } catch {
      return undefined;
    }
  };
  const data = getData();

  const dataRef = JSON.stringify(data);
  useEffect(() => {
    // Take callback and check if it exists.
    if (!dataRef) return;
    const data = JSON.parse(dataRef) as Login;

    // Run callback request to get account info.
    accountService
      .callback(data)
      .then((session) => {
        const userHasAccess = session.verified && !session.banned && hasAccess(session.role);
        if (userHasAccess) {
          accountService.storeSession(session);
          onLogin();
          history.push("/");
        } else {
          history.push({ pathname: "/verify", state: { ...session } });
        }
      })
      .catch((e) => {
        setFeedback({ message: e.message, variant: "error" });
        history.push("/login");
      });
  }, [dataRef, onLogin, history, setFeedback]);

  return <Loading visible={true} />;
};
