import React, { useCallback, useEffect, useRef } from "react";
import { createStyles, makeStyles } from "@material-ui/core";

import { NodeEnv } from "../../../types/node";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      margin: "auto",
    },
    debugButton: {
      position: "absolute",
      top: 2,
      left: 0,
      height: 42,
      width: "100%",
      cursor: "pointer",
      background: "transparent",
      border: "none",
      "&:hover": {
        background: "rgb(255, 255, 255, 0.25)",
      },
    },
    buttonContainer: {
      position: "relative",
    },
  }),
);

interface Props {
  className: string;
  clientSdkId: string;
  scenarioId: string;
  text: string;
  env: NodeEnv;
  onDebugButtonClick?: () => void;
}

export const Button: React.FC<Props> = ({ className, clientSdkId, scenarioId, env, onDebugButtonClick, text }) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const handleDebugButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onDebugButtonClick && onDebugButtonClick();
    },
    [onDebugButtonClick],
  );

  useEffect(() => {
    if (!buttonRef || !buttonRef.current) return;

    const yoti = (window as any).Yoti.Share.init({
      elements: [
        {
          clientSdkId: clientSdkId,
          scenarioId: scenarioId,
          domId: buttonRef.current.id,
          button: {
            label: text,
          },
        },
      ],
    });

    return yoti.destroy;
  }, [clientSdkId, scenarioId, text, buttonRef]);

  return (
    <div className={classes.buttonContainer}>
      {env !== "production" && <button className={classes.debugButton} onClick={handleDebugButtonClick} />}
      <div className={`${className} ${classes.button}`} ref={buttonRef} id="yoti-button" />
    </div>
  );
};
