import { Consent as ConsentData } from "../globalContext";

const CONSENT_KEY = "consent_key";

export class Consent {
  get(): ConsentData {
    const consentValue = localStorage.getItem(CONSENT_KEY);
    if (!consentValue) {
      return { accept: false };
    }
    return JSON.parse(consentValue) as ConsentData;
  }

  set(consent: ConsentData) {
    const consentValue = JSON.stringify(consent);
    localStorage.setItem(CONSENT_KEY, consentValue);
  }
}
