import React, { useCallback, useEffect, useRef, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { getMaxHeight, updateCanvasSize } from "../../../../utils/responsive";

import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import useTheme from "@material-ui/core/styles/useTheme";

// Metadata needed for the base64 image to be downloaded
const imgDownloadMetadata = "data:application/octet-stream;";

export const useStyles = makeStyles(() =>
  createStyles({
    card: {
      maxWidth: "700px",
      maxHeight: "700px",
      width: "fit-content",
      height: "fit-content",
      marginRight: "15px",
      marginBottom: "20px",
    },
    canvas: {
      display: "block",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      marginBottom: "-4px",
    },
  }),
);

export interface PlotInfo {
  id: number;
  bbox: number[];
  landmarks: number[];
  color: string;
  fromTinyFace: boolean;
}

interface PlotProps {
  data: PlotInfo[];
  image: string;
  pointSize: number;
  lineWidth: number;
  id?: number;
  title: string;
  pictureUsed?: boolean;
  cropped?: boolean;
  onCanvasLoaded?: (width: number, height: number, clientWidth: number, clientHeight: number) => void;
  widthScaleFactor?: number;
  heightScaleFactor?: number;
}

export const Plot: React.FC<PlotProps> = (props) => {
  const {
    data,
    image,
    pointSize,
    lineWidth,
    id,
    title,
    pictureUsed,
    cropped,
    onCanvasLoaded,
    widthScaleFactor,
    heightScaleFactor,
  } = props;

  const [size, setSize] = useState({ width: 0, height: 0 });
  const canvas = useRef<HTMLCanvasElement>(null);
  const [img, setImg] = useState<HTMLImageElement | null>(null);
  const [imgDownloadURL, setImgDownloadURL] = useState<string>("");
  const theme = useTheme();
  const color = pictureUsed ? theme.palette.primary.dark : theme.palette.primary.light;

  // Set image size when image change.
  useEffect(() => {
    const imgElem = new Image();
    imgElem.src = image;
    imgElem.onload = () => {
      setSize({ width: imgElem.width, height: imgElem.height });
      setImg(imgElem);
    };
  }, [image, widthScaleFactor, heightScaleFactor]);

  // Set canvas size with image size is set.
  const sizeDep = JSON.stringify(size);
  useEffect(() => {
    const size = JSON.parse(sizeDep);
    if (canvas && canvas.current) {
      canvas.current.width = size.width;
      canvas.current.height = size.height;
      if (onCanvasLoaded) {
        onCanvasLoaded(
          canvas.current.width,
          canvas.current.height,
          canvas.current.clientWidth,
          canvas.current.clientHeight,
        );
      }
      if (widthScaleFactor && heightScaleFactor) {
        canvas.current.style.maxWidth = `${size.width / widthScaleFactor}px`;
        canvas.current.style.maxHeight = `${size.height / heightScaleFactor}px`;
      }
    }
  }, [sizeDep, canvas, heightScaleFactor, widthScaleFactor, onCanvasLoaded]);

  const getImageExtensionFormat = useCallback(() => {
    return image.substring("data:image/".length, image.indexOf(";base64"));
  }, [image]);

  // Set canvas content when data, img, size or the canvas change.
  const dataDep = JSON.stringify(data);
  useEffect(() => {
    if (!canvas || !canvas.current) return;

    const ctx = canvas.current.getContext("2d");
    if (!ctx) return;

    if (img) {
      ctx.drawImage(img, 0, 0);
    }

    const data = JSON.parse(dataDep) as PlotInfo[];
    for (let i = 0; i < data.length; i++) {
      // Landmarks
      const landmarksNumber = data[i].landmarks.length / 2;
      for (let j = 0; j < landmarksNumber; j++) {
        ctx.beginPath();
        ctx.arc(data[i].landmarks[j], data[i].landmarks[j + landmarksNumber], pointSize, 0, 2 * Math.PI, false);
        ctx.fillStyle = data[i].color;
        ctx.fill();
        ctx.lineWidth = 1;
        ctx.strokeStyle = theme.palette.common.black;
        ctx.stroke();
        ctx.closePath();
      }

      //Bbox
      ctx.beginPath();
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = data[i].color;
      ctx.rect(
        data[i].bbox[0],
        data[i].bbox[1],
        data[i].fromTinyFace ? data[i].bbox[2] : data[i].bbox[2] - data[i].bbox[0],
        data[i].fromTinyFace ? data[i].bbox[3] : data[i].bbox[3] - data[i].bbox[1],
      );
      ctx.stroke();
      ctx.closePath();
    }

    // JPEG images lose quality if they are obtained from the canvas object
    // through the 'toDataURL' function. The original image is downloaded from
    // the base64 prop to get the original image wihout losing quality. If any
    // plot is drawed in the canvas, the downloaded image will be obtained from
    // the canvas despite losing quality.
    setImgDownloadURL(
      data.length > 0 && canvas && canvas.current
        ? canvas.current.toDataURL(`image/${getImageExtensionFormat()}`)
        : imgDownloadMetadata.concat(image.split(";")[1]),
    );
  }, [dataDep, img, sizeDep, canvas, lineWidth, pointSize, theme.palette.common.black, image, getImageExtensionFormat]);

  useEffect(() => {
    const resizeCanvas = () =>
      updateCanvasSize(
        canvas.current ? getMaxHeight(canvas.current.style.maxHeight) : 0,
        canvas.current ? canvas.current : undefined,
      );
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  const classes = useStyles();

  return (
    <>
      <Card className={classes.card} elevation={1}>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: color }} aria-label="recipe">
              {id}
            </Avatar>
          }
          title={title}
        />
        <div className={classes.canvas}>
          <a download={`${id?.toString()}.${getImageExtensionFormat()}`} href={imgDownloadURL}>
            <canvas style={!cropped ? { maxWidth: "auto", maxHeight: "350px" } : {}} ref={canvas} />
          </a>
        </div>
      </Card>
    </>
  );
};
