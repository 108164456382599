import { FieldItem } from "./FieldItem";
import { IDRnDResult } from "../../../../models/log";
import { ModelResultProps } from "../../../../config/modelsConfig";
import React from "react";

export const IDRnDDetails: React.FC<ModelResultProps> = (props) => {
  const modelResult = props.modelResult as IDRnDResult;

  return (
    <>
      <FieldItem label="Probability" value={modelResult.real} />
      <FieldItem label="Threshold" value={modelResult.threshold} />
      <FieldItem label="Quality" value={modelResult.quality} />
      <FieldItem label="Quality Threshold" value={modelResult.qualityThreshold} />
      <FieldItem label="Score" value={modelResult.score} />
    </>
  );
};
