export enum Picture {
  OriginalPicture = "original",
  CroppedPicture = "cropped",
  Optional = "optional",
  None = "",
}

export enum ImageExtensionFormat {
  PNG = "png",
  JPEG = "jpeg",
  Unknown = "null",
}

export interface VideoElement {
  videoWidth: number;
  videoHeight: number;
}

export interface ImageDimensions {
  width: number;
  height: number;
}

export enum StringFilterType {
  Empty = "",
  Null = "null",
  Correct = "correct",
  Error = "error",
  AIError = "ai_error",
}
