import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createStyles,
  makeStyles,
} from "@material-ui/core";

import { BRCKCampaignStats } from "../../models/myface";
import { Button } from "../../../../components/Utils/Button";
import { Conditional } from "../../../../components/Utils/Conditional";
import React from "react";
import { SaveOutlined } from "@material-ui/icons";
import { TooltipHelp } from "../../../../components/Utils/TooltipHelp";
import csvDownload from "../../../../utils/csvDownload";

const useStyles = makeStyles(() =>
  createStyles({
    tableCellNoWrap: {
      whiteSpace: "nowrap",
    },
    csvDownload: {
      marginTop: "10px",
    },
  }),
);

interface CampaignsProps {
  campaignsStats?: BRCKCampaignStats[];
  error?: Error;
  loading?: boolean;
}

export const CampaignsStats: React.FC<CampaignsProps> = (props) => {
  const classes = useStyles();

  const { campaignsStats, error, loading } = props;

  return (
    <Conditional error={error} loading={loading} errorMessage="Failed to load campaigns stats">
      {campaignsStats && (
        <>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Campaign</TableCell>
                  <TableCell align="center">Visits</TableCell>
                  <TableCell align="center">SignUps</TableCell>
                  <TableCell align="center">
                    With Error
                    <TooltipHelp title="Number of user who encountered an error in the Face Capture Module and did not upload any photos (valid or not)" />
                  </TableCell>
                  <TableCell align="center">Reached 1</TableCell>
                  <TableCell align="center">Reached 5</TableCell>
                  <TableCell align="center">Reached 10</TableCell>
                  <TableCell align="center">Reached 20</TableCell>
                  <TableCell align="center">Finished</TableCell>
                  <TableCell align="center">Reviewed</TableCell>
                  <TableCell align="center">Approved</TableCell>
                  <TableCell align="center">Rejected</TableCell>
                  <TableCell align="center">Expired</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaignsStats.map((stats) => (
                  <TableRow key={`campaign-${stats.campaign}`}>
                    <TableCell className={classes.tableCellNoWrap} component="th" scope="row">
                      {stats.campaign}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.visits}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.signUps}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.withError}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.reached1}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.reached5}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.reached10}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.reached20}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.finished}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.reviewed}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.approved}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.rejected}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {stats.expired}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => csvDownload(campaignsStats, "Campaigns stats.csv")}
            size="small"
            className={classes.csvDownload}
            startIcon={<SaveOutlined />}
          >
            Download CSV
          </Button>
        </>
      )}
    </Conditional>
  );
};
