import { Theme, createStyles, makeStyles, withStyles } from "@material-ui/core/styles";

import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { liteErrorFormat } from "../../utils/error";

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid",
    borderColor: theme.palette.error.main,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    errorContainer: {
      fontSize: "14px",
      marginLeft: 6,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.error.main,
    },
  }),
);

interface Props {
  error: string | JSX.Element;
  title: string;
  titleStyle?: string;
}

export const TooltipError: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { error, title, titleStyle } = props;
  const errorMessage = typeof error === "string" ? liteErrorFormat(error) : error;
  return (
    <div className={classes.root}>
      <CustomTooltip title={errorMessage}>
        <Typography color="textSecondary" gutterBottom className={titleStyle ? titleStyle : classes.errorContainer}>
          {title}
        </Typography>
      </CustomTooltip>
    </div>
  );
};
