import { IconButton, TableCell, createStyles, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import Config from "../../config";
import { DeleteRounded } from "@material-ui/icons";
import { Dialog } from "../Utils/Dialog";
import { Endpoint } from "../../models/endpoint";
import { EndpointService } from "../../api/endpoint";
import { GlobalContext } from "../../globalContext";
import { ItemDialog } from "./ItemDialog";
import { Sniffer } from "../../models/utils";
import { SnifferService } from "../../api/sniffer";
import { Table } from "../Utils/Table";

const svc = new EndpointService(Config.apiUrl);
const snifferSvc = new SnifferService(Config.apiUrl);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
      padding: 50,
    },
    actions: {
      width: 50,
    },
  }),
);

const initialEndpoint: Endpoint = { id: 0, mode: undefined, name: "", url: "", sniffers: [] };

export const Index: React.FC = () => {
  const classes = useStyles();

  const [items, setItems] = useState<Endpoint[]>([]);
  const [count, setCount] = useState(0);
  const [modes, setModes] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<Endpoint>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [itemToDelete, setItemToDelete] = useState<Endpoint>();
  const [sniffers, setSniffers] = useState<Sniffer[]>([]);

  const { setFeedback } = React.useContext(GlobalContext);

  const notifyError = useCallback(
    (err: Error) => {
      setFeedback({
        variant: "error",
        message: err.message,
      });
    },
    [setFeedback],
  );

  const notifySuccess = (msg: string) => {
    setFeedback({
      variant: "success",
      message: msg,
    });
  };

  const getItems = () => {
    svc
      .getAll(page, rowsPerPage)
      .then((result) => {
        setItems(result.page);
        setCount(result.count);
      })
      .catch((err) => notifyError(err));

    snifferSvc.getAll().then(setSniffers).catch(notifyError);
  };

  const saveItem = (item: Endpoint) => {
    const httpMethod = item.id === 0 ? "POST" : "PUT";

    svc
      .upsert(item, httpMethod)
      .then(getItems)
      .then(() => {
        setSelectedItem(undefined);
        notifySuccess("Endpoint saved");
      })
      .catch((err) => notifyError(err));
  };

  const removeItem = (item: Endpoint) => {
    svc
      .delete(item.id)
      .then(getItems)
      .then(() => notifySuccess("Endpoint removed"))
      .catch((err) => notifyError(err));
  };
  useEffect(() => {
    svc
      .getModes()
      .then((result) => setModes(result))
      .catch((err) => notifyError(err));
  }, [notifyError]);
  useEffect(getItems, [page, rowsPerPage]);

  const onPageChange = (page: number, numItems: number) => {
    setPage(page);
    setRowsPerPage(numItems);
  };

  const showDeleteConfirmation = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: Endpoint) => {
    event.preventDefault();
    event.stopPropagation();
    setItemToDelete(item);
  };

  const sniffersPrettier = (sniffers: Sniffer[]): string => {
    const sniffersToShow = sniffers.map((item) => item.name);
    return sniffersToShow.toString().split(",").join(" | ");
  };

  return (
    <div className={classes.root}>
      <Table
        addButtonLabel="Create Endpoint"
        count={count}
        onCreateClick={() => setSelectedItem(initialEndpoint)}
        onSelectItem={(item) => setSelectedItem(item)}
        onPageChange={onPageChange}
        title="Endpoints Management"
        columns={["", "Id", "Name", "URL", "Default sniffers"]}
        items={items}
        page={page}
        rowsPerPage={rowsPerPage}
        renderRow={(item) => {
          return [
            <TableCell key="actions" className={classes.actions}>
              <IconButton onClick={(e) => showDeleteConfirmation(e, item)}>
                <DeleteRounded />
              </IconButton>
            </TableCell>,
            <TableCell key="id">{item.id}</TableCell>,
            <TableCell key="name">{item.name}</TableCell>,
            <TableCell key="url">{item.url}</TableCell>,
            <TableCell key="sniffers">{sniffersPrettier(item.sniffers)}</TableCell>,
          ];
        }}
      />
      {selectedItem && (
        <ItemDialog
          modes={modes}
          onClose={() => setSelectedItem(undefined)}
          item={selectedItem}
          onConfirm={saveItem}
          sniffers={sniffers}
        />
      )}
      {itemToDelete && (
        <Dialog
          open={true}
          title="Delete Endpoint"
          action="Delete"
          message={`remove the endpoint ${itemToDelete.name}`}
          onConfirm={() => removeItem(itemToDelete)}
          onClose={() => setItemToDelete(undefined)}
        />
      )}
    </div>
  );
};
