export const defaultGetStartedSubtitle = `You must submit 25 good quality images
 to recieve €5. We’ll pay €2 for a minimum submision of 10 images.`;

export const defaultIntroPrivacy = `<h3>Privacy information</h3>
<p>
  If you take part you will use your device camera to take a series of
  different images of your face. We will delete and not use any images
  that are not of a good enough quality. We will keep the images for as
  long as they are needed for this research and a project. We also collect
  some information about the device or operating system you use to make
  sure we have images from a wide range of devices.
</p>
<p>
  We will collect your e-mail address to send you the reward using Paypal.
  Once the reward is issued, the email is removed from our database.
</p>
<p>
  We do not collect any other information that could identify you. The
  images will only be used for our internal research and development
  project. There will be no way to retrieve your specific images and no
  way for us to connect your images to any other information about you.
</p>
<br />
<a href="/privacy">View full privacy information</a>`;

export const defaultPrivacyPage = `<h1>Privacy information</h1>
<h3>Company and contact details</h3>
<p>Yoti Ltd 130 Fenchurch Street, London, EC3M 5DJ privacy@yoti.com</p>
<h3>Data collection and use</h3>
<p>
  If you take part you will use your device camera for up to three
  activities. Not all three activities may be available in the version
  you are using. 1: To take a series of different images of your face.
  2: To show other printed faces or images to your device camera (such
  as by using a printed photo, or holding up a magazine image of a
  person. 3: To show other faces or images to your device camera (such
  as by using another device or downloading an image from the internet).
</p>
<p>
  You can choose to do just the first activity, just the first two or to
  do all three.
</p>
<p>
  We will use these images to test and improve our MyFace technology. We
  will delete and not use any images that are not of a good enough
  quality. We will keep the images for as long as they are needed for
  this research and development project.
</p>
<p>
  We also collect some information about the device you use, such as
  make and model and operating system to make sure we have images from a
  wide range of devices.
</p>
<p>
  We will collect your email address to send you the reward using Paypal.
  We hash the email so we can’t read it and we use it to make sure individuals
  can’t do the activity multiple times. Each time an email is submitted, we apply
  the same irreversible hash technology and see if it already exists or not. We also
  use the hashed version to link all the images you submit. For our testing we need
  to know which images are of the same person. Once we have paid the reward, we delete
  the actual email address.
</p>
<p>
  We do not collect any other information that could identify you. The
  images will only be used for our internal research and development
  project. There will be no way to retrieve your specific images and no
  way for us to connect your images to any other information about you.
</p>
<p>
  Please make sure you do not present images of friends and family and
  do not use images in a way that would breach copyright laws.
</p>
<p>
  Lawful basis for processing: GDPR article 6(1)(f): legitimate
  interests. It is in our legitimate business interests to collect
  images and no other identifying information to test and improve our
  MyFace technology. This technology is used for security purposes to
  prevent and detect fraud by distinguishing between real people and
  images of people. It is in your legitimate interests to be rewarded
  for providing images.
</p>
<h3>Your rights</h3>
<p>
  The privacy laws we are subject to provide you with certain rights.
  However, not all of these apply in all circumstances. The rights also
  rely on us being able to identify and retrieve your specific
  information, which we will not be able to do, as we are not collecting
  any information with your image to identify you. Privacy laws also
  provide exemptions from rights where the information is used for
  research purposes and will not be used to make decisions about you.
</p>
<p>
  Please contact us if you want more information on privacy rights and
  when they do and do not apply in the context of this research project.
</p>
<p>
  You also have the right to complain to a privacy or data protection
  regulator. This could be your local one or the UK Information
  Commissioner’s Office <a href="https://www.ico.org.uk">www.ico.org.uk</a>
</p>
<h3>Cookies and analytics</h3>
<p>
  We use Google Analytics for performance statistics so we can understand
  how successful our adverts have been in getting people to come to this
  website and provide images. 
  The details of all the cookies are in the preference centre that you
  can access from the banner at the bottom of the screen and by clicking
  the ‘Cookies’ link in the footer.
  The default settings are that strictly necessary and performance cookies
  are on and advertising / tracking cookies are off. No cookies are
  dropped until you make a choice that accepts some or all cookies.
</p>
`;

export const defaultBRCKGetStartedSubtitle = `It will only take a few minutes.`;

export const defaultBRCKIntroPrivacy = `<h3>Privacy information</h3>
<p>
  If you take part you will use your device camera to take a series of
  different images of your face. We will delete and not use any images
  that are not of a good enough quality. We will keep the images for as
  long as they are needed for this research and a project. We also collect
  some information about the device or operating system you use to make
  sure we have images from a wide range of devices.
</p>
<p>
  We collect your BRCK ID number, but only so we can link all the images
  you submit and make sure you get credits for good-quality images. For
  our testing we need to know which images are of the same person.
</p>
<p>
  We do not collect any other information that could identify you. The
  images will only be used for our internal research and development
  project. There will be no way to retrieve your specific images and no
  way for us to connect your images to any other information about you.
</p>
<br />
<a href="/privacy">View full privacy information</a>`;

export const defaultBRCKPrivacyPage = `<h1>Privacy information</h1>
<h3>Company and contact details</h3>
<p>Yoti Ltd 130 Fenchurch Street, London, EC3M 5DJ privacy@yoti.com</p>
<h3>Data collection and use</h3>
<p>
  If you take part you will use your device camera for up to three
  activities. Not all three activities may be available in the version
  you are using. 1: To take a series of different images of your face.
  2: To show other printed faces or images to your device camera (such
  as by using a printed photo, or holding up a magazine image of a
  person. 3: To show other faces or images to your device camera (such
  as by using another device or downloading an image from the internet).
</p>
<p>
  You can choose to do just the first activity, just the first two or to
  do all three.
</p>
<p>
  We will use these images to test and improve our MyFace technology. We
  will delete and not use any images that are not of a good enough
  quality. We will keep the images for as long as they are needed for
  this research and development project.
</p>
<p>
  We also collect some information about the device you use, such as
  make and model and operating system to make sure we have images from a
  wide range of devices.
</p>
<p>
  If you are a BRCK user, we also collect your BRCK ID number, but only
  so we can link all the images you submit and make sure you get credits
  for good-quality images. For our testing we need to know which images
  are of the same person.
</p>
<p>
  For some people, we will collect your e-mail address to send you the
  reward voucher. We then hash the e-mail so we can’t read it but we
  keep it so that individuals can’t do the activity multiple times. Each
  time an e-mail is submitted, we apply the same irreversible hash
  technology and see if it already exists or not.we also use the hashved
  version to link all the images you submit. For our testing we need to
  know which images are of the same person.
</p>
<p>
  We do not collect any other information that could identify you. The
  images will only be used for our internal research and development
  project. There will be no way to retrieve your specific images and no
  way for us to connect your images to any other information about you.
</p>
<p>
  Please make sure you do not present images of friends and family and
  do not use images in a way that would breach copyright laws.
</p>
<p>
  Lawful basis for processing: GDPR article 6(1)(f): legitimate
  interests. It is in our legitimate business interests to collect
  images and no other identifying information to test and improve our
  MyFace technology. This technology is used for security purposes to
  prevent and detect fraud by distinguishing between real people and
  images of people. It is in your legitimate interests to be rewarded
  for providing images.
</p>
<h3>Your rights</h3>
<p>
  The privacy laws we are subject to provide you with certain rights.
  However, not all of these apply in all circumstances. The rights also
  rely on us being able to identify and retrieve your specific
  information, which we will not be able to do, as we are not collecting
  any information with your image to identify you. Privacy laws also
  provide exemptions from rights where the information is used for
  research purposes and will not be used to make decisions about you.
</p>
<p>
  Please contact us if you want more information on privacy rights and
  when they do and do not apply in the context of this research project.
</p>
<p>
  You also have the right to complain to a privacy or data protection
  regulator. This could be your local one or the UK Information
  Commissioner’s Office <a href="https://www.ico.org.uk">www.ico.org.uk</a>
</p>`;
