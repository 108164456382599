import { Organisation, PaginationOrg } from "../models/organisation";

import { ApiBase } from "./base";

export class OrganisationService extends ApiBase {
  getAll = (page: number, pageSize: number): Promise<PaginationOrg> => {
    return this.fetch<PaginationOrg>(`organisations?page=${page}&page-size=${pageSize}`);
  };

  create = (organisation: Organisation): Promise<Organisation> => {
    return this.fetch(`organisations`, {
      method: "POST",
      data: JSON.stringify(organisation),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  update = (organisation: Organisation): Promise<Organisation> => {
    return this.fetch(`organisations/${organisation.id}`, {
      method: "PUT",
      data: JSON.stringify(organisation),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
}
