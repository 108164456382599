import { Grid, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Button } from "./../Utils/Button";
import { GlobalContext } from "../../globalContext";
import { Session } from "../../models/account";
import { hasAccess } from "../../models/role";
import { useHistory } from "react-router-dom";

const getMsg = (session: Session, scanUrl: string) => {
  const MESSAGES = {
    BANNED: `You do not have access to the system. Please contact an Administrator.`,
    VERIFY: `You have been registered in the system. Please, wait until your account will be verified by an Admin user.`,
    NOT_ENOUGH_PERMISSION: (
      <p>
        You do not have enough permissions to access the system, please go to{" "}
        <a {...(scanUrl && { href: scanUrl })}>the scan website</a> to continue.
      </p>
    ),
  };

  const banned = session?.banned;
  const verified = session?.verified;
  const notEnoughPermission = !hasAccess(session?.role);

  if (banned) return MESSAGES.BANNED;
  if (!verified) return MESSAGES.VERIFY;
  if (notEnoughPermission) return MESSAGES.NOT_ENOUGH_PERMISSION;
  return MESSAGES.VERIFY;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: "700px",
      minHeight: "calc(100vh - 98px)",
      margin: "0 auto",
      padding: "0 15px",
    },
    text: {
      marginBottom: 15,
    },
  }),
);

export const VerifyMsg: React.FC = () => {
  const classes = useStyles();
  const {
    envSettings: { scanUrl },
  } = useContext(GlobalContext);
  const history = useHistory<Session>();
  const goToLoginPage = () => {
    history.push("/login");
  };

  const msg = getMsg(history.location.state, scanUrl);

  return (
    <Grid className={classes.root}>
      <Typography className={classes.text}>{msg}</Typography>
      <Button variant="contained" onClick={goToLoginPage} type="button">
        {"< Back"}
      </Button>
    </Grid>
  );
};
