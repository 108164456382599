import React from "react";

interface Props {
  value: number;
}

export const timestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp / 1000);
  const hours = date.getHours();
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);
  const microseconds = timestamp.toString().slice(-6);
  return `${hours}:${minutes}:${seconds}.${microseconds}`;
};

export const TimeLabel: React.FC<Props> = (props) => {
  const formattedTime = timestampToDate(props.value);
  return <>{formattedTime}</>;
};
