import { ColumnStats, StatInfo } from "../../models/stats";
import React, { useState } from "react";

import { DateFilter } from "./DateFilter";
import { Grid } from "@material-ui/core";
import { Stat } from "./Stat";

const statCharts: StatInfo[] = [
  { title: "General" },
  { title: "Browser", filter: "browser" },
  { title: "OS", filter: "os" },
  { title: "Device type", filter: "device_type" },
  { title: "Image extension format", filter: "image_extension_format" },
];

const statRows: Record<ColumnStats, string[]> = {
  feedback: ["correct", "incorrect", "unknown"],
  /* Note: Ignore camelcase rule for "prediction_result" because this value is 
     sent to the backend to be used in a database call. There is a column in the log table 
     named like this, so it has to be named the same. */
  // eslint-disable-next-line @typescript-eslint/camelcase
  prediction_result: ["real", "fake", "undetermined", "error"],
};

interface Props {
  column: ColumnStats;
}

export const StatsSection: React.FC<Props> = (props) => {
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const todayEnd = new Date();

  const [fromDate, setFromDate] = useState(lastWeek);
  const [toDate, setToDate] = useState(todayEnd);

  return (
    <>
      <DateFilter
        fromDate={fromDate}
        toDate={toDate}
        todayEnd={todayEnd}
        lastWeek={lastWeek}
        lastMonth={lastMonth}
        setFromDate={setFromDate}
        setToDate={setToDate}
      ></DateFilter>
      {statCharts.map((info) => {
        return (
          <Grid item key={info.title} xs={12}>
            <Stat from={fromDate} to={toDate} info={info} rows={statRows[props.column]} column={props.column} />
          </Grid>
        );
      })}
    </>
  );
};
