import { Grid, Switch } from "@material-ui/core";

import { ConfigurationProps } from "./ConfigurationPanel";
import ConfigurationSection from "./ConfigurationSection";
import ConfigurationSubsection from "./ConfigurationSubsection";
import { ConsentConfiguration } from "../../models/config";
import React from "react";
import { SwitchElement } from "../Utils/SwitchElement";
import TextField from "@material-ui/core/TextField";

const PREFERENCES_SECTION_DESCRIPTION = "Customization for the consent component";

enum ConsentSection {
  Preferences = "Preferences",
}

export const ConsentPanel: React.FC<ConfigurationProps<ConsentConfiguration>> = (props) => {
  const { isAdmin, onConfigurationChange, config } = props;
  const section = config.consentConfiguration;

  return (
    <div>
      <ConfigurationSection title={ConsentSection.Preferences} description={PREFERENCES_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Consent preferences">
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={9}>
                <TextField
                  label="Consent text"
                  value={section.consent}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...section,
                      consent: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  multiline
                  color="primary"
                  disabled={!isAdmin}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={9}>
                  <TextField
                    label="Consent checkbox text"
                    value={section.consentCheckbox}
                    onChange={(e) =>
                      onConfigurationChange({
                        ...section,
                        consentCheckbox: e.target.value,
                      })
                    }
                    margin="normal"
                    fullWidth
                    multiline
                    color="primary"
                    disabled={!isAdmin || !section.showConsentCheckbox}
                    InputProps={{
                      endAdornment: (
                        <Switch
                          checked={section.showConsentCheckbox}
                          onChange={(e, checked) =>
                            onConfigurationChange({
                              ...section,
                              showConsentCheckbox: checked,
                            })
                          }
                          color="primary"
                          disabled={!isAdmin}
                        />
                      ),
                    }}
                  />
                  <br />
                  <br />
                  <SwitchElement
                    title="Hide main page for Data Collectors"
                    checked={section.hideDataCollectorMainPage}
                    onChange={(checked) =>
                      onConfigurationChange({
                        ...section,
                        hideDataCollectorMainPage: checked,
                      })
                    }
                    disabled={!isAdmin}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ConfigurationSubsection>
      </ConfigurationSection>
    </div>
  );
};
