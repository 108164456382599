import { AntiSpoofingResult, RGBFASData } from "../../../../models/log";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { AntiSpoofingDetails } from "./AntiSpoofingDetails";
import ListItem from "@material-ui/core/ListItem";
import { ListLabel } from "../LogDetail";
import { ModelResultProps } from "../../../../config/modelsConfig";
import React from "react";
import { camelCaseToHuman } from "../../../../utils/strings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    models: {
      [theme.breakpoints.down(430)]: {
        fontSize: "4vw",
        display: "grid",
      },
    },
  }),
);

export const RGBFASDetails: React.FC<ModelResultProps> = (props) => {
  const modelResult = props.modelResult as AntiSpoofingResult;
  const data = modelResult.data as RGBFASData;
  const fields = Object.keys(data).map((key) => key as keyof RGBFASData);
  const classes = useStyles(props);

  return (
    <AntiSpoofingDetails {...props}>
      {fields.map(
        (key) =>
          data[key] !== undefined &&
          data[key] !== null && (
            <ListItem key={key} button disableRipple divider className={classes.models}>
              <ListLabel title={camelCaseToHuman(key)} value={`${data[key]}`} />
            </ListItem>
          ),
      )}
    </AntiSpoofingDetails>
  );
};
