import { TinyFaceInfo } from "../models/prediction";
import { checkFace } from "./streaming";

/**
 * Returns the bounding boxes for all faces in image provided.
 *
 * @param b64img Image the method will use to look for faces in base64.
 * @param threshold The minimum confidence threshold.
 */
export const getFaces = async (b64img: string, threshold: number): Promise<TinyFaceInfo[]> => {
  const imgElement: HTMLImageElement = document.createElement("img");
  imgElement.src = b64img;
  const faces = await checkFace(imgElement, threshold);
  return faces.map((f) => {
    const auxBbox = { x: f.box.x, y: f.box.y, width: f.box.width, height: f.box.height };
    return { bBox: auxBbox, score: f.score };
  });
};
