import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { AntiSpoofingResult } from "../../../../models/log";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { ListLabel } from "../LogDetail";
import { ModelResultProps } from "../../../../config/modelsConfig";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    models: {
      [theme.breakpoints.down(430)]: {
        fontSize: "4vw",
        display: "grid",
      },
    },
  }),
);

export const AntiSpoofingDetails: React.FC<ModelResultProps> = (props) => {
  const modelResult = props.modelResult as AntiSpoofingResult;
  const fake = 100 - modelResult.real;
  const classes = useStyles(props);

  return (
    <>
      <ListItem className={classes.models}>
        <ListLabel title={"Real"} value={modelResult.real.toFixed(2).toString() + "%"}></ListLabel>
      </ListItem>
      <Divider />
      <ListItem className={classes.models}>
        <ListLabel title={"Fake"} value={fake.toFixed(2).toString() + "%"}></ListLabel>
      </ListItem>
      <Divider />
      <ListItem divider className={classes.models}>
        <ListLabel title={"Threshold"} value={modelResult.classificationThreshold.toString()}></ListLabel>
      </ListItem>
      {props.children && (
        <>
          {props.children}
          <Divider />
        </>
      )}
    </>
  );
};
