import {
  ConfigurationPanel as ConfigurationPanelEntity,
  ConfigurationPanel as ConfigurationPanelModel,
  ConfigurationPanelSection,
  PluginConfiguration,
} from "../../models/config";
import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import { AuditTrailPanel } from "./AuditTrailPanel";
import { Button } from "../Utils/Button";
import CameraRoundedIcon from "@material-ui/icons/CameraRounded";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Config from "../../config";
import { ConfigService } from "../../api/config";
import { ConsentPanel } from "./ConsentPanel";
import { Dialog } from "../Utils/Dialog";
import { EndpointsPanel } from "./EndpointsPanel";
import { GlobalContext } from "../../globalContext";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { LogPanel } from "./LogPanel";
import Menu from "@material-ui/core/Menu";
import Moment from "moment";
import { Picture } from "../../models/image";
import { PredictionPanel } from "./PredictionPanel";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import { ScanPanel } from "./ScanPanel";
import SettingsInputAntennaRoundedIcon from "@material-ui/icons/SettingsInputAntennaRounded";
import SubjectRoundedIcon from "@material-ui/icons/SubjectRounded";
import TuneIcon from "@material-ui/icons/Tune";
import { UploadPanel } from "./UploadPanel";
import classNames from "classnames";
import { getItems as getPictureTypes } from "./PictureToSendForm";
import { isAdmin as isAdminFn } from "../../models/role";
import { saveAs } from "@progress/kendo-file-saver";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const service = new ConfigService(Config.apiUrl);

const menuRadius = "5px";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "8px 16px 0px 16px",
      marginBottom: "50px",
    },
    menuContainer: {
      border: "1px solid",
      borderColor: theme.palette.primary.light,
      width: "260px",
      borderRadius: menuRadius,
    },
    menu: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
      borderRadius: menuRadius,
    },
    menuItemTop: {
      borderTopLeftRadius: menuRadius,
      borderTopRightRadius: menuRadius,
    },
    menuItemBottom: {
      borderBottomLeftRadius: menuRadius,
      borderBottomRightRadius: menuRadius,
    },
    saveButton: {
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        marginBottom: "-5px",
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: "16px",
        marginBottom: "8px",
      },
    },
    leftPanel: {
      position: "fixed",
      marginTop: theme.spacing(1),
      left: 26,
    },
    menuMobile: {
      position: "fixed",
      left: 0,
    },
    leftPanelSmallLaptop: {
      overflowY: "scroll",
      height: 400,
    },
    icon: {
      paddingTop: 0,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 11,
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: 22,
      },
    },
    buttonGroup: {
      display: "flex",
      flexDirection: "column",
    },
  }),
);

/**
 * Entity that defines the confirmation dialog options and the callback the
 * application will run when the user clicks on accept button.
 */
interface ConfirmationInfo {
  action?: string;
  title?: string;
  question: string;
  message?: string;
  callback: () => void;
}

export interface ConfigurationProps<T> {
  isAdmin: boolean;
  config: ConfigurationPanelModel;
  onConfigurationChange: (configSection: T) => void;
}

export const ConfigurationPanel: React.FC = () => {
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const mobileWidth = useMediaQuery(theme.breakpoints.down("md"));
  const smallLaptop = !useMediaQuery("(min-height:825px)");
  const smallHeight = !useMediaQuery("(min-height:600px)");

  const {
    plugins,
    session,
    panelConfigurationData,
    setPanelConfigurationData,
    setFeedback,
    envSettings,
  } = React.useContext(GlobalContext);

  // Create a copy of the panel configuration so that we do not modify the
  // behaviour of the app before saving the changes.
  const [configurationCopy, setConfigurationCopy] = React.useState(panelConfigurationData);
  const [changeConfirmationInfo, setChangeConfirmationInfo] = React.useState<ConfirmationInfo>();

  const isConfigurationChanged = JSON.stringify(configurationCopy) === JSON.stringify(panelConfigurationData);

  // Keep configuration copy updated if it changes for other reasons.
  useEffect(() => {
    setConfigurationCopy(panelConfigurationData);
  }, [setConfigurationCopy, panelConfigurationData]);

  /**
   * Check the values that needs a confirmation by the user to be changed.
   * @param cfg The configuration entity to check.
   */
  function confirmationHandler(cfg: ConfigurationPanelEntity) {
    // Show confirmation dialog in case the current picture selected to send is
    // not allowed for the selfie capture type provided.
    const pictureTypesAllowed = getPictureTypes(cfg.scanConfiguration.selfieCaptureType);
    if (pictureTypesAllowed.indexOf(cfg.scanConfiguration.selectedInputPicture) < 0) {
      setChangeConfirmationInfo({
        question: " change the selfie capture type",
        message: `The picture to send will change to '${Picture.OriginalPicture}' as '${cfg.scanConfiguration.selectedInputPicture}' is not supported by selected capture type.`,
        callback: () => {
          // Set the 'selectedInputPicture' to be 'PictureOriginalPicture'.
          setConfigurationCopy({
            ...configurationCopy,
            scanConfiguration: {
              ...configurationCopy.scanConfiguration,
              selectedInputPicture: Picture.OriginalPicture,
            },
          });
        },
      });
    }
  }

  /** Restore ChangeConfirmationInfo because the dialog will be closed. */
  function closeConfirmationDialog() {
    setChangeConfirmationInfo(undefined);
  }

  function getConfigurationPanelSection<T>(
    section: React.FC<ConfigurationProps<T>>,
    name: keyof ConfigurationPanelEntity,
    label: string,
    icon: JSX.Element,
  ): ConfigurationPanelSection<T> {
    return {
      section: section,
      label: label,
      icon: icon,
      onConfigurationChange: (sectionConfig: T) => {
        const newCfg = { ...configurationCopy, [name]: sectionConfig };
        confirmationHandler(newCfg);
        setConfigurationCopy(newCfg);
      },
    };
  }

  const list: ConfigurationPanelSection<any>[] = [
    getConfigurationPanelSection(
      PredictionPanel,
      "predictionConfiguration",
      "Prediction",
      <RadioButtonCheckedRoundedIcon />,
    ),
    getConfigurationPanelSection(ScanPanel, "scanConfiguration", "Scan", <CameraRoundedIcon />),
    getConfigurationPanelSection(LogPanel, "logConfiguration", "Logs", <ListAltRoundedIcon />),
    getConfigurationPanelSection(UploadPanel, "uploadConfiguration", "Upload", <PublishRoundedIcon />),
    getConfigurationPanelSection(ConsentPanel, "consentConfiguration", "Consent", <AssignmentRoundedIcon />),
    getConfigurationPanelSection(
      EndpointsPanel,
      "endpointsConfiguration",
      "Endpoints",
      <SettingsInputAntennaRoundedIcon />,
    ),
  ];

  plugins.forEach((plugin, i) => {
    const onPluginSectionChange = (newPluginConfig: PluginConfiguration) => {
      const pluginSectionCopy = [...configurationCopy.pluginsConfiguration];
      pluginSectionCopy[i] = newPluginConfig;

      setConfigurationCopy({ ...configurationCopy, pluginsConfiguration: pluginSectionCopy });
    };

    list.push(plugin.configPanelSection(i, onPluginSectionChange));
  });

  // Add config log panel at the end of the list
  list.push({
    section: AuditTrailPanel,
    label: "Audit trail",
    icon: <SubjectRoundedIcon />,
    onConfigurationChange: () => {
      // Nothing to do
    },
  });

  const ChosenPanel: ConfigurationPanelSection<any> = list[selectedIndex];

  const isAdmin = (session && isAdminFn(session.role)) || false;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    if (mobileWidth || smallHeight) {
      setAnchorEl(null);
    }
    setSelectedIndex(index);
  };

  const onSaveChanges = () => {
    if (!configurationCopy) {
      setFeedback({
        variant: "error",
        message: "Cannot load panel configuration!",
      });
    } else {
      service
        .saveConfiguration(configurationCopy)
        .then(() => {
          setPanelConfigurationData(configurationCopy);
          setFeedback({
            variant: "success",
            message: "Panel configuration saved successfully!",
          });
        })
        .catch((e) => {
          setFeedback({
            variant: "error",
            message: e,
          });
        });
    }
  };

  const showSaveConfirmation = () => {
    setChangeConfirmationInfo({
      action: "Save",
      title: "Save Configuration",
      question: "save the configuration",
      callback: onSaveChanges,
    });
  };

  const downloadConfiguration = () => {
    const name = `${envSettings.env}_${Moment(new Date()).format("YYYYMMDD_HHmm")}_config.json`;
    const file = new Blob([JSON.stringify(configurationCopy, null, "\t")], {
      type: "application/json",
    });
    saveAs(file, name);
  };

  const getItems = () => {
    function listItem(section: ConfigurationPanelSection<any>, index: number) {
      return (
        <ListItem
          button
          key={`config-section-${section.label}`}
          selected={selectedIndex === index}
          onClick={(event) => handleListItemClick(event, index)}
          className={!mobileWidth || !smallHeight ? classes.menuItemTop : ""}
        >
          <ListItemIcon>{section.icon}</ListItemIcon>
          <ListItemText primary={section.label} />
        </ListItem>
      );
    }

    return (
      <div>
        {list.map(listItem)}
        {(mobileWidth || smallHeight) && (
          <>
            <ListItem className={classes.saveButton}>
              <Button
                variant="contained"
                color="success"
                onClick={showSaveConfirmation}
                disabled={!isAdmin || isConfigurationChanged}
                endIcon={<SaveRoundedIcon />}
                fullWidth
              >
                Save changes
              </Button>
            </ListItem>
            <ListItem>
              <Button
                variant="contained"
                color="primary"
                endIcon={<CloudDownloadIcon />}
                onClick={() => downloadConfiguration()}
                fullWidth
              >
                Download
              </Button>
            </ListItem>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={1} lg={3}>
          {mobileWidth || smallHeight ? (
            <div className={classes.menuMobile}>
              <IconButton aria-label="more" aria-haspopup="true" onClick={handleClick} className={classes.icon}>
                <TuneIcon fontSize="large" />
              </IconButton>
              <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                {getItems()}
              </Menu>
            </div>
          ) : (
            <div className={classes.leftPanel}>
              <div className={classes.menuContainer}>
                <List
                  component="nav"
                  className={smallLaptop ? classNames([classes.menu, classes.leftPanelSmallLaptop]) : classes.menu}
                >
                  {getItems()}
                </List>
              </div>
              <div className={classes.buttonGroup}>
                <div className={classes.saveButton}>
                  <Button
                    variant="contained"
                    color="success"
                    endIcon={<SaveRoundedIcon />}
                    onClick={showSaveConfirmation}
                    disabled={!isAdmin || isConfigurationChanged}
                    fullWidth
                  >
                    Save changes
                  </Button>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<CloudDownloadIcon />}
                  onClick={() => downloadConfiguration()}
                  fullWidth
                >
                  Download
                </Button>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={10} lg={9}>
          <ChosenPanel.section
            isAdmin={isAdmin}
            config={configurationCopy}
            onConfigurationChange={ChosenPanel.onConfigurationChange}
            pluginNumber={ChosenPanel.pluginNumber ?? 0}
          />
        </Grid>
      </Grid>
      {changeConfirmationInfo && (
        <Dialog
          open={true}
          title={changeConfirmationInfo.title || "Confirm configuration change"}
          action={changeConfirmationInfo.action || "Confirm"}
          message={changeConfirmationInfo.question || ""}
          secondMessage={changeConfirmationInfo.message || ""}
          onConfirm={() => changeConfirmationInfo?.callback()}
          onCancel={closeConfirmationDialog}
          onClose={closeConfirmationDialog}
        />
      )}
    </div>
  );
};
