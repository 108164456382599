import { ModelType, ModelTypeName, getModelType } from "../models/utils";

import { ConfigurationPanel } from "../models/config";

export function checkModelsDisplay(
  models: ModelType[],
  section: string,
  feedback: string,
  numberErrors: number,
): { result: string; numberErrors: number } {
  const distincValuesModels = Array.from(new Set(models.filter((item, index) => models.indexOf(item) !== index)));

  if (distincValuesModels.length > 1 || (distincValuesModels.length === 1 && distincValuesModels[0])) {
    feedback += `${numberErrors}. Models in log ${section} order are duplicated! \n `;
    numberErrors++;
  }

  return { result: feedback, numberErrors: numberErrors };
}

export function checkLabels(
  show: boolean,
  label: string,
  labelName: string,
  feedback: string,
  numberErrors: number,
): { result: string; numberErrors: number } {
  if (show && label === "") {
    feedback += `${numberErrors}. The ${labelName} label must not be empty! \n `;
    numberErrors++;
  }
  return { result: feedback, numberErrors: numberErrors };
}

export function checkImageSizeLimits(
  minImageSize: number,
  maxImageSize: number,
  feedback: string,
  numberErrors: number,
): { result: string; numberErrors: number } {
  if (minImageSize > maxImageSize) {
    feedback += `${numberErrors}. The minimum should be less than the maximum! \n `;
    numberErrors++;
  }
  return { result: feedback, numberErrors: numberErrors };
}

export function checkConfiguration(config: ConfigurationPanel): string {
  let res = "";
  let numberErrors = 1;
  const predictConfig = config.predictionConfiguration;
  const logConfig = config.logConfiguration;
  const labelConfing = config.scanConfiguration;
  const imageConfig = config.scanConfiguration;
  const uploadConfig = config.uploadConfiguration;
  const consentConfig = config.consentConfiguration;
  const scanConfig = config.scanConfiguration;
  const startLabel = labelConfing.startButtonLabel;
  const devLabel = labelConfing.devButtonLabel;
  const showDev = labelConfing.showDevButton;
  const showStart = labelConfing.showStartButton;
  const minImageSize = imageConfig.minImageSize;
  const maxImageSize = imageConfig.maxImageSize;

  //Prediction panel
  if (!predictConfig.requestedModels.some((m) => m.inFormula)) {
    res += `${numberErrors}. There has to be at least one model in the formula! \n `;
    numberErrors++;
  }

  // Log panel
  const checkLogsTable = checkModelsDisplay(
    logConfig.logTableColumnsOrder
      .filter((m) => Object.values(ModelTypeName).includes(m as ModelTypeName))
      .map((m) => getModelType(m as ModelTypeName)),
    "table",
    res,
    numberErrors,
  );
  res = checkLogsTable.result;
  numberErrors = checkLogsTable.numberErrors;
  const checkLogsDetails = checkModelsDisplay(logConfig.modelsResultDisplay, "details", res, numberErrors);
  res = checkLogsDetails.result;
  numberErrors = checkLogsDetails.numberErrors;

  //Label panel
  const checkStartLabel = checkLabels(showStart, startLabel, "start", res, numberErrors);
  res = checkStartLabel.result;
  numberErrors = checkStartLabel.numberErrors;
  const checkDevLabel = checkLabels(showDev, devLabel, "dev", res, numberErrors);
  res = checkDevLabel.result;
  numberErrors = checkDevLabel.numberErrors;

  //Image panel
  const checkSize = checkImageSizeLimits(minImageSize, maxImageSize, res, numberErrors);
  res = checkSize.result;
  numberErrors = checkSize.numberErrors;

  //Upload panel
  const checkUploadSize = checkImageSizeLimits(uploadConfig.minFileSize, uploadConfig.maxFileSize, res, numberErrors);
  res = checkUploadSize.result;
  numberErrors = checkUploadSize.numberErrors;
  const checkImageWidth = checkImageSizeLimits(
    uploadConfig.minImageWidth,
    uploadConfig.maxImageWidth,
    res,
    numberErrors,
  );
  res = checkImageWidth.result;
  numberErrors = checkImageWidth.numberErrors;
  const checkImageHeight = checkImageSizeLimits(
    uploadConfig.minImageHeight,
    uploadConfig.maxImageHeight,
    res,
    numberErrors,
  );
  res = checkImageHeight.result;
  numberErrors = checkImageHeight.numberErrors;

  //Consent panel
  if (consentConfig.consent === "") {
    res += `${numberErrors}. The consent text must not be empty! \n `;
    numberErrors++;
  }

  if (consentConfig.showConsentCheckbox && consentConfig.consentCheckbox === "") {
    res += `${numberErrors}. The consent checkbox text must not be empty! \n `;
    numberErrors++;
  }

  //Scan panel
  if (scanConfig.faceCaptureModuleTooDarkThreshold > scanConfig.faceCaptureModuleTooBrightThreshold) {
    res += `${numberErrors}. Dark threshold can't be greater than bright threshold! \n `;
    numberErrors++;
  }

  return res;
}
