import {
  BRCKCampaignStats,
  DailyStats as DailyStatsModel,
  FaceCaptureModuleErrorsStats,
  MyFaceUser,
} from "../models/myface";
import { Grid, createStyles, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { CampaignsStats } from "./Dashboard/CampaignsStats";
import Config from "../../../config";
import { DailyStats } from "./Dashboard/DailyStats";
import { FaceCaptureModuleErrors } from "./Dashboard/FaceCaptureModuleErrors";
import { MyFaceService } from "../api/myface";
import { UsersStats } from "./Dashboard/UsersStats";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: "20px",
    },
  }),
);

const myFaceService = new MyFaceService(Config.apiUrl);

export const MyFaceStatsSection: React.FC = () => {
  const classes = useStyles();

  // Daily stats
  const [dailyStats, setDailyStats] = useState<DailyStatsModel[]>();
  const [dailyStatsError, setDailyStatsError] = useState<Error>();
  const [dailyStatsLoading, setDailyStatsLoading] = useState(false);
  const [dailyStatsPage, setDailyStatsPage] = useState(0);
  const [dailyStatsRowCount, setDailyStatsRowCount] = useState(0);
  const [dailyStatsRowsPerPage, setDailyStatsRowsPerPage] = useState(10);

  function handleChangeDailyStatsRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setDailyStatsRowsPerPage(+event.target.value);
    setDailyStatsPage(0);
  }

  useEffect(() => {
    setDailyStatsLoading(true);
    myFaceService
      .getDailyStats(dailyStatsPage, dailyStatsRowsPerPage)
      .then((res) => {
        setDailyStatsRowCount(res.count);
        setDailyStats(res.stats);
      })
      .catch((e) => setDailyStatsError(e))
      .finally(() => setDailyStatsLoading(false));
  }, [dailyStatsPage, dailyStatsRowsPerPage]);

  // Campaigns stats
  const [campaignsStats, setCampaignsStats] = useState<BRCKCampaignStats[]>();
  const [campaignsStatsError, setCampaignsStatsError] = useState<Error>();
  const [campaignsStatsLoading, setCampaignsStatsLoading] = useState(false);

  useEffect(() => {
    setCampaignsStatsLoading(true);
    myFaceService
      .getBRCKCampaignStats()
      .then((res) => setCampaignsStats(res))
      .catch((e) => setCampaignsStatsError(e))
      .finally(() => setCampaignsStatsLoading(false));
  }, []);

  // Face Capture Module errors stats
  const [faceCaptureModuleErrorsStats, setFaceCaptureModuleErrorsStats] = useState<FaceCaptureModuleErrorsStats>();
  const [faceCaptureModuleErrorsStatsError, setFaceCaptureModuleErrorsStatsError] = useState<Error>();
  const [faceCaptureModuleErrorsStatsLoading, setFaceCaptureModuleErrorsStatsLoading] = useState(false);

  useEffect(() => {
    setFaceCaptureModuleErrorsStatsLoading(true);
    myFaceService
      .getFaceCaptureModuleErrorsStats()
      .then((res) => setFaceCaptureModuleErrorsStats(res))
      .catch((e) => setFaceCaptureModuleErrorsStatsError(e))
      .finally(() => setFaceCaptureModuleErrorsStatsLoading(false));
  }, []);

  // Users stats
  const [usersStats, setUsersStats] = useState<MyFaceUser[]>();
  const [usersStatsError, setUsersStatsError] = useState<Error>();
  const [usersStatsLoading, setUsersStatsLoading] = useState(false);
  const [usersPage, setUsersPage] = useState(0);
  const [usersRowCount, setUsersRowCount] = useState(0);
  const [usersRowsPerPage, setUsersRowsPerPage] = useState(10);

  function handleChangeUsersRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setUsersRowsPerPage(+event.target.value);
    setUsersPage(0);
  }

  useEffect(() => {
    setUsersStatsLoading(true);
    myFaceService
      .getUsers(usersPage, usersRowsPerPage)
      .then((res) => {
        setUsersRowCount(res.count);
        setUsersStats(res.users);
      })
      .catch((e) => setUsersStatsError(e))
      .finally(() => setUsersStatsLoading(false));
  }, [usersPage, usersRowsPerPage]);

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <CampaignsStats campaignsStats={campaignsStats} error={campaignsStatsError} loading={campaignsStatsLoading} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FaceCaptureModuleErrors
            faceCaptureModuleErrorsStats={faceCaptureModuleErrorsStats}
            error={faceCaptureModuleErrorsStatsError}
            loading={faceCaptureModuleErrorsStatsLoading}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DailyStats
            dailyStats={dailyStats}
            dailyStatsPage={dailyStatsPage}
            setDailyStatsPage={setDailyStatsPage}
            dailyStatsRowCount={dailyStatsRowCount}
            dailyStatsRowsPerPage={dailyStatsRowsPerPage}
            handleChangeDailyStatsRowsPerPage={handleChangeDailyStatsRowsPerPage}
            error={dailyStatsError}
            loading={dailyStatsLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <UsersStats
            usersStats={usersStats}
            usersPage={usersPage}
            setUsersPage={setUsersPage}
            usersRowCount={usersRowCount}
            usersRowsPerPage={usersRowsPerPage}
            handleChangeUsersRowsPerPage={handleChangeUsersRowsPerPage}
            error={usersStatsError}
            loading={usersStatsLoading}
          />
        </Grid>
      </Grid>
    </div>
  );
};
