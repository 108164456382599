import React, { useEffect, useState } from "react";

import { ListLabel } from "../LogDetail";

interface Aggregate {
  count: number;
  mean: number;
  m2: number;
}

interface Pixels {
  data: Uint8ClampedArray;
}

/**
 * Take an image and return the pixels list.
 *
 * @param img The image to calculate the pixels.
 * @returns The pixels values for the image provided.
 */
const getPixels = (img: string): Promise<Pixels> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      const context = canvas.getContext("2d");
      if (!context) {
        reject(new Error("cannot create canvas context"));
      } else {
        context.drawImage(image, 0, 0);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        resolve(imageData);
      }
    };
    image.src = img;
  });
};

// IMPORTANT: This method is a copy from the FCM that calculates the brightness
// to run the validation.
/**
 * @param {{count: number, mean:number, md:number}} currentValue
 * @param {number} newValue
 * @return {{count: number, mean:number, md:number}}
 */
const updateAggregate = (currentValue: Aggregate, newValue: number) => {
  let { count, mean, m2 } = currentValue;
  count++;
  const delta = newValue - mean; // delta is the difference between the new value and the old mean.
  mean += delta / count; // is the mean until Xk element.
  const delta2 = newValue - mean; // delta2 is the difference between the new value and the new mean.
  m2 += delta * delta2; // m2 is Welford's dividend for the variance until the Xk element.
  return { count, mean, m2 };
};

// IMPORTANT: This method is a copy from the FCM that calculates the brightness
// to run the validation.
/**
 * Calculate the mean and the variance of a certain image pixels excluding the alpha.
 * It uses the Welford's method to improve performance.
 *
 * @param {ImageData} pixels Image which variance/mean will be calculated.
 * @return {{mean: Number,variance: Number}}
 */
const getVariance = (pixels: Pixels) => {
  const { data } = pixels;

  let aggregate = { count: 0, mean: 0, m2: 0 };

  for (let i = 0; i < data.length; i += 4) {
    aggregate = updateAggregate(aggregate, data[i]);
    aggregate = updateAggregate(aggregate, data[i + 1]);
    aggregate = updateAggregate(aggregate, data[i + 2]);
  }

  return { mean: aggregate.mean, variance: aggregate.m2 / aggregate.count };
};

const round = (value: number, numDecimals: number) => {
  const numDecimalsFactor = Math.pow(10, numDecimals);
  return Math.round(value * numDecimalsFactor) / numDecimalsFactor;
};

export const roundBrightnessScore = (value: number) => round(value, 6);

interface Props {
  label: string;
  img?: string;
  backEndScore?: number;
}

export const BrightnessScore: React.FC<Props> = ({ label, img, backEndScore }) => {
  const [pixels, setPixels] = useState<Pixels>();

  useEffect(() => {
    if (!img) {
      return;
    }
    const base64Img = `data:image/png;base64,${img}`;
    getPixels(base64Img).then(setPixels);
  }, [img]);

  const feBrightnessScore = pixels ? roundBrightnessScore(getVariance(pixels).mean) : undefined;

  const beValue = backEndScore ? `BE: ${backEndScore}` : "";
  const feValue = feBrightnessScore ? `FE: ${feBrightnessScore}` : "";
  const title = backEndScore ? <b>{label}</b> : label;
  return (
    <li>
      <ListLabel title={title} value={[beValue, feValue].filter((v) => !!v).join(", ")} />
    </li>
  );
};
