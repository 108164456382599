import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
      color: theme.palette.common.black,
    },
  }),
);

interface Props {
  text?: string;
  visible: boolean;
  className?: string;
}

export const Loading: React.FC<Props> = (props) => {
  const { className, visible, text } = props;

  const classes = useStyles(props);
  if (!visible) {
    return null;
  }
  return (
    <span className={className}>
      <CircularProgress size={24} className={classes.progress} />
      {text ? ` ${text}` : ""}
    </span>
  );
};
