import { Filter, FilterValues, Sort } from "../models/filter";
import { LogDetail, LogImg, PaginationLog, SessionInfo } from "../models/log";

import { ApiBase } from "./base";
import { ModelCheckpointMap } from "../models/utils";
import { StringFilterType } from "../models/image";

export class LogService extends ApiBase {
  log = (page: number, pageSize: number, filters?: Filter[], sortElement?: Sort): Promise<PaginationLog> => {
    if (filters) {
      filters = filters.filter((f) => f.value !== undefined || f.secondValue !== undefined);
      filters.forEach((f, i) => {
        if (f.value === StringFilterType.Null && filters) {
          filters[i].value = null;
        }
        // We have to do these checks because of a method in FilterContainer.tsx that converts
        // the boolean values into binary integer, but as these values are stored in database
        // like booleans, we have to convert them again for the comparison.
        if (f.type === "boolean" && f.value === 1 && filters) {
          filters[i].value = true;
        }
        if (f.type === "boolean" && f.value === 0 && filters) {
          filters[i].value = false;
        }
      });
    }
    return this.fetch<PaginationLog>("list", {
      method: "POST",
      data: JSON.stringify({
        page: page,
        pageSize: pageSize,
        filters: filters ? filters : [],
        sort: sortElement ? sortElement : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  setSessionInfo = (info: SessionInfo): Promise<void> => {
    return this.fetch("session-info", {
      method: "POST",
      data: JSON.stringify(info),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  details = async (id: number): Promise<LogDetail> => {
    return await this.fetch<LogDetail>(`predict/${id}`);
  };

  img = async (id: number): Promise<LogImg> => {
    return await this.fetch<LogImg>(`img/${id}`);
  };

  filterValues = async (): Promise<FilterValues> => {
    return await this.fetch<FilterValues>(`filterValues`);
  };

  models = async (): Promise<ModelCheckpointMap> => {
    return await this.fetch<ModelCheckpointMap>(`models`);
  };

  setFeedback = (id: number, value?: string): Promise<void> => {
    return this.fetch(`feedback`, {
      method: "POST",
      data: JSON.stringify({ id: id, feedback: value }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  setFeedbackByRequestId = (requestId: string, feedback?: string): Promise<void> => {
    return this.fetch(`feedback-session`, {
      method: "POST",
      data: JSON.stringify({ requestId, feedback }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  updateFeedback = (id: number, feedback?: string) => {
    return this.fetch(`feedback`, {
      method: "PUT",
      data: JSON.stringify({ id, feedback }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  updateExpectedResult = (id: number, expectedResult?: string, sessionId?: string) => {
    return this.fetch(`expected-result`, {
      method: "PUT",
      data: JSON.stringify({ id, expectedResult, sessionId }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  updateExpectedLocation = (id: number, expectedLocation?: string, sessionId?: string) => {
    return this.fetch(`expected-location`, {
      method: "PUT",
      data: JSON.stringify({ id, expectedLocation, sessionId }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  updateExpectedBrightness = (id: number, expectedBrightness?: string, sessionId?: string) => {
    return this.fetch(`expected-brightness`, {
      method: "PUT",
      data: JSON.stringify({ id, expectedBrightness, sessionId }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  delete = (id: number): Promise<void> => {
    return this.fetch(`predict/${id}`, { method: "DELETE" });
  };

  deleteSession = (sessionId: string): Promise<void> => {
    return this.fetch(`session/${sessionId}`, { method: "DELETE" });
  };

  deleteBatch = (ids: number[], deleteSessions: boolean): Promise<void> => {
    return this.fetch(`logs/batch`, {
      method: "DELETE",
      data: { ids: ids, deleteSessions: deleteSessions },
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  download = async (filename: string, filters?: Filter[], onlyCSV?: boolean): Promise<ArrayBuffer> => {
    if (filters) {
      filters = filters.filter((f) => f.value !== undefined || f.secondValue !== undefined);
    }

    return await this.fetch<ArrayBuffer>("download", {
      method: "POST",
      data: JSON.stringify({
        fileName: filename,
        filters: filters ? filters : [],
        onlyCSV: onlyCSV ? onlyCSV : false,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    });
  };
}
