import { RoleCode } from "./role";

// invalidSessionError is the error code the backend will return in case there is
// a problem with the current session.
export const invalidSessionError = "INVALID_SESSION_ERROR";

export interface Session {
  exp: number;
  token: string;
  userId: number;
  role: RoleCode;
  verified: boolean;
  banned: boolean;
}

export enum LoginType {
  Google = "google",
  Yoti = "yoti",
}

export interface GoogleLogin {
  type: LoginType.Google;
  url: string;
  state: string;
}

export interface YotiLogin {
  type: LoginType.Yoti;
  url: string;
}

export type LoginResponse = GoogleLogin | YotiLogin;

export type Login =
  | {
      type: LoginType.Google;
      data: GoogleToken;
    }
  | {
      type: LoginType.Yoti;
      data: YotiToken;
    };

export interface YotiToken {
  token: string;
}

interface GoogleToken {
  code: string;
  state: string;
  storedState: string;
}
