import { AddBoxRounded, IndeterminateCheckBox } from "@material-ui/icons";
import { FaceResult, PredictionFormulaResult } from "../../models/prediction";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import { Button } from "./../Utils/Button";
import Config from "../../config";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { GlobalContext } from "../../globalContext";
import Grid from "@material-ui/core/Grid";
import { Loading } from "../Utils/Loading";
import { LogService } from "../../api/log";
import { ModelErrorDetails } from "../Log/LogDetail/ModelDetails/ModelErrorDetails";
import { ModelResult as ModelResultComponent } from "./Results/ModelResult";
import { ModelType } from "../../models/utils";
import { ModelsConfig } from "../../config/modelsConfig";
import { PredictionColor } from "../Utils/PredictionColor";
import { PredictionIcon } from "../Utils/PredictionIcon";
import { PredictionResultConfig } from "../../models/config";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { SessionContentResult } from "../../models/aiClient";
import Typography from "@material-ui/core/Typography";
import { predictionErrorFormat } from "../../utils/error";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface Props {
  face: FaceResult;
  onChangePredictionResult?: (res: PredictionFormulaResult) => void;
  sessionId?: string;
  sessionImages?: number;
  sessionSpoofs?: SessionContentResult[];
}

const service = new LogService(Config.apiUrl);

const SERVER_TIMEOUT = 5000;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 500,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "50px",
      marginTop: "15px",
    },
    topContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      margin: "4px",
    },
    predictionIcon: {
      display: "flex",
      alignItems: "center",
    },
    loadingIcon: {
      display: "block",
      textAlign: "center",
    },
    predictionTitle: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "24px",
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.common.black,
    },
    inlineElementWrapper: {
      display: "inline",
    },
    predictionValue: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "24px",
      },
      fontWeight: theme.typography.fontWeightMedium,
      marginLeft: 6,
    },
    feedbackContainer: {
      alignItems: "center",
      display: "flex",
      marginTop: "4px",
      marginBottom: "4px",
      marginLeft: "10px",
      width: "100%",
    },
    button: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "-12px",
        width: "20%",
        fontSize: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: "10px",
      },
    },
    submitButtonContainer: {
      display: "inline-block",
    },
    modelPrediction: {
      margin: 4,
      display: "flex",
      alignItems: "center",
    },
    otherModels: {
      display: "flex",
      alignItems: "center",
      margin: "4px",
      width: "100%",
    },
    imageWrapper: {
      textAlign: "center",
    },
    backButtonWrapper: {
      textAlign: "center",
    },
    showModelsButtonWrapper: {
      textAlign: "center",
    },
    clear: {
      clear: "both",
    },
    column: {
      float: "left",
    },
    result: {
      lineHeight: "20px",
    },
    icon: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
      },
    },
    routerLink: {
      textDecoration: "None",
    },
    radioButton: {
      "& svg": {
        [theme.breakpoints.down("xs")]: {
          width: "0.5em",
          height: "0.5em",
        },
      },
      "& span": {
        [theme.breakpoints.down("xs")]: {
          fontSize: "13px",
        },
      },
    },
  }),
);

const useResultImgStyles = makeStyles((theme) =>
  createStyles({
    image: (props: { vertical: boolean; mobileWidth: boolean; predictionResult?: PredictionFormulaResult }) => ({
      maxWidth: 500,
      width:
        props.mobileWidth && props.vertical
          ? "40%"
          : props.mobileWidth && !props.vertical
          ? "70%"
          : !props.mobileWidth && props.vertical
          ? "50%"
          : "85%",
      borderRadius: "20px",
      borderStyle: props.predictionResult ? "solid" : "",
      borderColor: props.predictionResult
        ? props.predictionResult === PredictionFormulaResult.Fake ||
          props.predictionResult === PredictionFormulaResult.Error
          ? theme.palette.error.main
          : props.predictionResult === PredictionFormulaResult.Undetermined
          ? theme.palette.warning.main
          : theme.palette.success.main
        : "",
      height: "auto",
    }),
  }),
);

export const PredictionResult: React.FC<Props> = (props) => {
  const { face, sessionImages, sessionSpoofs } = props;

  const classes = useStyles();
  const [predictionResult, setPredictionResult] = useState<PredictionFormulaResult>();
  const [uploadFeedbackMessage, setUploadFeedbackMessage] = useState<string>("Uploading the image to the server");
  const [vertical, setVertical] = useState<boolean>(false);
  const theme = useTheme();
  const mobileWidth = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    setTimeout(() => {
      setUploadFeedbackMessage("Server is taking longer than usual, please wait...");
    }, SERVER_TIMEOUT);

    const img = new Image();
    img.onload = function () {
      const height = img.height;
      const width = img.width;
      setVertical(height > width);
    };
    img.src = face.img;
  }, [face.img]);

  const resultImgClasses = useResultImgStyles({
    vertical,
    mobileWidth,
    predictionResult,
  });

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <Loading visible={face.loading} className={classes.loadingIcon} />
        {!face.loading ? (
          <Result {...props} onChangePredictionResult={setPredictionResult} />
        ) : (
          <Typography variant="h4" color="textPrimary" gutterBottom>
            {uploadFeedbackMessage}
          </Typography>
        )}
        <br />
        {sessionImages && sessionSpoofs && (
          <>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  {"Processed photos: " + sessionImages}
                </Grid>
                <Grid item sm={6}>
                  {"Spoofings: " + sessionSpoofs.length.toString()}
                </Grid>
              </Grid>
            </Typography>
            <Divider />
            <br />
          </>
        )}
        <div className={classes.imageWrapper}>
          <Container {...props}>
            <img alt="face" className={resultImgClasses.image} src={face.img} />
          </Container>
        </div>
      </div>
      <div className={classes.clear} />
      <br />
      <div className={classes.backButtonWrapper}>
        <RouterLink to="/" className={classes.routerLink}>
          <Button variant="contained" size="large">
            Back
          </Button>
        </RouterLink>
      </div>
    </div>
  );
};

const Container: React.FC<Props> = (props) => {
  const { face, children } = props;
  if (face.modelPredictionResultList) {
    return <Link {...props} />;
  }
  return <>{children}</>;
};

const Link: React.FC<Props> = (props) => {
  const { face, children } = props;
  return (
    <a download="face" href={face.img}>
      {children}
    </a>
  );
};

const Result: React.FC<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { panelConfigurationData, setFeedback } = React.useContext(GlobalContext);
  const [predictionFormulaRes, setPredictionFormulaRes] = useState<PredictionFormulaResult>();
  const [userFeeback, setUserFeedback] = useState<string | undefined>(undefined);
  const { onChangePredictionResult, face } = props;

  const mobileWidth = useMediaQuery(theme.breakpoints.down("xs"));
  const [modelResulstOpen, setModelResulstOpen] = useState<boolean>(false);

  const IconModelResults = !modelResulstOpen ? AddBoxRounded : IndeterminateCheckBox;

  useEffect(() => {
    if (face.error) {
      if (onChangePredictionResult) {
        onChangePredictionResult(PredictionFormulaResult.Error);
      }
      return;
    }
    if (!face.result) return;
    const result: PredictionFormulaResult = face.result;
    setPredictionFormulaRes(result);
    if (onChangePredictionResult) {
      onChangePredictionResult(result);
    }
  }, [onChangePredictionResult, face.result, setFeedback, face.error]);

  useEffect(() => {
    if (!mobileWidth) {
      setModelResulstOpen(true);
    } else {
      setModelResulstOpen(false);
    }
  }, [mobileWidth]);

  const history = useHistory();

  const onSubmitFeedback = () => {
    if (!face.id) return;
    let setFeedbackPromise: Promise<void>;
    let id: number | string;
    if (face.requestId) {
      id = face.requestId;
      setFeedbackPromise = service.setFeedbackByRequestId(face.requestId, userFeeback);
    } else if (face.id) {
      id = face.id;
      setFeedbackPromise = service.setFeedback(face.id, userFeeback);
    } else {
      // Do nothing if result id or request id don't exist.
      console.warn("submit feedback without ID");
      return;
    }

    setFeedbackPromise
      .then(() => {
        setFeedback({
          variant: "success",
          message: `Feedback for ID ${id} saved!`,
        });
      })
      .catch(() => {
        setFeedback({
          variant: "error",
          message: "Cannot save feedback!",
        });
      })
      .finally(() => {
        history.push("/");
      });
  };

  const modelPredictions: JSX.Element[] = [];
  const otherResults: JSX.Element[] = [];

  for (const key in face.modelPredictionResultList) {
    const modelType = (key as unknown) as ModelType;
    const predictionLabelInfo = ModelsConfig[modelType] ? ModelsConfig[modelType].Prediction : null;
    if (!predictionLabelInfo) {
      continue;
    }
    const list = predictionLabelInfo.Position === "prediction" ? modelPredictions : otherResults;
    const config:
      | PredictionResultConfig
      | undefined = panelConfigurationData.predictionConfiguration.predictionResult.find((r) => r.model === modelType);
    if (config && ((config.antiSpoofing && (config.percentage || config.text)) || config.text)) {
      list.push(
        <ModelResultComponent
          key={key}
          modelType={modelType}
          result={face.modelPredictionResultList[modelType]}
          config={config}
          formula={face.formulaResult}
        />,
      );
    }
  }

  // Prediction message to show
  let predictionMessage = "",
    predictionErrorMessage = "";

  switch (predictionFormulaRes) {
    case PredictionFormulaResult.Real:
      predictionMessage = "Passed";
      break;
    case PredictionFormulaResult.Fake:
      predictionMessage = "Failed";
      break;
    case PredictionFormulaResult.Undetermined:
      predictionMessage = "Undetermined";
      break;
    case PredictionFormulaResult.Error:
      predictionMessage = "Error";
      if (face.modelPredictionResultList) {
        predictionErrorMessage = predictionErrorFormat(face.modelPredictionResultList, face.formulaResult);
      }
      break;
    default:
      predictionMessage = "Error";
      if (face.error) {
        predictionErrorMessage = face.error;
      }
  }

  return (
    <div className={classes.result}>
      {panelConfigurationData && predictionMessage && (
        <>
          {panelConfigurationData.predictionConfiguration.showPrediction && (
            <>
              <div className={classes.topContainer}>
                <span className={classes.inlineElementWrapper}>
                  {predictionMessage && (
                    <PredictionIcon
                      className={classes.predictionIcon}
                      fontSize={mobileWidth ? 20 : 30}
                      prediction={predictionFormulaRes ? predictionFormulaRes : PredictionFormulaResult.Error}
                    />
                  )}
                </span>
                <span className={classes.inlineElementWrapper}>
                  <Typography color="textSecondary" className={classes.predictionTitle}>
                    Liveness Check:
                  </Typography>
                </span>
                <span className={classes.inlineElementWrapper}>
                  <PredictionColor
                    prediction={predictionFormulaRes ? predictionFormulaRes : PredictionFormulaResult.Error}
                  >
                    <Typography className={classes.predictionValue}>{predictionMessage}</Typography>
                  </PredictionColor>
                </span>
              </div>

              {predictionErrorMessage && <ModelErrorDetails error={predictionErrorMessage} />}

              {!predictionErrorMessage && panelConfigurationData.predictionConfiguration.showFeedback && (
                <>
                  <Divider />
                  <div className={classes.feedbackContainer}>
                    <RadioGroup
                      className={classes.inlineElementWrapper}
                      value={userFeeback}
                      onChange={(e) => setUserFeedback(e.target.value)}
                      row
                    >
                      <FormControlLabel
                        value="correct"
                        control={<Radio color="primary" />}
                        label="Correct"
                        labelPlacement="end"
                        className={classes.radioButton}
                      />
                      <FormControlLabel
                        value="incorrect"
                        control={<Radio color="primary" />}
                        label="Incorrect"
                        labelPlacement="end"
                        className={classes.radioButton}
                      />
                    </RadioGroup>
                    {userFeeback !== "" && (
                      <div className={classes.submitButtonContainer}>
                        <Button variant="contained" onClick={onSubmitFeedback}>
                          Submit
                        </Button>
                      </div>
                    )}
                  </div>
                </>
              )}

              <Divider />
            </>
          )}
          {mobileWidth && (modelPredictions.length > 0 || otherResults.length > 0) && (
            <div className={classes.showModelsButtonWrapper}>
              <Button size="small" onClick={() => setModelResulstOpen(!modelResulstOpen)}>
                <IconModelResults />
              </Button>
            </div>
          )}
          {modelResulstOpen && (
            <>
              <Grid container>
                {modelPredictions.map((modelResult, index) => {
                  return (
                    <Grid item xs={modelPredictions.length <= 4 ? 12 : 6} key={index}>
                      {modelResult}
                    </Grid>
                  );
                })}
              </Grid>
              <Divider />
              {otherResults.map((modelResult, index) => (
                <div key={index} className={classes.otherModels}>
                  {modelResult}
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};
