import { GetHandleProps, GetTrackProps, Handles, Rail, Slider, SliderItem, Tracks } from "react-compound-slider";

import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";

const sliderStyle: React.CSSProperties = {
  position: "relative",
  width: "100%",
  height: 80,
  marginTop: "40px",
};

interface HandleProps {
  disabled?: boolean;
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  decimalStep: boolean;
  step: number;
}

export function countDecimals(value: number) {
  if (Math.floor(value) !== value) return value.toString().split(".")[1].length || 0;
  return 0;
}

export function Handle(props: HandleProps) {
  const theme = useTheme();
  return (
    <div
      style={{
        left: `${props.handle.percent}%`,
        position: "absolute",
        marginLeft: -15,
        marginTop: 25,
        zIndex: 2,
        width: 30,
        height: 30,
        border: 0,
        textAlign: "center",
        cursor: props.disabled ? undefined : "pointer",
        borderRadius: "50%",
        backgroundColor: props.disabled ? theme.palette.primary.main : theme.palette.primary.dark,
        color: props.disabled ? theme.palette.primary.main : theme.palette.primary.dark,
      }}
      {...props.getHandleProps(props.handle.id)}
    >
      <div style={{ fontSize: 20, marginTop: -35 }}>
        {props.decimalStep ? props.handle.value.toFixed(countDecimals(props.step)) : props.handle.value}
      </div>
    </div>
  );
}

interface TrackProps {
  disabled?: boolean;
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
}

function Track(props: TrackProps) {
  const theme = useTheme();
  return (
    <div
      style={{
        position: "absolute",
        height: 10,
        zIndex: 1,
        marginTop: 35,
        backgroundColor: props.disabled ? theme.palette.primary.main : theme.palette.primary.dark,
        borderRadius: 5,
        cursor: props.disabled ? undefined : "pointer",
        left: `${props.source.percent}%`,
        width: `${props.target.percent - props.source.percent}%`,
      }}
      {...props.getTrackProps()} // this will set up events if you want it to be clickeable (optional)
    />
  );
}

interface SliderBarProps {
  disabled?: boolean;
  min: number;
  max: number;
  value: number;
  step: number;
  onChange: (value: number) => void;
}

export const SliderBar: React.FC<SliderBarProps> = (props) => {
  const theme = useTheme();
  return (
    <>
      <Slider
        rootStyle={sliderStyle}
        domain={[props.min, props.max]}
        step={props.step}
        mode={2}
        values={[props.value]}
        onChange={(values) => props.onChange(Number(values[0].toFixed(countDecimals(props.step))))}
        disabled={props.disabled}
      >
        <Rail>
          {(
            { getRailProps }, // adding the rail props sets up events on the rail
          ) => (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: 10,
                marginTop: 35,
                borderRadius: 5,
                backgroundColor: props.disabled ? theme.palette.primary.light : theme.palette.primary.main,
              }}
              {...getRailProps()}
            />
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  getHandleProps={getHandleProps}
                  decimalStep={!Number.isInteger(props.step)}
                  disabled={props.disabled}
                  step={props.step}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                  disabled={props.disabled}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </>
  );
};
