import { Theme, createStyles, makeStyles } from "@material-ui/core";

import React from "react";
import Typography from "@material-ui/core/Typography";

interface Props {
  title: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(5),
    },
    title: { fontSize: "24px" },
    children: { marginTop: theme.spacing(2) },
  }),
);

const ConfigurationSubsection: React.FC<Props> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom className={classes.title}>
        {title}
      </Typography>
      <div className={classes.children}>{children}</div>
    </div>
  );
};

export default ConfigurationSubsection;
