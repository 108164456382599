import { CroppingImgValidationResults, CroppingResult } from "../../../../models/log";

import { BrightnessScore } from "./BrightnessScore";
import React from "react";

type CroppedImgsType = CroppingResult["imgs"];

export interface Props {
  croppedImgsValidations?: CroppingImgValidationResults;
  croppedImgs: CroppedImgsType;
}

export const BrightnessValidationResult: React.FC<Props> = ({ croppedImgsValidations, croppedImgs }) => {
  // Join keys from "croppedImgs" and "croppedImgsValidations". This will avoid
  // value for the validation is not rendered if it is not in the cropped
  // images.
  const croppings = Object.keys(croppedImgs ? croppedImgs : [])
    .concat(croppedImgsValidations ? Object.keys(croppedImgsValidations) : [])
    .map((k) => k as keyof CroppedImgsType) // Cast 'k' to be a key of 'croppedImgs'
    .filter((v, i, self) => self.indexOf(v) === i); // Distinct

  return (
    <div>
      <b>Brightness:</b>
      <ul>
        {croppings.map((k) => (
          <div key={k}>
            <BrightnessScore
              label={k}
              img={croppedImgs ? croppedImgs[k] : undefined}
              backEndScore={
                croppedImgsValidations && croppedImgsValidations[k] ? croppedImgsValidations[k].brightness : undefined
              }
            />
          </div>
        ))}
      </ul>
    </div>
  );
};
