import React, { Ref, forwardRef, useImperativeHandle } from "react";

import { Feedback as FeedbackEntity } from "../../models/utils";
import { FeedbackRef } from "./../../App";
import { useSnackbar } from "notistack";

// feedbackTimeout is default feedback time in miliseconds
const feedbackTimeout = 3000;

export const Feedback = forwardRef((props, ref: Ref<FeedbackRef>) => {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Load the snackbar component with the data given.
   * @param data snackbar data entity.
   * @returns reference key for the snackbar created.
   */
  const setSnackbarData = (data: FeedbackEntity): number | string | null | undefined => {
    return enqueueSnackbar(data.message, {
      id: "startDownloadImages",
      variant: data.variant,
      autoHideDuration: data.duration !== undefined ? data.duration : feedbackTimeout,
      anchorOrigin: {
        vertical: data.vertical ? data.vertical : "bottom",
        horizontal: data.horizontal ? data.horizontal : "right",
      },
    });
  };

  useImperativeHandle(ref, () => ({ setSnackbarData }));

  return <></>;
});
