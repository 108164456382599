import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Conditional } from "../Utils/Conditional";
import Config from "../../config";
import { StatsService } from "../../api/stats";
import { ToolUses as ToolUsesModel } from "../../models/stats";

const statsService = new StatsService(Config.apiUrl);

const useStyles = makeStyles(() =>
  createStyles({
    tableCell: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "30px",
      whiteSpace: "nowrap",
    },
    title: {
      display: "inline-block",
    },
    titleContainer: {
      textAlign: "center",
      marginTop: "10px",
    },
  }),
);

export const ToolUses: React.FC = () => {
  const classes = useStyles();
  const [toolUses, setToolUses] = useState<ToolUsesModel>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    statsService
      .getToolUses()
      .then((res) => {
        setToolUses(res);
        setError(undefined);
      })
      .catch((e) => setError(e));
  }, [setError]);

  const getTableRow = (name: string, elements: JSX.Element[]) => {
    return (
      <TableRow>
        <TableCell component="th" scope="row">
          {name}
        </TableCell>
        {elements.map((element, index) => {
          return (
            <TableCell key={index} align="center">
              {element}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <Conditional error={error} errorMessage="Failed to load images sent stats">
      <>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography className={classes.title} variant="h4" color="textPrimary" gutterBottom>
              Images sent to the server
            </Typography>
          </Grid>
          {toolUses && (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell align="center" className={classes.tableCell}>
                        Total
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        Last Hour
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        Last Day
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        Last Week
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        Last Month
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        Last Year
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{getTableRow("Images sent", Object.values(toolUses.imagesSent))}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </>
    </Conditional>
  );
};
