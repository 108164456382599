import { createStyles, makeStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { ListLabel } from "../LogDetail";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    models: {
      [theme.breakpoints.down(430)]: {
        fontSize: "4vw",
        display: "grid",
      },
    },
  }),
);

interface Props {
  label: string;
  value: string | number;
}

// TODO AITOOL-1791: Use this component in all the models details components in
// order to remove the class `models` in this file that is duplicated in every
// model details.
export const FieldItem: React.FC<Props> = ({ label, value }) => {
  const classes = useStyles();

  return (
    <>
      <ListItem className={classes.models}>
        <ListLabel title={label} value={`${value}`}></ListLabel>
      </ListItem>
      <Divider />
    </>
  );
};
