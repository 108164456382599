import { FileInfo } from "../models/upload";
import { ImageExtensionFormat } from "../models/image";

// Image formats that the panel wants to store
const validFormats: string[] = [ImageExtensionFormat.PNG, ImageExtensionFormat.JPEG];

/**
 * Get the base64 string representation of the image from an image file.
 * @param img An image file.
 */
const readImg = (img: File): Promise<string> => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result == "string") {
        resolve(reader.result);
      }
    };
    reader.readAsDataURL(img);
  });
};

/**
 * Returns the image extension format from an image file.
 * @param img An image file.
 */
const getImageExtensionFormat = (img: File): ImageExtensionFormat => {
  let format: ImageExtensionFormat = ImageExtensionFormat.Unknown;
  const fileFormat = img.type.split("/").pop();
  if (fileFormat && validFormats.includes(fileFormat)) {
    format = fileFormat as ImageExtensionFormat;
  }
  return format;
};

/**
 * Returns an array of FileInfo entities with the files given decoding.
 * @param files Files to be decoded.
 */
export const readFiles = async (files: FileList | File[] | null): Promise<FileInfo[]> => {
  if (!files) {
    return [];
  }

  const result: FileInfo[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    result.push({
      base64content: await readImg(file),
      format: getImageExtensionFormat(file),
      name: file.name,
    });
  }
  return result;
};
