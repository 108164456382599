import { ExpectedBrightness, ExpectedLocation } from "../../../models/log";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "18px",
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: "17px",
      color: theme.palette.common.black,

      paddingTop: "20px",
      textAlign: "center",
      marginBottom: "10px",
    },
    radio: {
      display: "inline-block",
      margin: "auto",
    },
  }),
);

interface EnumRadioGroupProps {
  title: string;
  enumerate: typeof ExpectedLocation | typeof ExpectedBrightness;
  value: any;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void) | undefined;
}

export const EnumRadioGroup: React.FC<EnumRadioGroupProps> = (props) => {
  const classes = useStyles();
  const { title, enumerate, value, onChange } = props;
  return (
    <>
      <div className={classes.title}>{title}</div>
      <RadioGroup value={value} onChange={onChange} row>
        {Object.values(enumerate)
          .filter((value) => value !== "null")
          .map((val) => {
            return (
              <FormControlLabel
                key={val}
                value={val}
                control={<Radio color="primary" />}
                label={val.charAt(0).toUpperCase() + val.slice(1)}
                labelPlacement="top"
                className={classes.radio}
              />
            );
          })}
      </RadioGroup>
    </>
  );
};
