import { MenuItem, Select, TextField } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Button } from "../../Utils/Button";
import { DateTimeInput } from "../../Utils/DatetimeInput";
import DeleteIcon from "@material-ui/icons/Delete";
import { FilterType } from "../../../models/filter";
import { Filters } from "../../../utils/filters";
import { NumericField } from "../../Utils/NumericField";
import { PercentageInput } from "../../Utils/PercentageInput";
import React from "react";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";

interface Props {
  title: string;
  value?: string | number | boolean | null;
  op?: string;
  secondValue?: string | number | null;
  secondOp?: string;
  subTitles?: string[];
  values?: (string | number | boolean | null)[];
  type: FilterType;
  propertyName: keyof Filters;
  valueType: "string" | "number" | "boolean";
  filterRows: (
    value: string | number | boolean | null | undefined,
    propertyName: keyof Filters,
    op: string | undefined,
    search: boolean,
    secondValue: string | number | null | undefined,
    secondOp: string | undefined,
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      color: theme.palette.primary.dark,
      marginBottom: "20px",
      marginRight: "20px",
    },
    button: {
      color: theme.palette.primary.dark,
      height: "30",
    },
    rangeTextFieldWrapper: {
      display: "flex",
    },
    filterTitle: {
      fontWeight: "bold",
    },
    menuFilterTitle: {
      marginBottom: "16px",
    },
  }),
);

/**
 * This is the value the dropdown will use for null values as 'MenuItem' does
 * not allow 'null' as value. The component will handle this value and return
 * 'null' in case it is selected.
 */
const nullValue = "<<--NULL-->>";
const undefinedValue = "<<--UNDEFINED-->>";

export const FilterContainer: React.FC<Props> = (props) => {
  const classes = useStyles();

  const {
    title,
    value,
    secondValue,
    op,
    secondOp,
    subTitles,
    values,
    type,
    propertyName,
    valueType,
    filterRows,
  } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === nullValue) {
      event.target.value = null;
    } else if (event.target.value === undefinedValue) {
      event.target.value = undefined;
    }
    filterRows(
      event.target.value as string,
      propertyName,
      valueType === "number" ? "=" : undefined,
      false,
      undefined,
      undefined,
    );
  };

  const mapBoolean = (b: boolean) => {
    return b ? 1 : 0;
  };

  const getClearButton = (
    val: string | number | boolean | null | undefined,
    isSecondVal?: boolean,
  ): JSX.Element | undefined => {
    return val !== undefined ? (
      <Button
        variant="text"
        color="primary"
        onClick={() =>
          filterRows(
            isSecondVal ? value : undefined,
            propertyName,
            isSecondVal ? op : undefined,
            false,
            isSecondVal ? undefined : secondValue,
            isSecondVal ? undefined : secondOp,
          )
        }
      >
        <DeleteIcon />
      </Button>
    ) : undefined;
  };

  //In the Select component, to determine the value selected we have to do several checks:
  //    - If value is undefined return the undefinedVaue(<<--UNDEFINED-->>)
  //    - If value is null because we set it in the api/log.ts file, we have to set it back to the string "null"
  //    - If value is null and just that, we return the nullValue(<<--NULL-->>)
  //    - If value is not null nor undefined and the value is of boolean type, we have to map it to integer,
  //      because the MenuItem components does not accept boolean values
  //    - In other cases just return the value
  switch (type) {
    case FilterType.Menu:
      return (
        <>
          <Typography className={classNames(classes.menuFilterTitle, classes.filterTitle)}>{title}</Typography>
          <Select
            displayEmpty
            value={
              value !== undefined
                ? value === null
                  ? values?.includes("null")
                    ? "null"
                    : nullValue
                  : value === true || value === false
                  ? mapBoolean(value)
                  : value
                : undefinedValue
            }
            onChange={handleChange}
            fullWidth
          >
            <MenuItem key={0} value={undefinedValue} selected>
              No filter
            </MenuItem>
            {values &&
              subTitles &&
              values.map((element, i) => {
                // Check by null value as 'MenuItem' does not allow null for
                // 'value' property.
                return (
                  <MenuItem
                    key={element === null || typeof element === "boolean" ? nullValue : element}
                    value={element === null ? nullValue : typeof element === "boolean" ? mapBoolean(element) : element}
                  >
                    {subTitles[i] || `EMPTY (NULL)`}
                  </MenuItem>
                );
              })}
          </Select>
        </>
      );
    case FilterType.Search:
      return (
        <>
          <Typography className={classes.filterTitle}>{title}</Typography>
          <TextField
            label={"Search"}
            value={value === undefined ? "" : value}
            fullWidth
            onChange={(e) => {
              filterRows(
                e.target.value === "" ? undefined : e.target.value,
                propertyName,
                "",
                false,
                undefined,
                undefined,
              );
            }}
            className={classes.textField}
            InputProps={{ endAdornment: getClearButton(value) }}
          />
        </>
      );
    default:
      return (
        <>
          <Typography className={classes.filterTitle}>{title}</Typography>
          <div className={classes.rangeTextFieldWrapper}>
            {propertyName === "biologicalSex" ? (
              <>
                <PercentageInput
                  label={"Min"}
                  value={value}
                  onChange={(e) => {
                    filterRows(e, propertyName, e || e === 0 ? ">=" : undefined, false, secondValue, secondOp);
                  }}
                  className={classes.textField}
                  InputProps={{ endAdornment: getClearButton(value) }}
                ></PercentageInput>
                <PercentageInput
                  label={"Max"}
                  value={secondValue}
                  onChange={(e) => {
                    filterRows(value, propertyName, op, false, e, e || e === 0 ? "<=" : undefined);
                  }}
                  className={classes.textField}
                  InputProps={{ endAdornment: getClearButton(secondValue, true) }}
                ></PercentageInput>
              </>
            ) : propertyName === "time" ? (
              <div>
                <DateTimeInput
                  label={"Min"}
                  value={value ? Number(value) : undefined}
                  onChange={(e) =>
                    filterRows(
                      e ? e : undefined,
                      propertyName,
                      e || e === 0 ? ">=" : undefined,
                      false,
                      secondValue ? Number(secondValue) : undefined,
                      secondOp,
                    )
                  }
                  fullWidth
                ></DateTimeInput>
                <DateTimeInput
                  label={"Max"}
                  value={secondValue ? Number(secondValue) : undefined}
                  onChange={(e) =>
                    filterRows(
                      value ? Number(value) : undefined,
                      propertyName,
                      op,
                      false,
                      e ? e : undefined,
                      e || e === 0 ? "<=" : undefined,
                    )
                  }
                  fullWidth
                ></DateTimeInput>
              </div>
            ) : (
              type === "range" && (
                <>
                  <NumericField
                    label={"Min"}
                    value={value}
                    onChange={(e) => {
                      filterRows(e, propertyName, e || e === 0 ? ">=" : undefined, false, secondValue, secondOp);
                    }}
                    className={classes.textField}
                    InputProps={{ endAdornment: getClearButton(value) }}
                  />
                  <NumericField
                    label={"Max"}
                    value={secondValue}
                    onChange={(e) => {
                      filterRows(value, propertyName, op, false, e, e || e === 0 ? "<=" : undefined);
                    }}
                    className={classes.textField}
                    InputProps={{ endAdornment: getClearButton(secondValue, true) }}
                  />
                </>
              )
            )}
          </div>
        </>
      );
  }
};
