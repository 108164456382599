import { ImageExtensionFormat, Picture } from "./image";
import { ModelType, ModelTypeName, PredictionModel } from "./utils";

import { ConfigurationProps } from "../components/ConfigurationPanel/ConfigurationPanel";
import { LogTableColumn } from "./log";
import { PluginConfigurationProps } from "../plugin/models";

export interface PluginConfiguration {
  [propName: string]: any;
}

export interface ConfigurationPanel {
  lastUpdateUserID?: number;
  predictionConfiguration: PredictionConfiguration;
  scanConfiguration: ScanConfiguration;
  logConfiguration: LogConfiguration;
  consentConfiguration: ConsentConfiguration;
  endpointsConfiguration: EndpointsConfiguration;
  pluginsConfiguration: PluginConfiguration[];
  uploadConfiguration: UploadConfiguration;
}

export interface ConfigurationPanelSection<T> {
  section: React.FC<ConfigurationProps<T>> | React.FC<PluginConfigurationProps<T>>;
  label: string;
  icon: JSX.Element;
  onConfigurationChange: (configSection: T) => void;
  pluginNumber?: number;
}

export interface PredictionConfiguration {
  requestedModels: PredictionModel[];
  showPrediction: boolean;
  showFeedback: boolean;
  predictionResult: PredictionResultConfig[];
  minFaceSize: number;
  depthFASThreshold: number;
  maskDetectorThreshold: number;
  patchFASThreshold: number;
  rgbFASThreshold: number;
  rgbFASV2Threshold: number;
  rgbFASV2ConfidenceThreshold: number;
  contextFASThreshold: number;
  fiqaThreshold: number;
  effNetThreshold: number;
  metaFASThreshold: number;
  metaFASConfidenceThreshold: number;
  metaFASV2Threshold: number;
  metaFASV2ConfidenceThreshold: number;
  showStoreImagesOption: boolean;
  metaFASV2BThreshold: number;
  metaFASV2BConfidenceThreshold: number;
  metaFASV3Threshold: number;
  metaFASV3ConfidenceThreshold: number;
  metaFASV4Threshold: number;
  metaFASV4ConfidenceThreshold: number;
  metaFASV5Threshold: number;
  metaFASV5ConfidenceThreshold: number;
  metaFASV7Threshold: number;
  metaFASV7ConfidenceThreshold: number;
}

export interface ScanConfiguration {
  // Selfie capture
  selfieCaptureType: SelfieCaptureType;
  scanDelayNumber: number;
  scanDelayTime: number;
  tinyFaceDetectionSensibility: number;
  tinyFaceDetectorThreshold: number;
  faceCaptureModuleDebug: boolean;
  faceCaptureModuleShowOverlay: boolean;
  faceCaptureModuleUseBrightnessMethod: boolean;
  faceCaptureModuleUseBrightnessMaskMethod: boolean;
  faceCaptureModuleTooDarkThresholdMask: number;
  faceCaptureModuleTooBrightThresholdMask: number;
  faceCaptureModuleLowerMaskThreshold: number;
  faceCaptureModuleUpperMaskThreshold: number;
  faceCaptureModuleLowerMaskAcceptanceThreshold: number;
  faceCaptureModuleUpperMaskAcceptanceThreshold: number;
  faceCaptureModuleOverlaySize: string;
  faceCaptureModuleOverlaySizeLandscape: string;
  faceCaptureModuleInitialAutocaptureDelay: number;
  faceCaptureModuleAutocaptureDelay: number;
  faceCaptureModuleFaceSizeDeviation: number;
  faceCaptureModuleFaceSizeDeviationLandscape: number;
  faceCaptureModuleMinBBoxSize: number;
  faceCaptureModuleMinBBoxSizeLandscape: number;
  faceCaptureModuleIdealBBoxRelativeSize: number;
  faceCaptureModuleIdealBBoxRelativeSizeLandscape: number;
  faceCaptureModuleCenterDeviation: number;
  faceCaptureModuleMaxBBoxRelativeSizePortrait: number;
  faceCaptureModuleMaxBBoxRelativeSizeLandscape: number;
  faceCaptureModuleRelativeEdgeCropping: number;
  faceCaptureModuleScoreThreshold: number;
  faceCaptureModuleTooDarkThreshold: number;
  faceCaptureModuleTooBrightThreshold: number;
  aiSessionManagerURL: string;
  aiClientModuleDelay: number;
  aiClientLaplacianEnabled: boolean;
  // Image
  minImageSize: number;
  maxImageSize: number;
  pictureResolutionWidth: number;
  pictureResolutionHeight: number;
  imageExtensionFormat: ImageExtensionFormat;
  imageJPEGCompression: number;
  selectedInputPicture: Picture;
  numberPixelsWidth: number;
  numberPixelsHeight: number;
  // Cropping
  minPercentageFaceOverImage: number;
  maxPercentageFaceOverImage: number;
  upMarginPercentageCropping: number;
  downMarginPercentageCropping: number;
  leftMarginPercentageCropping: number;
  rightMarginPercentageCropping: number;
  relativeMarginPercentageCropping: number;
  startButtonLabel: string;
  showStartButton: boolean;
  devButtonLabel: string;
  showDevButton: boolean;
  // Ground truth
  showExpectedLocation: boolean;
  showExpectedBrightness: boolean;
  expectedLocationIsMandatory: boolean;
  expectedBrightnessIsMandatory: boolean;
}

export interface LogConfiguration {
  showHistograms: boolean;
  showDebugImages: boolean;
  logTableColumnsOrder: (ModelTypeName | LogTableColumn)[];
  modelsResultDisplay: ModelType[];
  saveIp: boolean;
  queryAuditLog: boolean;
  csvHeaders: string[];
  limitImagesDownload: number;
}

export interface UploadConfiguration {
  tinyFaceDetectorThreshold: number;
  maxFaces: number;
  minFileSize: number;
  maxFileSize: number;
  minImageWidth: number;
  maxImageWidth: number;
  minImageHeight: number;
  maxImageHeight: number;
  extensionFormats: string[];
  selectedInputPicture: Picture;
  relativeMarginPercentageCropping: number;
}

export interface ConsentConfiguration {
  consent: string;
  consentCheckbox: string;
  showConsentCheckbox: boolean;
  hideDataCollectorMainPage: boolean;
}

export interface PredictionResultConfig {
  model: ModelType;
  percentage: boolean;
  text: boolean;
  antiSpoofing: boolean;
}

export enum SelfieCaptureType {
  Scan = 0,
  Button,
  FaceCaptureModule,
  AIClientModule,
}

export interface EndpointsConfiguration {
  age: EndpointConfiguration;
  research: EndpointConfiguration;
  ageAntiSpoofing: EndpointConfiguration;
  antiSpoofing: EndpointConfiguration;
  selfcheckoutAgeAntiSpoofing: EndpointConfiguration;
  ageVerify: EndpointConfiguration;
  ageAntispoofingVerify: EndpointConfiguration;
}

interface EndpointConfiguration {
  storeImages: boolean;
  lastUpdateUserID?: number;
}

interface YotiCfg {
  clientSdkId?: string;
  scenarioId?: string;
}

export interface EnvSettings {
  env: string;
  pluginList: string[];
  yotiCfg?: YotiCfg;
  indexAgeEstimationPlot: { [key: string]: number };
  userCreationConfig: UserCreationConfig;
  organisationCreationConfig: OrganisationCreationConfig;
  scanUrl: string;
}

export interface UserCreationConfig {
  dailyMaxRequests: number;
  totalMaxRequests: number;
  picturesExpirationHours: number;
}

export interface OrganisationCreationConfig {
  dailyMaxRequests: number;
  totalMaxRequests: number;
  picturesExpirationHours: number;
}

export enum OverlaySize {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
}
