import React, { useState } from "react";

import { StandardTextFieldProps } from "@material-ui/core";
import { TextField } from "@material-ui/core";

const isNumber = (value: unknown) => {
  return !isNaN(Number(value));
};

interface Props extends Omit<StandardTextFieldProps, "onChange"> {
  onChange: (value?: number) => void;
}

export const NumericField: React.FC<Props> = (props) => {
  const [stringValue, setStringValue] = useState(props.value);

  return (
    <TextField
      {...props}
      // If the input is undefined means the value needs to be
      // cleared independently of the inner stringValue.
      value={props.value !== undefined ? stringValue : ""}
      onChange={(e) => {
        if (isNumber(e.target.value)) {
          setStringValue(e.target.value);
          if (props.onChange) {
            props.onChange(e.target.value !== "" ? Number(e.target.value) : undefined);
          }
        }
      }}
    />
  );
};
