import { createStyles, makeStyles } from "@material-ui/core/styles";

import { PredictionFormulaResult } from "../../models/prediction";
import React from "react";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
    },
    success: {
      color: theme.palette.success.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    undefined: {
      color: theme.palette.common.black,
    },
  }),
);

interface Props {
  prediction?: PredictionFormulaResult;
  className?: string;
}

export const PredictionColor: React.FC<Props> = (props) => {
  const { prediction, className } = props;

  const classes = useStyles();

  const getClassName = (): string => {
    switch (prediction) {
      case PredictionFormulaResult.Real:
        return classes.success;
      case PredictionFormulaResult.Undetermined:
        return classes.warning;
      case undefined:
        return classes.undefined;
      default:
        //Predict == Fake or Error
        return classes.error;
    }
  };

  return <span className={classNames(getClassName(), className)}>{props.children}</span>;
};
