import { IconButton as MaterialIconButon } from "@material-ui/core";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@material-ui/core/styles";

interface Props {
  title: string;
  ariaLabel: string;
  disabled?: boolean;
  rtlIcon: JSX.Element;
  ltrIcon: JSX.Element;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const IconButton: React.FC<Props> = (props) => {
  const { title, ariaLabel, disabled, rtlIcon, ltrIcon, onClick } = props;
  const theme = useTheme();

  return (
    <Tooltip title={title}>
      <div>
        <MaterialIconButon onClick={onClick} disabled={disabled} aria-label={ariaLabel}>
          {theme.direction === "rtl" ? rtlIcon : ltrIcon}
        </MaterialIconButon>
      </div>
    </Tooltip>
  );
};
