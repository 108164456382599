import { Grid, createStyles, makeStyles } from "@material-ui/core";

import { ColumnStats } from "../../models/stats";
import { ConfigSummary } from "./ConfigSummary";
import { DashboardSection } from "./DashboardSection";
import { GlobalContext } from "../../globalContext";
import React from "react";
import { StatsSection } from "./StatsSection";
import { ToolUses } from "./ToolUses";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: "20px",
      marginBottom: "50px",
    },
  }),
);

export const Dashboard: React.FC = () => {
  const classes = useStyles();
  const { plugins } = React.useContext(GlobalContext);

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={3}>
        <DashboardSection title="Configuration summary" initialState={true} storageKey="configSummaryShown">
          <ConfigSummary />
        </DashboardSection>
        <DashboardSection title="Feedback stats" storageKey="feedbackStatsShown">
          <StatsSection column={ColumnStats.feedback} />
        </DashboardSection>
        <DashboardSection title="Prediction result stats" storageKey="predictionResultStatsShown">
          <StatsSection column={ColumnStats.predictionResult} />
        </DashboardSection>
        <DashboardSection title="General stats" storageKey="generalStatsShown">
          <ToolUses />
        </DashboardSection>
        {plugins.map((plugin) => {
          return plugin.dashboardSections();
        })}
      </Grid>
    </div>
  );
};
