import React, { useEffect, useState } from "react";

import { PredictionLoader } from "../PredictionLoader";

export const WebcamLoader: React.FC = () => {
  const [video, setVideo] = useState<MediaDeviceInfo>();

  useEffect(() => {
    const videoDevices: MediaDeviceInfo[] = [];
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices.forEach((device) => {
        if (device.kind === "videoinput") {
          videoDevices.push(device);
        }
      });

      if (videoDevices.length > 0) {
        setVideo(videoDevices[0]);
      }
    });
  }, []);

  return <div>{video && <PredictionLoader camera={video} />}</div>;
};
