import * as faceapi from "face-api.js";

import React, { useEffect, useState } from "react";

import { GlobalContext } from "../globalContext";
import { Loading } from "../components/Utils/Loading";
import { Message } from "../components/Utils/Message";

const modelUrl = "/static/models";

export const TinyFace: React.FC = (props) => {
  const [tinyFaceError, setTinyFaceError] = useState<Error | undefined>();
  const { tinyFaceReady, setTinyFaceReady } = React.useContext(GlobalContext);

  useEffect(() => {
    if (!tinyFaceReady || tinyFaceError) {
      faceapi.nets.tinyFaceDetector
        .load(modelUrl)
        .then(() => setTinyFaceReady(true))
        .catch(setTinyFaceError);
    }
  }, [tinyFaceReady, setTinyFaceReady, tinyFaceError, setTinyFaceError]);

  return (
    <>
      {tinyFaceError && (
        <Message variant="error" message={`Cannot load the Tiny face model: ${tinyFaceError.message}`} />
      )}
      <Loading visible={!tinyFaceReady && !tinyFaceError} />
      {tinyFaceReady && !tinyFaceError && props.children}
    </>
  );
};
