import { BrightnessValidationResult, Props as BrightnessValidationResultProps } from "./BrightnessValidationResult";
import { Divider, ListItem, createStyles, makeStyles } from "@material-ui/core";
import { ValidationKey, ValidationResults as Validations } from "../../../../models/log";

import { FieldValidationResult } from "./FieldValidationResult";
import React from "react";

export const useStyle = makeStyles(() =>
  createStyles({
    panel: {
      display: "block",
    },
  }),
);

interface Props {
  validationResults?: Validations;
  brightnessValidationResult: BrightnessValidationResultProps;
}

export const ValidationResults: React.FC<Props> = ({ brightnessValidationResult, validationResults }) => {
  const classes = useStyle();

  const items: JSX.Element[] = [];

  // Brightness
  if (brightnessValidationResult && brightnessValidationResult.croppedImgsValidations) {
    items.push(<BrightnessValidationResult {...brightnessValidationResult} />);
  }

  // Fields
  if (validationResults) {
    Object.keys(validationResults).forEach((key) => {
      const value = validationResults[key as ValidationKey];
      if (value !== undefined && typeof value === "string") {
        items.push(<FieldValidationResult field={key} value={value} />);
      }
    });
  }

  return items.length > 0 ? (
    <>
      <Divider />
      <ListItem className={classes.panel}>
        <b>Validation Debug Info:</b>
        <br />
        <br />
        {items.map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </ListItem>
    </>
  ) : null;
};
