import { FileInfo } from "../models/upload";
import { ImageExtensionFormat } from "../models/image";
import { TinyBbox } from "../models/prediction";
import { b64toBlob } from "./files";
import { getFaces } from "./faceDetection";
import { imageSize } from "../components/Log/LogDetail/LogDetail";

export interface ResultRescale {
  rescaledBBox: TinyBbox;
  pictureWidth: number;
  pictureHeight: number;
}

export interface ConfigCropping {
  pictureResolutionWidth: number;
  pictureResolutionHeight: number;
  numberPixelsWidth: number;
  numberPixelsHeight: number;
  minPercentageFaceOverImage: number;
  maxPercentageFaceOverImage: number;
  minImageSize: number;
  maxImageSize: number;
}

export function checkPicture(
  resultRescale: ResultRescale,
  configCropping: ConfigCropping,
): { valid: boolean; errors: string[] } {
  const errors = [];
  let valid = true;

  const pictureWidth = resultRescale.pictureWidth;
  const pictureHeight = resultRescale.pictureHeight;
  const boxWidth = resultRescale.rescaledBBox.width;
  const boxHeight = resultRescale.rescaledBBox.height;
  const boxX = resultRescale.rescaledBBox.x;
  const boxY = resultRescale.rescaledBBox.y;

  if (boxHeight < configCropping.numberPixelsHeight) {
    valid = false;
    errors.push("Face Height is too small, please go closer to the camera");
  }

  if (boxWidth < configCropping.numberPixelsWidth) {
    valid = false;
    errors.push("Face Width is too small, please go closer to the camera");
  }

  if (boxHeight < configCropping.minPercentageFaceOverImage * pictureHeight) {
    valid = false;
    errors.push("You are too far from the camera");
  } else if (boxHeight > configCropping.maxPercentageFaceOverImage * pictureHeight) {
    valid = false;
    errors.push("You are too close to the camera");
  }

  if (
    boxX + boxWidth < pictureWidth / 2 ||
    boxY + boxHeight < pictureHeight / 2 ||
    boxX > pictureWidth / 2 ||
    boxY > pictureHeight / 2
  ) {
    valid = false;
    errors.push("Please, move your face to the center of the image");
  }

  return {
    valid: valid,
    errors: errors,
  };
}

interface CropPictureResult {
  croppedBbox: TinyBbox;
  originalPicture?: HTMLImageElement;
  croppedHeight?: number;
}

//This method is used to crop the image using the tiny face bounding
//box and extending it an extra percentage, one per side of the box
//(all this percentages can be changed in the configuration panel).
//This is meant to reduce the size of the photo, and consequently,
//to reduce the size on database and take better advantage of the bandwidth.
export function croppedPicture(
  selfie: string,
  resultRescale: ResultRescale,
  canvasOriginal: HTMLCanvasElement,
  canvasCropped: HTMLCanvasElement,
  upMarginPercentageCropping: number,
  downMarginPercentageCropping: number,
  leftMarginPercentageCropping: number,
  rightMarginPercentageCropping: number,
): Promise<CropPictureResult> {
  const pictureHeight = resultRescale.pictureHeight;
  const pictureWidth = resultRescale.pictureWidth;
  const bBox = resultRescale.rescaledBBox;
  let croppedBbox: TinyBbox = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };

  //First we plot the original picture in a canvas
  canvasOriginal.height = pictureHeight;
  canvasOriginal.width = pictureWidth;
  const ctxOriginal = canvasOriginal.getContext("2d");
  if (!ctxOriginal) {
    return Promise.resolve({
      croppedBbox: croppedBbox,
    });
  }
  ctxOriginal.save();

  // Create cropped context.
  const ctxCropped = canvasCropped.getContext("2d");
  if (!ctxCropped) {
    return Promise.resolve({
      croppedBbox: croppedBbox,
    });
  }
  ctxCropped.save();

  //Then we calculate x, y, width and height of the cropped image, by extending the tiny face bounding box.
  //We consider if part of the bounding box is out of the picture´s bounds, and if so we reset it.
  const x =
    bBox.x - leftMarginPercentageCropping * bBox.width < 0 ? 0 : bBox.x - leftMarginPercentageCropping * bBox.width;
  const y =
    bBox.y - upMarginPercentageCropping * bBox.height < 0 ? 0 : bBox.y - upMarginPercentageCropping * bBox.height;

  let width = bBox.width + bBox.width * (rightMarginPercentageCropping + leftMarginPercentageCropping);
  let height = bBox.height + bBox.height * (downMarginPercentageCropping + upMarginPercentageCropping);

  if (bBox.x - bBox.width * leftMarginPercentageCropping !== x) {
    width = bBox.width + bBox.x + bBox.width * rightMarginPercentageCropping;
  }
  if (bBox.y - bBox.height * upMarginPercentageCropping !== y) {
    height = bBox.height + bBox.y + bBox.height * downMarginPercentageCropping;
  }

  height = height + y > pictureHeight ? pictureHeight - y : height;
  width = width + x > pictureWidth ? pictureWidth - x : width;

  canvasCropped.width = width;
  canvasCropped.height = height;

  //Here we calculate the size(not true size, presentation size) of the cropped canvas basing on the measures of the original canvas
  canvasCropped.style.maxWidth = String(width / (pictureWidth / canvasOriginal.clientWidth) + "px");
  canvasCropped.style.maxHeight = String(height / (pictureHeight / canvasOriginal.clientHeight) + "px");

  return new Promise<HTMLImageElement>((resolve) => {
    const originalImg = new Image();
    originalImg.onload = function () {
      ctxOriginal.drawImage(originalImg, 0, 0, pictureWidth, pictureHeight);
      resolve(originalImg);
    };
    originalImg.src = selfie;
  }).then((originalImg) => {
    return new Promise((resolve) => {
      const img = new Image();
      //Then we plot the cropped picture in another canvas
      img.onload = function () {
        ctxCropped.drawImage(img, x, y, width, height, 0, 0, width, height);

        //Finally we return the bounding box, but taking as a reference the cropped picture
        croppedBbox = {
          x: x > 0 ? leftMarginPercentageCropping * bBox.width : bBox.x,
          y: y > 0 ? upMarginPercentageCropping * bBox.height : bBox.y,
          width: bBox.width,
          height: bBox.height,
        };
        resolve({
          croppedBbox: croppedBbox,
          originalPicture: originalImg,
          croppedHeight: height / (pictureHeight / canvasOriginal.clientHeight),
        });
      };
      img.src = selfie;
    });
  });
}

export interface Margin {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}

/**
 * Takes an image and the bounding boxes to return each face in a different
 * cropped image.
 *
 * @param b64img The image that contains the faces defined in bboxes parameter
 * in base 64.
 * @param bboxes The faces bboxes in image provided.
 * @param margin The margin that will be applied on cropping an image. This will
 * increase or decrease the final result for the cropped image to include more
 * or less background.
 * @param contentType The file extension for cropping images result.
 * @param quality The quality information for cropping images result.
 */
export const cropImg = async (
  b64img: string,
  bboxes: TinyBbox[],
  margin?: Margin,
  contentType: ImageExtensionFormat = ImageExtensionFormat.PNG,
  quality?: any,
): Promise<string[]> => {
  const results: string[] = [];
  if (bboxes.length <= 0) {
    return results;
  }

  const imgSize = await imageSize(b64img);
  for (let i = 0; i < bboxes.length; i++) {
    const resultRescale = {
      rescaledBBox: bboxes[i],
      pictureWidth: imgSize.width,
      pictureHeight: imgSize.height,
    };
    const canvasOriginal = document.createElement("canvas");
    const canvasCropped = document.createElement("canvas");
    await croppedPicture(
      b64img,
      resultRescale,
      canvasOriginal,
      canvasCropped,
      margin && margin.top ? margin.top : 0,
      margin && margin.bottom ? margin.bottom : 0,
      margin && margin.left ? margin.left : 0,
      margin && margin.right ? margin.right : 0,
    );
    results.push(canvasCropped.toDataURL(`image/${contentType}`, quality));
  }
  return results;
};

/**
 * Takes an image and returns it with the format provided.
 * @param b64img Base64 image.
 * @param format Format to be applied.
 * @param quality The quality information for result image.
 */
export const formatImg = (b64img: string, format: ImageExtensionFormat, quality?: any): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) {
      reject(Error("cannot format the image because cannot get canvas context"));
      return;
    }

    return imageSize(b64img).then((size) => {
      const img = new Image();
      img.onload = function () {
        context.canvas.width = size.width;
        context.canvas.height = size.height;
        context.drawImage(img, 0, 0, size.width, size.height);
        const result = context.canvas.toDataURL(`image/${format}`, quality);
        resolve(result);
      };
      img.src = b64img;
    });
  });
};

/**
 * Takes a picture and returns the cropped images with the faces on it.
 * @param file The picture file information.
 * @param format The output format for cropped images.
 * @param threshold The minimum confidence threshold on MTCNN result.
 * @param margin The margin that will be applied over the bbox result on
 * building the cropped image.
 * @param quality The quality information on building the cropped images.
 */
export const getCroppedFaces = async (
  file: FileInfo,
  format: ImageExtensionFormat,
  threshold: number,
  margin: Margin,
  quality?: any,
) => {
  // Get faces and apply cropping.
  const tinyFaceInfos = await getFaces(file.base64content, threshold);
  const croppedImgs = await cropImg(
    file.base64content,
    tinyFaceInfos.map((i) => {
      return i.bBox;
    }),
    margin,
    format,
    quality,
  );

  return croppedImgs.map((img, index) => {
    // Build file name (add index in case there are more than one face).
    let name = `${file.name.split(".").slice(0, -1)}`;
    if (croppedImgs.length > 1) {
      name += `_${index}`;
    }
    name += `.${format}`;
    // Create the blob entity.
    const blob = b64toBlob(img);
    return { name: name, content: blob };
  });
};
