import { createStyles, makeStyles } from "@material-ui/core/styles";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import React from "react";
import { Tooltip } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "13px",
      height: "13px",
    },
  }),
);

interface Props {
  title: string;
  className?: string;
}

export const TooltipHelp: React.FC<Props> = (props) => {
  const { className } = props;
  const classes = useStyles();

  return (
    <Tooltip className={classNames(classes.root, className)} title={props.title} placement="top" arrow>
      <HelpOutlineIcon fontSize="small"></HelpOutlineIcon>
    </Tooltip>
  );
};
