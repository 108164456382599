import { AccountBalanceWalletRounded, SaveOutlined, SearchRounded } from "@material-ui/icons";
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { MyFaceUser, MyFaceUserState } from "../../models/myface";
import React, { useEffect, useState } from "react";

import { Button } from "../../../../components/Utils/Button";
import { Conditional } from "../../../../components/Utils/Conditional";
import Config from "../../../../config";
import { DateLabel } from "../../../../components/Log/LogTable/DateLabel";
import { GlobalContext } from "../../../../globalContext";
import { MyFaceService } from "../../api/myface";
import { UserEventsDialog } from "./UserEventsDialog";
import csvDownload from "../../../../utils/csvDownload";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      float: "left",
    },
    tableCellNoWrap: {
      whiteSpace: "nowrap",
    },
    csvDownload: {
      marginTop: "10px",
    },
    iconButton: {
      color: theme.palette.primary.main,
      padding: 0,
    },
    pointer: {
      cursor: "pointer",
    },
  }),
);

const myFaceService = new MyFaceService(Config.apiUrl);

interface UsersProps {
  usersStats?: MyFaceUser[];
  usersPage: number;
  setUsersPage: (page: number) => void;
  usersRowCount: number;
  usersRowsPerPage: number;
  handleChangeUsersRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: Error;
  loading?: boolean;
}

export const UsersStats: React.FC<UsersProps> = (props) => {
  const classes = useStyles();

  const {
    usersStats,
    usersPage,
    setUsersPage,
    usersRowCount,
    usersRowsPerPage,
    handleChangeUsersRowsPerPage,
    error,
    loading,
  } = props;

  const { setFeedback } = React.useContext(GlobalContext);

  const [csvLoading, setCSVLoading] = useState(false);

  const [creditedUsers, setCreditedUsers] = useState<string[]>(
    usersStats ? [...usersStats.filter((u) => u.credited).map((u) => u.userID)] : [],
  );

  const downloadCSV = () => {
    setCSVLoading(true);
    myFaceService
      .getAllUsers()
      .then((res) => {
        // Remove unnecessary data
        const list = res.map((u) => {
          // Make all fields definition optional to remove the values that we
          // don't want to export in the csv.
          const u2: Partial<MyFaceUser> = u;
          delete u2.credited;
          delete u2.extraInfo;
          return u2;
        });
        csvDownload(list, "Users stats.csv");
      })
      .catch((e) => {
        setFeedback({
          variant: "error",
          message: `Failed to load users stats: ${e.message}`,
          horizontal: "right",
        });
      })
      .finally(() => setCSVLoading(false));
  };

  const creditUser = (userID: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      myFaceService
        .creditUser(userID)
        .then(() => {
          setCreditedUsers([...creditedUsers, userID]);
        })
        .catch((e) =>
          setFeedback({
            variant: "error",
            message: `Failed to credit user ${userID}: ${e.message}`,
            horizontal: "right",
          }),
        );
    };
  };

  const [selectedUserID, setSelectedUserID] = useState("");
  const [showOnlyErrors, setShowOnlyErrors] = useState(false);

  const showUserEvents = (userID: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedUserID(userID);
    };
  };

  const showUserErrors = (userID: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setShowOnlyErrors(true);
      setSelectedUserID(userID);
    };
  };

  useEffect(() => {
    if (usersStats) {
      setCreditedUsers((oldCreditedUsers) => {
        return [
          ...oldCreditedUsers,
          ...usersStats.filter((u) => u.credited && !oldCreditedUsers.includes(u.userID)).map((u) => u.userID),
        ];
      });
    }
  }, [usersStats]);

  return (
    <Conditional error={error} loading={loading} errorMessage="Failed to load users stats">
      {usersStats && (
        <>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell align="center">First Visit Date</TableCell>
                  <TableCell align="center">SignUp Date</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Face capture module error">
                      <span>FCME</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">Incorrect Photos</TableCell>
                  <TableCell align="center">Correct Photos</TableCell>
                  <TableCell align="center">Last Photo Date</TableCell>
                  <TableCell align="center">Last Correct Photo Date</TableCell>
                  <TableCell align="center">Finish Date</TableCell>
                  <TableCell align="center">Review Date</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Photos with unknown expected result">
                      <span>U</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Photos with real expected result">
                      <span>R</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Photos with display attack  expected result">
                      <span>D</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Photos with print attack expected result">
                      <span>P</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Photos with mask attack expected result">
                      <span>M</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Photos approved by the reviewer">
                      <span>A</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">Result</TableCell>
                  <TableCell align="center">Expired</TableCell>
                  <TableCell align="center">Credited</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersStats.map((user) => (
                  <TableRow key={`user-${user.userID}`}>
                    <TableCell className={classes.tableCellNoWrap} component="th" scope="row">
                      {user.userID}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      <DateLabel value={user.firstVisitDate} />
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {new Date(user.signUpDate) > new Date(0) ? <DateLabel value={user.signUpDate} /> : "-"}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      <span
                        onClick={user.faceCaptureModuleFailed ? showUserErrors(user.userID) : undefined}
                        className={user.faceCaptureModuleFailed ? classes.pointer : ""}
                      >
                        {user.faceCaptureModuleFailed ? "Yes" : "No"}
                      </span>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {user.numberIncorrectPhotos}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {user.numberCorrectPhotos}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {new Date(user.lastPhotoDate) > new Date(0) ? <DateLabel value={user.lastPhotoDate} /> : "-"}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {new Date(user.lastCorrectPhotoDate) > new Date(0) ? (
                        <DateLabel value={user.lastCorrectPhotoDate} />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {new Date(user.finishDate) > new Date(0) ? <DateLabel value={user.finishDate} /> : "-"}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {new Date(user.reviewDate) > new Date(0) ? <DateLabel value={user.reviewDate} /> : "-"}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      <Tooltip title="Photos with unknown expected result">
                        <span>{user.unknownImages}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      <Tooltip title="Photos with real expected result">
                        <span>{user.realImages}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      <Tooltip title="Photos with display attack  expected result">
                        <span>{user.displayAttackImages}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      <Tooltip title="Photos with print attack expected result">
                        <span>{user.printAttackImages}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      <Tooltip title="Photos with mask attack expected result">
                        <span>{user.maskAttackImages}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      <Tooltip title="Photos approved by the reviewer">
                        <span>{user.approvedImages}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {user.result ? user.result : "-"}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {new Date(user.finishDate) <= new Date(0) && user.result === MyFaceUserState.Rejected
                        ? "Yes"
                        : "No"}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellNoWrap}>
                      {user.credited || creditedUsers.includes(user.userID) ? "Yes" : "No"}
                    </TableCell>
                    <TableCell className={classes.tableCellNoWrap}>
                      <IconButton
                        aria-label="Delete"
                        onClick={showUserEvents(user.userID)}
                        className={classes.iconButton}
                      >
                        <SearchRounded />
                      </IconButton>
                    </TableCell>
                    <TableCell className={classes.tableCellNoWrap}>
                      <IconButton
                        aria-label="Delete"
                        onClick={creditUser(user.userID)}
                        className={classes.iconButton}
                        disabled={
                          creditedUsers.includes(user.userID) ||
                          (user.result !== MyFaceUserState.Approved && user.result !== MyFaceUserState.Rejected)
                        }
                      >
                        <AccountBalanceWalletRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              className={classes.pagination}
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={usersRowCount}
              rowsPerPage={usersRowsPerPage}
              page={usersPage}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={(_, p) => setUsersPage(p)}
              onChangeRowsPerPage={handleChangeUsersRowsPerPage}
            />
          </TableContainer>
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadCSV}
            size="small"
            className={classes.csvDownload}
            disabled={csvLoading}
            startIcon={csvLoading ? <CircularProgress size={"20px"} /> : <SaveOutlined />}
          >
            {csvLoading ? "Loading..." : "Download CSV"}
          </Button>
          <UserEventsDialog
            userID={selectedUserID}
            showOnlyErrors={showOnlyErrors}
            onClose={() => {
              setSelectedUserID("");
              setShowOnlyErrors(false);
            }}
          />
        </>
      )}
    </Conditional>
  );
};
