import { FormControl, Input, MenuItem, Select, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Button } from "../../Utils/Button";
import { Dialog } from "../../Utils/Dialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.common.black,
      marginRight: "10px",
    },
    value: {
      color: theme.palette.primary.main,
      marginRight: "20px",
    },
    dialogContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    button: {
      color: theme.palette.common.white,
    },
  }),
);

interface ListSelectProps {
  title: string;
  value: string;
  list: string[];
  logId: number;
  sessionId?: string;
  onDisplayValue: (val: any) => string;
  setValue: (value: string, sessionId?: string) => void;
}

export const ListSelect: React.FC<ListSelectProps> = (props) => {
  const classes = useStyles();
  const { value, sessionId, logId } = props;
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState(value);

  useEffect(() => {
    setSelection(value === null ? "unknown" : value);
  }, [value, setSelection]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelection(event.target.value as string);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = (sessionId?: string) => {
    props.setValue(selection, sessionId);
    handleClose();
  };

  return (
    <>
      <span className={classes.title}>{props.title}</span>
      <span className={classes.value}>
        {props.onDisplayValue(props.value)}{" "}
        <Button size="small" color="primary" variant="outlined" onClick={handleClickOpen}>
          Update value
        </Button>
      </span>
      {open && (
        <Dialog
          title={`Update value for log ${logId}`}
          action={"Save"}
          open={open}
          onClose={handleClose}
          onConfirm={handleSave}
          buttons={
            sessionId
              ? [
                  <Tooltip key={sessionId} title={sessionId} placement="top" arrow={true}>
                    <Button onClick={() => handleSave(sessionId)} className={classes.button} variant="contained">
                      Save Session
                    </Button>
                  </Tooltip>,
                ]
              : undefined
          }
        >
          <form className={classes.dialogContainer}>
            <FormControl className={classes.formControl}>
              <Select displayEmpty value={selection} onChange={handleChange} input={<Input />}>
                {props.list.map((element) => {
                  return (
                    <MenuItem key={element} value={element}>
                      {props.onDisplayValue(element)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </form>
        </Dialog>
      )}
    </>
  );
};
