import React, { useCallback, useEffect, useState } from "react";
import { Route as ReactRoute, RouteProps as ReactRouteProps, Redirect } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { AccountService } from "../api/account/account";
import Config from "../config";
import { GlobalContext } from "../globalContext";
import { Header } from "./Header";
import { PageLoading } from "./Utils/PageLoading";

const accountService = new AccountService(Config.apiUrl);

const useStyles = makeStyles(() => {
  // TODO: Use header heigth instead of hardcoding the value for 'minHeight'
  // property (78). The header height is in 'theme.mixins.toolbar' property.
  // Keep in mind the media quieries and the padding top in 'App' component that
  // need to change in case we implment this TODO.

  // The 'minHeight' property set the footer at botton of the page (not fixed).
  return createStyles({
    page: {
      margin: "0 20px",
      minHeight: "calc(100vh - 98px)",
    },
  });
});

const Session: React.FC = (props) => {
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);
  const classes = useStyles();

  const { setSession, configLoaded, setConfigLoaded } = React.useContext(GlobalContext);

  const onLogout = useCallback(() => {
    setSession(undefined);
    setLoggedIn(false);
  }, [setSession, setLoggedIn]);

  useEffect(() => {
    accountService
      .checkSession()
      .then((session) => {
        setLoggedIn(true);
        setSession(session);
      })
      .catch(onLogout);
  }, [setSession, setLoggedIn, onLogout]);

  const logout = useCallback(() => {
    accountService.logout().then(() => {
      onLogout();
    });
  }, [onLogout]);

  if (loggedIn === false) {
    return <Redirect to={accountService.loginPath} />;
  }

  if (loggedIn === undefined || configLoaded === undefined) {
    return <PageLoading />;
  }

  if (configLoaded === false) {
    setConfigLoaded(undefined);
    logout();
  }

  return (
    <>
      <Header loggedIn={loggedIn} onLogout={logout} />
      <div className={classes.page}>{props.children}</div>
    </>
  );
};

interface RouteProps extends ReactRouteProps {
  anonymous?: boolean;
}

export class Route extends ReactRoute<RouteProps> {
  render() {
    if (this.props.anonymous) {
      return super.render();
    }
    return <Session>{super.render()}</Session>;
  }
}
