import {
  CompensationProvider,
  Config,
  Currency,
  CurrencySymbol,
  MyFaceTask,
  MyFaceTasks,
  PaypalEndpoint,
  devicesAllowed,
} from "../models/myface";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DropResult,
  Droppable,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  RootRef,
  Select,
  Switch,
  TextareaAutosize,
} from "@material-ui/core";
import React, { useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  defaultBRCKGetStartedSubtitle,
  defaultBRCKIntroPrivacy,
  defaultBRCKPrivacyPage,
  defaultGetStartedSubtitle,
  defaultIntroPrivacy,
  defaultPrivacyPage,
} from "./DefaultCopy";

import { Button } from "../../../components/Utils/Button";
import { CheckboxListSelector } from "../../../components/Utils/CheckboxListSelector";
import ConfigurationSection from "../../../components/ConfigurationPanel/ConfigurationSection";
import ConfigurationSubsection from "../../../components/ConfigurationPanel/ConfigurationSubsection";
import { PluginConfiguration } from "../../../models/config";
import { PluginConfigurationProps } from "../../models";
import { SwitchElement } from "../../../components/Utils/SwitchElement";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { expectedResultToString } from "../../../models/log";

const PREFERENCES_SECTION_DESCRIPTION = "Preferences for MyFace";
const COMPENSATION_PROVIDER_SECTION_DESCRIPTION = "Configuration related to the compensation company provider";
const COPY_SECTION_DESCRIPTION = "Edit copies used in MyFace client";

enum ConfigPanelSection {
  Preferences = "Preferences",
  CompensationProvider = "Compensation provider",
  Copy = "Copy",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    sectionText: { fontSize: "24px" },
    nextSectionText: { marginTop: "30px", fontSize: "24px" },
    nextSubSectionText: { marginTop: "30px", fontSize: "16px" },
    modelMenu: { marginTop: "30px", marginLeft: "100px" },
    textField: {
      color: theme.palette.primary.main + "!important",
    },
    select: {
      width: "12rem",
      marginBottom: "16px",
    },
    input: {
      marginBottom: "16px",
      input: {
        color: theme.palette.primary.main + "!important",
      },
    },
    steps: {
      marginBottom: "24px",
    },
    list: {
      width: "240px",
    },
    textarea: {
      width: "100%",
    },
    copyLabelContainer: {
      padding: "12px 8px",
    },
  }),
);

function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle) => ({
  ...draggableStyle,

  ...(isDragging && {
    background: "#00000010",
    borderRadius: "4px",
  }),
});

const initialItemsList = (currentSelection?: MyFaceTask[]) => {
  if (currentSelection) {
    const result = JSON.parse(JSON.stringify(currentSelection)) as MyFaceTask[];

    MyFaceTasks.forEach((task) => {
      if (!result.includes(task)) {
        result.push(task);
      }
    });

    return result;
  } else {
    return MyFaceTasks;
  }
};

export const ConfigPanel: React.FC<PluginConfigurationProps<PluginConfiguration>> = (props) => {
  const classes = useStyles();

  const { isAdmin, onConfigurationChange, config, pluginNumber } = props;
  const myFaceConfig = config.pluginsConfiguration[pluginNumber] as Config;

  const initialItems = initialItemsList(myFaceConfig?.steps);
  const [items, setItems] = useState<MyFaceTask[]>(initialItems);
  const [selectedTasks, setSelectedTasks] = useState<boolean[]>(
    initialItems.map((task) => {
      return myFaceConfig ? myFaceConfig.steps.includes(task) : false;
    }),
  );

  const updateSteps = (listOfItems: MyFaceTask[], listOfSelectedItems: boolean[]) => {
    const steps = new Array<MyFaceTask>();
    listOfSelectedItems.forEach((value, index) => {
      if (value) {
        steps.push(listOfItems[index]);
      }
    });

    onConfigurationChange({
      ...myFaceConfig,
      steps: steps,
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(items, result.source.index, result.destination.index);
    const newSelections = reorder(selectedTasks, result.source.index, result.destination.index);

    setItems(newItems);
    setSelectedTasks(newSelections);

    updateSteps(newItems, newSelections);
  };

  return (
    <div>
      <Grid container direction="column">
        <ConfigurationSection title={ConfigPanelSection.Preferences} description={PREFERENCES_SECTION_DESCRIPTION}>
          <ConfigurationSubsection title="Expected result">
            <Grid item xs={12} className={classes.steps}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <RootRef rootRef={provided.innerRef}>
                      <List className={classes.list}>
                        {items.map((item, index) => (
                          <Draggable key={item} draggableId={item} index={index}>
                            {(provided, snapshot) => (
                              <RootRef rootRef={provided.innerRef}>
                                <ListItem
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                >
                                  <ListItemText primary={expectedResultToString(item)} />
                                  <ListItemSecondaryAction>
                                    <Switch
                                      checked={selectedTasks[index]}
                                      onChange={(e, checked) => {
                                        const copy = [...selectedTasks];
                                        copy[index] = checked;
                                        setSelectedTasks(copy);
                                        updateSteps(items, copy);
                                      }}
                                      color="primary"
                                      disabled={!isAdmin}
                                    />
                                  </ListItemSecondaryAction>
                                </ListItem>
                              </RootRef>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </List>
                    </RootRef>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </ConfigurationSubsection>
          <ConfigurationSubsection title="General">
            <Grid item xs={12}>
              <TextField
                label="Minimum photos"
                value={myFaceConfig.minPhotos ?? "-"}
                onChange={(e) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    minPhotos: Number(e.target.value),
                  })
                }
                type="number"
                margin="normal"
                className={classes.textField}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Minimum approved photos"
                value={myFaceConfig.minApprovedPhotos ?? "-"}
                onChange={(e) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    minApprovedPhotos: Number(e.target.value),
                  })
                }
                type="number"
                margin="normal"
                className={classes.textField}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Maximum photos"
                value={myFaceConfig.maxPhotos ?? "-"}
                onChange={(e) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    maxPhotos: Number(e.target.value),
                  })
                }
                type="number"
                margin="normal"
                className={classes.textField}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Reward text"
                value={myFaceConfig.rewardText ?? "-"}
                onChange={(e) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    rewardText: e.target.value,
                  })
                }
                margin="normal"
                className={classes.textField}
                disabled={!isAdmin}
              />
            </Grid>
          </ConfigurationSubsection>
          <ConfigurationSubsection title="FCM configuration">
            <Grid item xs={12}>
              <SwitchElement
                title={"Face Capture Module debug mode"}
                checked={myFaceConfig.faceCaptureModuleDebug}
                onChange={(checked: boolean) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    faceCaptureModuleDebug: checked,
                  })
                }
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12}>
              <SwitchElement
                title={"Face Capture Module show overlay"}
                checked={myFaceConfig.faceCaptureModuleOverlay}
                onChange={(checked: boolean) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    faceCaptureModuleOverlay: checked,
                  })
                }
                disabled={!isAdmin}
              />
            </Grid>
          </ConfigurationSubsection>
          <ConfigurationSubsection title="Devices allowed">
            <Grid item xs={12}>
              <CheckboxListSelector
                value={myFaceConfig.devicesAllowed}
                list={devicesAllowed}
                onChange={(value) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    devicesAllowed: value,
                  })
                }
              />
            </Grid>
          </ConfigurationSubsection>
        </ConfigurationSection>

        <ConfigurationSection
          title={ConfigPanelSection.CompensationProvider}
          description={COMPENSATION_PROVIDER_SECTION_DESCRIPTION}
        >
          <ConfigurationSubsection title="Compensation provider">
            <Grid item xs={12}>
              <RadioGroup
                value={myFaceConfig.compensationProvider}
                onChange={(e) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    compensationProvider: e.target.value,
                  })
                }
                row
              >
                <div className={""}>
                  <FormControlLabel
                    value={CompensationProvider.None}
                    control={<Radio color="primary" />}
                    label="None"
                    labelPlacement="top"
                    disabled={!isAdmin}
                  />
                  <FormControlLabel
                    value={CompensationProvider.Paypal}
                    control={<Radio color="primary" />}
                    label="Paypal"
                    labelPlacement="top"
                    disabled={!isAdmin}
                  />
                  <FormControlLabel
                    value={CompensationProvider.BRCK}
                    control={<Radio color="primary" />}
                    label="BRCK"
                    labelPlacement="top"
                    disabled={!isAdmin}
                  />
                </div>
              </RadioGroup>
            </Grid>
          </ConfigurationSubsection>
          {myFaceConfig.compensationProvider === CompensationProvider.Paypal && (
            <ConfigurationSubsection title="Configuration">
              <Typography color="textSecondary" gutterBottom className={classes.nextSubSectionText}>
                Paypal Endpoint
              </Typography>
              <Grid item xs={12} md={9}>
                <RadioGroup
                  value={myFaceConfig.paypalEndpoint}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      paypalEndpoint: e.target.value,
                    })
                  }
                  row
                >
                  <div className={""}>
                    <FormControlLabel
                      value={PaypalEndpoint.Live}
                      control={<Radio color="primary" />}
                      label="Live"
                      labelPlacement="top"
                      disabled={!isAdmin}
                    />
                    <FormControlLabel
                      value={PaypalEndpoint.Sandbox}
                      control={<Radio color="primary" />}
                      label="Sandbox"
                      labelPlacement="top"
                      disabled={!isAdmin}
                    />
                  </div>
                </RadioGroup>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  label="Paypal Client ID"
                  value={myFaceConfig.paypalClientID}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      paypalClientID: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  className={classes.textField}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  label="Paypal Secret"
                  value={myFaceConfig.paypalSecret}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      paypalSecret: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  className={classes.textField}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor="currency-select">Currency</InputLabel>
                  <Select
                    inputProps={{
                      id: "currency-select",
                    }}
                    value={myFaceConfig.paypalCurrency ? myFaceConfig.paypalCurrency : ""}
                    onChange={(e) =>
                      onConfigurationChange({
                        ...myFaceConfig,
                        paypalCurrency: e.target.value,
                      })
                    }
                    className={classes.select}
                  >
                    <MenuItem value={Currency.EUR.toString()}>{Currency.EUR}</MenuItem>
                    <MenuItem value={Currency.GBP.toString()}>{Currency.GBP}</MenuItem>
                    <MenuItem value={Currency.USD.toString()}>{Currency.USD}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor="max-payment-amount">Max payment amount for approved users</InputLabel>
                  <Input
                    id="max-payment-amount"
                    value={myFaceConfig.paypalMaxPayment ?? 0}
                    onChange={(e) =>
                      onConfigurationChange({
                        ...myFaceConfig,
                        paypalMaxPayment: Number(e.target.value),
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">{CurrencySymbol(myFaceConfig.paypalCurrency)}</InputAdornment>
                    }
                    type="number"
                    className={classes.input}
                    disabled={!isAdmin}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor="min-payment-amount">Min payment amount for rejected users</InputLabel>
                  <Input
                    id="min-payment-amount"
                    value={myFaceConfig.paypalMinPayment ?? 0}
                    onChange={(e) =>
                      onConfigurationChange({
                        ...myFaceConfig,
                        paypalMinPayment: Number(e.target.value),
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">{CurrencySymbol(myFaceConfig.paypalCurrency)}</InputAdornment>
                    }
                    type="number"
                    className={classes.input}
                    disabled={!isAdmin}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Min approved images for min payment"
                  value={myFaceConfig.paypalMinPaymentAfterImageThreshold ?? "-"}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      paypalMinPaymentAfterImageThreshold: Number(e.target.value),
                    })
                  }
                  type="number"
                  margin="normal"
                  className={classes.textField}
                  disabled={!isAdmin}
                />
              </Grid>
            </ConfigurationSubsection>
          )}
          {myFaceConfig.compensationProvider === CompensationProvider.BRCK && (
            <ConfigurationSubsection title="Configuration">
              <Grid item xs={12} md={9}>
                <TextField
                  label="BRCK base url"
                  value={myFaceConfig.brckBaseURL}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      brckBaseURL: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  className={classes.textField}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  label="BRCK access token"
                  value={myFaceConfig.brckAccessToken}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      brckAccessToken: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  className={classes.textField}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  label="BRCK refresh token"
                  value={myFaceConfig.brckRefreshToken}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      brckRefreshToken: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  className={classes.textField}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  label="BRCK campaign ID"
                  value={myFaceConfig.brckCampaignID}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      brckCampaignID: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  className={classes.textField}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  label="BRCK partner ID"
                  value={myFaceConfig.brckPartnerID}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      brckPartnerID: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                  className={classes.textField}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="BRCK min credits"
                  value={myFaceConfig.brckMinPoints}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      brckMinPoints: Number(e.target.value),
                    })
                  }
                  type="number"
                  margin="normal"
                  className={classes.textField}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="BRCK max credits"
                  value={myFaceConfig.brckMaxPoints}
                  onChange={(e) =>
                    onConfigurationChange({
                      ...myFaceConfig,
                      brckMaxPoints: Number(e.target.value),
                    })
                  }
                  type="number"
                  margin="normal"
                  className={classes.textField}
                  disabled={!isAdmin}
                />
              </Grid>
            </ConfigurationSubsection>
          )}
        </ConfigurationSection>

        <ConfigurationSection title={ConfigPanelSection.Copy} description={COPY_SECTION_DESCRIPTION}>
          <ConfigurationSubsection title="Intro page get started subtitle">
            <Grid item xs={12} className={classes.steps}>
              <Grid container spacing={1} alignItems="center" className={classes.copyLabelContainer}>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      onConfigurationChange({
                        ...myFaceConfig,
                        getStartedSubtitle: defaultBRCKGetStartedSubtitle,
                      })
                    }
                  >
                    Default BRCK
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      onConfigurationChange({
                        ...myFaceConfig,
                        getStartedSubtitle: defaultGetStartedSubtitle,
                      })
                    }
                  >
                    Default MyFace
                  </Button>
                </Grid>
              </Grid>
              <TextareaAutosize
                value={myFaceConfig.getStartedSubtitle}
                className={classes.textarea}
                rowsMin={4}
                rowsMax={15}
                onChange={(e) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    getStartedSubtitle: e.target.value,
                  })
                }
              />
            </Grid>
          </ConfigurationSubsection>
          <ConfigurationSubsection title="Main page">
            <Grid item xs={12} className={classes.steps}>
              <Grid container spacing={1} alignItems="center" className={classes.copyLabelContainer}>
                <Grid item>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={() =>
                      onConfigurationChange({
                        ...myFaceConfig,
                        introductionPrivacy: defaultBRCKIntroPrivacy,
                      })
                    }
                  >
                    Default BRCK
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      onConfigurationChange({
                        ...myFaceConfig,
                        introductionPrivacy: defaultIntroPrivacy,
                      })
                    }
                  >
                    Default MyFace
                  </Button>
                </Grid>
              </Grid>
              <TextareaAutosize
                value={myFaceConfig.introductionPrivacy}
                className={classes.textarea}
                rowsMin={4}
                rowsMax={15}
                onChange={(e) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    introductionPrivacy: e.target.value,
                  })
                }
              />
            </Grid>
          </ConfigurationSubsection>
          <ConfigurationSubsection title="Privacy page">
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center" className={classes.copyLabelContainer}>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      onConfigurationChange({
                        ...myFaceConfig,
                        privacy: defaultBRCKPrivacyPage,
                      })
                    }
                  >
                    Default BRCK
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      onConfigurationChange({
                        ...myFaceConfig,
                        privacy: defaultPrivacyPage,
                      })
                    }
                  >
                    Default MyFace
                  </Button>
                </Grid>
              </Grid>
              <TextareaAutosize
                className={classes.textarea}
                value={myFaceConfig.privacy}
                rowsMin={4}
                rowsMax={15}
                onChange={(e) =>
                  onConfigurationChange({
                    ...myFaceConfig,
                    privacy: e.target.value,
                  })
                }
              />
            </Grid>
          </ConfigurationSubsection>
        </ConfigurationSection>
      </Grid>
    </div>
  );
};
