import { KeyboardDatePicker } from "@material-ui/pickers";
import Moment from "moment";
import React from "react";

const dateFormat = "YYYY/MM/DD";

interface Props {
  label: string;
  value?: Date;
  onChange: (value?: Date) => void;
}

export const DateInput: React.FC<Props> = (props) => {
  const dateValue = props.value ? Moment(props.value) : null;

  return (
    <KeyboardDatePicker
      variant="inline"
      label={props.label}
      value={dateValue}
      onChange={(date) => {
        props.onChange(date?.toDate());
      }}
      format={dateFormat}
    />
  );
};
