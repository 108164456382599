import Moment from "moment";
import React from "react";

interface Props {
  value: number | string | Date | Moment.Moment;
  format?: "date" | "datetime" | "time";
}

export const DateLabel: React.FC<Props> = (props) => {
  const { value, format } = props;

  let date: Moment.Moment;
  if (typeof value === "number") {
    date = Moment.unix(value);
  } else {
    date = Moment(value);
  }

  if (format === "date") {
    return <>{date.format("YYYY-MM-DD")}</>;
  } else if (format === "time") {
    return <>{date.format("HH:mm:ss")}</>;
  } else {
    return <>{date.format("YYYY-MM-DD HH:mm:ss")}</>;
  }
};
