import { Login, LoginResponse, LoginType } from "../../models/account";
import { begin as googleBegin, callback as googleCallback } from "./google";
import { begin as yotiBegin, callback as yotiCallback } from "./yoti";

import { NodeEnv } from "../../types/node";

/**
 * This entity contains all the provider callbacks the application will run when
 * the OAuth service returns the token to request for the user info. The
 * application won't run the callback request if this method throws an exception.
 */
export const providers: Record<LoginType, (query: URLSearchParams) => Login> = {
  google: googleCallback,
  yoti: yotiCallback,
};

/**
 * Handle the response for the first request the application performs to get the
 * OAuth token.
 * @param res The response from OAuth service with the token.
 */
export const begin = (res: LoginResponse, env: NodeEnv) => {
  switch (res.type) {
    case LoginType.Google:
      googleBegin(res);
      break;
    case LoginType.Yoti:
      yotiBegin(res, env);
      break;
  }
};
