import { Grid, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { AccountService } from "../../api/account/account";
import Config from "../../config";
import { GlobalContext } from "../../globalContext";
import { Button as GoogleButton } from "./Google/Button";
import { LoginType } from "../../models/account";
import { Redirect } from "react-router-dom";
import { Button as YotiButton } from "./Yoti/Button";
import { begin } from "../../api/account/factory";

const accountService = new AccountService(Config.apiUrl);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: "400px",
      minHeight: "calc(100vh - 98px)",
      margin: "0 auto",
      padding: "0 15px",
      textAlign: "center",
    },
    button: {
      width: 191,
      height: 46,
    },
  }),
);

export const Login: React.FC = () => {
  const classes = useStyles();
  const { session, envSettings } = useContext(GlobalContext);

  const login = async (loginType: LoginType) => {
    const res = await accountService.login(loginType);
    begin(res, Config.env);
  };

  return session ? (
    <Redirect to="/" />
  ) : (
    <Grid className={classes.root}>
      <Typography>Sign in with one of the following methods:</Typography>
      <br />
      <GoogleButton className={classes.button} onClick={() => login(LoginType.Google)} />
      {envSettings.yotiCfg && envSettings.yotiCfg.clientSdkId && envSettings.yotiCfg.scenarioId && (
        <YotiButton
          className={classes.button}
          clientSdkId={envSettings.yotiCfg.clientSdkId}
          scenarioId={envSettings.yotiCfg.scenarioId}
          env={Config.env}
          text="Use Yoti"
          onDebugButtonClick={() => login(LoginType.Yoti)}
        />
      )}
    </Grid>
  );
};
