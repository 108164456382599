import { createStyles, makeStyles } from "@material-ui/core/styles";

import { BiologicalSexResult } from "../../../models/log";
import { ModelLabelProps } from "../../../config/modelsConfig";
import React from "react";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    predictionValue: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.body2.fontSize,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.common.black,
      marginLeft: 6,
    },
    prob: {
      color: theme.palette.primary.main,
      marginLeft: "1ch",
    },
  }),
);

export const BiologicalSex: React.FC<ModelLabelProps> = (props) => {
  const classes = useStyles(props);
  const res = props.result as BiologicalSexResult;

  return (
    <>
      <Typography color="textSecondary" className={classes.predictionValue}>
        <span>{res.probs >= 0.5 ? "Female" : "Male"}</span>
        <span className={classes.prob}>{(res.probs * 100).toFixed(3)}%</span>
      </Typography>
    </>
  );
};
