import { ImageDimensions } from "../models/image";
import { TinyBbox } from "../models/prediction";
import { UploadValidationError } from "../models/upload";

/**
 * Returns the image resolution dimensions in pixels.
 * @param image An image base64 string.
 */
const getImageDimensions = async (image: string): Promise<ImageDimensions> => {
  return new Promise(function (resolved) {
    const i = new Image();
    i.onload = function () {
      resolved({ width: i.width, height: i.height });
    };
    i.src = image;
  });
};

/**
 * Returns an array with the image dimensions validation conditions that are not satisfied or empty if none.
 * @param image An image base64 string.
 * @param maxImageWidth Max image width allowed
 * @param minImageWidth Min image width allowed
 * @param maxImageHeight Max image height allowed
 * @param minImageHeight Min image height allowed
 */
export const validateImageDimensions = async (
  image: string,
  maxImageWidth: number,
  minImageWidth: number,
  maxImageHeight: number,
  minImageHeight: number,
): Promise<UploadValidationError[]> => {
  const imgDimensions = await getImageDimensions(image);

  return new Promise(function (resolved) {
    const feedback: UploadValidationError[] = [];

    if (imgDimensions.width > maxImageWidth) {
      feedback.push(UploadValidationError.ImageLargeWidth);
    } else if (imgDimensions.width < minImageWidth) {
      feedback.push(UploadValidationError.ImageSmallWidth);
    }

    if (imgDimensions.height > maxImageHeight) {
      feedback.push(UploadValidationError.ImageLargeHeight);
    } else if (imgDimensions.height < minImageHeight) {
      feedback.push(UploadValidationError.ImageSmallHeight);
    }

    resolved(feedback);
  });
};

/**
 * Returns the tiny face validation condition that is not satisfied or undefined if none.
 * @param img An image file.
 */
export const validateImageFaces = (bboxes: TinyBbox[], maxFaces: number): UploadValidationError | undefined => {
  if (bboxes.length === 0) {
    return UploadValidationError.NoFace;
  } else if (bboxes.length > maxFaces) {
    return UploadValidationError.TooManyFaces;
  }
};
