export function poll<T>(fn: () => Promise<T | undefined>, interval: number) {
  const checkCondition = (resolve: (value?: T | PromiseLike<T>) => void) => {
    fn().then((r) => {
      if (r) {
        resolve(r);
      } else {
        setTimeout(checkCondition, interval, resolve);
      }
    });
  };
  return new Promise<T | undefined>(checkCondition);
}
