import { ExpectedResult } from "../../../models/log";
import { ExtraInfo } from "../../models";

export type MyFaceTask = Exclude<ExpectedResult, ExpectedResult.Unknown>;

export enum DeviceAllowed {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop",
}

export const devicesAllowed = Object.values(DeviceAllowed) as DeviceAllowed[];

export interface ReviewUserInfo {
  id: string;
  expired: boolean;
}

export enum CompensationProvider {
  Paypal = "paypal",
  BRCK = "brck",
  None = "none",
}

export enum PaypalEndpoint {
  Sandbox = "https://api.sandbox.paypal.com",
  Live = "https://api.paypal.com",
}

export enum Currency {
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD",
}

export function CurrencySymbol(currency: Currency) {
  switch (currency) {
    case Currency.EUR:
      return "€";
    case Currency.GBP:
      return "£";
    case Currency.USD:
      return "$";
    default:
      return "-";
  }
}

export interface Config {
  steps: MyFaceTask[];
  minPhotos: number;
  minApprovedPhotos: number;
  maxPhotos: number;
  rewardText: string;
  devicesAllowed: DeviceAllowed[];
  introductionPrivacy: string;
  privacy: string;
  getStartedSubtitle: string;

  compensationProvider: CompensationProvider;

  paypalEndpoint: PaypalEndpoint;
  paypalClientID: string;
  paypalSecret: string;
  paypalMaxPayment: number;
  paypalMinPayment: number;
  paypalMinPaymentAfterImageThreshold: number;
  paypalCurrency: Currency;

  brckBaseURL: string;
  brckAccessToken: string;
  brckRefreshToken: string;
  brckCampaignID: string;
  brckPartnerID: string;
  brckMinPoints: number;
  brckMaxPoints: number;

  faceCaptureModuleDebug: boolean;
  faceCaptureModuleOverlay: boolean;
}

export const MyFaceTasks: MyFaceTask[] = [
  ExpectedResult.Real,
  ExpectedResult.DisplayAttack,
  ExpectedResult.PrintAttack,
  ExpectedResult.MaskAttack,
];

export enum MyFaceUserState {
  Unknown = "",
  NotStarted = "not started",
  SignedUp = "signedup",
  Pending = "pending",
  InReview = "in review",
  Approved = "approved",
  Rejected = "rejected",
}

export interface MyFaceUser {
  userID: string;
  firstVisitDate: Date;
  signUpDate: Date;
  faceCaptureModuleFailed: boolean;
  numberIncorrectPhotos: number;
  numberCorrectPhotos: number;
  lastPhotoDate: Date;
  lastCorrectPhotoDate: Date;
  finishDate: Date;
  reviewDate: Date;
  unknownImages: number;
  realImages: number;
  displayAttackImages: number;
  printAttackImages: number;
  maskAttackImages: number;
  approvedImages: number;
  result: MyFaceUserState;
  credited: boolean;
  extraInfo: ExtraInfo;
}

export interface MyFaceUsersResult {
  users: MyFaceUser[];
  count: number;
}

export interface MyFaceImage {
  userID: string;
  logID: number;
  expectedResult: ExpectedResult;
  img: string;
  state: MyFaceUserState;
  error?: Error;
}

export interface ReviewStats {
  unknown: number;
  notStarted: number;
  notFinished: number;
  pending: number;
  inReview: number;
  approved: number;
  rejected: number;
  expired: number;
  inReviewExpired: number;
}

export interface DailyResult {
  stats: DailyStats[];
  count: number;
}

export interface DailyStats {
  day: Date;
  visits: number;
  signUps: number;
  finished: number;
  reviewed: number;
  approved: number;
  rejected: number;
  expired: number;
}

export interface BRCKCampaignStats {
  campaign: string;
  visits: number;
  signUps: number;
  withError: number;
  reached1: number;
  reached5: number;
  reached10: number;
  reached20: number;
  finished: number;
  reviewed: number;
  approved: number;
  rejected: number;
  expired: number;
}

export const RejectionReasonsList = [
  "No real faces",
  "Not enough image variations",
  "Bad image quality - overexposed",
  "Bad image quality - underexposed",
  "Bad image quality - blurry",
  "Face occluded",
  "Extreme face pose",
  "Different subjects",
  "Other reasons",
] as const;
export type RejectionReasons = typeof RejectionReasonsList[number];

export enum MyFaceTrackingEvent {
  FaceCaptureModuleError = "FACE_CAPTURE_MODULE_ERROR",
  Action = "ACTION",
  PageVisit = "PAGE_VISIT",
}

export enum MyFaceTrackingEventAction {
  FaceCaptureModuleLoaded = "FACE_CAPTURE_MODULE_LOADED",
}

export interface MyFaceEvent {
  userID: string;
  event: MyFaceTrackingEvent;
  data: string;
  date: Date;
}

export interface FaceCaptureModuleErrorsStats {
  noCamera: number;
  noCameraPercentage: number;
  genericCameraError: number;
  genericCameraErrorPercentage: number;
  unsupportedBrowser: number;
  unsupportedBrowserPercentage: number;
  noCameraPermission: number;
  noCameraPermissionPercentage: number;
  overconstrained: number;
  overconstrainedPercentage: number;
  faceDetectionInitError: number;
  faceDetectionInitErrorPercentage: number;
  internalError: number;
  internalErrorPercentage: number;
}

export enum FaceCaptureModuleErrorCodes {
  NoCamera = "NO_CAMERA",
  GenericCameraError = "GENERIC_CAMERA_ERROR",
  UnsupportedBrowser = "UNSUPPORTED_BROWSER",
  NoCameraPermission = "NO_CAMERA_PERMISSION",
  Overconstrained = "OVERCONSTRAINED",
  FaceDetectionInitError = "FACE_DETECTION_INIT_ERROR",
  InternalError = "INTERNAL_ERROR",
}
