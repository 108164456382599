import { AuditLogChange } from "../../../models/audit";
import React from "react";
import { TooltipAuditChanges } from "../../Utils/TooltipAuditChanges";

interface ValueChangesProps {
  list: AuditLogChange[];
  value: any;
}

export const ValueChanges: React.FC<ValueChangesProps> = (props) => {
  const { list, value } = props;
  const valueToShow = value === "" || value === null ? "Unknown" : value.charAt(0).toUpperCase() + value.slice(1);

  return (
    <>
      {list?.length > 0 ? (
        <TooltipAuditChanges changes={list}>
          <>{valueToShow} *</>
        </TooltipAuditChanges>
      ) : (
        valueToShow
      )}
    </>
  );
};
