import { InputLabel, MenuItem, Select, TextField, createStyles, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

import { Dialog } from "../Utils/Dialog";
import { INFINITE_PLACEHOLDER } from "../../utils/forms";
import { Role } from "../../models/role";
import { SwitchElement } from "../Utils/SwitchElement";
import { User } from "../../models/user";

interface Props {
  initialUser: User;
  open: boolean;
  roles: Role[];
  handleClose: () => void;
  onSubmit: (user: User) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    formElement: {
      margin: "16px 0px",
    },
  }),
);

export const EditUserDialog: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { initialUser, open, roles, handleClose, onSubmit } = props;
  const [user, setUser] = useState<User>(initialUser);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState<boolean>(false);
  const isNewUser = user.id === 0;

  const onClose = () => {
    setUser(initialUser);
    handleClose();
  };

  const clickOpenDialog = (checked: boolean) => {
    setShowConfirmationDialog(checked);
    setUser({ ...user, banned: checked });
  };

  return (
    <>
      <Dialog
        open={open}
        title={isNewUser ? "Create user" : `Edit user ${user.email}`}
        action={isNewUser ? "Create" : "Update"}
        onConfirm={() => onSubmit(user)}
        onClose={handleClose}
        onCancel={onClose}
      >
        {isNewUser && (
          <div className={classes.formElement}>
            <TextField
              className={classes.formElement}
              label="Email"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
              fullWidth={true}
            />
          </div>
        )}

        <div className={classes.formElement}>
          <SwitchElement
            title="Verified"
            checked={user.verified}
            onChange={(checked) => setUser({ ...user, verified: checked })}
          />
        </div>

        <div className={classes.formElement}>
          <SwitchElement title="Banned" checked={user.banned} onChange={(checked) => clickOpenDialog(checked)} />
        </div>

        <div className={classes.formElement}>
          <SwitchElement
            title="Save images"
            checked={user.saveImages}
            onChange={(checked) => setUser({ ...user, saveImages: checked })}
          />
        </div>

        <div className={classes.formElement}>
          <InputLabel id="role-input">Role</InputLabel>
          <Select
            id="role-input"
            label="Role"
            value={user.roleId}
            fullWidth
            onChange={(e) => {
              setUser({ ...user, roleId: Number(e.target.value) });
            }}
          >
            {roles.map((r, i) => {
              return (
                <MenuItem key={i} value={r.id}>
                  {r.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <TextField
          fullWidth
          className={classes.formElement}
          label="Maximum daily requests"
          type="number"
          placeholder={INFINITE_PLACEHOLDER}
          InputLabelProps={{
            shrink: true,
          }}
          value={user.requestsPerDayLimit}
          onChange={(e) =>
            setUser({
              ...user,
              requestsPerDayLimit: e.target.value ? Number(e.target.value) : undefined,
            })
          }
        />

        <TextField
          fullWidth
          className={classes.formElement}
          label="Maximum requests in total"
          type="number"
          placeholder={INFINITE_PLACEHOLDER}
          InputLabelProps={{
            shrink: true,
          }}
          value={user.requestsInTotalLimit}
          onChange={(e) =>
            setUser({
              ...user,
              requestsInTotalLimit: e.target.value ? Number(e.target.value) : undefined,
            })
          }
        />

        <TextField
          className={classes.formElement}
          label="Expiration hours for pictures"
          type="number"
          placeholder={INFINITE_PLACEHOLDER}
          InputLabelProps={{
            shrink: true,
          }}
          value={user.expirationHours}
          onChange={(e) =>
            setUser({
              ...user,
              expirationHours: e.target.value ? Number(e.target.value) : undefined,
            })
          }
        />
      </Dialog>
      {showConfirmationDialog && (
        <Dialog
          open={showConfirmationDialog}
          title={"Ban User"}
          message={`ban ${initialUser.email}`}
          action={"Ban"}
          onConfirm={() => setUser({ ...user, banned: true })}
          onClose={() => setShowConfirmationDialog(false)}
          onCancel={() => setUser({ ...user, banned: false })}
        ></Dialog>
      )}
    </>
  );
};
