import React, { useContext } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core";

import { GlobalContext } from "../globalContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    environment: {
      ...theme.typography.button,
      ...theme.shape,
      position: "absolute",
      left: 0,
      top: 10,
      textDecoration: "none",
      padding: theme.spacing(1),
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      marginLeft: 26,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0.5),
        marginLeft: 13,
      },
    },
  }),
);

export const EnvTitle: React.FC = () => {
  const classes = useStyles();
  const { envSettings } = useContext(GlobalContext);
  const { env } = envSettings;

  return (
    <>
      {env && env !== "" && env.toLowerCase() !== "prod" && env.toLowerCase() !== "production" && (
        <div className={classes.environment}>{env}</div>
      )}
    </>
  );
};
