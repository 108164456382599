import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField as TextFieldComponent,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

const DEFAULT_DELAY = 500;

interface Props {
  delay?: number;
  onChangeValue: (value?: string) => void;
}

export const TextFieldDelay: React.FC<
  Props & (StandardTextFieldProps | FilledTextFieldProps | OutlinedTextFieldProps)
> = (props) => {
  const [inputValue, setInputValue] = useState<string>("");
  const { delay, onChangeValue } = props;

  useEffect(() => {
    const timer = setTimeout(
      () => {
        onChangeValue(inputValue);
      },
      delay ? delay : DEFAULT_DELAY,
    );

    return () => clearTimeout(timer);
  }, [inputValue, delay, onChangeValue]);
  return <TextFieldComponent value={inputValue} {...props} onChange={(e) => setInputValue(e.target.value)} />;
};
