import { BrightnessInfo, BrightnessInfoMask, CameraInfo, ModelType } from "./utils";
import { ExpectedBrightness, ExpectedLocation, ModelPredictionType } from "./log";
import { ImageExtensionFormat, Picture } from "./image";

export interface Device extends IUAParser.IResult {
  cameraInfo?: CameraInfo;
}

export interface ImageInfo {
  originalPhoto: PhotoInfo;
  device: Device;
  fromCamera: number;
  expectedResult?: string;
  tag?: string;
  croppedPhoto?: PhotoInfo;
  pictureUsed: Picture;
  imageExtensionFormat: ImageExtensionFormat;
  imageJPEGCompression?: number;
  expectedLocation?: ExpectedLocation;
  expectedBrightness?: ExpectedBrightness;
  brightness?: BrightnessInfo;
  brightnessMask?: BrightnessInfoMask;
}

export interface TinyBbox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface PhotoInfo {
  image?: string;
  tinyFaceInfo?: TinyFaceInfo;
}

export interface FaceResult {
  id?: number;
  error?: string;
  img: string;
  loading: boolean;
  modelPredictionResultList?: Record<ModelType, ModelPredictionType>;
  formulaResult: ModelType[];
  result?: PredictionFormulaResult;

  // Properties for multiframe result.
  sessionId?: string;
  requestId?: string;
}

export enum PredictionFormulaResult {
  Real = "real",
  Fake = "fake",
  Undetermined = "undetermined",
  Error = "error",
}

export interface PredictionResult {
  id: number;
  modelPredictionResultList: Record<ModelType, ModelPredictionType>;
  formula: ModelType[];
  result: PredictionFormulaResult;
  error?: string;
}

export interface TinyFaceInfo {
  bBox: TinyBbox;
  score: number;
}
