import { ModelType, getModelTypeName } from "../../../../models/utils";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { HistogramCard } from "../../../Histogram/HistogramCard";
import List from "@material-ui/core/List";
import { ModelResultType } from "../../../../models/log";
import { PhotoDebugImage } from "./PhotoDebugImage";
import { PredictionFormulaResult } from "../../../../models/prediction";
import { PredictionIcon } from "../../../Utils/PredictionIcon";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modelTitle: {
      marginLeft: "0.2em",
      fontSize: "1.3em",
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black,
    },
    modelTitleContainer: {
      display: "flex",
      alignItems: "center",
    },
    mtcnnDetailContainer: {
      flex: "1",
      flexDirection: "column",
    },
    mtcnnDetailTitle: {
      color: theme.palette.common.black,
    },
    modelPhoto: {
      width: "140px",
    },
    grid: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
    },
    clear: {
      clear: "both",
    },
    list: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
);

interface Props {
  cropped?: string[];
  debugImage?: string;
  showHistogram: boolean;
  showDebugImages: boolean;
  modelType: ModelType;
  modelResult: ModelResultType;
}

export const ModelDetailsContainer: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  let prediction: PredictionFormulaResult | undefined = undefined;

  const { cropped, debugImage, showHistogram, showDebugImages, modelType, modelResult, children } = props;

  if ("prediction" in modelResult) {
    prediction = modelResult.prediction;
  }

  return (
    <>
      <div>
        <div className={classes.modelTitleContainer}>
          {prediction && <PredictionIcon fontSize={30} prediction={prediction} />}
          <span className={classes.modelTitle}>{getModelTypeName(modelType)}:</span>
        </div>
        <List component="nav" className={classes.list}>
          <Divider />
          {children}
          <Divider />
        </List>
        {showDebugImages && (
          <>
            <Grid container direction="row" justify="space-around" alignItems="center">
              {cropped &&
                cropped.map((c, index) => (
                  <Grid item className={classes.grid} key={index}>
                    <PhotoDebugImage title="Cropped image" image={c} />
                  </Grid>
                ))}
              {debugImage && (
                <Grid item className={classes.grid}>
                  <PhotoDebugImage title="Debug image" image={debugImage} />
                </Grid>
              )}
            </Grid>
          </>
        )}
        {cropped && showHistogram && cropped.map((c) => <HistogramCard key={c} img={c} width={560} />)}
      </div>
      <div className={classes.clear} />
    </>
  );
};
