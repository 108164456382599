import { AddBoxRounded, IndeterminateCheckBox } from "@material-ui/icons";
import { Button, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { ExpandButton } from "../../../Utils/ExpandButton";
import { ListLabel } from "../LogDetail";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TimeLabel } from "./TimeLabel";
import { TimeStruct } from "../../../../models/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "1",
      flexDirection: "column",
    },
    tableContainer: {
      overflowY: "auto",
      height: "400px",
    },
    tableContainerExpanded: {
      overflowY: "auto",
    },
    tableRowOdd: {
      backgroundColor: theme.palette.background.default,
    },
    tableRowEven: {
      backgroundColor: theme.palette.background.paper,
    },
    tableCell: {
      fontSize: "12pt",
    },
    expandButtonWraper: {
      textAlign: "right",
    },
  }),
);

const useTimeColor = makeStyles(() =>
  createStyles({
    coloredCell: (props: { color: string }) => ({
      fontSize: "12pt",
      backgroundColor: props.color,
    }),
  }),
);

const colors: { [key: string]: string } = {};
const lightBlue = [
  "coordinator_between_request_and_mtcnn",
  "read_predict_req",
  "validate_predict_request",
  "base64_to_image",
  "validate_image_grayscale",
  "validate_image_size",
  "coordinator_between_mtcnn_ai",
  "validate_mtcnn_response",
  "validate_INSUFFICIENT_AREA_AROUND_THE_FACE",
  "coordinator_build_prediction",
];
const blue = ["coordinator_to_mtcnn", "coordinator_to_ai"];
const lightGreen = [
  "mtcnn",
  "image_processing_mtcnn",
  "mtcnn_validate",
  "reshape_landmarks",
  "cropped_192x256",
  "cropped_112x112",
  "cropped_224x224",
  "cropped_256x256",
  "cropped_112x112margin20",
  "cropping",
];
const green = ["mtcnn_tf", "mtcnn_to_coordinator"];
const lightOrange = ["ai_service", "image_processing", "run_models", "pre_process", "prediction", "build_montage"];
colors["predict"] = "#c6b1c9";
lightBlue.forEach((value) => (colors[value] = "#bfcde6"));
blue.forEach((value) => (colors[value] = "#0096ce"));
lightGreen.forEach((value) => (colors[value] = "#b8daba"));
green.forEach((value) => (colors[value] = "#228b22"));
lightOrange.forEach((value) => (colors[value] = "#ffd5b1"));
colors["ai_results_to_coordinator"] = "#ffc08c";

const keyParser = (key: string): string => {
  let res = "Unknown";
  switch (key) {
    case "predict":
      res = "Main flow";
      break;
    case "cropping":
      res = "Cropping (total)";
      break;
    case "coordinator_between_request_and_mtcnn":
      res = "Coordinator";
      break;
    case "read_predict_req":
      res = "IO HTTP";
      break;
    case "validate_predict_request":
      res = "Validation";
      break;
    case "base64_to_image":
      res = "B64 to image";
      break;
    case "validate_image_grayscale":
      res = "Check grayscale";
      break;
    case "validate_image_size":
      res = "Check image size";
      break;
    case "coordinator_to_mtcnn":
      res = "Coordinator to MTCNN request";
      break;
    case "mtcnn":
      res = "MTCNN";
      break;
    case "image_processing_mtcnn":
      res = "Image processing for MTCNN";
      break;
    case "mtcnn_tf":
      res = "E2E MTCNN TF time";
      break;
    case "mtcnn_validate":
      res = "MTCNN face validation";
      break;
    case "reshape_landmarks":
      res = "Filter BBox and landmarks";
      break;
    case "cropped_192x256":
    case "cropped_112x112":
    case "cropped_224x224":
    case "cropped_256x256":
    case "cropped_112x112margin20":
      res = "Cropped";
      break;
    case "mtcnn_to_coordinator":
      res = "Send cropped to coordinator";
      break;
    case "coordinator_between_mtcnn_ai":
      res = "MTCNN response till request of ai service";
      break;
    case "validate_mtcnn_response":
      res = "MTCNN response validation";
      break;
    case "validate_INSUFFICIENT_AREA_AROUND_THE_FACE":
      res = "MTCNN response zero px validation";
      break;
    case "coordinator_to_ai":
      res = "Send request and cropped to AI Services";
      break;
    case "ai_service":
      res = "AI Service";
      break;
    case "image_processing":
      res = "Image processing for AI Service";
      break;
    case "run_models":
      res = "Run models";
      break;
    case "pre_process":
      res = "Model pre process";
      break;
    case "prediction":
      res = "Prediction";
      break;
    case "build_montage":
      res = "Build montage";
      break;
    case "ai_results_to_coordinator":
      res = "AI Service results sent to coordinator";
      break;
    case "coordinator_build_prediction":
      res = "Build prediction";
      break;
  }
  return res;
};

interface Props {
  timeDetails: TimeStruct[];
  startTimeKey: string;
  endTimeKey: string;
}

export const ProcessingTime: React.FC<Props> = (props) => {
  const [timeDetailOpen, setTimeDetailOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const IconTimeDetails = !timeDetailOpen ? AddBoxRounded : IndeterminateCheckBox;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ListLabel
        title={"Processing time"}
        value={
          (
            (props.timeDetails.filter((t) => t.key === props.endTimeKey)[0].endTime -
              props.timeDetails.filter((t) => t.key === props.startTimeKey)[0].startTime) /
            1000000
          ).toFixed(3) + " s"
        }
      ></ListLabel>
      <Button size="small" onClick={() => setTimeDetailOpen(!timeDetailOpen)}>
        <IconTimeDetails />
      </Button>
      {timeDetailOpen && (
        <span className={classes.expandButtonWraper}>
          <ExpandButton
            expanded={expanded}
            contractTooltip="Contract table"
            expandTooltip="Expand table"
            label="Toggle table expansion"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setExpanded(!expanded);
            }}
          />
        </span>
      )}
      {timeDetailOpen && (
        <Paper>
          <TableContainer className={expanded ? classes.tableContainerExpanded : classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className={classes.tableCell}>Task</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.tableCell}>Duration(s)</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.tableCell}>Start</span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.tableCell}>Finish</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.timeDetails.map((t, i) => {
                  return <Row key={i} listID={i} startTime={t.startTime} endTime={t.endTime} keyName={t.key} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.expandButtonWraper}>
            <ExpandButton
              expanded={expanded}
              contractTooltip="Contract table"
              expandTooltip="Expand table"
              label="Toggle table expansion"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            />
          </div>
        </Paper>
      )}
    </div>
  );
};

interface RowProps {
  listID: number;
  startTime: number;
  endTime: number;
  keyName: string;
}

const Row: React.FC<RowProps> = (props) => {
  const classes = useStyles();

  const { listID, startTime, endTime, keyName } = props;

  const colorClasses = useTimeColor({ color: colors[keyName] });

  return (
    <TableRow className={listID % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd}>
      <TableCell className={colorClasses.coloredCell}>{keyParser(keyName)}</TableCell>
      <TableCell className={classes.tableCell}>{((endTime - startTime) / 1000000).toFixed(6)}</TableCell>
      <TableCell className={classes.tableCell}>
        <TimeLabel value={startTime} />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <TimeLabel value={endTime} />
      </TableCell>
    </TableRow>
  );
};
