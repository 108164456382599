import { ExpectedBrightness, ExpectedLocation, ExpectedResult } from "../../../models/log";
import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Button } from "../../Utils/Button";
import Divider from "@material-ui/core/Divider";
import { EnumRadioGroup } from "./EnumRadioGroup";
import { GlobalContext } from "../../../globalContext";
import MenuItem from "@material-ui/core/MenuItem";
import { PredictionLoader } from "./PredictionLoader";
import Select from "@material-ui/core/Select";
import { SelfieCaptureType } from "../../../models/config";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "325px",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "10% 32px 40px 32px",
    },
    selectStyle: {
      padding: "10px",
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: "14px",
      cursor: "pointer",
      width: "100%",
      margin: "auto",
    },
    title: {
      fontSize: "22px",
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: "17px",
      color: theme.palette.common.black,
      paddingTop: "20px",
      textAlign: "center",
      marginBottom: "10px",
    },
    select: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "10px",
      backgroundColor: theme.palette.common.black,
      padding: "2px 10px 2px 10px",
      color: theme.palette.common.white,
      fontSize: "18px",
      fontWeight: theme.typography.fontWeightRegular,
      "& svg": {
        color: theme.palette.common.white,
      },
    },
    radio: {
      display: "block",
      margin: "auto",
      width: "33%",
    },
    buttonGroup: {
      display: "flex",
      flexDirection: "column",
      "& button": {
        margin: "10px 0px",
      },
    },
  }),
);

export const DevPanel: React.FC = () => {
  const [userMediaSelected, setUserMediaSelected] = useState<MediaDeviceInfo>();
  const [userMedias, setUserMedias] = useState<MediaDeviceInfo[]>([]);
  const [expectedResult, setExpectedResult] = useState<ExpectedResult>();
  const [expectedLocation, setExpectedLocation] = useState<ExpectedLocation>();
  const [expectedBrightness, setexpectedBrightness] = useState<ExpectedBrightness>();

  const { panelConfigurationData } = React.useContext(GlobalContext);

  useEffect(() => {
    const videoDevices: MediaDeviceInfo[] = [];
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices.forEach((device) => {
        if (device.kind === "videoinput") {
          videoDevices.push(device);
        }
      });
      setUserMediaSelected(videoDevices[0]);
      setUserMedias(videoDevices);
    });
  }, []);

  const validateForm = (): boolean => {
    const expectedLocationRequired =
      panelConfigurationData.scanConfiguration.expectedLocationIsMandatory &&
      panelConfigurationData.scanConfiguration.showExpectedLocation;

    const expectedBrightnessRequired =
      panelConfigurationData.scanConfiguration.expectedBrightnessIsMandatory &&
      panelConfigurationData.scanConfiguration.showExpectedBrightness;

    return (!expectedLocationRequired || !!expectedLocation) && (!expectedBrightnessRequired || !!expectedBrightness);
  };

  const classes = useStyles();

  return (
    <div>
      {!expectedResult && (
        <div className={classes.root}>
          <div className={classes.title}>Select camera input</div>
          <Divider />
          {panelConfigurationData.scanConfiguration.selfieCaptureType === SelfieCaptureType.FaceCaptureModule ? (
            <Typography align="center" variant="subtitle1">
              Disabled when using Face Capture Module
            </Typography>
          ) : (
            <Select
              className={classes.select}
              value={userMediaSelected ? userMedias.indexOf(userMediaSelected) : 0}
              onChange={(e) => setUserMediaSelected(userMedias[(e.target.value as unknown) as number])}
            >
              {userMedias.map((userMedia, key) => (
                <MenuItem key={key} value={key}>
                  {userMedia.label || `video_${key}`}
                </MenuItem>
              ))}
            </Select>
          )}

          {(panelConfigurationData.scanConfiguration.showExpectedLocation ||
            panelConfigurationData.scanConfiguration.showExpectedBrightness) && (
            <>
              <div className={classes.title}>Extra configuration</div>
              <Divider />
            </>
          )}

          {panelConfigurationData.scanConfiguration.showExpectedLocation && (
            <EnumRadioGroup
              title={"Select location"}
              enumerate={ExpectedLocation}
              value={expectedLocation}
              onChange={(event) => setExpectedLocation(event.target.value as ExpectedLocation)}
            />
          )}

          {panelConfigurationData.scanConfiguration.showExpectedBrightness && (
            <EnumRadioGroup
              title={"Select brightness"}
              enumerate={ExpectedBrightness}
              value={expectedBrightness}
              onChange={(event) => setexpectedBrightness(event.target.value as ExpectedBrightness)}
            />
          )}

          <div className={classes.title}>Select input picture type</div>
          <Divider />
          <div className={classes.buttonGroup}>
            <Button
              onClick={() => setExpectedResult(ExpectedResult.Real)}
              variant="contained"
              disabled={!validateForm()}
              fullWidth
            >
              Real
            </Button>
            <Button
              onClick={() => setExpectedResult(ExpectedResult.DisplayAttack)}
              variant="contained"
              disabled={!validateForm()}
              fullWidth
            >
              Display attack
            </Button>
            <Button
              onClick={() => setExpectedResult(ExpectedResult.PrintAttack)}
              variant="contained"
              disabled={!validateForm()}
              fullWidth
            >
              Print attack
            </Button>
            <Button
              onClick={() => setExpectedResult(ExpectedResult.MaskAttack)}
              variant="contained"
              disabled={!validateForm()}
              fullWidth
            >
              Mask attack
            </Button>
          </div>
        </div>
      )}
      {userMediaSelected && expectedResult && (
        <PredictionLoader
          expectedResult={expectedResult}
          expectedLocation={expectedLocation}
          expectedBrightness={expectedBrightness}
          camera={userMediaSelected}
        />
      )}
    </div>
  );
};
