import React, { Fragment, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { IconButton as PaginationIconButton } from "./IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    normalPageIndex: {
      marginLeft: theme.spacing(0.1),
      marginRight: theme.spacing(0.1),
    },
    currentPageIndex: {
      fontWeight: "bold",
      fontSize: "40px",
    },
    textField: {
      maxWidth: "100px",
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const paginationStep = 10;

interface Props {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export function TablePaginationActions(props: Props) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage } = props;
  const pages = Math.ceil(count / rowsPerPage);
  const lastPage = Math.max(0, pages) - 1;
  const firstPageReached = page === 0;
  const lastPageReached = page >= pages - 1;

  const [customPage, setCustomPage] = useState<number>();

  return (
    <div className={classes.root}>
      <PaginationIconButton
        title={"Go to first page"}
        onClick={(e) => {
          onChangePage(e, 0);
        }}
        disabled={firstPageReached}
        ariaLabel="first page"
        rtlIcon={<LastPageIcon />}
        ltrIcon={<FirstPageIcon />}
      />
      <PaginationIconButton
        title={"Jump back " + paginationStep + " pages"}
        onClick={(e) => {
          onChangePage(e, page - paginationStep);
        }}
        disabled={page < paginationStep}
        ariaLabel="previous step pages"
        rtlIcon={<ArrowForwardIcon />}
        ltrIcon={<ArrowBackIcon />}
      />
      <PaginationIconButton
        title={"Go previous page"}
        onClick={(e) => {
          onChangePage(e, page - 1);
        }}
        disabled={firstPageReached}
        ariaLabel="previous page"
        rtlIcon={<KeyboardArrowRight />}
        ltrIcon={<KeyboardArrowLeft />}
      />
      <PageButtons page={page} lastPage={lastPage} onChangePage={onChangePage} />
      <PaginationIconButton
        title={"Go next page"}
        onClick={(e) => {
          onChangePage(e, page + 1);
        }}
        disabled={lastPageReached}
        ariaLabel="next page"
        rtlIcon={<KeyboardArrowLeft />}
        ltrIcon={<KeyboardArrowRight />}
      />
      <PaginationIconButton
        title={"Jump next " + paginationStep + " pages"}
        onClick={(e) => {
          onChangePage(e, page + paginationStep);
        }}
        disabled={page >= pages - paginationStep}
        ariaLabel="next step pages"
        rtlIcon={<ArrowBackIcon />}
        ltrIcon={<ArrowForwardIcon />}
      />
      <PaginationIconButton
        title={"Go to last page"}
        onClick={(e) => {
          onChangePage(e, lastPage);
        }}
        disabled={lastPageReached}
        ariaLabel="last page"
        rtlIcon={<FirstPageIcon />}
        ltrIcon={<LastPageIcon />}
      />
      <TextField
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        label="Go to"
        placeholder={"0-" + lastPage}
        type="number"
        value={customPage || ""}
        onChange={(e) => setCustomPage(e.currentTarget.value ? Number(e.currentTarget.value) : undefined)}
      ></TextField>
      <Tooltip title={"Go to imput page"}>
        <div>
          <IconButton
            onClick={(e) => {
              if (customPage) {
                onChangePage(e, customPage);
                setCustomPage(undefined);
              }
            }}
            disabled={!customPage || customPage < 0 || customPage > lastPage}
          >
            Go
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
}

interface PageButtonsProps {
  page: number;
  lastPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const PageButtons: React.FC<PageButtonsProps> = (props) => {
  const classes = useStyles();
  const { lastPage, page, onChangePage } = props;
  const pages = [page - 3, page - 2, page - 1, page, page + 1, page + 2, page + 3];

  return (
    <span>
      {pages.map((p) => (
        <Fragment key={p}>
          {p >= 0 && p <= lastPage && (
            <Tooltip title={"Go to page " + p}>
              <IconButton
                edge="start"
                className={classNames(classes.normalPageIndex, p === page ? classes.currentPageIndex : null)}
                onClick={(e) => {
                  onChangePage(e, p);
                }}
              >
                {p}
              </IconButton>
            </Tooltip>
          )}
        </Fragment>
      ))}
    </span>
  );
};
