import { Login, LoginType, YotiLogin } from "../../models/account";

import { NodeEnv } from "../../types/node";

export const callback = (query: URLSearchParams): Login => {
  const token = query.get("token") || "";

  return {
    type: LoginType.Yoti,
    data: { token },
  };
};

export const begin = (res: YotiLogin, env: NodeEnv) => {
  if (env === "production") {
    throw new Error("Yoti login does not implement begin method");
  }
  window.location.href = res.url;
};
