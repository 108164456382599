import { amber, blue, common, green, grey, red } from "@material-ui/core/colors";

import { createMuiTheme } from "@material-ui/core/styles";

export const theme = (createMuiTheme as any)({
  palette: {
    success: { light: green[100], main: green[500], dark: green[900] },
    error: { light: red[100], main: red[500], dark: red[700] },
    primary: {
      light: grey[300],
      main: grey[500],
      dark: grey[900],
      contrastText: grey[700],
    },
    warning: { light: amber[100], main: amber[500], dark: amber[900] },
    blue: { light: blue[100], main: blue[500], dark: blue[900] },
    common: { white: common.white, black: common.black },
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: 14,
    fontWeightLight: "lighter",
    body2: {
      fontSize: 16,
    },
    h6: {
      fontSize: 18,
    },
    subtitle1: {
      fontSize: 14,
      color: grey[600],
    },
  },
});
/* eslint-enable */

// Make the overrides after creating the theme to be able to access the
// variables.
theme.overrides = {
  MuiInput: {
    underline: {
      "&:after": {
        borderBottomColor: theme.palette.common.black,
      },
    },
    focused: {
      color: theme.palette.common.black + "! important",
    },
  },
};
