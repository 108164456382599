import { LogPrediction, ModelPredictionType } from "../models/log";
import { ModelType, getModelTypeName } from "../models/utils";

/**
 * Format the complete error in a short way
 * @param message
 */
export function liteErrorFormat(message: string): string {
  return message.split("::").slice(0, 2).join(": ");
}

/**
 * Returns the error reason from the error message. It is the latest segment in
 * 'str'. The token that joins all segments is '::'.
 * @param str
 */
export function latestErrorMsg(str: string): string {
  const messages = str.split("::");
  if (messages.length === 0) {
    return "";
  }
  return messages[messages.length - 1].trim();
}

/**
 * Format the complete error
 * @param message
 */
export function errorFormat(message: string): string {
  return message.replace(/::/g, ": ");
}

export const PREDICTION_ERROR_MESSAGE =
  "The following TensorFlow models that are included in the model formula got an error: ";

/**
 * Create an error message including all TensorFlow models
 * name that failed and take part on the model formula
 * @param modelPredictionList
 * @param modelFormula
 */
export function predictionErrorFormat(
  modelPredictionList: Record<ModelType, ModelPredictionType>,
  modelFormula: ModelType[],
): string {
  let message = "";
  const failedModels: ModelType[] = [];

  for (const key in modelPredictionList) {
    const modelType = (key as unknown) as ModelType;
    const modelResult = modelPredictionList[modelType] as LogPrediction;
    //Add models that had failed and part on the prediction formula
    if (modelResult.error && modelFormula.indexOf(modelType) > 0) {
      failedModels.push(modelType);
    }

    message = PREDICTION_ERROR_MESSAGE;

    //Concat all model names
    message = message.concat(
      failedModels
        .map((m) => {
          return getModelTypeName(m);
        })
        .join(", ")
        .replace(/,$/, ""),
    );
  }
  return message;
}
