import JSZip from "jszip";
import { saveAs } from "@progress/kendo-file-saver";

/**
 * Create a new Blob entity from a base64 string provided.
 * @param b64Data The base64 string.
 * @param contentType The content type for base64 content provided
 * @param sliceSize Content size the method will use to transform the base64
 * data.
 */
export const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
  // Check the b64 data contains the content type and use it in case the content
  // type provided is empty.
  const segments = b64Data.split(",");
  b64Data = segments.length > 1 ? segments[1] : segments[0];
  if (!contentType && segments.length > 1) {
    contentType = segments[0];
  }

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export interface FileContent {
  name: string;
  content: Blob;
}

export const createAndDownloadZip = (zipName: string, files: FileContent[]) => {
  const zip = new JSZip();
  files.forEach((f) => zip.file(f.name, f.content));

  // Create zip and download it.
  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, zipName);
  });
};
