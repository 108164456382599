import { CancelRounded, CheckCircleRounded, HelpRounded, SvgIconComponent } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { PredictionColor } from "./PredictionColor";
import { PredictionFormulaResult } from "../../models/prediction";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    icon: ({ fontSize }: Props) => ({
      marginRight: "0.2em",
      fontSize: fontSize,
    }),
  }),
);

interface Props {
  prediction: PredictionFormulaResult;
  fontSize: number;
  className?: string;
}

const iconResults: { [key: string]: SvgIconComponent } = {};
iconResults[PredictionFormulaResult.Real] = CheckCircleRounded;
iconResults[PredictionFormulaResult.Fake] = CancelRounded;
iconResults[PredictionFormulaResult.Error] = CancelRounded;
iconResults[PredictionFormulaResult.Undetermined] = HelpRounded;

export const PredictionIcon: React.FC<Props> = (props) => {
  const { prediction, className } = props;

  const classes = useStyles(props);
  const Icon = iconResults[prediction];

  return (
    <PredictionColor prediction={prediction} className={className}>
      <Icon className={classes.icon} />
    </PredictionColor>
  );
};
