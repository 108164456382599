import { Grid, Switch, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import { OverlaySize, ScanConfiguration, SelfieCaptureType } from "../../models/config";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { ConfigurationProps } from "./ConfigurationPanel";
import ConfigurationSection from "./ConfigurationSection";
import ConfigurationSubsection from "./ConfigurationSubsection";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ImageExtensionFormat } from "../../models/image";
import { PercentageOfCropping } from "./PercentageOfCropping";
import { PictureToSendForm } from "./PictureToSendForm";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import { SliderBar } from "../Utils/SliderBar";
import { SwitchElement } from "../Utils/SwitchElement";
import Typography from "@material-ui/core/Typography";

const SELFIE_CAPTURE_SECTION_DESCRIPTION =
  "Configuration related to the scan tools used to capture the image to use as input for the precition";
const IMAGE_SECTION_DESCRIPTION = "Configuration related to image properties";
const LABELS_SECTION_DESCRIPTION = "Dinamic values for labels along scan flow";
const CROPPING_SECTION_DESCRIPTION =
  "Cropping rules applied to the image captured before sending the image to the AI Services";
const GROUND_TRUTH_SECTION_DESCRIPTION =
  "Information used by AI Services models to check the accuracy of the prediction against the real world";

enum ScanSection {
  SelfieCapture = "Selfie Capture",
  Image = "Image",
  Labels = "Labels",
  Cropping = "Cropping",
  GroundTruth = "Ground truth",
}

// Image
const resolutions: string[] = ["1280x720", "1920x1080"];

// Ground truth
interface SwitchField {
  title: string;
  field: keyof ScanConfiguration;
  disabled: boolean;
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SwitchElements {
  name: string;
  fields: Array<SwitchField>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: { fontSize: "24px" },
    section: {
      marginBottom: "60px",
    },
    radioGroup: {
      marginTop: "16px",
    },
    scanDelaySlider: {
      [theme.breakpoints.up(395)]: {
        marginLeft: 20,
      },
      textAlign: "center",
      display: "block",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    scanDelaySliderTitle: {
      fontSize: "18px",
      textAlign: "left",
    },
    radioButtonGroup: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: { flexDirection: "row" },
    },
    scanDelaySlidersContainer: {
      marginTop: "20px",
      [theme.breakpoints.up("lg")]: {
        width: "40%",
      },
    },
    faceCaptureModuleLabel: {
      [theme.breakpoints.up("sm")]: {
        textAlign: "center",
      },
    },
    sectionSubtitle: { fontSize: "18px" },
    radio: {
      display: "block",
    },
    textField: {
      color: theme.palette.primary.main + "!important",
    },
    configElement: {
      marginTop: "16px",
    },
    tinyFaceDetectorThreshold: {
      width: "50%",
    },
    imageCompressionSlider: {
      [theme.breakpoints.up("md")]: {
        width: "50%",
        display: "flex",
        flexDirection: "column",
      },
    },
    imageFormat: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  }),
);

export const ScanPanel: React.FC<ConfigurationProps<ScanConfiguration>> = (props) => {
  const classes = useStyles();

  const { isAdmin, onConfigurationChange, config } = props;
  const section = config.scanConfiguration;

  const theme = useTheme();
  const mobileWidth = useMediaQuery(theme.breakpoints.down("xs"));
  const radioButtonLabelPlacement = mobileWidth ? "end" : "top";

  // Image
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resolution = event.target.value;
    const resolutionSplitted = resolution.split("x", 2);
    onConfigurationChange({
      ...section,
      pictureResolutionWidth: Number(resolutionSplitted[0]),
      pictureResolutionHeight: Number(resolutionSplitted[1]),
    });
  };

  const setPanelConfigurationSlider = (value: number, name: keyof ScanConfiguration) => {
    if (!name) return;
    onConfigurationChange({
      ...section,
      [name]: value,
    });
  };

  // Ground truth
  const groundTruthItems: Array<SwitchElements> = [
    {
      name: "Expected location",
      fields: [
        {
          title: "Show on dev panel",
          field: "showExpectedLocation",
          disabled: !isAdmin,
        },
        {
          title: "Make mandatory",
          field: "expectedLocationIsMandatory",
          disabled: !isAdmin || !section.showExpectedLocation,
        },
      ],
    },
    {
      name: "Expected brightness",
      fields: [
        {
          title: "Show on dev panel",
          field: "showExpectedBrightness",
          disabled: !isAdmin,
        },
        {
          title: "Make mandatory",
          field: "expectedBrightnessIsMandatory",
          disabled: !isAdmin || !section.showExpectedBrightness,
        },
      ],
    },
  ];

  const changeConfiguration = (fieldName: keyof ScanConfiguration, checked: boolean) => {
    onConfigurationChange({ ...section, [fieldName]: checked });
  };

  const TitleSizeOverlayMap = new Map<string, string>([
    [OverlaySize.XS, "Extra Small"],
    [OverlaySize.SM, "Small"],
    [OverlaySize.MD, "Medium"],
    [OverlaySize.LG, "Large"],
    [OverlaySize.XL, "Extra Large"],
  ]);

  return (
    <div>
      <ConfigurationSection title={ScanSection.SelfieCapture} description={SELFIE_CAPTURE_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Selfie capture type">
          <div className={classes.radioGroup}>
            <RadioGroup
              value={section.selfieCaptureType}
              onChange={(e) =>
                onConfigurationChange({
                  ...section,
                  selfieCaptureType: Number(e.target.value),
                })
              }
            >
              <div className={classes.radioButtonGroup}>
                <FormControlLabel
                  value={SelfieCaptureType.Scan}
                  control={<Radio color="primary" />}
                  label="Scan"
                  labelPlacement={radioButtonLabelPlacement}
                  disabled={!isAdmin}
                />
                <FormControlLabel
                  value={SelfieCaptureType.Button}
                  control={<Radio color="primary" />}
                  label="Button"
                  labelPlacement={radioButtonLabelPlacement}
                  disabled={!isAdmin}
                />
                <FormControlLabel
                  value={SelfieCaptureType.FaceCaptureModule}
                  className={classes.faceCaptureModuleLabel}
                  control={<Radio color="primary" />}
                  label="Face Capture Module"
                  labelPlacement={radioButtonLabelPlacement}
                  disabled={!isAdmin}
                />
                <FormControlLabel
                  value={SelfieCaptureType.AIClientModule}
                  className={classes.faceCaptureModuleLabel}
                  control={<Radio color="primary" />}
                  label="AI Client Module"
                  labelPlacement={radioButtonLabelPlacement}
                  disabled={!isAdmin}
                />
              </div>
            </RadioGroup>
          </div>
        </ConfigurationSubsection>

        {section.selfieCaptureType === SelfieCaptureType.Scan && (
          <ConfigurationSubsection title="Scan type configuration">
            <Grid container spacing={10}>
              <Grid item md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Scan delay photos number
                </Typography>
                <SliderBar
                  min={1}
                  max={10}
                  step={1}
                  value={section.scanDelayNumber}
                  onChange={(v) => setPanelConfigurationSlider(v, "scanDelayNumber")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Scan delay time (s)
                </Typography>
                <SliderBar
                  min={0.1}
                  max={1}
                  step={0.1}
                  value={section.scanDelayTime}
                  onChange={(v) => setPanelConfigurationSlider(v, "scanDelayTime")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Detection sensibility
                </Typography>
                <SliderBar
                  min={0.1}
                  max={1}
                  step={0.01}
                  value={section.tinyFaceDetectionSensibility}
                  onChange={(v) => setPanelConfigurationSlider(v, "tinyFaceDetectionSensibility")}
                  disabled={!isAdmin}
                />
              </Grid>
            </Grid>
          </ConfigurationSubsection>
        )}
        {(section.selfieCaptureType === SelfieCaptureType.FaceCaptureModule ||
          section.selfieCaptureType === SelfieCaptureType.AIClientModule) && (
          <div className={classes.section}>
            <ConfigurationSubsection title="FCM preferences">
              <Grid item md={6}>
                <SwitchElement
                  title="Debug mode"
                  tooltipMessage="Display useful debug information over the camera stream"
                  variant="h6"
                  checked={section.faceCaptureModuleDebug}
                  onChange={(checked: boolean) =>
                    onConfigurationChange({
                      ...section,
                      faceCaptureModuleDebug: checked,
                    })
                  }
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item md={6}>
                <SwitchElement
                  title="Show overlay"
                  tooltipMessage="Show a face overlay over the camera stream"
                  variant="h6"
                  checked={section.faceCaptureModuleShowOverlay}
                  onChange={(checked: boolean) =>
                    onConfigurationChange({
                      ...section,
                      faceCaptureModuleShowOverlay: checked,
                    })
                  }
                  disabled={!isAdmin}
                />
              </Grid>
              {section.faceCaptureModuleShowOverlay && (
                <>
                  <div>
                    <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                      Overlay Size Portrait
                    </Typography>
                    <div className={classes.radioGroup}>
                      <RadioGroup
                        value={section.faceCaptureModuleOverlaySize}
                        onChange={(e) =>
                          onConfigurationChange({
                            ...section,
                            faceCaptureModuleOverlaySize: e.target.value,
                          })
                        }
                      >
                        <div className={classes.radioButtonGroup}>
                          {Object.values(OverlaySize).map((field) => (
                            <FormControlLabel
                              value={field}
                              control={<Radio color="primary" />}
                              label={TitleSizeOverlayMap.get(field)}
                              labelPlacement={radioButtonLabelPlacement}
                              disabled={!isAdmin}
                              key={field}
                            />
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                  <div>
                    <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                      Overlay Size Landscape
                    </Typography>
                    <div className={classes.radioGroup}>
                      <RadioGroup
                        value={section.faceCaptureModuleOverlaySizeLandscape}
                        onChange={(e) =>
                          onConfigurationChange({
                            ...section,
                            faceCaptureModuleOverlaySizeLandscape: e.target.value,
                          })
                        }
                      >
                        <div className={classes.radioButtonGroup}>
                          {Object.values(OverlaySize).map((field) => (
                            <FormControlLabel
                              value={field}
                              control={<Radio color="primary" />}
                              label={TitleSizeOverlayMap.get(field)}
                              labelPlacement={radioButtonLabelPlacement}
                              disabled={!isAdmin}
                              key={field}
                            />
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                </>
              )}
            </ConfigurationSubsection>
            <Typography color="textSecondary" gutterBottom className={classes.sectionTitle}>
              {(section.selfieCaptureType === SelfieCaptureType.FaceCaptureModule ? "FCM " : "AI Client Module ") +
                " type preferences"}
            </Typography>
            <Divider />
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Initial Autocapture Delay (milliseconds)
                </Typography>

                <SliderBar
                  min={0}
                  max={2000}
                  step={10}
                  value={section.faceCaptureModuleInitialAutocaptureDelay}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleInitialAutocaptureDelay")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Autocapture Delay (milliseconds)
                </Typography>
                <SliderBar
                  min={10}
                  max={1000}
                  step={10}
                  value={section.faceCaptureModuleAutocaptureDelay}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleAutocaptureDelay")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Face Size Deviation Portrait
                </Typography>
                <SliderBar
                  min={0}
                  max={0.6}
                  step={0.01}
                  value={section.faceCaptureModuleFaceSizeDeviation}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleFaceSizeDeviation")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Face Size Deviation Landscape
                </Typography>
                <SliderBar
                  min={0}
                  max={0.6}
                  step={0.01}
                  value={section.faceCaptureModuleFaceSizeDeviationLandscape}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleFaceSizeDeviationLandscape")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Min Bounding Box Size Portrait
                </Typography>
                <SliderBar
                  min={100}
                  max={1000}
                  step={1}
                  value={section.faceCaptureModuleMinBBoxSize}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleMinBBoxSize")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Min Bounding Box Size Landscape
                </Typography>
                <SliderBar
                  min={100}
                  max={1000}
                  step={1}
                  value={section.faceCaptureModuleMinBBoxSizeLandscape}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleMinBBoxSizeLandscape")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Ideal BBox Relative Size Portrait
                </Typography>
                <SliderBar
                  min={0}
                  max={1}
                  step={0.01}
                  value={section.faceCaptureModuleIdealBBoxRelativeSize}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleIdealBBoxRelativeSize")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Ideal BBox Relative Size Landscape
                </Typography>
                <SliderBar
                  min={0}
                  max={1}
                  step={0.01}
                  value={section.faceCaptureModuleIdealBBoxRelativeSizeLandscape}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleIdealBBoxRelativeSizeLandscape")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Allowed Center Relative Deviation
                </Typography>
                <SliderBar
                  min={0.0}
                  max={0.6}
                  step={0.01}
                  value={section.faceCaptureModuleCenterDeviation}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleCenterDeviation")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Max Bounding Box Relative Size (PORTRAIT)
                </Typography>
                <SliderBar
                  min={0.01}
                  max={0.99}
                  step={0.01}
                  value={section.faceCaptureModuleMaxBBoxRelativeSizePortrait}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleMaxBBoxRelativeSizePortrait")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Max Bounding Box Relative Size (LANDSCAPE)
                </Typography>
                <SliderBar
                  min={0.01}
                  max={0.99}
                  step={0.01}
                  value={section.faceCaptureModuleMaxBBoxRelativeSizeLandscape}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleMaxBBoxRelativeSizeLandscape")}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                  Tiny Face Detector Score Threshold
                </Typography>
                <SliderBar
                  min={0.0}
                  max={0.99}
                  step={0.01}
                  value={section.faceCaptureModuleScoreThreshold}
                  onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleScoreThreshold")}
                  disabled={!isAdmin}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {section.selfieCaptureType === SelfieCaptureType.FaceCaptureModule && (
          <div className={classes.section}>
            <Typography color="textSecondary" gutterBottom className={classes.sectionTitle}>
              Brightness
            </Typography>
            <Divider />
            <ConfigurationSubsection title="Brightness preferences">
              <Grid item md={6}>
                <SwitchElement
                  title="Use Brightness Method"
                  tooltipMessage="Use the brightness method in order to make the validations"
                  variant="h6"
                  checked={section.faceCaptureModuleUseBrightnessMethod}
                  onChange={(checked: boolean) =>
                    onConfigurationChange({
                      ...section,
                      faceCaptureModuleUseBrightnessMethod: checked,
                    })
                  }
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item md={6}>
                <SwitchElement
                  title="Use Brightness Mask Method"
                  tooltipMessage="Use the brightness mask method in order to make the validations"
                  variant="h6"
                  checked={section.faceCaptureModuleUseBrightnessMaskMethod}
                  onChange={(checked: boolean) =>
                    onConfigurationChange({
                      ...section,
                      faceCaptureModuleUseBrightnessMaskMethod: checked,
                    })
                  }
                  disabled={!isAdmin}
                />
              </Grid>
            </ConfigurationSubsection>
            <Grid container spacing={10}>
              {section.faceCaptureModuleUseBrightnessMethod && (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                      Too dark threshold (Brightness method)
                    </Typography>
                    <SliderBar
                      min={0}
                      max={255}
                      step={1}
                      value={section.faceCaptureModuleTooDarkThreshold}
                      onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleTooDarkThreshold")}
                      disabled={!isAdmin}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                      Too bright threshold (Brightness method)
                    </Typography>
                    <SliderBar
                      min={0}
                      max={255}
                      step={1}
                      value={section.faceCaptureModuleTooBrightThreshold}
                      onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleTooBrightThreshold")}
                      disabled={!isAdmin}
                    />
                  </Grid>
                </>
              )}
              {section.faceCaptureModuleUseBrightnessMaskMethod && (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                      Too dark threshold (Brightness Mask method)
                    </Typography>
                    <SliderBar
                      min={0}
                      max={255}
                      step={1}
                      value={section.faceCaptureModuleTooDarkThresholdMask}
                      onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleTooDarkThresholdMask")}
                      disabled={!isAdmin}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                      Too bright threshold (Brightness Mask method)
                    </Typography>
                    <SliderBar
                      min={0}
                      max={255}
                      step={1}
                      value={section.faceCaptureModuleTooBrightThresholdMask}
                      onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleTooBrightThresholdMask")}
                      disabled={!isAdmin}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                      Lower Mask Threshold
                    </Typography>
                    <SliderBar
                      min={0}
                      max={255}
                      step={1}
                      value={section.faceCaptureModuleLowerMaskThreshold}
                      onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleLowerMaskThreshold")}
                      disabled={!isAdmin}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                      Upper Mask Threshold
                    </Typography>
                    <SliderBar
                      min={0}
                      max={255}
                      step={1}
                      value={section.faceCaptureModuleUpperMaskThreshold}
                      onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleUpperMaskThreshold")}
                      disabled={!isAdmin}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                      Lower Mask Acceptance Threshold
                    </Typography>
                    <SliderBar
                      min={0}
                      max={1}
                      step={0.1}
                      value={section.faceCaptureModuleLowerMaskAcceptanceThreshold}
                      onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleLowerMaskAcceptanceThreshold")}
                      disabled={!isAdmin}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                      Upper Mask Acceptance Threshold
                    </Typography>
                    <SliderBar
                      min={0}
                      max={1}
                      step={0.1}
                      value={section.faceCaptureModuleUpperMaskAcceptanceThreshold}
                      onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleUpperMaskAcceptanceThreshold")}
                      disabled={!isAdmin}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        )}
        {section.selfieCaptureType === SelfieCaptureType.AIClientModule && (
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.scanDelaySliderTitle}>
                AI Client Module Delay (milliseconds)
              </Typography>
              <SliderBar
                min={10}
                max={1000}
                step={10}
                value={section.aiClientModuleDelay}
                onChange={(v) => setPanelConfigurationSlider(v, "aiClientModuleDelay")}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SwitchElement
                title="Calculate laplacian"
                tooltipMessage="Calculate and store laplacian variance. (High CPU cost)"
                variant="h6"
                checked={section.aiClientLaplacianEnabled}
                onChange={(checked: boolean) =>
                  onConfigurationChange({
                    ...section,
                    aiClientLaplacianEnabled: checked,
                  })
                }
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="AI Session Manager URL"
                value={section.aiSessionManagerURL}
                onChange={(e) =>
                  onConfigurationChange({
                    ...section,
                    aiSessionManagerURL: e.target.value,
                  })
                }
                margin="normal"
                fullWidth
                disabled={!isAdmin}
              />
            </Grid>
          </Grid>
        )}
        {section.selfieCaptureType !== SelfieCaptureType.FaceCaptureModule &&
          section.selfieCaptureType !== SelfieCaptureType.AIClientModule && (
            <ConfigurationSubsection title="Tiny Face Detector Threshold">
              <div className={classes.tinyFaceDetectorThreshold}>
                <SliderBar
                  min={0.01}
                  max={0.99}
                  step={0.01}
                  value={section.tinyFaceDetectorThreshold}
                  onChange={(v) => setPanelConfigurationSlider(v, "tinyFaceDetectorThreshold")}
                  disabled={!isAdmin}
                />
              </div>
            </ConfigurationSubsection>
          )}
      </ConfigurationSection>

      <ConfigurationSection title={ScanSection.Image} description={IMAGE_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Image size (KiB)">
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.sectionSubtitle}>
                Minimum
              </Typography>
              <SliderBar
                min={10}
                max={1000}
                step={10}
                value={section.minImageSize}
                onChange={(v) => setPanelConfigurationSlider(v, "minImageSize")}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.sectionSubtitle}>
                Maximum
              </Typography>
              <SliderBar
                min={500}
                max={10000}
                step={100}
                value={section.maxImageSize}
                onChange={(v) => setPanelConfigurationSlider(v, "maxImageSize")}
                disabled={!isAdmin}
              />
            </Grid>
          </Grid>
        </ConfigurationSubsection>
        <ConfigurationSubsection title="Image resolution">
          <div className={classes.radioGroup}>
            <RadioGroup
              value={String(section.pictureResolutionWidth) + "x" + String(section.pictureResolutionHeight)}
              onChange={handleChange}
              row
            >
              {resolutions.map((resolution) => {
                return (
                  <FormControlLabel
                    key={resolution}
                    value={resolution}
                    control={<Radio color="primary" />}
                    label={resolution}
                    labelPlacement="top"
                    disabled={!isAdmin}
                    className={classes.radio}
                  />
                );
              })}
            </RadioGroup>
          </div>
        </ConfigurationSubsection>
        <ConfigurationSubsection title="Image format">
          <div className={classes.radioGroup}>
            <RadioGroup
              value={section.imageExtensionFormat}
              onChange={(e) =>
                onConfigurationChange({
                  ...section,
                  imageExtensionFormat: e.target.value as ImageExtensionFormat,
                })
              }
              row
            >
              <div className={classes.imageFormat}>
                <div>
                  <FormControlLabel
                    value={ImageExtensionFormat.PNG}
                    control={<Radio color="primary" />}
                    label={ImageExtensionFormat.PNG.toUpperCase()}
                    labelPlacement="top"
                    disabled={!isAdmin}
                  />
                  <FormControlLabel
                    value={ImageExtensionFormat.JPEG}
                    control={<Radio color="primary" />}
                    label={ImageExtensionFormat.JPEG.toUpperCase()}
                    labelPlacement="top"
                    disabled={!isAdmin}
                  />
                </div>
                {section.imageExtensionFormat === ImageExtensionFormat.JPEG && (
                  <div className={classes.imageCompressionSlider}>
                    <Typography color="textSecondary" gutterBottom>
                      Image compression
                    </Typography>
                    <SliderBar
                      min={0}
                      max={1}
                      step={0.01}
                      value={section.imageJPEGCompression}
                      onChange={(v) => setPanelConfigurationSlider(v, "imageJPEGCompression")}
                      disabled={!isAdmin}
                    />
                  </div>
                )}
              </div>
            </RadioGroup>
          </div>
        </ConfigurationSubsection>
      </ConfigurationSection>

      <ConfigurationSection title={ScanSection.Labels} description={LABELS_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Preferences">
          <Grid container>
            <Grid item xs={12}>
              <TextField
                label="Start button label"
                value={section.startButtonLabel}
                onChange={(e) =>
                  onConfigurationChange({
                    ...section,
                    startButtonLabel: e.target.value,
                  })
                }
                margin="normal"
                className={classes.textField}
                disabled={!isAdmin || !section.showStartButton}
                InputProps={{
                  endAdornment: (
                    <Switch
                      checked={section.showStartButton}
                      onChange={(e, checked) =>
                        onConfigurationChange({
                          ...section,
                          showStartButton: checked,
                        })
                      }
                      color="primary"
                      disabled={!isAdmin}
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Dev button label"
                value={section.devButtonLabel}
                onChange={(e) =>
                  onConfigurationChange({
                    ...section,
                    devButtonLabel: e.target.value,
                  })
                }
                margin="normal"
                className={classes.textField}
                disabled={!isAdmin || !section.showDevButton}
                InputProps={{
                  endAdornment: (
                    <Switch
                      checked={section.showDevButton}
                      onChange={(e, checked) =>
                        onConfigurationChange({
                          ...section,
                          showDevButton: checked,
                        })
                      }
                      color="primary"
                      disabled={!isAdmin}
                    />
                  ),
                }}
              />
            </Grid>
          </Grid>
        </ConfigurationSubsection>
      </ConfigurationSection>

      <ConfigurationSection title={ScanSection.Cropping} description={CROPPING_SECTION_DESCRIPTION}>
        <ConfigurationSubsection title="Picture to send">
          <div className={classes.radioGroup}>
            <PictureToSendForm
              disabled={!isAdmin}
              selfieCaptureType={config.scanConfiguration.selfieCaptureType}
              value={section.selectedInputPicture}
              onChange={(value) => {
                onConfigurationChange({ ...section, selectedInputPicture: value });
              }}
            />
          </div>
        </ConfigurationSubsection>
        <ConfigurationSubsection title="Number of pixels">
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.sectionSubtitle}>
                Width
              </Typography>
              <SliderBar
                min={100}
                max={300}
                step={1}
                value={section.numberPixelsWidth}
                onChange={(v) => setPanelConfigurationSlider(v, "numberPixelsWidth")}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.sectionSubtitle}>
                Height
              </Typography>
              <SliderBar
                min={100}
                max={300}
                step={1}
                value={section.numberPixelsHeight}
                onChange={(v) => setPanelConfigurationSlider(v, "numberPixelsHeight")}
                disabled={!isAdmin}
              />
            </Grid>
          </Grid>
        </ConfigurationSubsection>
        <ConfigurationSubsection title="Percentage of face over the image size">
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.sectionSubtitle}>
                Minimum face size
              </Typography>
              <SliderBar
                min={0.01}
                max={0.49}
                step={0.01}
                value={section.minPercentageFaceOverImage}
                onChange={(v) => setPanelConfigurationSlider(v, "minPercentageFaceOverImage")}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" gutterBottom className={classes.sectionSubtitle}>
                Maximum face size
              </Typography>
              <SliderBar
                min={0.5}
                max={0.99}
                step={0.01}
                value={section.maxPercentageFaceOverImage}
                onChange={(v) => setPanelConfigurationSlider(v, "maxPercentageFaceOverImage")}
                disabled={!isAdmin}
              />
            </Grid>
          </Grid>
        </ConfigurationSubsection>
        <PercentageOfCropping
          disabled={!isAdmin}
          cfg={section}
          onConfigurationChange={onConfigurationChange}
          selfieCaptureType={config.scanConfiguration.selfieCaptureType}
        />
      </ConfigurationSection>

      <ConfigurationSection title={ScanSection.GroundTruth} description={GROUND_TRUTH_SECTION_DESCRIPTION}>
        {groundTruthItems.map((e) => (
          <ConfigurationSubsection title={e.name} key={e.name}>
            {e.fields.map((f) => (
              <div className={classes.configElement} key={e.name + f.title}>
                <SwitchElement
                  title={f.title}
                  checked={section[f.field] as boolean}
                  onChange={(checked) => changeConfiguration(f.field, checked)}
                  disabled={f.disabled}
                />
              </div>
            ))}
          </ConfigurationSubsection>
        ))}
      </ConfigurationSection>
    </div>
  );
};
