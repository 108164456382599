import { CancelRounded, CheckCircleRounded } from "@material-ui/icons";
import {
  Checkbox,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Role, RoleCode } from "../../models/role";

import Config from "../../config";
import { EditUserDialog } from "./UserDialog";
import { GlobalContext } from "../../globalContext";
import Moment from "moment";
import { TablePaginationActions } from "./../Utils/TablePaginationActions";
import { User } from "../../models/user";
import { UserManagementTableToolbar } from "./UserManagementTableToolbar";
import { UserService } from "./../../api/user";
import clone from "clone";

const userService = new UserService(Config.apiUrl);

const initialUser: User = {
  banned: false,
  email: "",
  id: 0,
  roleId: 0,
  signUpDate: 0,
  verified: false,
  logsNumber: 0,
  requestsPerDay: 0,
  requestsInTotal: 0,
  saveImages: true,
  saveImagesScanTool: true,
  saveImagesFaceMatchingTool: true,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 50,
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    iconSuccess: {
      color: theme.palette.success.main,
    },
    iconError: {
      color: theme.palette.error.main,
    },
    tableCellHeader: {
      lineHeight: "1.2 !important",
    },
    pagination: {
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
      margin: "10px 0px",
    },
    tableContainer: {
      [theme.breakpoints.down("sm")]: {
        maxHeight: "55vh",
      },
      maxHeight: "65vh",
    },
  }),
);

interface PageInfo {
  page: number;
  rowsPerPage: number;
  email?: string;
}

export const UserManagement: React.FC = () => {
  const classes = useStyles();

  const [users, setUsers] = useState<User[]>([]);
  const [userSelected, setUserSelected] = useState<User>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [usersSelected, setUsersSelected] = useState<number[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  // pagination
  const [rowCount, setRowCount] = React.useState(0);
  const [pageInfo, setPageInfo] = React.useState<PageInfo>({ page: 0, rowsPerPage: 10 });
  const pageInfoDep = JSON.stringify(pageInfo);

  const { setFeedback, envSettings } = React.useContext(GlobalContext);

  initialUser.requestsPerDayLimit = envSettings.userCreationConfig.dailyMaxRequests;
  initialUser.requestsInTotalLimit = envSettings.userCreationConfig.totalMaxRequests;
  initialUser.expirationHours = envSettings.userCreationConfig.picturesExpirationHours;

  const getUsers = useCallback(() => {
    const pageInfo = JSON.parse(pageInfoDep) as PageInfo;
    userService
      .getAll(pageInfo.page, pageInfo.rowsPerPage, pageInfo.email)
      .then((res) => {
        setRowCount(res.count);
        setUsers(res.users);
      })
      .catch((e) =>
        setFeedback({
          variant: "error",
          message: `Could not get users from database: ${e.message}`,
        }),
      );
  }, [pageInfoDep, setFeedback]);

  useEffect(() => {
    getUsers();

    userService
      .getRoles()
      .then((res) => setRoles(res))
      .catch((e) =>
        setFeedback({
          variant: "error",
          message: `Could not get roles from database: ${e.message}`,
        }),
      );
  }, [setFeedback, getUsers]);

  const clickRow = (id: number) => {
    setUserSelected(users.find((u) => u.id === id));
    setDialogOpen(true);
  };

  const saveUser = (user: User) => {
    setDialogOpen(false);
    const isNewUser = user.id === 0;
    const method = isNewUser ? userService.create : userService.update;
    method(user)
      .then((res) => {
        // Get the users page in case a new user was included.
        if (isNewUser) {
          getUsers();
        } else {
          setUsers(
            users.map((u) => {
              return res.id === u.id ? res : u;
            }),
          );
        }
        setFeedback({
          variant: "success",
          message: `User with ID ${res.id} updated successfully!`,
        });
      })
      .catch((e) =>
        setFeedback({
          variant: "error",
          message: `Could not update user with ID ${user.id}: ${e.message}`,
        }),
      );
  };

  const updateUserBatch = (users: User[]) => {
    userService
      .batchUpdate(users)
      .then(() => {
        getUsers();
        setFeedback({
          variant: "success",
          message: `Users updated successfully!`,
        });
      })
      .catch((e) =>
        setFeedback({
          variant: "error",
          message: `Could not update uses: ${e.message}`,
        }),
      );
  };

  const bulkVerify = () => {
    const usersToUpdate = users.filter((u) => usersSelected.includes(u.id));
    usersToUpdate.forEach((u) => (u.verified = !u.verified));
    updateUserBatch(usersToUpdate);
  };

  const bulkBan = () => {
    const usersToUpdate = users.filter((u) => usersSelected.includes(u.id));
    usersToUpdate.forEach((u) => (u.banned = !u.banned));
    updateUserBatch(usersToUpdate);
  };

  // TODO: AITOOL-1055: Refactor PredictionColor and PredictionIcon from /Utils to be used
  // in different contexts like here
  const getIcon = (value: boolean) => {
    if (value) {
      return <CheckCircleRounded className={classes.iconSuccess} />;
    }
    return <CancelRounded className={classes.iconError} />;
  };

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setPageInfo({ ...pageInfo, rowsPerPage: +event.target.value, page: 0 });
  }

  const emailChange = useCallback((email?: string) => {
    setPageInfo((previous) => ({ ...previous, email: email, page: 0 }));
  }, []);

  const showCreateUserDialog = useCallback(() => {
    setDialogOpen(true);
    const userRoleId: number = roles.find((r) => r.code === RoleCode.User)?.id || 0;
    setUserSelected({ ...clone(initialUser), roleId: userRoleId });
  }, [roles]);

  return (
    <div className={classes.root}>
      <Paper elevation={5}>
        <UserManagementTableToolbar
          usersSelected={usersSelected}
          onVerify={bulkVerify}
          onBan={bulkBan}
          onEmailChange={emailChange}
          onCreateClick={showCreateUserDialog}
        />
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    onChange={(e) => {
                      setUsersSelected(e.target.checked ? users.map((u) => u.id) : []);
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCellHeader}>ID</TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Email
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Verified
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Role
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Save images
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Save images (Scan Tool)
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Save images (Face Matching Tool)
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Sign-up date
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Latest log-in
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Number of logs
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Banned
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Daily requests (used/max)
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Total requests (used/max)
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Expiration (h)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow hover key={user.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={usersSelected.find((i) => user.id === i) !== undefined}
                      onChange={(e) => {
                        e.target.checked
                          ? setUsersSelected([...usersSelected, user.id])
                          : setUsersSelected(usersSelected.filter((i) => i !== user.id));
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" onClick={() => clickRow(user.id)}>
                    {user.id}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {user.email}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {getIcon(user.verified)}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {roles.find((r) => r.id === user.roleId)?.name}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {getIcon(user.saveImages)}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {getIcon(user.saveImagesScanTool)}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {getIcon(user.saveImagesFaceMatchingTool)}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {Moment.unix(user.signUpDate / 1000000000).format("lll")}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {user.latestLogin ? Moment.unix(user.latestLogin / 1000000000).format("lll") : "-"}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {user.logsNumber}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {getIcon(user.banned)}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {user.requestsPerDayLimit
                      ? `${user.requestsPerDay}/${user.requestsPerDayLimit}`
                      : user.requestsPerDay}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {user.requestsInTotalLimit
                      ? `${user.requestsInTotal}/${user.requestsInTotalLimit}`
                      : user.requestsInTotal}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(user.id)}>
                    {user.expirationHours ? user.expirationHours : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={rowCount}
          rowsPerPage={pageInfo.rowsPerPage}
          page={pageInfo.page}
          onChangePage={(_, p) => setPageInfo({ ...pageInfo, page: p })}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>

      {dialogOpen && userSelected && (
        <EditUserDialog
          initialUser={userSelected}
          open={dialogOpen}
          roles={roles}
          handleClose={() => setDialogOpen(false)}
          onSubmit={saveUser}
        />
      )}
    </div>
  );
};
