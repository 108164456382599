import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";

import { Button } from "../../../../components/Utils/Button";
import { Conditional } from "../../../../components/Utils/Conditional";
import Config from "../../../../config";
import { DailyStats as DailyStatsModel } from "../../models/myface";
import { DateLabel } from "../../../../components/Log/LogTable/DateLabel";
import { GlobalContext } from "../../../../globalContext";
import { MyFaceService } from "../../api/myface";
import { SaveOutlined } from "@material-ui/icons";
import csvDownload from "../../../../utils/csvDownload";

const useStyles = makeStyles(() =>
  createStyles({
    pagination: {
      float: "left",
    },
    tableCellNoWrap: {
      whiteSpace: "nowrap",
    },
    csvDownload: {
      marginTop: "10px",
    },
  }),
);

const myFaceService = new MyFaceService(Config.apiUrl);

interface DailyProps {
  dailyStats?: DailyStatsModel[];
  dailyStatsPage: number;
  setDailyStatsPage: (page: number) => void;
  dailyStatsRowCount: number;
  dailyStatsRowsPerPage: number;
  handleChangeDailyStatsRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: Error;
  loading?: boolean;
}

export const DailyStats: React.FC<DailyProps> = (props) => {
  const classes = useStyles();

  const {
    dailyStats,
    dailyStatsPage,
    setDailyStatsPage,
    dailyStatsRowCount,
    dailyStatsRowsPerPage,
    handleChangeDailyStatsRowsPerPage,
    error,
    loading,
  } = props;

  const { setFeedback } = React.useContext(GlobalContext);

  const [csvLoading, setCSVLoading] = useState(false);

  const downloadCSV = () => {
    setCSVLoading(true);
    myFaceService
      .getAllDailyStats()
      .then((res) => {
        csvDownload(res, "Daily stats.csv");
      })
      .catch((e) => {
        setFeedback({
          variant: "error",
          message: `Failed to load daily stats: ${e.message}`,
          horizontal: "right",
        });
      })
      .finally(() => setCSVLoading(false));
  };

  return (
    <Conditional error={error} errorMessage="Failed to load daily stats" loading={loading}>
      {dailyStats && (
        <>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Day</TableCell>
                  <TableCell align="center">Visits</TableCell>
                  <TableCell align="center">SignUps</TableCell>
                  <TableCell align="center">Finished</TableCell>
                  <TableCell align="center">Reviewed</TableCell>
                  <TableCell align="center">Approved</TableCell>
                  <TableCell align="center">Rejected</TableCell>
                  <TableCell align="center">Expired</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dailyStats.map((stats) => {
                  return (
                    <TableRow key={`daily-stats-${stats.day}`}>
                      <TableCell className={classes.tableCellNoWrap} component="th" scope="row">
                        <DateLabel value={stats.day} format="date" />
                      </TableCell>
                      <TableCell align="center" className={classes.tableCellNoWrap}>
                        {stats.visits}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCellNoWrap}>
                        {stats.signUps}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCellNoWrap}>
                        {stats.finished}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCellNoWrap}>
                        {stats.reviewed}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCellNoWrap}>
                        {stats.approved}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCellNoWrap}>
                        {stats.rejected}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCellNoWrap}>
                        {stats.expired}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              className={classes.pagination}
              rowsPerPageOptions={[10, 15, 20]}
              component="div"
              count={dailyStatsRowCount}
              rowsPerPage={dailyStatsRowsPerPage}
              page={dailyStatsPage}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={(_, p) => setDailyStatsPage(p)}
              onChangeRowsPerPage={handleChangeDailyStatsRowsPerPage}
            />
          </TableContainer>
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadCSV}
            size="small"
            className={classes.csvDownload}
            disabled={csvLoading}
            startIcon={csvLoading ? <CircularProgress size={"20px"} /> : <SaveOutlined />}
          >
            {csvLoading ? "Loading..." : "Download CSV"}
          </Button>
        </>
      )}
    </Conditional>
  );
};
