/**
 * Capitalize the string provided.
 * @param str The string to capitalize
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

/**
 * Transforms a string in snake_case to human readable and capitalize all words.
 * For example:
 * - hello_world -> Hello World
 * @param str The string to transform
 */
export const snakeToHuman = (str: string) => {
  if (!str) {
    return str;
  }

  const words = str.split("_");
  return words.map(capitalize).join(" ");
};

/**
 * Transform a string in camelCase format to human readable and capitalize all
 * words. For example:
 * - helloWorld -> Hello World
 * @param str The string to transform
 */
export const camelCaseToHuman = (str: string) => {
  if (!str) {
    return str;
  }

  const words = str.match(/[A-Za-z][a-z]*/g) || [];
  return words.map(capitalize).join(" ");
};
