import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import React from "react";
import { ValidationResults } from "../../../../models/log";
import { errorFormat } from "../../../../utils/error";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "1px solid",
      borderColor: theme.palette.error.main,
      background: theme.palette.error.light,
      color: theme.palette.error.main,
      borderRadius: "4px",
      padding: "5px",
    },
  }),
);

interface Props {
  error: string;
  validationResults?: ValidationResults;
}

export const ModelErrorDetails: React.FC<Props> = ({ error, validationResults }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p>
        {errorFormat(error)}
        {validationResults && ` - ${JSON.stringify(validationResults, null, 2)}`}
      </p>
    </div>
  );
};
