import { FullscreenExitRounded, FullscreenRounded } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandButtonIcon: {
      color: theme.palette.primary.main,
    },
  }),
);

interface Props {
  expanded: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  contractTooltip?: string;
  expandTooltip?: string;
  label?: string;
}

export const ExpandButton: React.FC<Props> = (props) => {
  const { expanded, onClick, contractTooltip, expandTooltip, label } = props;
  const classes = useStyles();

  return (
    <Tooltip
      title={expanded ? (contractTooltip ? contractTooltip : "Contract") : expandTooltip ? expandTooltip : "Expand"}
    >
      <IconButton
        aria-label={label ? label : "Toggle expansion"}
        onClick={onClick}
        className={classes.expandButtonIcon}
      >
        {expanded ? <FullscreenExitRounded /> : <FullscreenRounded />}
      </IconButton>
    </Tooltip>
  );
};
