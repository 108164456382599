import { ApiBase } from "./base";
import { PaginationAuditLog } from "../models/audit";
export class AuditService extends ApiBase {
  configLog = (page: number, pageSize: number): Promise<PaginationAuditLog> => {
    return this.fetch<PaginationAuditLog>("audit/config", {
      method: "POST",
      data: JSON.stringify({
        page: page,
        pageSize: pageSize,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
}
