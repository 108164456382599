import { Data, Value } from "./dragAndDrop";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import React, { useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { ColumnPanel } from "./Column";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    rootDisabled: {
      display: "flex",
      pointerEvents: "none",
      opacity: 0.5,
    },
    orderColumn: {
      margin: "8px",
      border: "solid 1px lightgrey",
      borderRadius: "2px",
      width: "220px",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      height: "350px",
      backgroundColor: theme.palette.grey[400],
    },
    title: {
      padding: "8px",
      fontWeight: theme.typography.fontWeightBold,
    },
    values: {
      padding: "8px",
      flexGrow: 1,
      display: "inline-block",
    },
    value: {
      padding: "8px",
      marginBottom: "8px",
      backgroundColor: theme.palette.grey[400],
      fontSize: theme.typography.fontSize,
    },
  }),
);

/**
 * Returns the values ​​that the component handles and in which column they are.
 * @param all An array of names of all the values that the component handles.
 * @param selected An array of names of the values that are going to be used.
 */
export function initilizateData<T>(all: T[], selected: T[]): Data<T> {
  const allValues: { [key: string]: Value<T> } = {};
  const selectedValues: { [key: string]: Value<T> } = {};
  const valuesNotUsed: string[] = [];
  const valuesUsed: string[] = [];

  all.forEach((value, index) => {
    allValues["value" + index] = { id: "value" + index, name: value };
    if (!selected.includes(value)) {
      valuesNotUsed.push("value" + index);
    }
  });

  selected.forEach((value) => {
    const id = all.indexOf(value);
    selectedValues["value" + id] = { id: "value" + id, name: value };
    valuesUsed.push("value" + id);
  });

  return {
    values: allValues,
    columns: {
      column1: {
        id: "column1",
        title: "Not used values",
        values: valuesNotUsed,
      },
      column2: {
        id: "column2",
        title: "Used values",
        values: valuesUsed,
      },
    },
  };
}

interface Props<T> {
  allValues: T[];
  selectedValues: T[];
  disable: boolean;
  onItemsChange: (data: Value<T>[]) => void;
  actualStateValues: T[];
}

export function DragAndDropSelection<T>(props: Props<T>): React.ReactElement<Props<T>> | null {
  const { allValues, selectedValues, disable, onItemsChange, actualStateValues } = props;
  const [data, setData] = useState<Data<T>>(initilizateData(allValues, selectedValues));
  const classes = useStyles();

  const changeItems: (data: Data<T>) => void = (data) => {
    const valuesIDs = data.columns["column2"].values;
    const valuesToChange = valuesIDs.map((id) => data.values[id]);
    onItemsChange(valuesToChange);
    setData(data);
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const columnStart = data.columns[source.droppableId];
    const columnFinish = data.columns[destination.droppableId];

    if (columnStart === columnFinish) {
      const newValues = Array.from(columnStart.values);
      newValues.splice(source.index, 1);
      newValues.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...columnStart,
        values: newValues,
      };

      const newData = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      changeItems(newData);
      return;
    }

    // Moving items from one list to another
    const newValuesStart = Array.from(columnStart.values);
    newValuesStart.splice(source.index, 1);
    const newColumnStart = {
      ...columnStart,
      values: newValuesStart,
    };

    const newValuesFinnish = Array.from(columnFinish.values);
    newValuesFinnish.splice(destination.index, 0, draggableId);
    const newColumnFinnish = {
      ...columnFinish,
      values: newValuesFinnish,
    };

    const newData = {
      ...data,
      columns: {
        ...data.columns,
        [newColumnStart.id]: newColumnStart,
        [newColumnFinnish.id]: newColumnFinnish,
      },
    };
    changeItems(newData);
  };

  const columns = Object.values(data.columns);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={disable ? classes.rootDisabled : classes.root}>
        {columns.map((column) => {
          const values = column.values.map((valueId) => data.values[valueId]);
          return (
            <ColumnPanel
              key={column.id}
              column={column}
              values={values}
              ordered={column.id === "column2" ? true : false}
            ></ColumnPanel>
          );
        })}
        <div className={classes.orderColumn}>
          <Typography className={classes.title}>Current Order: </Typography>
          <div className={classes.values}>
            {actualStateValues.map((value) => (
              <Typography key={actualStateValues.indexOf(value)} className={classes.value}>
                {actualStateValues.indexOf(value) + 1 + "." + value}
              </Typography>
            ))}
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}
