import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

import React from "react";
import { withStyles } from "@material-ui/core";

interface Props extends CheckboxProps {
  checkedcolor: string;
}

export const CustomCheckbox: React.FC<Props> = (props) => {
  const StyledCheckbox = withStyles({
    root: {
      "&$checked": {
        color: props.checkedcolor,
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  return <StyledCheckbox {...props} />;
};
