import { ImageExtensionFormat } from "./image";

export interface FileInfo {
  base64content: string;
  name: string;
  format: ImageExtensionFormat;
}

export enum UploadValidationError {
  NoFace,
  TooManyFaces,
  NotAnImage,
  InvalidFormat,
  FileSmallSize,
  FileLargeSize,
  ImageSmallWidth,
  ImageLargeWidth,
  ImageSmallHeight,
  ImageLargeHeight,
  Unknown,
}
