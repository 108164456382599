import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";

interface Props<T> {
  value: T[];
  list: T[];
  onChange: (formats: T[]) => void;
}

export function CheckboxListSelector<T>(props: Props<T>): React.ReactElement {
  return (
    <>
      {props.list.map((item, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={props.value.indexOf(item) >= 0}
              onChange={(e, checked) => {
                let formats = Object.assign([], props.value);
                if (checked && props.value.indexOf(item) < 0) {
                  // Add item if does not exist.
                  formats.push(item);
                } else if (!checked) {
                  // Remove item.
                  formats = formats.filter((f) => f !== item);
                }
                props.onChange(formats);
              }}
              color="primary"
            />
          }
          label={<span>{item}</span>}
        />
      ))}
    </>
  );
}
