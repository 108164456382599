import { LogImg as LogItemImg, TensorflowResult } from "../../../models/log";
import React, { useEffect, useState } from "react";

import { ImagePreview } from "./Image/ImagePreview";
import { PinnedImgInfo } from "./Image/ImagePreview";
import { PlotInfo } from "./Image/Plot";
import { TinyBbox } from "../../../models/prediction";
import { useTheme } from "@material-ui/core";

interface Props {
  details: LogItemImg;
  tinyBBoxOriginal?: TinyBbox;
  modelsResult: TensorflowResult;
  pinnedImage?: PinnedImgInfo;
  changePinnedImg: (img?: PinnedImgInfo) => void;
  tinyBBoxCropped?: TinyBbox;
  showHistogram: boolean;
}

export const LogImg: React.FC<Props> = (props) => {
  const [mtcnnFasPlotInfo, setMTCNNPlotInfo] = useState<PlotInfo>();
  const [tinyFacePlotInfo, setTinyFacePlotInfo] = useState<PlotInfo>();
  const [tinyFaceCroppedPlotInfo, setTinyFaceCroppedPlotInfo] = useState<PlotInfo>();

  const id: number = props.details.id;

  const { cropping } = props.modelsResult;

  const theme = useTheme();
  const themeBlueColor = (theme.palette as any).blue.dark;

  useEffect(() => {
    const createModelPlotInfo = (
      set: (value: PlotInfo | undefined) => void,
      color: string,
      id: number,
      fromTinyFace: boolean,
    ) => {
      cropping === null
        ? set(undefined)
        : set({
            id: id,
            bbox: cropping.bbox,
            landmarks: cropping.landmarks,
            color: color,
            fromTinyFace: fromTinyFace,
          });
    };

    const createTinyFacePlotInfo = (
      details: LogItemImg,
      id: number,
      set: (value: PlotInfo | undefined) => void,
      tinyBBox?: TinyBbox,
    ) => {
      if (details && tinyBBox && tinyBBox.width > 0) {
        const bboxParsed: number[] = [tinyBBox.x, tinyBBox.y, tinyBBox.width, tinyBBox.height];
        set({
          id: id,
          bbox: bboxParsed,
          landmarks: [],
          color: theme.palette.success.dark,
          fromTinyFace: true,
        });
      } else {
        set(undefined);
      }
    };

    if (props.modelsResult.aiResult) {
      createModelPlotInfo(setMTCNNPlotInfo, themeBlueColor, 0, false);
      createTinyFacePlotInfo(props.details, 1, setTinyFacePlotInfo, props.tinyBBoxOriginal);
      createTinyFacePlotInfo(props.details, 2, setTinyFaceCroppedPlotInfo, props.tinyBBoxCropped);
    }
  }, [
    props.details,
    props.modelsResult.aiResult,
    props.tinyBBoxOriginal,
    props.tinyBBoxCropped,
    cropping,
    themeBlueColor,
    theme.palette.success.dark,
  ]);

  return (
    <ImagePreview
      id={id}
      img={props.details.img}
      mtcnnPlotInfo={mtcnnFasPlotInfo}
      tinyFacePlotInfo={tinyFacePlotInfo}
      pinnedImg={props.pinnedImage}
      onPinnedImgChange={props.changePinnedImg}
      croppedImg={props.details.croppedImg}
      tinyFaceCroppedPlotInfo={tinyFaceCroppedPlotInfo}
      pictureUsed={props.details.pictureUsed}
      showHistogram={props.showHistogram}
    />
  );
};
