import { AppBar, Toolbar } from "@material-ui/core";
import { RoleCode, isAdmin, isAtLeastDataCollector, isAtLeastTester } from "../models/role";
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import { Button } from "./Utils/Button";
import { Dialog } from "./Utils/Dialog";
import { EnvTitle } from "./EnvTitle";
import { GlobalContext } from "../globalContext";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export interface HeaderLink {
  name: string;
  path: string;
  shouldBeShown(role: RoleCode): boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.common.white,
      boxShadow: "none",
      position: "fixed",
      top: "0",
      marginBottom: "10px",
      paddingRight: "10px",
    },
    buttons: {
      position: "absolute",
      right: 0,
      "& button": {
        marginRight: 15,
      },
      "& a": {
        textDecoration: "none",
        padding: "11px 0",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    config: {
      color: theme.palette.primary.main,
    },
    configContainer: {},
    menuMobile: {
      position: "absolute",
      right: 0,
      top: 0,
    },
    links: {
      textDecoration: "none",
      color: "inherit",
    },
  }),
);

interface Props {
  loggedIn: boolean;
  onLogout: () => void;
}

export const Header: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { onLogout } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const mobileWidth = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { plugins, session } = React.useContext(GlobalContext);

  const menuItemWrapper = (data: JSX.Element, logout?: boolean) => {
    return <MenuItem onClick={logout ? () => setShowConfirmationDialog(true) : handleClose}>{data}</MenuItem>;
  };
  const buttonWrapper = (page: string) => {
    return (
      <Button color="primary" type="button">
        {page}
      </Button>
    );
  };
  const getItems = (
    buttonWrapper: (item: string) => JSX.Element = (item: string) => <p>{item}</p>,
    wrapperFn: (item: JSX.Element, logout?: boolean) => JSX.Element = (item: JSX.Element) => item,
  ) => {
    return (
      <div className={mobileWidth ? "" : classes.buttons}>
        <Link to="/" className={classes.links}>
          {wrapperFn(buttonWrapper("Scan"))}
        </Link>
        {session && isAtLeastTester(session.role) && (
          <Link to="/upload-photo" className={classes.links}>
            {wrapperFn(buttonWrapper("Upload"))}
          </Link>
        )}
        {session && isAtLeastDataCollector(session.role) && (
          <Link to="/log" className={classes.links}>
            {wrapperFn(buttonWrapper("Log"))}
          </Link>
        )}
        {session && isAdmin(session.role) && (
          <>
            <Link to="/dashboard" className={classes.links}>
              {wrapperFn(buttonWrapper("Dashboard"))}
            </Link>
            <Link to="/crop" className={classes.links}>
              {wrapperFn(buttonWrapper("Crop"))}
            </Link>
            <Link to="/user-management" className={classes.links}>
              {wrapperFn(buttonWrapper("Users"))}
            </Link>
            <Link to="/endpoint-management" className={classes.links}>
              {wrapperFn(buttonWrapper("Endpoints"))}
            </Link>
            <Link to="/organisations" className={classes.links}>
              {wrapperFn(buttonWrapper("Organisations"))}
            </Link>
          </>
        )}
        {session &&
          plugins.map((plugin) => {
            return plugin.headerLinks().map((link) => {
              if (link.shouldBeShown(session.role)) {
                return (
                  <Link key={`header-${link.path}`} to={link.path} className={classes.links}>
                    {wrapperFn(buttonWrapper(link.name))}
                  </Link>
                );
              }
              return undefined;
            });
          })}
        {wrapperFn(
          mobileWidth ? (
            <p>Logout</p>
          ) : (
            <Button color="primary" variant="text" onClick={() => setShowConfirmationDialog(true)}>
              Logout
            </Button>
          ),
          true,
        )}
        {session &&
          isAtLeastTester(session.role) &&
          wrapperFn(
            <div className={classes.configContainer}>
              <Link to="/configuration" className={classes.links}>
                {mobileWidth ? <p>Configuration</p> : <SettingsIcon className={classes.config} />}
              </Link>
            </div>,
          )}
        {showConfirmationDialog && (
          <Dialog
            title={"Logout"}
            action={"log out"}
            onConfirm={onLogout}
            onClose={() => setShowConfirmationDialog(false)}
            open={showConfirmationDialog}
            message={"log out"}
          ></Dialog>
        )}
      </div>
    );
  };

  return (
    <AppBar position="static" color="primary" className={classes.root}>
      <Toolbar>
        {mobileWidth ? (
          <>
            <EnvTitle />
            <div className={classes.menuMobile}>
              <IconButton aria-label="more" aria-haspopup="true" onClick={handleClick}>
                <MenuIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                {getItems(undefined, menuItemWrapper)}
              </Menu>
            </div>
          </>
        ) : (
          <>
            <EnvTitle />
            <div className={classes.buttons}>{getItems(buttonWrapper, undefined)}</div>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

Header.displayName = "Header";

export default Header;
