import { Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import { ScanConfiguration, SelfieCaptureType } from "../../models/config";

import ConfigurationSubsection from "./ConfigurationSubsection";
import React from "react";
import { SliderBar } from "../Utils/SliderBar";

const useStyles = makeStyles(() =>
  createStyles({
    label: { fontSize: "18px" },
  }),
);

interface Props {
  cfg: ScanConfiguration;
  disabled?: boolean;
  selfieCaptureType: SelfieCaptureType;

  onConfigurationChange: (cfg: ScanConfiguration) => void;
}

export const PercentageOfCropping: React.FC<Props> = ({ cfg, disabled, selfieCaptureType, onConfigurationChange }) => {
  const classes = useStyles();

  const setPanelConfigurationSlider = (value: number, name: keyof ScanConfiguration) => {
    onConfigurationChange({
      ...cfg,
      [name]: value,
    });
  };

  return (
    <>
      <ConfigurationSubsection title="Percentage of cropping">
        <Grid container spacing={10}>
          {[SelfieCaptureType.Button, SelfieCaptureType.Scan].indexOf(selfieCaptureType) >= 0 ? (
            <>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.label}>
                  Up margin
                </Typography>
                <SliderBar
                  min={0}
                  max={1}
                  step={0.05}
                  value={cfg.upMarginPercentageCropping}
                  onChange={(v) => setPanelConfigurationSlider(v, "upMarginPercentageCropping")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.label}>
                  Down margin
                </Typography>
                <SliderBar
                  min={0}
                  max={1}
                  step={0.05}
                  value={cfg.downMarginPercentageCropping}
                  onChange={(v) => setPanelConfigurationSlider(v, "downMarginPercentageCropping")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.label}>
                  Left margin
                </Typography>
                <SliderBar
                  min={0}
                  max={1}
                  step={0.05}
                  value={cfg.leftMarginPercentageCropping}
                  onChange={(v) => setPanelConfigurationSlider(v, "leftMarginPercentageCropping")}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography color="textSecondary" gutterBottom className={classes.label}>
                  Right margin
                </Typography>
                <SliderBar
                  min={0}
                  max={1}
                  step={0.05}
                  value={cfg.rightMarginPercentageCropping}
                  onChange={(v) => setPanelConfigurationSlider(v, "rightMarginPercentageCropping")}
                  disabled={disabled}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={6}>
              <SliderBar
                min={0.05}
                max={1}
                step={0.05}
                value={cfg.relativeMarginPercentageCropping}
                onChange={(v) => setPanelConfigurationSlider(v, "relativeMarginPercentageCropping")}
                disabled={disabled}
              />
            </Grid>
          )}
        </Grid>
      </ConfigurationSubsection>

      <ConfigurationSubsection title="Relative Edge Cropping">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <SliderBar
              min={0}
              max={0.4}
              step={0.01}
              value={cfg.faceCaptureModuleRelativeEdgeCropping}
              onChange={(v) => setPanelConfigurationSlider(v, "faceCaptureModuleRelativeEdgeCropping")}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </ConfigurationSubsection>
    </>
  );
};
