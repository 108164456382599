import { ArrowDownward, ArrowUpward, HighlightOff, UnfoldMoreOutlined } from "@material-ui/icons";
import { Checkbox, Grid, IconButton, Paper, TableContainer, Tooltip } from "@material-ui/core";
import { Filter, Order, Sort } from "../../../models/filter";
import { LogData, LogImg, Log as LogItem, LogTableColumn, getAllLogTableColumnDescription } from "../../../models/log";
import { Option, SplitButton } from "../../Utils/SplitButton";
import React, { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import { Button } from "../../Utils/Button";
import Config from "../../../config";
import { Dialog } from "../../Utils/Dialog";
import { ExpandButton } from "../../Utils/ExpandButton";
import { GlobalContext } from "../../../globalContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import { LogDetail as LogItemDetails } from "../LogDetail/LogDetail";
import { LogService } from "../../../api/log";
import LogTableRow from "./LogTableRow";
import { LogTableToolbar } from "./../LogTable/LogTableToolbar";
import { Message } from "../../Utils/Message";
import Moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActions } from "./../../Utils/TablePaginationActions";
import TableRow from "@material-ui/core/TableRow";
import { isAdmin as isAdminFn } from "../../../models/role";
import { saveAs } from "@progress/kendo-file-saver";
import { useSnackbar } from "notistack";

const logService = new LogService(Config.apiUrl);

enum dialogsVariant {
  DOWNLOAD = "DOWNLOAD",
  DOWNLOAD_CSV = "DOWNLOAD_CSV",
  DELETE = "DELETE",
  BATCH_DELETE = "BATCH_DELETE",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 50,
      [theme.breakpoints.down("xs")]: {
        padding: "50px 0px",
      },
    },
    input: {
      display: "none",
    },
    paginationContainer: {
      marginRight: "auto",
    },
    pagination: {
      float: "left",
    },
    downloadContainer: {
      marginRight: theme.spacing(5),
    },
    tableCell: {
      whiteSpace: "nowrap",
    },
    clear: {
      clear: "both",
    },
    success: {
      color: theme.palette.success.main,
    },
    error: { color: theme.palette.error.main },
    paperTable: {
      overflowX: "auto",
      width: "100%",
    },
    logDetailsContainer: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    bottomLinearLoading: {
      width: "100%",
      top: -15,
      marginBottom: -4,
    },
    downloadCSVButton: {
      marginLeft: "20px",
    },
  }),
);

export const Log: React.FC = () => {
  const [logToRemove, setLogToRemove] = useState<LogItem>();
  const [logs, setLogs] = useState<LogItem[]>([]);
  const [error, setError] = useState<Error | undefined>();
  const [page, setPage] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [logData, setLogData] = React.useState<LogData>();
  const [logImg, setLogImg] = React.useState<LogImg | undefined>(undefined);
  const [imageError, setImageError] = React.useState<Error | undefined>(undefined);
  const [imageStored, setImageStored] = React.useState<boolean | undefined>(undefined);
  const [expanded, setExpanded] = React.useState(false);
  const [selectedRowRef, setSelectedRowRef] = React.useState<React.RefObject<HTMLDivElement> | undefined>();
  const theme = useTheme();
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [logsSelected, setLogsSelected] = useState<LogItem[]>([]);
  const [dialogSelected, setDialogSelected] = useState<dialogsVariant>();
  const [sortFilter, setSortFilter] = useState<Sort | undefined>();

  const sessionLogsSelectedNumber = Array.from(new Set(logsSelected.map((l) => l.sessionId))).filter((s) => s !== null)
    .length;

  const logTableColumnDescription = getAllLogTableColumnDescription();

  const { session } = React.useContext(GlobalContext);
  const isAdmin = (session && isAdminFn(session.role)) || false;

  const { closeSnackbar } = useSnackbar();

  const classes = useStyles();

  const loadLogs = useCallback(() => {
    setLoading(true);
    logService
      .log(page, rowsPerPage, filters, sortFilter)
      .then((res) => {
        setRowCount(res.count);
        setLogs(res.logs);
        setError(undefined);
      })
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, [page, rowsPerPage, filters, sortFilter]);

  useEffect(() => {
    loadLogs();
  }, [loadLogs]);

  const toggleDetails = (item: LogItem) => {
    if (logData?.details.id === item.id) {
      setLogImg(undefined);
      setLogData(undefined);
    } else {
      setLogImg(undefined);
      setImageStored(item.storeImagesEndpoint);
      logService
        .details(item.id)
        .then((details) => {
          setLogData({ item: item, details: details });
          setError(undefined);
        })
        .catch((e) => setError(e));
      if (item.storeImagesEndpoint !== false) {
        logService
          .img(item.id)
          .then((img) => {
            setLogImg(img);
            setImageError(undefined);
          })
          .catch((e) => setImageError(Error(e.response?.data?.error_message)));
      }
    }
  };

  const tableBodyRef = useRef<HTMLDivElement>(null);

  // We need this function so that if the user shrinks the log table after
  // selecting one of the latter rows, the table scrolls to show the selected
  // row instead of it being hidden.
  const scrollToRow = (rowRef: RefObject<HTMLDivElement>) => {
    setSelectedRowRef(rowRef);

    if (rowRef.current && tableBodyRef.current) {
      if (!expanded) {
        tableBodyRef.current.classList.remove("expanded");
      }
      // If first selection, scroll to the selected row.
      if (logData === undefined && logImg === undefined) {
        tableBodyRef.current.scrollTop = rowRef.current.offsetTop - tableBodyRef.current.offsetTop;
      }
    }
  };

  const handleDeleteLogCallback = (fn: Promise<void>) => {
    fn.then(() => {
      setError(undefined);
      setLogData(undefined);
      return logService.log(page, rowsPerPage, filters);
    })
      .then((res) => {
        setRowCount(res.count);
        setLogs(res.logs);
        setError(undefined);
      })
      .catch((e) => setError(e));
  };

  const onDeleteClick = (log: LogItem) => {
    setLogToRemove(log);
    setDialogSelected(dialogsVariant.DELETE);
  };

  const deleteResult = (item: LogItem) => {
    handleDeleteLogCallback(logService.delete(item.id));
    if (logsSelected.findIndex((l) => l.id === item.id) >= 0) {
      setLogsSelected(logsSelected.filter((l) => l.id !== item.id));
    }
  };

  const deleteSessionResult = (sessionId: string) => {
    handleDeleteLogCallback(logService.deleteSession(sessionId));
  };

  const deleteBatch = (items: LogItem[], deleteSessions?: boolean) => {
    handleDeleteLogCallback(
      logService.deleteBatch(
        items.map((i) => i.id),
        !!deleteSessions,
      ),
    );
    setDialogSelected(undefined);
    setLogsSelected([]);
  };

  const getTableRowStyle = (currentItem: LogItem): React.CSSProperties => {
    if (logData && logData.details.id === currentItem.id) {
      const backgroundColor = theme.palette.primary.light;
      return { backgroundColor };
    }
    return {};
  };

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const { panelConfigurationData, setFeedback, setDownloadingImages, envSettings } = React.useContext(GlobalContext);
  const { env } = envSettings;

  const onDownloadClick = (onlyCSV?: boolean) => {
    let downloadFeedbackKey: undefined | null | string | number = undefined;

    setDownloadingImages(true);
    const filename = env + "_" + Moment(new Date()).format("YYYYMMDD_HHmm");

    downloadFeedbackKey = setFeedback({
      variant: "default",
      message: `Downloading ${onlyCSV ? "CSV file" : "all images from database"}...`,
      horizontal: "right",
      duration: null,
    });
    logService
      .download(filename, filters, onlyCSV)
      .then((res) => {
        const blob = new Blob([res], {
          type: onlyCSV ? "text/csv" : "application/zip",
        });
        saveAs(blob, filename + (onlyCSV ? ".csv" : ".zip"));
        setFeedback({
          variant: "success",
          message: "The download was succesful",
          horizontal: "right",
        });
      })
      .catch((e) => {
        setFeedback({
          variant: "error",
          message: e.message || `Cannot download the ${onlyCSV ? "CSV file" : "images"}`,
          horizontal: "right",
          duration: 6000,
        });
      })
      .finally(() => {
        if (downloadFeedbackKey !== null) {
          closeSnackbar(downloadFeedbackKey);
        }
        setDownloadingImages(false);
      });
  };

  const changeFilters = (filters: Filter[]) => {
    setPage(0);
    setFilters(filters);
  };

  const confirmSession = (log: LogItem) => {
    if (log.sessionId) {
      deleteSessionResult(log.sessionId);
      setLogToRemove(undefined);
    }
  };

  const onCheckboxChange = (log: LogItem, checked: boolean) => {
    checked ? setLogsSelected([...logsSelected, log]) : setLogsSelected(logsSelected.filter((l) => l.id !== log.id));
  };

  const CSVOption: Option = {
    label: "Download CSV",
    callback: () => setDialogSelected(dialogsVariant.DOWNLOAD_CSV),
    disabled: false,
    errorMessage: "",
  };
  const ImagesOption: Option = {
    label: "Download all images",
    callback: () => setDialogSelected(dialogsVariant.DOWNLOAD),
    disabled: rowCount > panelConfigurationData.logConfiguration.limitImagesDownload,
    errorMessage: `Cannot download more images than the stablished limit: ${panelConfigurationData.logConfiguration.limitImagesDownload}`,
  };

  return (
    <>
      {error && <Message variant="error" message={error.message} />}
      <Grid container className={classes.root}>
        <Paper elevation={5} className={classes.paperTable}>
          <LogTableToolbar
            logsFound={rowCount}
            logsSelected={logsSelected}
            onDelete={() => setDialogSelected(dialogsVariant.BATCH_DELETE)}
            onChangeFilters={changeFilters}
          />
          <Grid item xs={12} sm={12}>
            {loading && <LinearProgress />}
            <TableContainer
              ref={tableBodyRef}
              className={"column log-table" + (logData === undefined || expanded ? " expanded" : "")}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onChange={(e) => {
                          setLogsSelected(e.target.checked ? logs : []);
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">Actions</TableCell>
                    {panelConfigurationData.logConfiguration.logTableColumnsOrder.map((p, i) => {
                      return (
                        <TableCell align="center" key={i} style={{ width: 150 }}>
                          {p === sortFilter?.field && (
                            <IconButton
                              size={"small"}
                              onClick={() => {
                                setSortFilter(undefined);
                              }}
                            >
                              <HighlightOff />
                            </IconButton>
                          )}
                          <Tooltip title={logTableColumnDescription[p]} placement="top" arrow>
                            <span>{p === sortFilter?.field ? <b>{p}</b> : p}</span>
                          </Tooltip>
                          {(p === sortFilter?.field && sortFilter.order === Order.ASC && (
                            <IconButton
                              size={"small"}
                              onClick={() => {
                                const elementToSortLog = {
                                  field: p,
                                  order: Order.DESC,
                                };
                                setSortFilter(elementToSortLog);
                              }}
                            >
                              <ArrowUpward />
                            </IconButton>
                          )) ||
                            (p === sortFilter?.field && sortFilter.order === Order.DESC && (
                              <IconButton
                                size={"small"}
                                onClick={() => {
                                  const elementToSortLog = {
                                    field: p,
                                    order: Order.ASC,
                                  };
                                  setSortFilter(elementToSortLog);
                                }}
                              >
                                <ArrowDownward />
                              </IconButton>
                            )) ||
                            (p !== LogTableColumn.Thumbnail && p !== LogTableColumn.Feedback && (
                              <IconButton
                                size={"small"}
                                onClick={() => {
                                  const elementToSortLog = {
                                    field: p,
                                    order:
                                      sortFilter?.field === p && sortFilter.order === Order.ASC
                                        ? Order.DESC
                                        : Order.ASC,
                                  };
                                  setSortFilter(elementToSortLog);
                                }}
                              >
                                <UnfoldMoreOutlined />
                              </IconButton>
                            ))}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.map((l) => (
                    <LogTableRow
                      key={l.id}
                      log={l}
                      checked={logsSelected.find((log) => log.id === l.id) !== undefined}
                      logTableColumnsOrder={panelConfigurationData.logConfiguration.logTableColumnsOrder}
                      style={getTableRowStyle(l)}
                      onClick={toggleDetails}
                      onDelete={() => onDeleteClick(l)}
                      onClickScroll={scrollToRow}
                      onCheckboxChange={onCheckboxChange}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {loading && logs.length > 0 && <LinearProgress className={classes.bottomLinearLoading} />}
            <Grid container alignItems="center">
              <Grid item className={classes.paginationContainer}>
                <TablePagination
                  className={classes.pagination}
                  rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
                  component="div"
                  count={rowCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={(_, p) => setPage(p)}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </Grid>
              <Grid item className={classes.downloadContainer}>
                {isAdmin && <SplitButton options={[CSVOption, ImagesOption]} />}
              </Grid>
              <Grid item>
                {logData && (
                  <ExpandButton
                    expanded={expanded}
                    contractTooltip="Contract table"
                    expandTooltip="Expand table"
                    label="Toggle table expansion"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setExpanded(!expanded);
                      if (selectedRowRef !== undefined && tableBodyRef.current && selectedRowRef.current) {
                        const scrollPos = selectedRowRef.current.offsetTop - tableBodyRef.current.offsetTop;
                        const tableBody = tableBodyRef.current;
                        // setTimeout 0 is useful in this case because it will schedule the function at the
                        // end of the execution queue, allowing JS to re-render the log table after changing
                        // it size with setExpanded and therefore, scrolling to the right position.
                        setTimeout(() => {
                          tableBody.scrollTop = scrollPos;
                        }, 0);
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {logData && (
          <Grid item xs={12}>
            <Paper elevation={5} className={classes.logDetailsContainer}>
              <LogItemDetails
                data={logData}
                logImg={logImg ? logImg : undefined}
                imgError={imageError}
                storedImg={imageStored}
                onChange={loadLogs}
              />
            </Paper>
          </Grid>
        )}
      </Grid>

      <div className={classes.clear} />

      {logToRemove && dialogSelected === dialogsVariant.DELETE && (
        <Dialog
          title={"Delete log"}
          message={`delete the log with id ${logToRemove.id}`}
          action={"Delete"}
          onConfirm={() => deleteResult(logToRemove)}
          onClose={() => setLogToRemove(undefined)}
          buttons={
            logToRemove.sessionId
              ? [
                  <Button
                    key={logToRemove.sessionId}
                    fullWidth
                    variant="contained"
                    onClick={() => confirmSession(logToRemove)}
                  >
                    Delete Session
                  </Button>,
                ]
              : undefined
          }
        />
      )}

      {dialogSelected === dialogsVariant.DOWNLOAD && (
        <Dialog
          title={"Download images"}
          message={"download the images"}
          action={"Download"}
          onConfirm={onDownloadClick}
          onClose={() => setDialogSelected(undefined)}
        />
      )}

      {dialogSelected === dialogsVariant.DOWNLOAD_CSV && (
        <Dialog
          title={"Download CSV"}
          message={"download the CSV"}
          action={"Download"}
          onConfirm={() => onDownloadClick(true)}
          onClose={() => setDialogSelected(undefined)}
        />
      )}

      {dialogSelected === dialogsVariant.BATCH_DELETE && (
        <Dialog
          title={"Delete logs"}
          action={"Delete"}
          message={`delete ${logsSelected.length} ${logsSelected.length > 1 ? "logs" : "log"} ${
            sessionLogsSelectedNumber > 0
              ? " (some of the logs selected belong to " + sessionLogsSelectedNumber + " sessions)"
              : ""
          }`}
          secondMessage={
            sessionLogsSelectedNumber > 0
              ? `1. 'Delete' will delete all logs selected.\n\n2. 'Delete logs without session' will delete all logs selected except those that belong to a session.\n\n3. 'Delete logs and its sessions' will delete all logs selected and for those logs that belong to a session, the whole session will be deleted.`
              : ""
          }
          onConfirm={() => deleteBatch(logsSelected)}
          onClose={() => setDialogSelected(undefined)}
          buttons={
            sessionLogsSelectedNumber > 0
              ? [
                  <Button
                    key={"delete-logs-without-session"}
                    variant="contained"
                    fullWidth
                    onClick={() => deleteBatch(logsSelected.filter((l) => !l.sessionId))}
                  >
                    Delete logs without session
                  </Button>,
                  <Button
                    key={"delete-logs-ands-its-sessions"}
                    variant="contained"
                    fullWidth
                    onClick={() => deleteBatch(logsSelected, true)}
                  >
                    Delete logs and its sessions
                  </Button>,
                ]
              : undefined
          }
        />
      )}
    </>
  );
};
