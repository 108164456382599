import { IconButton, Theme, Toolbar, Tooltip, Typography, createStyles, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

import { Button } from "../../Utils/Button";
import { DeleteRounded } from "@material-ui/icons";
import { Filter } from "../../../models/filter";
import FilterListIcon from "@material-ui/icons/FilterList";
import { FilterPanel } from "./FilterPanel";
import { Log as LogItem } from "../../../models/log";
import clsx from "clsx";

interface Props {
  logsFound: number;
  logsSelected: LogItem[];

  onDelete: () => void;
  onChangeFilters: (filtes: Filter[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { flexDirection: "column", display: "flex" },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flexDirection: "row",
      display: "flex",
      justifyContent: "flex-end",
    },
    highlight: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    title: {
      flex: "1 1 100%",
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
      fontSize: 18,
    },
    subtitle: {
      flex: "1 1 100%",
      fontWeight: theme.typography.fontWeightRegular,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
      fontSize: 18,
    },
    buttonGroup: {
      flexDirection: "row",
      display: "flex",
    },
    button: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      margin: "6px 10px",
    },
  }),
);

export const LogTableToolbar: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { logsFound, logsSelected, onDelete, onChangeFilters } = props;

  const [filterPanelExpanded, setFilterPanelExpanded] = useState<boolean>(false);

  const size = logsSelected.length;

  return (
    <div className={classes.root}>
      <Toolbar
        className={clsx(classes.toolbar, {
          [classes.highlight]: size > 0,
        })}
      >
        {size > 0 ? (
          <Typography className={classes.title} color="inherit" variant="h6" component="div">
            {size} selected
          </Typography>
        ) : (
          <>
            <div className={classes.titleContainer}>
              <Typography className={classes.title} color="inherit" variant="h6" component="div">
                Log panel
              </Typography>
              <Typography className={classes.subtitle} color="inherit" variant="h6" component="div">
                {logsFound} {`${logsFound === 1 ? "log" : "logs"} found`}
              </Typography>
            </div>
            <Tooltip title="Filter panel">
              <IconButton color="primary" onClick={() => setFilterPanelExpanded(!filterPanelExpanded)}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </>
        )}

        {size > 0 && (
          <div className={classes.buttonGroup}>
            <Button className={classes.button} variant="contained" endIcon={<DeleteRounded />} onClick={onDelete}>
              Remove
            </Button>
          </div>
        )}
      </Toolbar>

      <FilterPanel expanded={filterPanelExpanded} onSetFilters={onChangeFilters} />
    </div>
  );
};
