import { Divider, ListItem } from "@material-ui/core";
import { ListLabel, logDetailUseStyles } from "../LogDetail";

import { JSONTree } from "../../../Utils/JSON-tree";
import React from "react";
import { SecureField } from "../../../../models/log";

interface Props {
  isSecure?: boolean;
  secure?: SecureField;
}

const formatIsSecureRequest = (isSecure?: boolean): string => {
  switch (isSecure) {
    case true:
      return "Secure";
    case false:
      return "Non secure";
    default:
      return "Unknown";
  }
};

export const SecureData: React.FC<Props> = ({ isSecure, secure }) => {
  const classes = logDetailUseStyles();

  return (
    <>
      <ListItem>
        <ListLabel title="Is secure request" value={formatIsSecureRequest(isSecure)} />
      </ListItem>
      {secure && (
        <>
          <Divider />
          <ListItem>
            <div className={classes.bboxAndLandmarksContainer}>
              <span className={classes.title}>Secure information:</span>
              <JSONTree
                hideRoot={true}
                data={{
                  version: secure.version,
                  token: [secure.token],
                  signature: [secure.signature],
                  verification: [secure.verification],
                }}
              />
            </div>
          </ListItem>
        </>
      )}
    </>
  );
};
