import { AntiSpoofingResult, FIQAData } from "../../../../models/log";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { ListLabel } from "../LogDetail";
import { ModelResultProps } from "../../../../config/modelsConfig";
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      paddingTop: 0,
    },
    tableTitle: {
      paddingBottom: 0,
    },
    models: {
      [theme.breakpoints.down(430)]: {
        fontSize: "4vw",
        display: "grid",
      },
    },
  }),
);

export const FIQADetails: React.FC<ModelResultProps> = (props) => {
  const modelResult = props.modelResult as AntiSpoofingResult;
  const customData = modelResult.data as FIQAData;
  const classes = useStyles();

  return (
    <>
      <ListItem className={classes.models}>
        <ListLabel title={"Score"} value={modelResult.real.toFixed(3)}></ListLabel>
      </ListItem>
      <Divider />
      <ListItem divider className={classes.models}>
        <ListLabel title={"Threshold"} value={modelResult.classificationThreshold.toString()}></ListLabel>
      </ListItem>
      <Divider />
      <ListItem className={classes.tableTitle}>Probabilities</ListItem>
      <ListItem className={classes.tableWrapper}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {customData.probs.map((_, i) => {
                  return (
                    <TableCell key={i} align="center">
                      {i}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {customData.probs.map((prob, index) => {
                  return (
                    <TableCell key={index} align="center">
                      {prob.toFixed(4)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ListItem>
      <Divider />
    </>
  );
};
