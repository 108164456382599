import {
  ExpectedBrightness,
  ExpectedLocation,
  ExpectedResult,
  Feedback,
  FeedbackToString,
  ImageMetadata,
  LogData,
  LogImg,
  expectedResultToString,
} from "../../../models/log";
import { ImageFeedbackIcon, ImageFeedbackType } from "../../Utils/ImageFeedbackIcon";
import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Config from "../../../config";
import Divider from "@material-ui/core/Divider";
import { GlobalContext } from "../../../globalContext";
import Grid from "@material-ui/core/Grid";
import { ImageExtensionFormat } from "../../../models/image";
import { JSONTree } from "../../Utils/JSON-tree";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { ListSelect } from "./ListSelect";
import { LogImg as LogItemImg } from "./LogImg";
import { LogService } from "../../../api/log";
import { ModelErrorDetails } from "./ModelDetails/ModelErrorDetails";
import { ModelResult } from "./ModelResult";
import { Paper } from "@material-ui/core";
import { PinnedImgInfo } from "./Image/ImagePreview";
import { SecureData } from "./SecureData/SecureData";
import { ValidationResults } from "./ValidationResults/ValidationResults";
import { isAdmin } from "../../../models/role";

const logService = new LogService(Config.apiUrl);

export const logDetailUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    nav: {
      paddingTop: "0px",
    },
    device: {
      marginLeft: "20px",
    },
    title: {
      color: theme.palette.common.black,
      marginRight: "10px",
    },
    value: {
      color: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        marginRight: "6px",
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: "20px",
      },
      wordBreak: "break-all",
    },
    progress: {
      margin: theme.spacing(2),
    },
    bboxAndLandmarksContainer: {
      flex: "1",
      flexDirection: "column",
    },
    deviceInfoPaper: {
      padding: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        marginRight: "-150px",
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: 0,
      },
    },
    imageIconWrapper: {
      display: "flex",
      height: "200px",
      width: "200px",
    },
  }),
);

interface Props {
  data: LogData;
  logImg?: LogImg;
  imgError?: Error;
  storedImg?: boolean;
  onChange: () => void;
}

interface RawMTCNNData {
  bbox: number[];
  landmarks: number[];
  score?: number;
}

interface MTCNNData {
  bbox: MTCNNFields;
  landmarks: MTCNNFields;
  score?: number;
}

type MTCNNFields = { [id: string]: number };

const bboxLabels = ["x0", "y0", "x1", "y1"];
const landmarkLabels = [
  "left_eye_x",
  "right_eye_x",
  "nose_x",
  "left_mouth_x",
  "right_mouth_x",
  "left_eye_y",
  "right_eye_y",
  "nose_y",
  "left_mouth_y",
  "right_mouth_y",
];

const getBBox = (details: RawMTCNNData) => {
  return details?.bbox?.reduce<MTCNNFields>((acc, value, index) => {
    if (index < bboxLabels.length) {
      return { ...acc, [bboxLabels[index]]: value };
    } else {
      return { ...acc, [index]: value };
    }
  }, {});
};

const getLandmarks = (details: RawMTCNNData) => {
  return details?.landmarks?.reduce<MTCNNFields>((acc, value, index) => {
    if (index < landmarkLabels.length) {
      return { ...acc, [landmarkLabels[index]]: value };
    } else {
      return { ...acc, [index]: value };
    }
  }, {});
};

export const imageSize = (img: string): Promise<ImageMetadata> => {
  return new Promise((resolve) => {
    const i = new Image();
    i.onload = () => {
      const binary = atob(img.split(",")[1]);
      resolve({ height: i.height, size: binary.length, width: i.width });
    };
    i.src = img;
  });
};

export const LogDetail: React.FC<Props> = (props) => {
  const { data, onChange, logImg, imgError, storedImg } = props;
  const [imageMetadata, setImageMetadata] = useState<ImageMetadata>();
  const [pinnedImg, setPinnedImg] = useState<PinnedImgInfo | undefined>(undefined);
  const tinyFaceDetectorDetails = {
    bbox: data.details.tinyBBoxOriginal,
    score: data.details.tinyFaceDetectorScore ? data.details.tinyFaceDetectorScore : 0,
  };
  const [croppedImageMetadata, setCroppedImageMetadata] = useState<ImageMetadata>();
  const { panelConfigurationData } = React.useContext(GlobalContext);
  const [feedback, setFeedbackDetails] = useState(data.item.feedback);
  const [expectedResult, setExpectedResult] = useState(data.item.expectedResult);
  const [expectedLocation, setExpectedLocation] = useState(data.item.expectedLocation);
  const [expectedBrightness, setExpectedBrightness] = useState(data.item.expectedBrightness);

  const { session, plugins, setFeedback } = React.useContext(GlobalContext);

  useEffect(() => {
    setFeedbackDetails(data.item.feedback);
    setExpectedResult(data.item.expectedResult);
    setExpectedLocation(data.item.expectedLocation);
    setExpectedBrightness(data.item.expectedBrightness);
  }, [data]);

  const setLogFeedback = (feedback: string) => {
    if (feedback === data.item.feedback) return;

    logService
      .updateFeedback(data.item.id, feedback === "unknown" ? undefined : feedback)
      .then(() => {
        setFeedbackDetails(feedback);
        onChange();
      })
      .catch((e) => {
        setFeedback({
          variant: "error",
          message: `Failed to update the feedback for log ${data.item.id}: ${e.message}`,
          horizontal: "right",
        });
      });
  };

  const setLogExpectedResult = (expectedResult: string, sessionId?: string) => {
    if (expectedResult === data.item.expectedResult) return;

    logService
      .updateExpectedResult(data.item.id, expectedResult === "unknown" ? undefined : expectedResult, sessionId)
      .then(() => {
        setExpectedResult(expectedResult);
        onChange();
      })
      .catch((e) => {
        setFeedback({
          variant: "error",
          message: `Failed to update the expected result for log ${data.item.id}: ${e.message}`,
          horizontal: "right",
        });
      });
  };

  const setLogExpectedLocation = (expectedLocation: string, sessionId?: string) => {
    if (expectedLocation === data.item.expectedLocation) return;

    logService
      .updateExpectedLocation(data.item.id, expectedLocation === "unknown" ? undefined : expectedLocation, sessionId)
      .then(() => {
        setExpectedLocation(expectedLocation);
        onChange();
      })
      .catch((e) => {
        setFeedback({
          variant: "error",
          message: `Failed to update the expected location for log ${data.item.id}: ${e.message}`,
          horizontal: "right",
        });
      });
  };

  const setLogExpectedBrightness = (expectedBrightness: string, sessionId?: string) => {
    if (expectedBrightness === data.item.expectedBrightness) return;

    logService
      .updateExpectedBrightness(
        data.item.id,
        expectedBrightness === "unknown" ? undefined : expectedBrightness,
        sessionId,
      )
      .then(() => {
        setExpectedBrightness(expectedBrightness);
        onChange();
      })
      .catch((e) => {
        setFeedback({
          variant: "error",
          message: `Failed to update the expected brightness for log ${data.item.id}: ${e.message}`,
          horizontal: "right",
        });
      });
  };

  useEffect(() => {
    if (logImg) {
      setImageMetadata(undefined);
      if (logImg.img) {
        imageSize(logImg.img).then((metadata) => setImageMetadata(metadata));
      }
      setCroppedImageMetadata(undefined);
      if (logImg.croppedImg) {
        imageSize(logImg.croppedImg).then((metadata) => setCroppedImageMetadata(metadata));
      }
    }
  }, [logImg]);

  const changePinnedImg = (img?: PinnedImgInfo) => {
    setPinnedImg(img);
  };

  let mtcnnData: MTCNNData | undefined;
  if (data.details.modelResult.cropping?.bbox) {
    const rawMtcnnData: RawMTCNNData =
      data.details.modelResult.cropping.bbox.length === 4
        ? {
            bbox: data.details.modelResult.cropping.bbox,
            landmarks: data.details.modelResult.cropping.landmarks,
          }
        : {
            bbox: data.details.modelResult.cropping.bbox.slice(0, 4),
            landmarks: data.details.modelResult.cropping.landmarks,
            score: Number(data.details.modelResult.cropping.bbox[4]),
          };
    mtcnnData = {
      score: rawMtcnnData.score,
      bbox: getBBox(rawMtcnnData),
      landmarks: getLandmarks(rawMtcnnData),
    };
  }
  const classes = logDetailUseStyles();

  const renderImage = (imgData?: LogImg, err?: Error, storedImg?: boolean): JSX.Element => {
    //For any reason the image has been removed from the dir.
    if (!imgData && err) {
      return (
        <Paper className={classes.imageIconWrapper}>
          <ImageFeedbackIcon feedbackType={ImageFeedbackType.noImage} tooltipText={"No image stored"} />
        </Paper>
      );
    }

    if (storedImg === false) {
      return (
        <Paper className={classes.imageIconWrapper}>
          <ImageFeedbackIcon feedbackType={ImageFeedbackType.noImage} tooltipText={"No image stored"} />
        </Paper>
      );
    }

    if (err) {
      return (
        <Paper className={classes.imageIconWrapper}>
          <ImageFeedbackIcon feedbackType={ImageFeedbackType.error} tooltipText={err.message} />
        </Paper>
      );
    }

    if (!imgData) {
      return (
        <Paper className={classes.imageIconWrapper}>
          <ImageFeedbackIcon feedbackType={ImageFeedbackType.loading} tooltipText={"Loading image"} />
        </Paper>
      );
    }

    return (
      <LogItemImg
        details={imgData}
        tinyBBoxOriginal={data.details.tinyBBoxOriginal}
        modelsResult={data.details.modelResult}
        pinnedImage={pinnedImg}
        changePinnedImg={changePinnedImg}
        tinyBBoxCropped={data.details.tinyBBoxCropped}
        showHistogram={panelConfigurationData.logConfiguration.showHistograms}
      />
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={3}>
        {data.details.error && (
          <Grid item xs={12} sm={12}>
            <ModelErrorDetails error={data.details.error} validationResults={data.details.validationResults} />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          {renderImage(logImg, imgError, storedImg)}
        </Grid>
        <Grid container item xs={12} sm={12}>
          <Paper elevation={1}>
            <List component="nav" className={classes.nav}>
              {session && isAdmin(session.role) && (
                <>
                  <ListItem>
                    <Grid item xs={6} sm={12}>
                      <ListSelect
                        logId={data.item.id}
                        title={"Feedback"}
                        list={[Feedback.Unknown, Feedback.Correct, Feedback.Incorrect]}
                        onDisplayValue={FeedbackToString}
                        value={feedback}
                        setValue={setLogFeedback}
                      />
                    </Grid>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Grid item xs={6} sm={12}>
                      <ListSelect
                        logId={data.item.id}
                        sessionId={data.item.sessionId}
                        title={"Expected Result"}
                        list={[
                          ExpectedResult.Unknown,
                          ExpectedResult.Real,
                          ExpectedResult.DisplayAttack,
                          ExpectedResult.PrintAttack,
                          ExpectedResult.MaskAttack,
                        ]}
                        onDisplayValue={expectedResultToString}
                        value={expectedResult}
                        setValue={setLogExpectedResult}
                      />
                    </Grid>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Grid item xs={6} sm={12}>
                      <ListSelect
                        logId={data.item.id}
                        sessionId={data.item.sessionId}
                        title={"Expected Location"}
                        list={[ExpectedLocation.Unknown, ExpectedLocation.Indoor, ExpectedLocation.Outdoor]}
                        onDisplayValue={(value: string) =>
                          value === null ? "Unknown" : value.charAt(0).toUpperCase() + value.slice(1)
                        }
                        value={expectedLocation}
                        setValue={setLogExpectedLocation}
                      />
                    </Grid>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Grid item xs={6} sm={12}>
                      <ListSelect
                        logId={data.item.id}
                        sessionId={data.item.sessionId}
                        title={"Expected Brightness"}
                        list={[
                          ExpectedBrightness.Unknown,
                          ExpectedBrightness.Dark,
                          ExpectedBrightness.Normal,
                          ExpectedBrightness.Bright,
                        ]}
                        onDisplayValue={(value: string) =>
                          value === null ? "Unknown" : value.charAt(0).toUpperCase() + value.slice(1)
                        }
                        value={expectedBrightness}
                        setValue={setLogExpectedBrightness}
                      />
                    </Grid>
                  </ListItem>
                  <Divider />
                </>
              )}
              {data.details.metadata?.os && (
                <>
                  <ListItem>
                    <ListLabel title={"Operating system"} value={data.details.metadata.os} />
                  </ListItem>
                  <Divider />
                </>
              )}

              {data.details.metadata?.browser && (
                <>
                  <ListItem>
                    <ListLabel title={"Browser"} value={data.details.metadata.browser} />
                  </ListItem>
                  <Divider />
                </>
              )}
              {data.details.metadata?.device && (
                <>
                  <ListItem>
                    <ListLabel
                      title={"Device"}
                      value={data.details.metadata.device ? data.details.metadata.device : "-"}
                    />
                  </ListItem>
                  <Divider />
                </>
              )}

              {data.details.metadata?.chOS && (
                <>
                  <ListItem>
                    <ListLabel title={"Client hints OS"} value={data.details.metadata.chOS} />
                  </ListItem>
                  <Divider />
                </>
              )}
              {data.details.metadata?.chBrowser && (
                <>
                  <ListItem>
                    <ListLabel title={"Client hints browser"} value={data.details.metadata.chBrowser} />
                  </ListItem>
                  <Divider />
                </>
              )}
              {data.details.metadata?.chIsMobile !== null && data.details.metadata?.chIsMobile !== undefined && (
                <>
                  <ListItem>
                    <ListLabel title={'Client hints "Is Mobile"'} value={`${data.details.metadata.chIsMobile}`} />
                  </ListItem>
                  <Divider />
                </>
              )}
              {imageMetadata && (
                <MetadataList
                  title="Original picture"
                  metadata={imageMetadata}
                  format={data.details.configuration.imageExtensionFormat}
                  compression={data.details.configuration.imageJPEGCompression}
                />
              )}
              <>
                <ListItem>
                  <ListLabel title={"User Email"} value={`${data.details.userEmail || "-"}`} />
                </ListItem>
                <Divider />
              </>
              {data.details.organisation && (
                <>
                  <ListItem>
                    <ListLabel title={"Organisation Name"} value={data.details.organisation.name} />
                  </ListItem>
                  <Divider />
                </>
              )}
              {plugins.map((plugin) => {
                return (
                  <ul key={plugin.name()}>
                    {data.details.extraInfo && plugin.logDetailsExtraInfo(data.details.extraInfo)}
                  </ul>
                );
              })}
              {croppedImageMetadata && (
                <MetadataList
                  title="Cropped picture"
                  metadata={croppedImageMetadata}
                  format={data.details.configuration.imageExtensionFormat}
                  compression={data.details.configuration.imageJPEGCompression}
                />
              )}
              {data.details.tag && (
                <>
                  <ListItem>
                    <ListLabel title={"Tag"} value={data.details.tag} />
                  </ListItem>
                  <Divider />
                </>
              )}
              {data.details &&
                data.details.configuration &&
                data.details.configuration.minFaceSize !== undefined &&
                data.details.configuration.minFaceSize > 0 && (
                  <ListItem>
                    <ListLabel
                      title={"MTCNN min face size"}
                      value={data.details.configuration.minFaceSize.toString()}
                    />
                  </ListItem>
                )}
              {data.details && mtcnnData && (
                <>
                  <ListItem>
                    <div className={classes.bboxAndLandmarksContainer}>
                      <span className={classes.title}>MTCNN details:</span>
                      <Grid item container xs={7} sm={12}>
                        <ListLabel title={"Width"} value={(mtcnnData.bbox.x1 - mtcnnData.bbox.x0).toFixed(2)} />
                        <ListLabel title={"Height"} value={(mtcnnData.bbox.y1 - mtcnnData.bbox.y0).toFixed(2)} />
                      </Grid>
                      <JSONTree hideRoot={true} data={mtcnnData} />
                    </div>
                  </ListItem>
                </>
              )}
              {data.details &&
                data.details.configuration &&
                data.details.configuration.minFaceSize !== undefined &&
                data.details.configuration.minFaceSize > 0 &&
                tinyFaceDetectorDetails.bbox && (
                  <>
                    <Divider />
                    <ListItem>
                      <div className={classes.bboxAndLandmarksContainer}>
                        <span className={classes.title}>Tiny Face Detector Details:</span>
                        <Grid item container xs={7} sm={12}>
                          <ListLabel title={"Width"} value={tinyFaceDetectorDetails.bbox.width.toFixed(2)} />
                          <ListLabel title={"Height"} value={tinyFaceDetectorDetails.bbox.height.toFixed(2)} />
                        </Grid>
                        <JSONTree hideRoot={true} data={tinyFaceDetectorDetails} />
                      </div>
                    </ListItem>
                  </>
                )}
              {data.details && data.details.ip && (
                <ListItem>
                  <ListLabel title={"Client IP"} value={data.details.ip} />
                </ListItem>
              )}
              {data.details.sessionId && (
                <>
                  <Divider />
                  <ListItem>
                    <ListLabel title={"Session ID"} value={data.details.sessionId} />
                  </ListItem>
                </>
              )}
              {data.details.applicationId && (
                <>
                  <Divider />
                  <ListItem>
                    <ListLabel title={"Application ID"} value={data.details.applicationId} />
                  </ListItem>
                </>
              )}
              {data.details.requestId && (
                <>
                  <Divider />
                  <ListItem>
                    <ListLabel title={"Request ID"} value={data.details.requestId} />
                  </ListItem>
                </>
              )}
              <Divider />
              <ListItem>
                <ListLabel title={"Level of assurance"} value={data.details.levelOfAssurance || "Not defined"} />
              </ListItem>
              <Divider />
              <ListItem>
                <ListLabel
                  title={"Metadata device field"}
                  value={data.details.metadataField?.device || "Not defined"}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListLabel title={"Image validation level"} value={data.details.imgValidationLevel || "Not defined"} />
              </ListItem>
              <Divider />
              <ListItem>
                <ListLabel
                  title={"Age estimation model field"}
                  value={data.details.ageEstimationModel || "Not defined"}
                />
              </ListItem>
              <Divider />
              <SecureData isSecure={data.details.isSecure} secure={data.details.secureField} />
              <Divider />
              <ListItem>
                <ListLabel title={"Level of assurance"} value={data.details.levelOfAssurance || "Not defined"} />
              </ListItem>
              <Divider />
              <ListItem>
                <ListLabel title={"Operator"} value={data.details.operator || "Not defined"} />
              </ListItem>
              <Divider />
              <ListItem>
                <ListLabel title={"Threshold"} value={`${data.details.threshold}` || "Not defined"} />
              </ListItem>
              {data.details.endpointResult && (
                <>
                  <Divider />
                  <ListItem>
                    <div className={classes.bboxAndLandmarksContainer}>
                      <span className={classes.title}>Endpoint result:</span>
                      <JSONTree hideRoot={true} data={data.details.endpointResult} />
                    </div>
                  </ListItem>
                </>
              )}
              {data.item.laplacianMean && data.item.laplacianVariance && data.item.laplacianTime && (
                <>
                  <Divider />
                  <ListItem>
                    <div className={classes.bboxAndLandmarksContainer}>
                      <span className={classes.title}>
                        <b>{"Laplacian result:"}</b>
                      </span>
                      <ListLabel title={"Mean"} value={data.item.laplacianMean.toString()} />
                      <ListLabel title={"Variance"} value={data.item.laplacianVariance.toString()} />
                      <ListLabel
                        title={"Score (Variance/mean)"}
                        value={(data.item.laplacianVariance / data.item.laplacianMean).toString()}
                      />
                      <ListLabel title={"Processing time"} value={data.item.laplacianTime.toString() + " ms"} />
                    </div>
                  </ListItem>
                </>
              )}
              {data.item.brightnessScore && data.item.brightnessDuration && (
                <>
                  <Divider />
                  <ListItem>
                    <div className={classes.bboxAndLandmarksContainer}>
                      <span className={classes.title}>
                        <b>{"Image brightness:"}</b>
                      </span>
                      <ListLabel title={"Score"} value={data.item.brightnessScore.toString()} />
                      <ListLabel title={"Duration"} value={`${data.item.brightnessDuration} ms`} />
                    </div>
                  </ListItem>
                </>
              )}
              {data.item.brightnessScoreMask &&
                data.item.brightnessLowerMask &&
                data.item.brightnessUpperMask &&
                data.item.brightnessDurationMask && (
                  <>
                    <Divider />
                    <ListItem>
                      <div className={classes.bboxAndLandmarksContainer}>
                        <span className={classes.title}>
                          <b>{"Image brightness (Masks):"}</b>
                        </span>
                        <ListLabel title={"Score"} value={data.item.brightnessScoreMask.toString()} />
                        <ListLabel title={"Lower Mask"} value={data.item.brightnessLowerMask.toString()} />
                        <ListLabel title={"Upper Mask"} value={data.item.brightnessUpperMask.toString()} />
                        <ListLabel title={"Duration"} value={`${data.item.brightnessDurationMask} ms`} />
                      </div>
                    </ListItem>
                  </>
                )}
              {data.item.brightnessTooDarkThreshold && data.item.brightnessTooBrightThreshold && (
                <>
                  <Divider />
                  <ListItem>
                    <div className={classes.bboxAndLandmarksContainer}>
                      <span className={classes.title}>
                        <b>{"Score thresholds (Masks):"}</b>
                      </span>
                      <ListLabel title={"Too dark threshold"} value={data.item.brightnessTooDarkThreshold.toString()} />
                      <ListLabel
                        title={"Too bright threshold"}
                        value={data.item.brightnessTooBrightThreshold.toString()}
                      />
                    </div>
                  </ListItem>
                </>
              )}
              {data.item.brightnessLowerMaskThreshold && data.item.brightnessUpperMaskThreshold && (
                <>
                  <Divider />
                  <ListItem>
                    <div className={classes.bboxAndLandmarksContainer}>
                      <span className={classes.title}>
                        <b>{"Mask thresholds:"}</b>
                      </span>
                      <ListLabel
                        title={"Lower mask threshold"}
                        value={data.item.brightnessLowerMaskThreshold.toString()}
                      />
                      <ListLabel
                        title={"Upper mask threshold"}
                        value={data.item.brightnessUpperMaskThreshold.toString()}
                      />
                    </div>
                  </ListItem>
                </>
              )}
              {data.item.brightnessLowerMaskAcceptanceThreshold && data.item.brightnessUpperMaskAcceptanceThreshold && (
                <>
                  <Divider />
                  <ListItem>
                    <div className={classes.bboxAndLandmarksContainer}>
                      <span className={classes.title}>
                        <b>{"Mask acceptance thresholds:"}</b>
                      </span>
                      <ListLabel
                        title={"Lower mask acceptance threshold"}
                        value={data.item.brightnessLowerMaskAcceptanceThreshold.toString()}
                      />
                      <ListLabel
                        title={"Upper mask acceptance threshold"}
                        value={data.item.brightnessUpperMaskAcceptanceThreshold.toString()}
                      />
                    </div>
                  </ListItem>
                </>
              )}
              <ValidationResults
                validationResults={data.details.validationResults}
                brightnessValidationResult={{
                  croppedImgs: data.details.modelResult.cropping?.imgs,
                  croppedImgsValidations: data.details.croppedImgsValidations,
                }}
              />
            </List>
          </Paper>
        </Grid>
        {data.details.modelResult.mtcnn !== null && data.details.modelResult.cropping !== null && (
          <ModelResult modelsResult={data.details.modelResult} />
        )}
      </Grid>
      <Grid item xs={5} sm={10}>
        {data.details.metadata && (
          <Paper elevation={1} className={classes.deviceInfoPaper}>
            <span className={classes.device}>Device information</span>
            <JSONTree data={data.details.metadata.serialized} />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

interface ListLabelProps {
  title: string | JSX.Element;
  value: string;
}

export const ListLabel: React.FC<ListLabelProps> = (props) => {
  const classes = logDetailUseStyles();

  const { title, value } = props;

  return (
    <>
      <span className={classes.title}>{title}</span>
      <span className={classes.value}>{value}</span>
    </>
  );
};

interface MetadataListProps {
  title: string;
  metadata: ImageMetadata;
  format: ImageExtensionFormat;
  compression: number;
}

export const MetadataList: React.FC<MetadataListProps> = (props) => {
  const { title, metadata, format, compression } = props;
  const classes = logDetailUseStyles();
  return (
    <>
      <ListItem>
        <Grid item container xs={12}>
          <span className={classes.title}>
            <b>{title}:</b>
          </span>
          <Grid item>
            <ListLabel title={"Image width"} value={metadata.width.toString()} />
          </Grid>
          <Grid item>
            <ListLabel title={"Image height"} value={metadata.height.toString()} />
          </Grid>
          <Grid item>
            <ListLabel title={"Image size"} value={(metadata.size / 1024).toFixed(3) + " Kb"} />
          </Grid>
          {format && (
            <Grid item>
              <ListLabel title={"Extension format"} value={format.toUpperCase()} />
            </Grid>
          )}
          {format === ImageExtensionFormat.JPEG && compression && (
            <Grid item>
              <ListLabel title={"JPEG compression"} value={compression.toString()} />
            </Grid>
          )}
        </Grid>
      </ListItem>
      <Divider />
    </>
  );
};
