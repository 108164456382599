import {} from "@material-ui/core/styles";

import { ModelLabelProps, ModelsConfig } from "../../../config/modelsConfig";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import { GlobalContext } from "../../../globalContext";
import { LogPrediction } from "../../../models/log";
import { PredictionIcon } from "../../Utils/PredictionIcon";
import React from "react";
import { TooltipError } from "../../Utils/TooltipError";
import Typography from "@material-ui/core/Typography";
import { getModelTypeName } from "../../../models/utils";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: "4px",
      display: "flex",
      alignItems: "center",
    },
    modelPredictionTitleAS: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.common.black,
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "14px",
      },
    },
    modelPredictionTitleOM: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black,
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    modelPredictionValue: {
      fontSize: "14px",
      marginLeft: 6,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.common.black,
    },
    predictionIcon: {
      margin: "2px",
      display: "flex",
      alignItems: "center",
    },
    icon: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
      },
    },
  }),
);

export const ModelResult: React.FC<ModelLabelProps> = (props) => {
  const classes = useStyles(props);
  const { panelConfigurationData } = React.useContext(GlobalContext);
  const theme = useTheme();
  const mobileWidth = useMediaQuery(theme.breakpoints.down("xs"));

  if (!props.config || (!props.config.percentage && !props.config.text)) {
    return null;
  }
  const predictionLabelInfo = ModelsConfig[props.modelType] ? ModelsConfig[props.modelType].Prediction : null;
  if (!predictionLabelInfo) {
    return null;
  }

  const antiSpoofingModels = panelConfigurationData.predictionConfiguration.predictionResult
    .filter((m) => m.antiSpoofing)
    .map((m) => m.model);

  const prediction = antiSpoofingModels.includes(props.modelType)
    ? (props.result as LogPrediction).prediction
    : undefined;

  return (
    <div className={classes.root}>
      {prediction && (
        <PredictionIcon className={classes.predictionIcon} prediction={prediction} fontSize={mobileWidth ? 15 : 25} />
      )}
      <Typography
        color="textSecondary"
        className={
          antiSpoofingModels.includes(props.modelType) ? classes.modelPredictionTitleAS : classes.modelPredictionTitleOM
        }
      >
        {getModelTypeName(props.modelType)}:
      </Typography>
      {!props.result.error ? (
        <predictionLabelInfo.Component
          modelType={props.modelType}
          result={props.result}
          config={props.config}
          formula={props.formula}
        />
      ) : (
        <TooltipError title={"TENSORFLOW MODEL ERROR"} error={props.result.error} />
      )}
    </div>
  );
};
