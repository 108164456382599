import { ApiBase } from "./base";
import { Sniffer } from "../models/utils";

export class SnifferService extends ApiBase {
  getAll = (): Promise<Sniffer[]> => {
    return this.fetch<Sniffer[]>("sniffers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
}
