import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import React from "react";
import Switch from "@material-ui/core/Switch";
import { TooltipHelp } from "./TooltipHelp";
import { Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";

interface Props {
  title: string;
  tooltipMessage?: string;
  checked: boolean;
  onChange: (checked: boolean, index?: number) => void;
  variant?: Variant;
  index?: number; // used for boolean arrays
  disabled?: boolean;
}

export const SwitchElement: React.FC<Props> = (props) => {
  const { title, tooltipMessage, variant } = props;

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: { display: "flex", flexDirection: "row", alignItems: "center" },
      switch: { marginTop: "-9px", paddingLeft: "8px" },
      tooltip: {
        paddingBottom: theme.typography[variant ?? "body1"].fontSize,
        paddingLeft: "1px",
        color: theme.palette.text.secondary,
      },
    }),
  )();

  return (
    <div className={classes.root}>
      <Typography variant={variant ?? "body1"} color="textSecondary" gutterBottom>
        <>{title}</>
      </Typography>
      {tooltipMessage && <TooltipHelp className={classes.tooltip} title={tooltipMessage} />}
      <div className={classes.switch}>
        <Switch
          checked={props.checked}
          color="primary"
          onChange={(e) => props.onChange(e.target.checked, props.index)}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};
