import { BrightnessInfo, BrightnessInfoMask, CameraInfo, FCMResult } from "../../../models/utils";
import { ExpectedBrightness, ExpectedLocation, ExpectedResult } from "../../../models/log";
import { FaceResult, PhotoInfo, PredictionFormulaResult, TinyBbox } from "../../../models/prediction";
import { ImageExtensionFormat, Picture } from "../../../models/image";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ResultRescale, croppedPicture } from "../../../utils/cropping";
import { SnackbarProvider, WithSnackbarProps } from "notistack";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { getMaxHeight, updateCanvasSize } from "../../../utils/responsive";

import { AIClientModule } from "./AiClient/AIClientModule";
import { Button } from "../../Utils/Button";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Conditional } from "../../Utils/Conditional";
import Config from "../../../config";
import Device from "../../../config/device";
import { FaceCaptureModule } from "./FaceCaptureModule";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { GlobalContext } from "../../../globalContext";
import { IconButton } from "@material-ui/core";
import { PredictionResult as PredictionResultComponent } from "../PredictionResult";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { SelfieCaptureLoader } from "./SelfieCapture";
import { SelfieCaptureType } from "../../../models/config";
import { SessionContentResult } from "../../../models/aiClient";
import { TensorflowPrediction } from "../../../api/tensorflow_prediction";

const service = new TensorflowPrediction(Config.apiUrl);

interface Props {
  expectedResult?: ExpectedResult;
  expectedLocation?: ExpectedLocation;
  expectedBrightness?: ExpectedBrightness;
  camera: MediaDeviceInfo;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "50px",
    },
    imageContainer: {
      textAlign: "center",
      marginTop: "16px",
    },
    imageRadioButton: {
      display: "inline-block",
    },
    imagesContainer: {
      textAlign: "center",
    },
    imageContainerOriginalPicture: {
      textAlign: "center",
      marginTop: "16px",
      display: "none",
    },
    imagesContainerOriginalPicture: {
      textAlign: "center",
      display: "none",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    faceCaptureModule: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "30px",
      [theme.breakpoints.down("md")]: {
        "& div div": {
          maxWidth: "80vw",
          borderRadius: "20px",
        },
      },
      [theme.breakpoints.up("md")]: {
        "& div div": {
          maxWidth: "50vw",
          borderRadius: "20px",
        },
      },
    },
    snackbarClose: {
      color: theme.palette.common.white,
    },
  }),
);

export const PredictionLoader: React.FC<Props> = (props) => {
  const { expectedResult, expectedLocation, expectedBrightness, camera } = props;

  const classes = useStyles();

  const canvasOriginal = useRef<HTMLCanvasElement>(null);
  const canvasCropped = useRef<HTMLCanvasElement>(null);
  const [srcPicture, setSrcPicture] = useState<string>("");
  const [croppedBBox, setCroppedBBox] = useState<TinyBbox>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [bBox, setBBox] = useState<TinyBbox>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [info, setInfo] = useState<CameraInfo>();
  const [face, setFace] = useState<FaceResult | undefined>(undefined);
  const [image, setImage] = useState<Picture>(Picture.None);
  const [croppedHeight, setCroppedHeight] = useState<number | undefined>(0);

  const [sessionImages, setSessionImages] = useState<number | undefined>();
  const [spoofedImages, setSpoofedImages] = useState<SessionContentResult[] | undefined>();

  const { panelConfigurationData } = React.useContext(GlobalContext);

  const upMarginPercentageCropping: number = panelConfigurationData.scanConfiguration.upMarginPercentageCropping;
  const downMarginPercentageCropping: number = panelConfigurationData.scanConfiguration.downMarginPercentageCropping;
  const leftMarginPercentageCropping: number = panelConfigurationData.scanConfiguration.leftMarginPercentageCropping;
  const rightMarginPercentageCropping: number = panelConfigurationData.scanConfiguration.rightMarginPercentageCropping;
  const selectedInputPicture: Picture = panelConfigurationData.scanConfiguration.selectedInputPicture;

  const usingFaceCaptureModule =
    panelConfigurationData.scanConfiguration.selfieCaptureType === SelfieCaptureType.FaceCaptureModule;
  const usingAIClientModule =
    panelConfigurationData.scanConfiguration.selfieCaptureType === SelfieCaptureType.AIClientModule;

  const onSubmitImage = async (
    originalPic?: string,
    box?: TinyBbox,
    score?: number,
    brightness?: BrightnessInfo,
    brightnessMask?: BrightnessInfoMask,
  ) => {
    const img = originalPic ? originalPic : srcPicture;
    const pictureToSend =
      usingFaceCaptureModule || usingAIClientModule ? Picture.OriginalPicture : image ? image : selectedInputPicture;
    const originalPhoto: PhotoInfo = {
      image: img,
      tinyFaceInfo: {
        bBox: box ? box : bBox,
        score: score ? score : 0,
      },
    };
    let croppedImg = "";
    let croppedPhoto: PhotoInfo = {};

    if (
      canvasCropped &&
      canvasCropped.current &&
      !usingFaceCaptureModule &&
      !usingAIClientModule &&
      selectedInputPicture === "optional"
    ) {
      croppedImg =
        panelConfigurationData.scanConfiguration.imageExtensionFormat === ImageExtensionFormat.PNG
          ? canvasCropped.current.toDataURL("image/png")
          : canvasCropped.current.toDataURL(
              "image/jpeg",
              panelConfigurationData.scanConfiguration.imageJPEGCompression,
            );
      croppedPhoto = {
        image: croppedImg,
        tinyFaceInfo: {
          bBox: croppedBBox,
          score: 0,
        },
      };
    }

    service
      .checkImg({
        originalPhoto: originalPhoto,
        device: { ...Device, cameraInfo: info },
        fromCamera: 1,
        expectedResult: expectedResult ? expectedResult : undefined,
        tag: undefined,
        croppedPhoto: croppedPhoto,
        pictureUsed: pictureToSend,
        imageExtensionFormat: panelConfigurationData.scanConfiguration.imageExtensionFormat,
        imageJPEGCompression:
          panelConfigurationData.scanConfiguration.imageExtensionFormat === ImageExtensionFormat.JPEG
            ? panelConfigurationData.scanConfiguration.imageJPEGCompression
            : undefined,
        expectedLocation: expectedLocation ? expectedLocation : undefined,
        expectedBrightness: expectedBrightness ? expectedBrightness : undefined,
        brightness,
        brightnessMask,
      })
      .then((res) => {
        setFace({
          id: res.id,
          img: pictureToSend === Picture.OriginalPicture ? img : croppedImg,
          modelPredictionResultList: res.modelPredictionResultList,
          loading: false,
          formulaResult: res.formula,
          result: res.result,
        });
      })
      .catch((res) => {
        setFace({
          img: pictureToSend === Picture.OriginalPicture ? img : croppedImg,
          loading: false,
          formulaResult: [],
          error: res.message,
        });
      });
  };

  const onFaceDetected = async (
    img: string,
    info: CameraInfo,
    bbox: TinyBbox,
    pictureHeight: number,
    pictureWidth: number,
    score: number,
  ) => {
    setSrcPicture(img);
    setInfo(info);
    setBBox(bbox);
    if (canvasOriginal && canvasOriginal.current && canvasCropped && canvasCropped.current) {
      const resultRescale: ResultRescale = {
        rescaledBBox: bbox,
        pictureWidth: pictureWidth,
        pictureHeight: pictureHeight,
      };
      const cropped = await croppedPicture(
        img,
        resultRescale,
        canvasOriginal.current,
        canvasCropped.current,
        upMarginPercentageCropping,
        downMarginPercentageCropping,
        leftMarginPercentageCropping,
        rightMarginPercentageCropping,
      );
      setCroppedBBox(cropped.croppedBbox);
      setCroppedHeight(cropped.croppedHeight);
      if (selectedInputPicture === "original" && cropped.originalPicture) {
        onSubmitImage(img, bbox, score);
        setFace({
          id: -1,
          img: img,
          loading: true,
          formulaResult: [],
        });
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    let croppedImg = "";

    if (canvasCropped && canvasCropped.current) {
      croppedImg =
        panelConfigurationData.scanConfiguration.imageExtensionFormat === ImageExtensionFormat.PNG
          ? canvasCropped.current.toDataURL("image/png")
          : canvasCropped.current.toDataURL(
              "image/jpeg",
              panelConfigurationData.scanConfiguration.imageJPEGCompression,
            );
    }
    onSubmitImage();
    setFace({
      id: -1,
      img: image === Picture.OriginalPicture ? srcPicture : croppedImg,
      loading: true,
      formulaResult: [],
    });
  };

  useEffect(() => {
    const resizeCanvas = () =>
      updateCanvasSize(
        canvasOriginal.current ? getMaxHeight(canvasOriginal.current.style.maxHeight) : 0,
        canvasOriginal.current ? canvasOriginal.current : undefined,
      );
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  useEffect(() => {
    if (croppedHeight) {
      const resizeCanvas = () =>
        updateCanvasSize(croppedHeight, canvasCropped.current ? canvasCropped.current : undefined, true);
      resizeCanvas();
      window.addEventListener("resize", resizeCanvas);
      return () => window.removeEventListener("resize", resizeCanvas);
    }
  }, [croppedHeight]);

  // Face capture module
  const [faceCaptureModuleError, setFaceCaptureModuleError] = useState<Error>();

  const onFaceCaptureModuleScan = (result: FCMResult) => {
    setFace({
      id: -1,
      img: result.image,
      loading: true,
      formulaResult: [],
    });
    onSubmitImage(
      result.image,
      { x: result.bbox.x, y: result.bbox.y, width: result.bbox.width, height: result.bbox.height },
      result.score,
      result.brightnessInfo,
      result.brightnessInfoMask,
    );
  };

  const notistackRef = React.createRef<WithSnackbarProps>();
  const onClickDismiss = (key: string | number) => () => {
    const snackbar = notistackRef.current;
    if (snackbar) {
      snackbar.closeSnackbar(key);
    }
  };

  const isSpoof = useCallback(
    (image: SessionContentResult): boolean => {
      switch (expectedResult) {
        case ExpectedResult.Real:
          return (
            image.prediction === PredictionFormulaResult.Fake ||
            image.prediction === PredictionFormulaResult.Undetermined
          );
        case ExpectedResult.Unknown:
          return false;
        default:
          //All attacks are suposed to be fake results ideally.
          return image.prediction === PredictionFormulaResult.Real;
      }
    },
    [expectedResult],
  );

  const setFeedback = useCallback(
    (predictions: SessionContentResult[]) => {
      setSessionImages(predictions.length);
      setSpoofedImages(predictions.filter((image) => isSpoof(image)));
    },
    [isSpoof],
  );

  const onAIClientModuleScan = useCallback(
    (
      res: PredictionFormulaResult,
      img?: string,
      sessionId?: string,
      requestId?: string,
      sessionContent?: SessionContentResult[],
    ) => {
      // Do not trigger a prediction because we already have the
      // result from the AI client module.
      if (sessionContent) setFeedback(sessionContent);

      setFace({
        id: 1,
        img: img ? img : "",
        loading: false,
        formulaResult: [],
        result: res,
        sessionId: sessionId,
        requestId: requestId,
      });
    },
    [setFeedback],
  );

  return (
    <div className={classes.root}>
      {face ? (
        <PredictionResultComponent face={face} sessionImages={sessionImages} sessionSpoofs={spoofedImages} />
      ) : srcPicture ? (
        <>
          <div
            className={
              selectedInputPicture === "original" ? classes.imagesContainerOriginalPicture : classes.imagesContainer
            }
          >
            <canvas ref={canvasOriginal} style={{ maxWidth: "auto", maxHeight: "350px" }}></canvas>
            <canvas ref={canvasCropped}></canvas>
          </div>
          <div
            className={
              selectedInputPicture === "original" ? classes.imageContainerOriginalPicture : classes.imageContainer
            }
          >
            <div className={classes.radioGroup}>
              <RadioGroup value={image} onChange={(e) => setImage(e.target.value as Picture)} row>
                <FormControlLabel
                  value={Picture.OriginalPicture}
                  control={<Radio color="primary" />}
                  label="Original"
                  labelPlacement="top"
                  className={classes.imageRadioButton}
                />
                <FormControlLabel
                  value={Picture.CroppedPicture}
                  control={<Radio color="primary" />}
                  label="Cropped"
                  labelPlacement="top"
                  className={classes.imageRadioButton}
                />
              </RadioGroup>

              {image !== Picture.None && (
                <Button
                  variant="contained"
                  onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => handleSubmit(e)}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </>
      ) : panelConfigurationData.scanConfiguration.selfieCaptureType === SelfieCaptureType.FaceCaptureModule ? (
        <Conditional error={faceCaptureModuleError} errorMessage="Error in the Face Capture Module">
          <div className={classes.faceCaptureModule}>
            <FaceCaptureModule onScan={onFaceCaptureModuleScan} onError={(e) => setFaceCaptureModuleError(e)} />
          </div>
        </Conditional>
      ) : panelConfigurationData.scanConfiguration.selfieCaptureType === SelfieCaptureType.AIClientModule ? (
        <Conditional error={faceCaptureModuleError} errorMessage="Error in the Face Capture Module">
          <div className={classes.faceCaptureModule}>
            <AIClientModule
              expectedResult={expectedResult}
              expectedLocation={expectedLocation}
              expectedBrightness={expectedBrightness}
              onScan={onAIClientModuleScan}
              onError={(e) => setFaceCaptureModuleError(e)}
            />
          </div>
        </Conditional>
      ) : (
        <SnackbarProvider
          maxSnack={2}
          preventDuplicate={true}
          ref={notistackRef}
          action={(key: string | number) => (
            <IconButton className={classes.snackbarClose} onClick={onClickDismiss(key)}>
              <CloseRoundedIcon />
            </IconButton>
          )}
        >
          <SelfieCaptureLoader camera={camera} onFaceDetected={onFaceDetected} />
        </SnackbarProvider>
      )}
    </div>
  );
};
