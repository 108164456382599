import { CancelRounded, CheckCircleRounded } from "@material-ui/icons";
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { GetSnifferAllowedFromOrg, Organisation } from "../../models/organisation";
import React, { useCallback, useEffect, useState } from "react";

import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { Button } from "../Utils/Button";
import Config from "../../config";
import { CopyIconButton } from "../Utils/CopyIconButton";
import { CreateAndEditOrganisationDialog } from "./CreateAndEditOrganisationDialog";
import { Endpoint } from "../../models/endpoint";
import { EndpointService } from "../../api/endpoint";
import { GlobalContext } from "../../globalContext";
import Moment from "moment";
import { OrganisationService } from "../../api/organisation";
import { Sniffer } from "../../models/utils";
import { SnifferService } from "../../api/sniffer";
import { TablePaginationActions } from "../Utils/TablePaginationActions";

const organisationService = new OrganisationService(Config.apiUrl);
const snifferService = new SnifferService(Config.apiUrl);
const endpointService = new EndpointService(Config.apiUrl);

export enum DialogVariant {
  CREATE = "Create",
  EDIT = "Edit",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
      padding: 50,
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      flexDirection: "row",
      display: "flex",
      justifyContent: "flex-end",
    },
    toolbarTitle: {
      flex: "1 1 100%",
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
      fontSize: 18,
    },
    buttonContainer: {
      minWidth: 242,
    },
    iconSuccess: {
      color: theme.palette.success.main,
    },
    iconError: {
      color: theme.palette.error.main,
    },
    tableCell: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100px",
      whiteSpace: "nowrap",
    },
    tableCellHeader: {
      lineHeight: "1.2 !important",
    },
    pagination: {
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
      margin: "10px 0px",
    },
    tableContainer: {
      [theme.breakpoints.down("sm")]: {
        maxHeight: "55vh",
      },
      maxHeight: "65vh",
    },
  }),
);

export const OrganisationManagement: React.FC = () => {
  const classes = useStyles();

  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [organisationSelected, setOrganisationSelected] = useState<Organisation>();
  const [dialogVariant, setDialogVariant] = useState<DialogVariant | undefined>();
  const [sniffers, setSniffers] = useState<Sniffer[]>([]);
  const { setFeedback } = React.useContext(GlobalContext);
  const [endpoints, setEndpoints] = useState<Endpoint[]>([]);
  // pagination
  const [page, setPage] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getOrganisations = useCallback(() => {
    organisationService
      .getAll(page, rowsPerPage)
      .then((res) => {
        setRowCount(res.count);
        setOrganisations(res.orgs);
      })
      .catch((e) =>
        setFeedback({
          variant: "error",
          message: `Could not get organisations from database: ${e.message}`,
        }),
      );
  }, [page, rowsPerPage, setFeedback]);

  useEffect(() => {
    endpointService
      .getAll(0, 1000)
      .then((res) => setEndpoints(res.page))
      .catch((err) => setFeedback({ variant: "error", message: err }));
  }, [setFeedback]);

  useEffect(() => {
    getOrganisations();
  }, [getOrganisations]);

  useEffect(() => {
    snifferService
      .getAll()
      .then((allSniffers) => setSniffers(allSniffers))
      .catch(() => {
        setFeedback({
          variant: "error",
          message: `Failed to get the list of sniffers`,
          horizontal: "right",
        });
      });
  }, [setFeedback]);

  const createOrganisation = (org: Organisation) => {
    const { name } = org;
    organisationService
      .create(org)
      .then(() => {
        getOrganisations();
        setFeedback({
          variant: "success",
          message: `Organisation ${name} added successfully!`,
        });
        setDialogVariant(undefined);
        setOrganisationSelected(undefined);
      })
      .catch((e) => {
        setFeedback({
          variant: "error",
          message: `Organisation ${name} could not be added: ${e.message}`,
        });
      });
  };

  const updateOrganisation = (newOrganisation: Organisation) => {
    organisationService
      .update(newOrganisation)
      .then(() => {
        getOrganisations();
        setFeedback({
          variant: "success",
          message: `Organisation ${newOrganisation.name} updated successfully!`,
        });
        setDialogVariant(undefined);
        setOrganisationSelected(undefined);
      })
      .catch((e) => {
        setFeedback({
          variant: "error",
          message: `Organisation ${newOrganisation.name} could not be updated: ${e.message}`,
        });
      });
  };

  const clickRow = (id: number) => {
    setOrganisationSelected(organisations.find((o) => o.id === id));
    setDialogVariant(DialogVariant.EDIT);
  };

  // TODO: AITOOL-1055: Refactor PredictionColor and PredictionIcon from /Utils to be used
  // in different contexts like here
  const getIcon = (value: boolean) => {
    if (value) {
      return <CheckCircleRounded className={classes.iconSuccess} />;
    }
    return <CancelRounded className={classes.iconError} />;
  };

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <div className={classes.root}>
      <Paper elevation={5}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.toolbarTitle} color="inherit" component="div">
            Organisations management
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<AddCircleRoundedIcon />}
              onClick={() => setDialogVariant(DialogVariant.CREATE)}
            >
              Add new organisation
            </Button>
          </div>
        </Toolbar>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>ID</TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Name
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Daily requests (used/max)
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Total requests (used/max)
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Creation date
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Token
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Sniffer list
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Expiration (h)
                </TableCell>
                <TableCell className={classes.tableCellHeader} align="center">
                  Banned
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organisations.map((organisation) => (
                <TableRow hover key={organisation.id}>
                  <TableCell component="th" scope="row" onClick={() => clickRow(organisation.id)}>
                    {organisation.id}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(organisation.id)}>
                    {organisation.name}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(organisation.id)}>
                    {organisation.maxRequestsPerDay
                      ? `${organisation.dailyRequestsUsed}/${organisation.maxRequestsPerDay}`
                      : organisation.dailyRequestsUsed}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(organisation.id)}>
                    {organisation.maxRequestsTotal
                      ? `${organisation.totalRequestsUsed}/${organisation.maxRequestsTotal}`
                      : organisation.totalRequestsUsed}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(organisation.id)}>
                    {Moment.unix(organisation.creationDate / 1000000000).format("lll")}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(organisation.id)}>
                    {organisation.token}
                    <CopyIconButton title={"Token"} text={organisation.token}></CopyIconButton>
                  </TableCell>
                  {organisation.sniffersConfig ? (
                    <Tooltip
                      disableHoverListener={!organisation.sniffersConfig?.some((x) => x.allowed)}
                      title={
                        <>
                          {sniffers
                            .filter((sniffer) => GetSnifferAllowedFromOrg(sniffer, organisation.sniffersConfig))
                            .map((sniffer) => {
                              return sniffer.name;
                            })
                            .join(", ")}
                        </>
                      }
                    >
                      <TableCell align="center" className={classes.tableCell} onClick={() => clickRow(organisation.id)}>
                        {sniffers
                          .filter((sniffer) => GetSnifferAllowedFromOrg(sniffer, organisation.sniffersConfig))
                          .map((sniffer) => {
                            return sniffer.name;
                          })
                          .join(", ")}
                      </TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell align="center" onClick={() => clickRow(organisation.id)}>
                      -
                    </TableCell>
                  )}
                  <TableCell align="center" onClick={() => clickRow(organisation.id)}>
                    {organisation.expirationHours ? organisation.expirationHours : "-"}
                  </TableCell>
                  <TableCell align="center" onClick={() => clickRow(organisation.id)}>
                    {getIcon(organisation.banned)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(_, p) => setPage(p)}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>

      {dialogVariant === DialogVariant.CREATE && (
        <CreateAndEditOrganisationDialog
          open={!!dialogVariant}
          handleClose={() => setDialogVariant(undefined)}
          onConfirm={createOrganisation}
          variant={DialogVariant.CREATE}
          title="Add new organisation"
          confirmButtonText="Create"
          endpoints={endpoints}
        />
      )}

      {dialogVariant === DialogVariant.EDIT && (
        <CreateAndEditOrganisationDialog
          open={!!dialogVariant}
          handleClose={() => setDialogVariant(undefined)}
          onConfirm={updateOrganisation}
          item={organisationSelected}
          variant={DialogVariant.EDIT}
          title={`Edit organisation: ${organisationSelected?.name}`}
          confirmButtonText="Update"
          endpoints={endpoints}
        />
      )}
    </div>
  );
};
