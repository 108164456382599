import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";

import React from "react";
import { camelCaseToHuman } from "../../utils/strings";

export const statColumns = ["total", "real", "displayAttack", "printAttack", "maskAttack", "unknown"] as const;

export type StatColumn = typeof statColumns[number];

export type RowData = Record<StatColumn, number>;

export interface Row {
  title: string;
  percentage?: boolean;
  data: RowData;
}

interface Props {
  rows: Row[];
}

const useStyles = makeStyles(() =>
  createStyles({
    tableCell: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "30px",
      whiteSpace: "nowrap",
    },
    divider: {
      marginBottom: "30px",
    },
  }),
);

/**
 * Returns the color the a row will use in first column where the title is
 * located.
 *
 * @param theme Application theme.
 * @param propertyName The property name for a row in stats table.
 */
const getRowTitleColor = (theme: Theme, propertyName: string) => {
  const themeMap: { [key: string]: string[] } = {};

  themeMap[theme.palette.error.main] = ["fake", "incorrect"];
  themeMap[theme.palette.success.main] = ["real", "correct"];

  for (const key in themeMap) {
    if (themeMap[key].find((item) => propertyName.toLowerCase().includes(item))) {
      return key;
    }
  }
  return theme.palette.text.secondary;
};

export const StatTable: React.FC<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const getValue = (percentage: boolean, value: number) => {
    if (!percentage) {
      return value;
    }
    return value === 0 ? "-" : `${(value * 100).toFixed(2)}%`;
  };

  return (
    <>
      {props.rows.length > 0 && (
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper} className={classes.divider}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {statColumns.map((c) => (
                    <TableCell key={c} align="center" className={classes.tableCell}>
                      {camelCaseToHuman(c)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.rows.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" style={{ color: getRowTitleColor(theme, row.title) }}>
                        {camelCaseToHuman(row.title)}
                      </TableCell>
                      {statColumns.map((c) => (
                        <TableCell key={c} component="th" scope="row">
                          {getValue(!!row.percentage, row.data[c])}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
};
