import { ConfigurationPanelSection } from "../models/config";
import { ExtraInfo } from "./models";
import { HeaderLink } from "../components/Header";
import { PluginConfiguration } from "../models/config";
import { Route } from "../components/Session";

export interface Plugin<T> {
  name(): string;
  routes(): Route[];
  headerLinks(): HeaderLink[];
  logDetailsExtraInfo(extraInfo: ExtraInfo): JSX.Element[];
  dashboardSections(): JSX.Element[];
  configPanelSection(
    pluginNumber: number,
    setSection: (newConfig: PluginConfiguration) => void,
  ): ConfigurationPanelSection<PluginConfiguration>;
}

export enum Plugins {
  MyFace = "myface",
}
