import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Button } from "./Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { GlobalContext } from "../../globalContext";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import React from "react";
import { SaveOutlined } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

export interface Option {
  label: string;
  callback: () => void;
  disabled: boolean;
  errorMessage: string;
}

interface Props {
  options: Option[];
}

export const SplitButton: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { downloadingImages } = React.useContext(GlobalContext);

  const { options } = props;

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // AITOOL-1834: create our custom button group component to not override the style of our buttons.
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <div ref={anchorRef}>
          <Button
            startIcon={downloadingImages ? <CircularProgress size={"20px"} /> : <SaveOutlined />}
            disabled={downloadingImages || options[selectedIndex].disabled}
            size="small"
            onClick={options[selectedIndex].callback}
            variant="outlined"
            color="primary"
          >
            {options[selectedIndex].disabled ? (
              <Tooltip title={options[selectedIndex].errorMessage} placement="top" arrow>
                <span>{options[selectedIndex].label}</span>
              </Tooltip>
            ) : (
              <>{options[selectedIndex].label}</>
            )}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            disabled={downloadingImages}
          >
            <ArrowDropDownIcon />
          </Button>
        </div>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        selected={index === selectedIndex}
                        onClick={() => handleMenuItemClick(index)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};
