import {
  Divider,
  Table as MaterialUITable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";

import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { Button } from "../Utils/Button";
import React from "react";
import { TablePaginationActions } from "../Utils/TablePaginationActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      flexDirection: "row",
      display: "flex",
      justifyContent: "flex-end",
    },
    toolbarTitle: {
      flex: "1 1 100%",
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
      fontSize: 18,
    },
    buttonContainer: {
      whiteSpace: "nowrap",
      textAlign: "center",
    },
    pagination: {
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
      margin: "10px 0px",
    },
    tableContainer: {
      [theme.breakpoints.down("sm")]: {
        maxHeight: "55vh",
      },
      maxHeight: "65vh",
    },
    tableRow: {
      cursor: "pointer",
    },
    tableCellHeader: {
      lineHeight: "1.2 !important",
    },
  }),
);

interface Props<T> {
  addButtonLabel?: string;
  columns: string[];
  count: number;
  items: T[];
  numItemsOption?: number[];
  onCreateClick: () => void;
  onPageChange: (page: number, numItems: number) => void;
  onSelectItem: (item: T) => void;
  page: number;
  renderRow: (item: T) => JSX.Element[]; // <TableCell>
  rowsPerPage: number;
  title: string;
}

export function Table<T>(props: Props<T>): React.ReactElement {
  const {
    addButtonLabel,
    columns,
    count,
    items,
    numItemsOption,
    onCreateClick,
    onPageChange,
    onSelectItem,
    page,
    renderRow,
    rowsPerPage,
    title,
  } = props;

  const classes = useStyles();

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numItems = +event.target.value;
    const page = 0; // Reset the page if the number of items changes
    onPageChange(page, numItems);
  };

  return (
    <Paper elevation={5}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.toolbarTitle} color="inherit" component="div">
          {title}
        </Typography>
        {addButtonLabel && (
          <div className={classes.buttonContainer}>
            <Button variant="contained" color="primary" endIcon={<AddCircleRoundedIcon />} onClick={onCreateClick}>
              {addButtonLabel}
            </Button>
          </div>
        )}
      </Toolbar>
      <TableContainer className={classes.tableContainer}>
        <MaterialUITable stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell key={index} className={classes.tableCellHeader}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow className={classes.tableRow} onClick={() => onSelectItem(item)} key={index}>
                {renderRow(item)}
              </TableRow>
            ))}
          </TableBody>
        </MaterialUITable>
      </TableContainer>
      <Divider />
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={numItemsOption || [5, 10, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_, p) => onPageChange(p, rowsPerPage)}
        onChangeRowsPerPage={handleRowsPerPageChange}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
  );
}
