import { createStyles, makeStyles } from "@material-ui/core/styles";

import React from "react";
import btnActive from "./btn_google_signin_light_pressed_web.png";
import btnFocus from "./btn_google_signin_light_focus_web.png";
import btnNormal from "./btn_google_signin_light_normal_web.png";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      cursor: "pointer",
      border: "none",
      backgroundColor: "white",
      backgroundImage: `url("${btnNormal}")`,
      outline: "-webkit-focus-ring-color none",
      "&:focus": {
        backgroundImage: `url("${btnFocus}")`,
      },
      "&:active": {
        backgroundImage: `url("${btnActive}")`,
      },
    },
  }),
);

interface Props {
  className: string;
  onClick: () => void;
}

export const Button: React.FC<Props> = ({ className, onClick }) => {
  const classes = useStyles();

  return <button type="button" className={`${classes.button} ${className}`} onClick={onClick} />;
};
