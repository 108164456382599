import { FileContent, b64toBlob, createAndDownloadZip } from "../../../utils/files";
import { LinearProgress, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { formatImg, getCroppedFaces } from "../../../utils/cropping";

import { Button } from "../../Utils/Button";
import { CheckboxListSelector } from "../../Utils/CheckboxListSelector";
import { FileInfo } from "../../../models/upload";
import { GlobalContext } from "../../../globalContext";
import { ImageExtensionFormat } from "../../../models/image";
import { TinyFace } from "../../../utils/tinyface";
import TransformIcon from "@material-ui/icons/Transform";
import { UploadButton } from "./UploadButton";

const validFormats: ImageExtensionFormat[] = [ImageExtensionFormat.PNG, ImageExtensionFormat.JPEG];
const validImageTypes: ("cropped" | "original")[] = ["original", "cropped"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 50,
      maxWidth: 500,
      margin: "auto",
    },
    paragraph: {
      textAlign: "justify",
    },
    feedback: {
      marginTop: 15,
      marginBottom: 15,
    },
    buttonGroup: {
      flexDirection: "row",
      display: "flex",
      marginTop: theme.spacing(1),
      "& button": {
        margin: "0px 5px",
      },
    },
  }),
);

export const Crop: React.FC = () => {
  const classes = useStyles();

  const { panelConfigurationData } = React.useContext(GlobalContext);
  const threshold = panelConfigurationData.scanConfiguration.tinyFaceDetectorThreshold;
  const {
    upMarginPercentageCropping,
    downMarginPercentageCropping,
    leftMarginPercentageCropping,
    rightMarginPercentageCropping,
  } = panelConfigurationData.scanConfiguration;
  const [formats, setFormats] = useState<ImageExtensionFormat[]>([]);
  const [imageTypes, setImageTypes] = useState<("cropped" | "original")[]>([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number>();
  const [files, setFiles] = useState<FileInfo[]>([]);

  const [feedback, setFeedback] = useState("");

  // Method that takes the list of files and create the zip result with all
  // images requested and download it.
  const createZip = async () => {
    setProgress(0);
    setFeedback("");
    const imgs: FileContent[] = [];
    const faceNotFound: string[] = [];

    // Loop over all files.
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setLoading(true);
      setFeedback(`Processing file ${file.name} (${i + 1}/${files.length})`);
      setProgress(((i + 1) * 100) / files.length);

      // For all formats requested.
      for (let j = 0; j < formats.length; j++) {
        const format = formats[j];
        const quality =
          format === ImageExtensionFormat.JPEG
            ? panelConfigurationData.scanConfiguration.imageJPEGCompression
            : undefined;

        // If include the cropped faces.
        if (imageTypes.indexOf("cropped") >= 0) {
          const croppedImgs = await getCroppedFaces(
            file,
            format,
            threshold,
            {
              top: upMarginPercentageCropping,
              bottom: downMarginPercentageCropping,
              right: rightMarginPercentageCropping,
              left: leftMarginPercentageCropping,
            },
            quality,
          );
          if (croppedImgs.length === 0 && faceNotFound.indexOf(file.name) < 0) {
            faceNotFound.push(file.name);
          } else if (croppedImgs.length > 0) {
            imgs.push(...croppedImgs.map((img) => ({ ...img, name: `cropped/${format}/${img.name}` })));
          }
        }

        // If include original image.
        if (imageTypes.indexOf("original") >= 0) {
          const img = await formatImg(file.base64content, format, quality);
          imgs.push({
            name: `original/${format}/${file.name.split(".").slice(0, -1).join(".")}.${format}`,
            content: b64toBlob(img),
          });
        }
      }
    }

    // Create and download zip file
    createAndDownloadZip("croppings.zip", imgs);

    // Notify about faces not found.
    if (faceNotFound.length > 0) {
      setFeedback(`Face not found for images: ${faceNotFound.join(", ")}`);
    }
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <TinyFace>
        <p className={classes.paragraph}>
          Upload pictures to apply the cropping and formats selected and download them in a single zip file. Keep in
          mind the browser will take long time if the number of files is too large.
        </p>
        <b>Formats:&nbsp;&nbsp;</b>
        <CheckboxListSelector list={validFormats} value={formats} onChange={setFormats} />
        <br />
        <b>Outputs:&nbsp;&nbsp;</b>
        <CheckboxListSelector list={validImageTypes} value={imageTypes} onChange={setImageTypes} />
        <br />
        <br />
        <b>Num files:&nbsp;&nbsp;</b>
        {files.length}
        <br />
        <div className={classes.buttonGroup}>
          <UploadButton multiple={true} onImagesSelected={setFiles} disabled={loading} />
          <Button
            onClick={() => createZip()}
            disabled={formats.length === 0 || imageTypes.length === 0 || files.length === 0 || loading}
            endIcon={<TransformIcon />}
            size="large"
            variant="contained"
          >
            Run
          </Button>
        </div>

        {feedback && <div className={classes.feedback}>{feedback}</div>}
        {progress && <LinearProgress variant="determinate" color="primary" value={progress} />}
      </TinyFace>
    </div>
  );
};
