export type StatsMap = Record<string, Stats>;

export interface StatType {
  title: string;
  stats: Stats;
  statClass: string;
}

export type Stats = Record<string, number>;

export type FilterType = "device_type" | "browser" | "os" | "image_extension_format";

export interface StatInfo {
  title: string;
  filter?: FilterType;
}

export enum ColumnStats {
  feedback = "feedback",
  predictionResult = "prediction_result",
}

export interface ToolUses {
  imagesSent: ToolStats;
}

interface ToolStats {
  total: number;
  lastHour: number;
  lastDay: number;
  lastWeek: number;
  lastMonth: number;
  lastYear: number;
}
