import { createStyles, makeStyles } from "@material-ui/core/styles";

import { AgePrediction } from "../../../../models/log";
import { ModelCellProps } from "../../../../config/modelsConfig";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    stDev: {
      color: theme.palette.primary.main,
      marginLeft: "1ch",
    },
    noResult: {
      width: "100%",
      textAlign: "center",
    },
  }),
);

export const AgeTableCell: React.FC<ModelCellProps> = (props) => {
  const classes = useStyles();
  const p = props.prediction as AgePrediction;
  return (
    <>
      {p.age !== null && p.stDev !== null ? (
        <>
          <span>{p.age.toFixed(2)}</span>
          <span className={classes.stDev}>({p.stDev.toFixed(3)})</span>
        </>
      ) : (
        <div className={classes.noResult}>-</div>
      )}
    </>
  );
};
