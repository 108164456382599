import { Grid, createStyles, makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { DateInput } from "../Utils/DateInput";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.main,
      height: 30,
    },
    divider: {
      marginBottom: "30px",
    },
  }),
);

interface DateFilterProps {
  fromDate: Date;
  toDate: Date;
  todayEnd: Date;
  lastWeek: Date;
  lastMonth: Date;

  setFromDate: (date: Date) => void;
  setToDate: (date: Date) => void;
}

export const DateFilter: React.FC<DateFilterProps> = (props) => {
  const classes = useStyles();
  const todayStart = new Date();

  return (
    <Grid item xs={12} className={classes.divider}>
      <Grid container direction="row" spacing={3} alignItems="center">
        <Grid item>
          <DateInput
            label="From"
            value={props.fromDate}
            onChange={(newDate) => newDate && props.setFromDate(newDate)}
          />
        </Grid>
        <Grid item>
          <DateInput label="To" value={props.toDate} onChange={(newDate) => newDate && props.setToDate(newDate)} />
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            size="small"
            value={props.fromDate}
            exclusive={true}
            onChange={(e, value) => {
              props.setFromDate(value);
              props.setToDate(props.todayEnd);
            }}
          >
            <ToggleButton className={classes.button} value={todayStart}>
              Today
            </ToggleButton>
            <ToggleButton className={classes.button} value={props.lastWeek}>
              Last week
            </ToggleButton>
            <ToggleButton className={classes.button} value={props.lastMonth}>
              Last month
            </ToggleButton>
            <ToggleButton className={classes.button} value={new Date(0)}>
              Total
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};
