import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Draggable } from "react-beautiful-dnd";
import React from "react";
import { Typography } from "@material-ui/core";
import { Value } from "./dragAndDrop";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    value: {
      padding: "8px",
      border: "solid 1px lightgrey",
      borderRadius: "2px",
      marginBottom: "8px",
      backgroundColor: theme.palette.common.white,
    },
    text: {
      fontSize: theme.typography.fontSize,
    },
  }),
);

interface Props<T> {
  value: Value<T>;
  index: number;
  order: boolean;
}

export function ValuePanel<T>(props: Props<T>): React.ReactElement<Props<T>> {
  const classes = useStyles();
  const { value, index, order } = props;
  return (
    <Draggable draggableId={value.id} index={index}>
      {(provided) => (
        <div
          className={classes.value}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Typography className={classes.text}>
            {order && index + 1 + "."}
            {value.name}
          </Typography>
        </div>
      )}
    </Draggable>
  );
}
