import { Theme, makeStyles } from "@material-ui/core/styles";

import React from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import clsx from "clsx";

interface ContainerProps extends Props {
  className?: string;
}

const containerUseStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

const MessageContainer: React.FC<ContainerProps> = (props) => {
  const classes = containerUseStyles(props);
  const { className, message } = props;

  return (
    <SnackbarContent
      className={clsx(classes[props.variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {message}
        </span>
      }
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  margin: {
    marginBottom: theme.spacing(2),
  },
  root: {
    minWidth: "0px !important",
  },
}));

interface Props {
  message: string;
  variant: "success" | "error";
}

export const Message: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <MessageContainer variant={props.variant} className={clsx(classes.root, classes.margin)} message={props.message} />
  );
};
