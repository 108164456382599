import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { AgeEstimationResult } from "../../../../models/log";
import Divider from "@material-ui/core/Divider";
import { GlobalContext } from "../../../../globalContext";
import { Line } from "react-chartjs-2";
import ListItem from "@material-ui/core/ListItem";
import { ListLabel } from "../LogDetail";
import { ModelResultProps } from "../../../../config/modelsConfig";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    models: {
      [theme.breakpoints.down(430)]: {
        fontSize: "4vw",
        display: "grid",
      },
    },
  }),
);

export const AgeEstimationDetails: React.FC<ModelResultProps> = (props) => {
  const { envSettings } = React.useContext(GlobalContext);

  function getIndexPlot(modelToCheck: string): number {
    const startIndex = envSettings.indexAgeEstimationPlot[modelToCheck];
    return startIndex ? startIndex : -9;
  }

  const modelResult = props.modelResult as AgeEstimationResult;
  const distLabels = modelResult.ageDistribution.map((item, index) => index + getIndexPlot(modelResult.checkpoint));
  const classes = useStyles(props);

  return (
    <>
      <ListItem className={classes.models}>
        <ListLabel title={"Age"} value={modelResult.age.toFixed(2).toString() + " years"}></ListLabel>
      </ListItem>
      <Divider />
      <ListItem className={classes.models}>
        <ListLabel title={"Standard desviation"} value={modelResult.stDev.toFixed(2).toString()}></ListLabel>
      </ListItem>
      <Divider />
      <ListItem>
        <Line
          data={{
            labels: distLabels,
            datasets: [
              {
                label: "Age distribution",
                fill: false,
                lineTension: 0.1,
                backgroundColor: "rgba(75,192,192,0.4)",
                borderColor: "rgba(75,192,192,1)",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "rgba(75,192,192,1)",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgba(75,192,192,1)",
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: modelResult.ageDistribution,
              },
            ],
          }}
        />
      </ListItem>
      <Divider />
    </>
  );
};
