import CircularProgress from "@material-ui/core/CircularProgress";
import { Message } from "../../components/Utils/Message";
import React from "react";

interface Props {
  error?: Error;
  errorMessage?: string;
  loading?: boolean;
}

export const Conditional: React.FC<Props> = (props) => {
  const { error, errorMessage, loading } = props;

  return error ? (
    <Message variant="error" message={errorMessage ? `${errorMessage}: ${error.message}` : error.message} />
  ) : loading ? (
    <>
      <CircularProgress /> Loading
    </>
  ) : (
    <>{props.children}</>
  );
};
